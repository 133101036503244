import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import createRoutes from './views/routes';
import { Provider } from 'react-redux';
import Store from './states/index';
import { createStore } from 'redux';
import Authentication from './resources/oauth/Authentication';

Authentication.inicializarInterceptos();

const routes = createRoutes();
const store = createStore(Store);

ReactDOM.render(<Provider store={store}>{routes}</Provider>, document.getElementById('root'));
