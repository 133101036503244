/* 
    Autor: Gabriela Farias
*/
import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Loading from '../../components/Loading/Loading';
import logo from '../../assets/images/novo_logo_RADAR.png';

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    zIndex: 1,
    height: 800,
    marginBottom: 0
  },
  image: {
    width: 300,
    objectFit: 'cover'
  }
};

/**
 * Tela com mensagem de Carregando custimizada
 */

const Carregando = props => {
  const { classes } = props;

  return (
    <div className={classes.container}>
      <Loading />
      <img src={logo} className={classes.image} alt='carregando' />
    </div>
  );
};

export default withRouter(withStyles(styles)(Carregando));
