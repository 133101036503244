import React from 'react';
import Usuarios from './Usuario/Usuarios';
import EditarUsuario from './Usuario/EditarUsuario';
import Clientes from './Clientes/Clientes';
import EditarCliente from './Clientes/EditarCliente';
import EditarQuestionario from './Questionarios/EditarQuestionario';
import Questionarios from './Questionarios/Questionarios';
import Demonstrativos from './Demonstrativos/Demonstrativos';
import Analise from './Demonstrativos/Analise';
import Perfil from './Perfil/Perfil';
import Dashboard from './Dashboard/Dashboard';
import Respostas from './Respostas/Respostas';
import LancarRespostas from './Respostas/LancarRespostas';
import Auditoria from './Auditoria/Auditoria';
import EditarAuditoria from './Auditoria/EditarAuditoria';

const routes = [
  {
    path: '/app',
    exact: true,
    main: () => <Dashboard />,
  },
  {
    path: '/app/perfil',
    main: () => <Perfil />,
  },
  {
    path: '/app/usuario',
    exact: true,
    main: () => <Usuarios />,
  },
  {
    path: '/app/usuario/editar/:id',
    main: () => <EditarUsuario />,
  },
  {
    path: '/app/usuario/novo',
    main: () => <EditarUsuario />,
  },
  {
    path: '/app/cliente',
    exact: true,
    main: () => <Clientes />,
  },
  {
    path: '/app/cliente/editar/:id',
    main: () => <EditarCliente />,
  },
  {
    path: '/app/cliente/novo',
    main: () => <EditarCliente />,
  },
  {
    path: '/app/questionarios',
    exact: true,
    main: () => <Questionarios />,
  },
  {
    path: '/app/questionarios/editar/:id',
    main: () => <EditarQuestionario />,
  },
  {
    path: '/app/questionarios/novo',
    main: () => <EditarQuestionario />,
  },
  {
    path: '/app/demonstrativos',
    exact: true,
    main: () => <Demonstrativos />,
  },
  {
    path: '/app/demonstrativos/analise/:id',
    exact: true,
    main: () => <Analise />,
  },
  {
    path: '/app/respostas',
    exact: true,
    main: () => <Respostas />,
  },
  {
    path: '/app/respostas/:id',
    main: () => <LancarRespostas />,
  },
  {
    path: '/app/auditoria',
    exact: true,
    main: () => <Auditoria />,
  },
  {
    path: '/app/auditoria/:id',
    exact: true,
    main: () => <EditarAuditoria />,
  },
];

export default routes;
