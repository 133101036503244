//@Autor Gabriela Farias

import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2BA69F'
    }
  },
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiInput: {
      underline: {
        '&:hover:not($disabled):not($focused):not($error):before': { borderBottomColor: '#2BA69F' }
      },
      formControl: {
        marginTop: '16px !important'
      }
    },
    MuiInputBase: {
      root: {
        fontSize: 14,
        fontFamily: 'Roboto'
      },
      input: {
        height: '20px !important'
      }
    }
  }
});

export default props => (
  <MuiThemeProvider theme={theme}>
    <Select {...props}>{props.children}</Select>
  </MuiThemeProvider>
);
