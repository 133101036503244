import React from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from '@sweetalert/with-react';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2BA69F'
    }
  },
  typography: {
    useNextVariants: true
  }
});

const loadingSwal = configs => {
  const { onMounted, config, title = 'Salvando' } = configs || {};

  return swal(
    (() => {
      onMounted && onMounted();
      return (
        <div>
          <MuiThemeProvider theme={theme}>
            <CircularProgress />
          </MuiThemeProvider>
        </div>
      );
    })(),
    {
      buttons: false,
      closeOnClickOutside: false,
      closeOnEsc: false,
      title,
      ...config
    }
  );
};

export default loadingSwal;
