import React, { Component } from 'react';
import CombTab from '../../components/CombTab/CombTab';
import Autocomplete from '../../components/AutoComplete/AutoComplete';
import Perguntas from './perguntas/Perguntas';
import Entrevistadores from './entrevistadores/Entrevistadores';
import { connect } from 'react-redux';
import { withRouter, Prompt } from 'react-router-dom';
import { Formik, FastField } from 'formik';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import DatePicker from '../../components/DatePicker/DatePicker';
import Regras from './regras/Regras';
import SwalConfirmacao from '../../utils/functions/SwalConfirmacao/SwalConfirmacao';
import FormSelectOwn from '../../components/FormSelectOwn/FormSelectOwn';
import { bindActionCreators } from 'redux';
import { updateToolbar, enableLoading } from '../../states/actions/ToolbarActions';
import SwalMessage from '../../utils/functions/SwalMessage/SwalMessage';
import CardIcon from '../../components/CardIcon/CardIcon';
import * as moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import ButtonFABMenu from '../../components/ButtonFABMenu/ButtonFABMenu';
import { getAllClientes } from '../../resources/api/cliente';
import clone from '../../utils/functions/Clone/Clone';
import TextField from '../../components/TextFieldOwn/TextFieldOwn';
import Excecoes from './excecoes/Excecoes';
import loadingSwal from '../../utils/functions/LoadingSwal/LoadingSwal';
import {
  showButtonFAB,
  defaultEntrevistadores,
  defaultPerguntas,
  defaultRegras,
  changeListaPerguntas,
  changeListaRegras,
  changeEntrevistadores,
  changeStatusQuestionario,
  changeAtualizandoQuestionario,
  setInitialIdAlternativa,
  changeClienteQuestionario,
  defaultExcecao,
  setIdQuestionario,
  defaultQuestionario
} from '../../states/actions/QuestionarioActions';
import {
  getQuestionarioById,
  insertQuestionario,
  updateQuestionario
} from '../../resources/api/questionario';

const style = theme => ({
  btnConfirmSwal: {
    backgroundColor: '#2BA69F !important',
    '&:hover': {
      backgroundColor: '#2BA69F !important'
    }
  }
});

class EditarQuestionario extends Component {
  constructor(props) {
    super(props);

    this.initialQuestionario = {
      idQuestionario: this.props.match.params.id,
      cliente: '',
      dtInicio: null,
      dtTermino: null,
      nrRespostas: '',
      dsTitulo: '',
      dsQuestionario: '',
      stQuestionario: 0
    };

    this.initialPerguntas = clone(this.props.perguntasData);
    this.initialEntrevistadores = clone(this.props.entrevistadoresData);
    this.initialRegras = clone(this.props.regrasData);

    this.state = {
      key: '',
      keyAutocomplete: '',
      tabSelected: 0,
      enableReinitialize: !!this.props.match.params.id,
      canLeave: false,
      clientes: [],
      questionario: clone(this.initialQuestionario)
    };

    const title = 'Cadastro de questionário';
    if (this.props.itensState.name !== title) {
      this.props.updateToolbar({ toolbar: title, name: title });
    }
    this.props.enableLoading(true);

    this._isMounted = false;

    this.isDirty = function () {
      return (
        JSON.stringify(this.initialQuestionario) !== JSON.stringify(this.values) ||
        JSON.stringify(this.initialPerguntas) !== JSON.stringify(this.props.perguntasData) ||
        JSON.stringify(this.initialEntrevistadores) !== JSON.stringify(this.props.entrevistadoresData) ||
        JSON.stringify(this.initialRegras) !== JSON.stringify(this.props.regrasData) ||
        this.props.editandoPergunta ||
        this.props.editandoEntrevistador ||
        this.props.editandoExcecao ||
        this.props.editandoRegra ||
        this.props.importandoPerguntas
      );
    }.bind(this);

    window.onbeforeunload = function () {
      if (this.isDirty()) {
        return true;
      }
    }.bind(this);

    this.handleSubmit = null;
    this.setFieldValue = null;
    this.values = null;

    this.submit = this.submit.bind(this);
    this.onChangeAutocomplete = this.onChangeAutocomplete.bind(this);
    this.loadSuggestionsAutocomplete = this.loadSuggestionsAutocomplete.bind(this);
    this.tratarValueAutocomplete = this.tratarValueAutocomplete.bind(this);
    this.onTrySubmit = this.onTrySubmit.bind(this);
    this.onChangeTab = this.onChangeTab.bind(this);
    this.onChangeStatusQuestionario = this.onChangeStatusQuestionario.bind(this);
    this.iniciarAtributos = this.iniciarAtributos.bind(this);
    this.onTryExit = this.onTryExit.bind(this);
    this.disableOptionStQuestionarioIf = this.disableOptionStQuestionarioIf.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.iniciarAtributos();
    this.props.showButtonFAB();
    this.loadSuggestionsAutocomplete();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.defaultEntrevistadores();
    this.props.defaultPerguntas();
    this.props.defaultRegras();
    this.props.defaultExcecao();
    this.props.defaultQuestionario();
    window.onbeforeunload = undefined;
  }

  submit() {
    this.props.enableLoading(true);

    loadingSwal({ title: !this.state.questionario.idQuestionario ? 'Salvando' : 'Atualizando' });

    const questionario = {
      ...this.values,
      dtInicio: this.values.dtInicio.format('YYYY-MM-DDTHH:mm:ssZZ'),
      dtTermino: this.values.dtTermino.format('YYYY-MM-DDTHH:mm:ssZZ'),
      questaoList: this.props.perguntasData,
      questionarioUsuarioList: this.props.entrevistadoresData,
      analiseItemList: this.props.regrasData
    };

    questionario.questaoList
      .filter(item => item.stRegistro !== 2)
      .forEach((item, i) => (item.nrOrdem = i));
    questionario.analiseItemList
      .filter(item => item.stRegistro !== 2)
      .forEach((item, i) => (item.nrOrdem = i));

    if (this.state.questionario.idQuestionario) {
      this._isMounted &&
        updateQuestionario(questionario)
          .then(res => {
            SwalMessage({
              title: 'Sucesso',
              text: 'Questionário atualizado com sucesso',
              icon: 'success',
              callback: () => {
                this.props.enableLoading(false);
                this._isMounted &&
                  this.setState({ canLeave: true }, () => this.props.history.push('/app/questionarios'));
              }
            });
          })
          .catch(err => {
            if (err.response && err.response.data.codigo === 'CLIENTE_INATIVO') {
              SwalMessage({
                title: 'Falha ao atualizar questionário',
                text: 'O cliente selecionado esta inativo, escolha outro cliente',
                err,
                callback: () => this.props.enableLoading(false)
              });
            } else if (err.response && err.response.data.codigo === 'QUESTIONARIO_COM_RESPOSTAS') {
              SwalMessage({
                title: 'Falha ao atualizar questionário',
                text: 'Não é possível atualizar este questionário pois ele possui respostas',
                err,
                callback: () => this.props.enableLoading(false)
              });
            } else if (err.response && err.response.data.codigo === 'QUESTAO_RELACIONADA_MARCOS') {
              SwalMessage({
                title: 'Falha ao atualizar questionário',
                text: 'Uma questão excluída está sendo usada como uma variação em uma análise',
                err,
                callback: () => this.props.enableLoading(false)
              });
            } else {
              SwalMessage({
                title: 'Falha ao atualizar questionário',
                text: 'Não foi possível validar sua edição, tente novamente',
                err,
                callback: () => this.props.enableLoading(false)
              });
            }
          });
    } else {
      questionario.questaoList.forEach(item => {
        item.stRegistro = 0;
        item.alternativaList && item.alternativaList.forEach(subItem => (subItem.stRegistro = 0));
      });
      questionario.analiseItemList.forEach(item => (item.stRegistro = 0));

      this._isMounted &&
        insertQuestionario(questionario)
          .then(res => {
            SwalMessage({
              title: 'Sucesso',
              text: 'Questionário cadastrado com sucesso',
              icon: 'success',
              callback: () => {
                this.props.enableLoading(false);
                this._isMounted &&
                  this.setState({ canLeave: true }, () => this.props.history.push('/app/questionarios'));
              }
            });
          })
          .catch(err => {
            if (err.response && err.response.data.codigo === 'CLIENTE_INATIVO') {
              SwalMessage({
                title: 'Falha ao validar cadastro',
                text: 'O cliente selecionado esta inativo, escolha outro cliente',
                err,
                callback: () => this.props.enableLoading(false)
              });
            } else {
              SwalMessage({
                title: 'Falha ao validar cadastro',
                text: 'Não foi possível validar seu cadastro, tente novamente',
                err,
                callback: () => this.props.enableLoading(false)
              });
            }
          });
    }
  }

  iniciarAtributos() {
    if (this.state.questionario.idQuestionario) {
      this.props.setIdQuestionario(+this.state.questionario.idQuestionario);

      this._isMounted &&
        getQuestionarioById(this.state.questionario.idQuestionario)
          .then(res => {
            const questionario = {
              idQuestionario: res.data.idQuestionario,
              cliente: res.data.cliente,
              dtInicio: moment(res.data.dtInicio),
              dtTermino: moment(res.data.dtTermino),
              nrRespostas: res.data.nrRespostas,
              dsTitulo: res.data.dsTitulo,
              dsQuestionario: res.data.dsQuestionario,
              stQuestionario: res.data.stQuestionario
            };

            this.initialQuestionario = clone(questionario);

            this._isMounted &&
              this.setState(
                {
                  keyAutocomplete: Math.random(),
                  key: Math.random(),
                  questionario
                },
                () => this._isMounted && this.setState({ enableReinitialize: false })
              );

            res.data.questionarioUsuarioList.forEach(
              item => (item.dsBloqueado = ['Sim', 'Não'][item.stQuestionarioUsuario])
            );
            res.data.analiseItemList.forEach(item => {
              item.stRegistro = 1;
              item.dsTipoAnaliseUI = ['Simplificada', 'Combinatória', 'Evolutiva'][item.tpItem];
              item.questaoAnaliseList &&
                item.questaoAnaliseList.forEach(subItem => (subItem.stRegistro = 1));
            });
            let maiorIdAlternativa = 1;
            res.data.questaoList.forEach(item => {
              item.tipoRespostaUI = this.props.tiposResposta[item.tpQuestao].nome;
              item.stRegistro = 1;

              item.alternativaList &&
                item.alternativaList.forEach(subItem => {
                  subItem.stRegistro = 1;
                  if (subItem.idAlternativa > maiorIdAlternativa) {
                    maiorIdAlternativa = subItem.idAlternativa;
                  }
                });
            });
            this.props.setInitialIdAlternativa(maiorIdAlternativa);
            this.props.changeStatusQuestionario(res.data.stQuestionario);

            this.props.changeClienteQuestionario(res.data.cliente);
            this.props.changeListaPerguntas(res.data.questaoList);
            this.props.changeListaRegras(res.data.analiseItemList);
            this.props.changeEntrevistadores(res.data.questionarioUsuarioList);
            this.props.changeAtualizandoQuestionario(true);

            this.initialPerguntas = clone(this.props.perguntasData);
            this.initialEntrevistadores = clone(this.props.entrevistadoresData);
            this.initialRegras = clone(this.props.regrasData);

            this.props.enableLoading(false);
          })
          .catch(err => {
            SwalMessage({
              title: 'Falha ao carregar questionário',
              text: 'Não foi possível carregar o questionário para edição, tente novamente',
              err,
              callback: () => {
                this.props.enableLoading(false);
                this.setState({ enableReinitialize: false });
              }
            });
          });
    } else {
      this.props.enableLoading(false);
      this.props.changeAtualizandoQuestionario(false);
      this.props.changeStatusQuestionario(0);
    }
  }

  onTrySubmit() {
    const { editandoPergunta, editandoEntrevistador, editandoRegra, perguntasData, editandoExcecao } =
      this.props;

    function allFluxosExcecaoValid(prev, atu) {
      return (
        prev &&
        (atu.stRegistro !== 2 && atu.tpQuestao === 0 && +atu.stFluxoExcecao === 1
          ? atu.questaoAvanco || atu.stFinalizaQuestionario === 1
          : true)
      );
    }

    const acceptSubmit = () => {
      this.setState({ tabSelected: 0 });
      this.handleSubmit && this.handleSubmit();
    };

    let erro = null;
    let confirm = null;
    if (editandoPergunta) {
      erro = { tab: 1, msg: 'Termine a edição da pergunta' };
    } else if (editandoExcecao) {
      erro = { tab: 2, msg: 'Termine a edição do fluxo de exceção' };
    } else if (editandoEntrevistador) {
      erro = { tab: 3, msg: 'Termine a edição do entrevistador' };
    } else if (editandoRegra) {
      erro = { tab: 4, msg: 'Termine a edição da regra de análise' };
    } else if (!perguntasData.length && this.state.questionario.stQuestionario === 1) {
      erro = { tab: 1, msg: 'Adicione pelo menos uma pergunta' };
    } else if (!perguntasData.reduce(allFluxosExcecaoValid, true)) {
      erro = { tab: 2, msg: 'Determine todos os fluxos de exceção' };
    } else if (
      (this.initialQuestionario.stQuestionario === 0 || this.initialQuestionario.stQuestionario === 2) &&
      this.state.questionario.stQuestionario === 1
    ) {
      confirm =
        'Mudando o status para "Em andamento" não será mais possível editar perguntas, não é possível voltar atrás, deseja mesmo continuar?';
    } else if (
      this.initialQuestionario.stQuestionario === 1 &&
      this.state.questionario.stQuestionario === 2
    ) {
      confirm =
        'Mudando o status para "Fechado" não será mais possível editar perguntas e entrevistadores, deseja mesmo continuar?';
    }

    if (confirm !== null) {
      this.setState({ tabSelected: 0 });
      SwalConfirmacao({
        text: confirm,
        classNameBtnConfirm: this.props.classes.btnConfirmSwal
      }).then(res => {
        if (res) acceptSubmit();
      });
      return;
    }

    if (erro !== null) {
      SwalMessage({ title: erro.msg, callback: () => this.setState({ tabSelected: erro.tab }) });
      return;
    }

    acceptSubmit();
  }

  tratarValueAutocomplete() {
    if (this.state.questionario.cliente && this.state.questionario.cliente.nmFantasia !== undefined) {
      return this.state.questionario.cliente;
    }
    return '';
  }

  onChangeAutocomplete(value) {
    if (
      this.state.questionario.stQuestionario !== 0 ||
      this.props.regrasData.find(item => item.tpItem === 2)
    ) {
      return;
    }

    this.setState(
      {
        keyAutocomplete: Math.random(),
        questionario: { ...this.state.questionario, cliente: value }
      },
      () => this.props.changeClienteQuestionario(value)
    );
    this.setFieldValue('cliente', value);
  }

  loadSuggestionsAutocomplete() {
    this._isMounted &&
      getAllClientes()
        .then(res => this.setState({ clientes: res.data.filter(item => item.stCliente === 1) }))
        .catch(err => {
          SwalMessage({
            title: 'Falha ao carregar clientes',
            text: 'Não foi possível carregar os clientes, tente novamente',
            err
          });
        });
  }

  onChangeTab(tab) {
    if (tab === 0) {
      this.props.showButtonFAB();
    }
    this.setState({ tabSelected: tab });
  }

  onChangeStatusQuestionario(e) {
    if (+e.target.value === 1 && this.props.perguntasData.length === 0) {
      SwalMessage({
        text: 'Adicione pelo menos uma pergunta para mudar o status para "em andamento"',
        callback: () => this.setState({ tabSelected: 1 })
      });
      return;
    } else {
      this.setState({ questionario: { ...this.state.questionario, stQuestionario: +e.target.value } });
      this.setFieldValue('stQuestionario', e.target.value);
      this.props.changeStatusQuestionario(+e.target.value);
      return;
    }
  }

  onTryExit(location) {
    if (this.isDirty()) {
      SwalConfirmacao({
        text: 'Existem alterações não salvas, deseja sair e descarta-las?',
        textConfirm: 'Sair',
        textCancel: 'Voltar',
        config: { closeOnClickOutside: false }
      }).then(res => {
        if (res) {
          this.setState(
            { canLeave: true },
            () => this.state.canLeave && this.props.history.push(location.pathname)
          );
        }
      });
      return false;
    }

    this.setState(
      { canLeave: true },
      () => this.state.canLeave && this.props.history.push(location.pathname)
    );
  }

  disableOptionStQuestionarioIf(option, indexOption) {
    if (!this.initialQuestionario.idQuestionario) return false;

    return (
      (this.initialQuestionario.stQuestionario === 0 && indexOption === 2) ||
      ((this.initialQuestionario.stQuestionario === 1 ||
        this.initialQuestionario.stQuestionario === 2) &&
        indexOption === 0)
    );
  }

  render() {
    return (
      <>
        <Prompt when={!this.state.canLeave} message={this.onTryExit} />

        <Formik
          key={this.state.key}
          initialValues={{ ...this.state.questionario }}
          onSubmit={this.submit}
          enableReinitialize={this.state.enableReinitialize}
          validateOnBlur
          validateOnChange={false}
          validationSchema={Yup.lazy(values =>
            Yup.object().shape({
              cliente: Yup.object().required('Campo obrigatório').typeError('Campo obrigatório'),
              dtInicio: Yup.date()
                .max(values.dtTermino || new Date(9999, 12, 31), 'Data maior que a data final')
                .required('Digite uma data válida')
                .typeError('Digite uma data válida'),
              dtTermino: Yup.date()
                .min(values.dtInicio || new Date(0, 0, 0), 'Data menor que a data inicial')
                .required('Digite uma data válida')
                .typeError('Digite uma data válida'),
              nrRespostas: Yup.number()
                .typeError('Digite um número')
                .min(0, 'O número mínimo é 0')
                .max(2000000000, 'o número máximo é dois bilhões')
                .required('Campo obrigatório'),
              stQuestionario: Yup.number().required('Campo obrigatório'),
              dsTitulo: Yup.string()
                .max(200, 'O máximo de caracteres é 200')
                .required('Campo obrigatório'),
              dsQuestionario: Yup.string()
            })
          )}
          render={({ values, errors, touched, handleBlur, setFieldValue, submitForm }) => {
            this.handleSubmit = submitForm;
            this.setFieldValue = setFieldValue;
            this.values = { ...values, stQuestionario: +values.stQuestionario };

            return (
              <div style={{ width: '100%' }}>
                <CombTab
                  onChangeTab={this.onChangeTab}
                  tabSelected={this.state.tabSelected}
                  tab={[
                    {
                      title: 'Identificação',
                      value: 0,
                      content: (
                        <div style={{ height: 'calc(100vh - 160px)' }}>
                          <CardIcon disableHeader>
                            <form autoComplete='off'>
                              <Grid container style={{ paddingTop: 25 }}>
                                <Grid item xs={4} style={{ height: 70, paddingRight: 15 }}>
                                  <Autocomplete
                                    itens={this.state.clientes}
                                    campoOp='nmFantasia'
                                    campoChave='idCliente'
                                    label='Cliente'
                                    name='cliente'
                                    id='cliente'
                                    error={
                                      this.tratarValueAutocomplete() === '' &&
                                      errors.cliente &&
                                      touched.cliente
                                    }
                                    helperText={
                                      this.tratarValueAutocomplete() === '' &&
                                      errors.cliente &&
                                      touched.cliente
                                        ? errors.cliente
                                        : null
                                    }
                                    onBlurOwn={handleBlur}
                                    onChangeOwn={this.onChangeAutocomplete}
                                    onChangeAutoComplete={this.loadSuggestionsAutocomplete}
                                    value={this.tratarValueAutocomplete()}
                                    key={this.state.keyAutocomplete}
                                    valueAutoComplete={this.tratarValueAutocomplete()}
                                    valueSelect={this.tratarValueAutocomplete().nmFantasia}
                                    maxHeight='calc(100vh - 250px)'
                                    disabled={
                                      this.state.questionario.stQuestionario !== 0 ||
                                      !!this.props.regrasData.find(
                                        item => item.stRegistro !== 2 && item.tpItem === 2
                                      )
                                    }
                                  />
                                </Grid>

                                <Grid item xs={2} style={{ height: 70, paddingRight: 15 }}>
                                  <DatePicker
                                    maxDate={values.dtTermino || moment('0000-00-00')}
                                    label='Data inicial'
                                    id='dtInicio'
                                    name='dtInicio'
                                    value={values.dtInicio}
                                    onChange={value => setFieldValue('dtInicio', value)}
                                    onBlur={handleBlur('dtInicio')}
                                    error={errors.dtInicio && touched.dtInicio}
                                    errorText={errors.dtInicio}
                                  />
                                </Grid>

                                <Grid item xs={2} style={{ height: 70, paddingRight: 15 }}>
                                  <DatePicker
                                    minDate={values.dtInicio || moment('0000-00-00')}
                                    label='Data final'
                                    id='dtTermino'
                                    name='dtTermino'
                                    value={values.dtTermino}
                                    onChange={value => setFieldValue('dtTermino', value)}
                                    onBlur={handleBlur('dtTermino')}
                                    error={errors.dtTermino && touched.dtTermino}
                                    errorText={errors.dtTermino}
                                  />
                                </Grid>

                                <Grid item xs={2} style={{ height: 70, paddingRight: 15 }}>
                                  <FastField name='nrRespostas'>
                                    {({ form, field }) => (
                                      <TextField
                                        {...field}
                                        id='nrRespostas'
                                        label='Número de entrevistas'
                                        error={form.errors.nrRespostas && form.touched.nrRespostas}
                                        helperText={
                                          form.errors.nrRespostas && form.touched.nrRespostas
                                            ? form.errors.nrRespostas
                                            : null
                                        }
                                        type='number'
                                        inputProps={{ min: 0, maxLength: 10 }}
                                      />
                                    )}
                                  </FastField>
                                </Grid>

                                <Grid item xs={2} style={{ height: 70 }}>
                                  <FormSelectOwn
                                    name='stQuestionario'
                                    label='Situação'
                                    value={values.stQuestionario}
                                    onChange={this.onChangeStatusQuestionario}
                                    onBlur={handleBlur}
                                    options={['Aberto', 'Em andamento', 'Fechado']}
                                    optionLabel={opt => opt}
                                    optionValue={(opt, i) => i}
                                    disableOptionIf={this.disableOptionStQuestionarioIf}
                                    error={errors.stQuestionario && touched.stQuestionario && true}
                                    errorText={errors.stQuestionario}
                                  />
                                </Grid>
                              </Grid>

                              <Grid container spacing={0}>
                                <Grid item xs style={{ height: 70, marginBottom: 5, marginTop: 5 }}>
                                  <FastField name='dsTitulo'>
                                    {({ form, field }) => (
                                      <TextField
                                        {...field}
                                        id='dsTitulo'
                                        label='Título'
                                        error={form.errors.dsTitulo && form.touched.dsTitulo}
                                        helperText={
                                          form.errors.dsTitulo && form.touched.dsTitulo
                                            ? form.errors.dsTitulo
                                            : null
                                        }
                                        inputProps={{ maxLength: 200 }}
                                      />
                                    )}
                                  </FastField>
                                </Grid>
                              </Grid>

                              <Grid container spacing={0}>
                                <Grid item xs style={{ height: 90 }}>
                                  <FastField name='dsQuestionario'>
                                    {({ form, field }) => (
                                      <TextField
                                        {...field}
                                        id='dsQuestionario'
                                        label='Descrição'
                                        error={form.errors.dsQuestionario && form.touched.dsQuestionario}
                                        helperText={
                                          form.errors.dsQuestionario && form.touched.dsQuestionario
                                            ? form.errors.dsQuestionario
                                            : null
                                        }
                                        multiline={true}
                                        rowsMax={2}
                                      />
                                    )}
                                  </FastField>
                                </Grid>
                              </Grid>
                            </form>
                          </CardIcon>
                        </div>
                      )
                    },
                    { title: 'Perguntas', value: 1, content: <Perguntas /> },
                    { title: 'Fluxos de exceção', value: 2, content: <Excecoes /> },
                    { title: 'Entrevistadores', value: 3, content: <Entrevistadores /> },
                    { title: 'Regras de análise', value: 4, content: <Regras /> }
                  ]}
                />

                {this.props.buttonFabEnabled && (
                  <ButtonFABMenu
                    actionSalvar={{ onClick: this.onTrySubmit }}
                    actionCancelar={{ onClick: () => this.props.history.push('/app/questionarios') }}
                  />
                )}
              </div>
            );
          }}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    mainQuestionarioReducer,
    tabRegrasQuestionarioReducer,
    tabEntrevistadoresQuestionarioReducer,
    tabPerguntasQuestionarioReducer,
    tabExcecoesQuestionarioReducer
  } = state.questionarioReducer;

  return {
    itensState: state.toolbarReducer,
    buttonFabEnabled: mainQuestionarioReducer.buttonFabEnabled,
    editandoPergunta: tabPerguntasQuestionarioReducer.editandoPergunta,
    importandoPerguntas: tabPerguntasQuestionarioReducer.importandoPerguntas,
    editandoEntrevistador: tabEntrevistadoresQuestionarioReducer.editandoEntrevistador,
    editandoRegra: tabRegrasQuestionarioReducer.editandoRegra,
    perguntasData: tabPerguntasQuestionarioReducer.perguntasData,
    entrevistadoresData: tabEntrevistadoresQuestionarioReducer.entrevistadoresData,
    regrasData: tabRegrasQuestionarioReducer.regrasData,
    editandoExcecao: tabExcecoesQuestionarioReducer.editandoExcecao,
    tiposResposta: tabPerguntasQuestionarioReducer.tiposResposta
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      showButtonFAB,
      updateToolbar,
      defaultEntrevistadores,
      defaultPerguntas,
      defaultRegras,
      defaultQuestionario,
      changeListaPerguntas,
      changeListaRegras,
      changeEntrevistadores,
      changeStatusQuestionario,
      changeAtualizandoQuestionario,
      setInitialIdAlternativa,
      enableLoading,
      changeClienteQuestionario,
      defaultExcecao,
      setIdQuestionario
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(style)(EditarQuestionario)));
