/* Autor: Kátia Miglioli
CampoInput: Usado em caso do texto que se apresenta no input e na lista sao diferentes.
*/
import React from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '../TextFieldOwn/TextFieldOwn';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2BA69F'
    }
  },
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiInput: {
      underline: {
        '&:hover:not($disabled):not($error):not($focused):before': {
          borderBottom: '1px solid #2BA69F'
        }
      }
    },
    MuiInputLabel: {
      root: {
        fontSize: 14,
        writeSpace: 'noWrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%'
      }
    },
    MuiFormControl: {
      root: {
        width: '100%'
      }
    },
    MuiIconButton: {
      root: {
        padding: '5px'
      }
    },
    MuiPaper: {
      root: {
        overflowX: 'hidden',
        overflow: 'auto',
        maxHeight: '300px'
      }
    }
  }
});

function renderInput(inputProps) {
  const { InputProps, classes, ref, onBlur, name, ...other } = inputProps;

  return (
    <MuiThemeProvider theme={theme}>
      <TextField
        disabled={inputProps.disabled}
        InputProps={{
          inputRef: ref,
          autoComplete: 'off',
          ...InputProps
        }}
        name={name}
        onBlur={onBlur}
        autoComplete='off'
        {...other}
      />
    </MuiThemeProvider>
  );
}

function renderSuggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem,
  campoOp,
  campoChave
}) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem + '' || '').indexOf(suggestion[campoOp]) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion[campoChave]}
      selected={isHighlighted}
      component='div'
      style={{
        fontWeight: isSelected ? 500 : 400,
        fontSize: 16
      }}
    >
      {suggestion[campoOp]}
    </MenuItem>
  );
}
renderSuggestion.propTypes = {};

function getSuggestions(value, suggestions, campoOp, valueAutoComplete) {
  if (valueAutoComplete === value) {
    value = '';
  }
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return suggestions.filter(suggestion => {
    const keep =
      count < 30 &&
      suggestion[campoOp]
        .slice(0, inputLength)
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '') === inputValue.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    if (keep) {
      count += 1;
    }

    return keep;
  });
}
const styles = theme => ({
  root: {
    flexGrow: 1
  },

  container: {
    flexGrow: 1,
    position: 'relative'
  },
  paper: {
    position: 'absolute',
    zIndex: 2,
    left: 0,
    right: 0
  },
  inputRoot: {
    flexWrap: 'wrap'
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1
  },
  divider: {
    height: theme.spacing.unit * 2
  }
});

class AutoComplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuIsOpen: false
    };
    this.changeIsOpen = this.changeIsOpen.bind(this);
    this.eventKeyPressDown = this.eventKeyPressDown.bind(this);
  }

  changeIsOpen() {
    this.setState({ menuIsOpen: this.state.menuIsOpen ? false : true });
  }
  eventKeyPressDown(event) {
    if (event.key === 'Escape') {
      this.setState({ menuIsOpen: false });
    } else {
      this.setState({ menuIsOpen: true });
    }
  }
  render() {
    const { classes } = this.props;
    const suggestions = this.props.itens;
    const campoOp = this.props.campoOp;
    const campoChave = this.props.campoChave;
    const campoInput = this.props.campoInput === undefined ? campoOp : this.props.campoInput;

    return (
      <div className={classes.root} style={this.props.style}>
        <Downshift
          id='downshift-simple'
          onOuterClick={() => this.setState({ menuIsOpen: false })}
          key={this.props.key}
          onChange={(selection, e) => {
            if (this.props.onChangeOwn) {
              this.props.onChangeOwn(selection, e);
            }
            this.setState({ menuIsOpen: false });
          }}
          onBlur={this.props.onBlurOwn}
          initialSelectedItem={this.props.valueAutoComplete === '' ? null : this.props.valueAutoComplete}
          style={{ width: 'auto' }}
          itemToString={item => (item !== null ? `${item[campoInput]}` : '')}
          isOpen={this.state.menuIsOpen}
        >
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            highlightedIndex,
            inputValue,
            isOpen,
            selectedItem,
            clearSelection
          }) => (
            <div className={classes.container}>
              {renderInput({
                disabled: this.props.disabled,
                classes,
                fullWidth: true,
                InputProps: getInputProps({
                  onChange: event => {
                    if (this.props.onChangeAutoComplete) {
                      this.props.onChangeAutoComplete();
                    }
                    if (event.target.value === '') {
                      clearSelection();
                    }
                  },
                  onBlur: event => {
                    this.props.onBlurOwn && this.props.onBlurOwn(event);
                  },
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton disabled={this.props.disabled} onClick={this.changeIsOpen}>
                        <ExpandMoreIcon
                          style={{
                            transform: this.state.menuIsOpen ? 'rotateX(180deg)' : ''
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                  onKeyDown: this.eventKeyPressDown,
                  name: this.props.name
                }),
                label: this.props.label,
                error: this.props.error && !this.props.disabled ? true : false,
                helperText: !this.props.disabled && !this.state.menuIsOpen && this.props.helperText
              })}
              <div {...getMenuProps()}>
                {isOpen ? (
                  <MuiThemeProvider theme={theme}>
                    <Paper
                      className={classes.paper}
                      style={{ maxHeight: this.props.maxHeight || 300 }}
                      square
                    >
                      {getSuggestions(inputValue, suggestions, campoOp, this.props.valueSelect).map(
                        (suggestion, index) =>
                          renderSuggestion({
                            suggestion,
                            index,
                            itemProps: getItemProps({ item: suggestion }),
                            highlightedIndex,
                            selectedItem,
                            campoOp,
                            campoChave
                          })
                      )}
                    </Paper>
                  </MuiThemeProvider>
                ) : null}
              </div>
            </div>
          )}
        </Downshift>
      </div>
    );
  }
}

AutoComplete.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AutoComplete);
