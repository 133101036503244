//@author Katia Miglioli
/* Props:
usePaddings = {true/false} -> coloca espaço entre o card e a borda da tela
stateCardblock = {true/false} -> Bloqueia se o card pode ser expandido ou nao
logoUrl={...png} - > icone no canto superior direito do card
onFilters -> funcao que sera executada pelo botao filtrar retorna a funcao um array com os chips(tambem pode se acessado pelo redux)
filtros -> objeto que possui os campos a serem filtrado,deve conter tres partes obrigatorias em cada item:
label: que contem o texto internacionalizado que deverá ser exibido,
field: 'identificador' desse item, o nome original do item,
type: tipo do item(string,number,date e enum).
no caso de um item enum: deve-se conter mais um campo o options, onde deve conter um objeto com as opcoes
que ficaram no campo de pesquisa, objeto com os campos label,field e value, onde value equivale a type
identData - > Campo que identifica os dados da tela no redux
*/

import React from 'react';
import CardIcon from '../CardIcon/CardIcon';
import CardFiltersOpen from './CardFiltersOpen';
import CardFiltersClosed from './CardFiltersClosed';

const CardFilters = props => {
  return (
    <div>
      <CardIcon
        usePaddings={props.usePaddings}
        stateCardblock={true}
        logoUrl={props.logoURL}
        filtros={true}
        enableExpandButton
        titulo='Filtros'
        header={
          <CardFiltersClosed
            identData={props.identData}
            viewKey={props.viewKey}
            onFilterChanged={props.onFilterChanged}
          />
        }
      >
        <CardFiltersOpen
          filtros={props.filtros}
          onFilter={props.onFilter}
          viewKey={props.viewKey}
          onFilterChanged={props.onFilterChanged}
        />
      </CardIcon>
    </div>
  );
};

export default CardFilters;
