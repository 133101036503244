//@author Gabriela Farias
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppMainToolbar from '../../components/AppMainToolbar/AppMainToolbar';
import MenuLateral from './../../components/MenuLateral/MenuLateral';
import Carregando from './../../components/Carregando/Carregando';
import { Route, withRouter, Redirect } from 'react-router-dom';
import routes from '../rotas';
import Authentication from '../../resources/oauth/Authentication';
import Admin from '../../resources/oauth/Admin';
import Loading from '../../components/Loading/Loading';
import { connect } from 'react-redux';
import { addInfoUsuario, clearInfoUsuario } from '../../states/actions/LoginActions';
import { bindActionCreators } from 'redux';
import { updateToolbar } from '../../states/actions/ToolbarActions';

const styles = theme => ({
  containerAppMainToolbar: {
    width: '100%',
    height: '100%',
    position: 'relative'
  }
});

class AppMainLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statusCarregando: true,
      loadingGlobal: this.props.loading,
      location: ''
    };

    this._isMounted = false;

    this.layout = this.layout.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    this._isMounted &&
      Authentication.refreshToken()
        .then(() => {
          this._isMounted &&
            Admin.findPerfil()
              .then(res => {
                if (res && res.tpUsuario === 1) {
                  this.props.addInfoUsuario(res);
                } else {
                  this.props.clearInfoUsuario();
                  Admin.resetPerfil();
                  this.props.history.push('/login');
                }
                this._isMounted && this.setState({ statusCarregando: false });
              })
              .catch(err => {
                this.props.clearInfoUsuario();
                Admin.resetPerfil();
                this.props.history.push('/login');
              });
        })
        .catch(err => {
          this.props.clearInfoUsuario();
          Admin.resetPerfil();
          this.props.history.push('/login');
        });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  shouldComponentUpdate(newProps, newState) {
    return (
      this.state.statusCarregando !== newState.statusCarregando ||
      this.state.location !== window.location.pathname ||
      newProps.loading !== this.state.loadingGlobal
    );
  }

  componentWillUpdate(nextProps) {
    if (nextProps.loading !== this.state.loadingGlobal) {
      this.setState({ loadingGlobal: this.props.loading });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.state.location !== window.location.pathname) {
      this.setState({ location: window.location.pathname });
    }
  }

  layout(classes) {
    return (
      <div className={classes.containerAppMainToolbar}>
        <AppMainToolbar>
          <div style={{ display: 'flex', height: '100%', overflow: 'hidden' }}>
            <MenuLateral />

            {routes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact} component={route.main} />
            ))}

            <Route exact path='/app' render={() => <Redirect to='/app/demonstrativos' />} />
          </div>
        </AppMainToolbar>
      </div>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        {this.state.statusCarregando ? <Carregando /> : this.layout(classes)}

        {this.state.loadingGlobal && <Loading />}
      </>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.toolbarReducer.loading,
  itensState: state.toolbarReducer,
  usuarioLogged: state.loginReducer.usuario
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ addInfoUsuario, updateToolbar, clearInfoUsuario }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(AppMainLayout)));
