import axios from 'axios';
import { URL_API } from '../common';

const URL_API_ESTADO = `${URL_API}/uf`;

const getAllEstados = async () => {
  return await axios.get(URL_API_ESTADO);
};

const getEstadoById = async id => {
  return await axios.get(URL_API_ESTADO + '/' + id);
};

export { getAllEstados, getEstadoById };
