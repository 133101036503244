/* 
    autor: Gabriela Farias
*/
import React, { Component } from 'react';
import List from '@material-ui/core/List';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import './MenuLateral.css';
import { Link, withRouter } from 'react-router-dom';
import logoWithWrite from '../../assets/images/novo_logo_RADAR.png';
import logo from '../../assets/images/selo_25anos_radar-1.png';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ItensMenu from './itensMenu';
import { bindActionCreators } from 'redux';
import { closeMenu } from '../../states/actions/MenuLateralActions';

const styles = theme => ({
  fundo: {
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  itens: {
    borderRadius: 10,
    maxHeight: 33,
    display: 'inline'
  },
  drawerPaper: {
    position: 'absolute',
    whiteSpace: 'nowrap',
    backgroundColor: '#124268',
    width: 270,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: 200
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    position: 'fixed',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: 200
    }),
    width: 55,
    [theme.breakpoints.up('sm')]: {
      width: 55
    }
  },
  formIcon: {
    marginRight: 10,
    marginTop: 8,
    marginLeft: '-5px'
  },
  listItem: {
    borderRadius: 7,
    paddingLeft: 13,
    paddingRight: 13
  },
  link: {
    color: 'transparent',
    display: 'inline',
    whiteSpace: 'nowrap',
    height: 45,
    borderRadius: 5,
    border: '#95989A'
  },
  icon: {
    backgroundColor: '#FFFFFF',
    backgroundRepeat: 'no-repeat',
    height: '80px !important',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  logoWithWrite: {
    width: 210,
    objectFit: 'cover'
  },
  logo: {
    width: 35,
    objectFit: 'cover'
  },
  iconsMenu: {
    height: 200,
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column'
  }
});

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: '1px solid #EBEBEB'
      }
    },
    MuiListItem: {
      gutters: {
        paddingLeft: 0,
        paddingRight: 0
      },
      root: {
        height: '100%',
        paddingTop: 14
      }
    }
  }
});

const url = '/app'; /*  Link inicial */

/* 
    Menu lateral customizado
*/

class MenuLateral extends Component {
  constructor(props) {
    super(props);

    this.itensDoMenu = this.itensDoMenu.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  onSelect(menu) {
    menu.selecionado = !menu.selecionado;
  }

  itensDoMenu(itensMenu) {
    const { classes } = this.props;

    return (
      <List className={classes.iconsMenu}>
        {itensMenu.map(item => (
          <div
            key={item.name}
            onClick={() => {
              this.props.history.push(`${url}${item.route}`);
              this.props.closeMenu();
            }}
            className={classes.link}
            style={{
              backgroundColor: window.location.pathname.includes(item.route) ? '#2BA69F' : null,
              boxShadow: window.location.pathname.includes(item.route)
                ? '0px 4px 4px rgba(0, 0, 0, 0.1)'
                : null,
              marginLeft: 5,
              marginRight: 5
            }}
          >
            <ListItem button key={item.name} onClick={() => this.onSelect(item)}>
              <InputAdornment className={classes.formIcon}>
                <img src={item.icon} alt='icone' />
              </InputAdornment>
              <div
                className={this.props.itensState.open ? 'run-animation-open' : 'run-animation-close'}
                style={{ marginLeft: item.icon === undefined && 0 }}
                key={item.name}
              >
                {item.name}
              </div>
            </ListItem>
          </div>
        ))}
      </List>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <Drawer
            variant='permanent'
            open={!this.props.itensState.open}
            classes={{
              paper: classNames(
                classes.drawerPaper,
                !this.props.itensState.open && classes.drawerPaperClose
              )
            }}
          >
            <Link to='/app' onClick={() => this.props.closeMenu()} className={classes.icon}>
              {!this.props.itensState.open ? (
                <img
                  src={logo}
                  id={
                    this.props.itensState.open ? 'run-animation-open-logo' : 'run-animation-close-logo'
                  }
                  className={classes.logo}
                  alt='logo'
                />
              ) : (
                <img
                  src={logoWithWrite}
                  id={
                    this.props.itensState.open ? 'run-animation-close-logo' : 'run-animation-open-logo'
                  }
                  className={classes.logoWithWrite}
                  alt='logo'
                />
              )}
            </Link>
            {this.itensDoMenu(ItensMenu)}
          </Drawer>
        </MuiThemeProvider>
      </div>
    );
  }
}

MenuLateral.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  itensState: state.menuLateralReducer
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ closeMenu }, dispatch);
};

let EnhacedComponent = withStyles(styles)(MenuLateral);
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EnhacedComponent)
);
