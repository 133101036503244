import ActionTypes from '../actionTypes/ToolbarActionTypes';

const updateToolbar = payload => ({
  type: ActionTypes.UPDATE_TOOLBAR,
  payload
});

const enableLoading = payload => ({ type: ActionTypes.ENABLE_LOADING, payload });

export { updateToolbar, enableLoading };
