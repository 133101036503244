import ActionTypes from '../actionTypes/QuestionarioActionTypes';
import { combineReducers } from 'redux';
import clone from '../../utils/functions/Clone/Clone';
import moveItem from '../../utils/functions/MoveItem/MoveItem';

const initialStateMain = {
  buttonFabEnabled: true,
  statusQuestionario: '',
  clienteQuestionario: '',
  idQuestionario: '',
  atualizandoQuestionario: false
};

const mainQuestionarioReducer = (state = initialStateMain, action) => {
  switch (action.type) {
    case ActionTypes.SHOW_BUTTON_FAB:
      return { ...state, buttonFabEnabled: true };

    case ActionTypes.HIDDEN_BUTTON_FAB:
      return { ...state, buttonFabEnabled: false };

    case ActionTypes.CHANGE_STATUS_QUESTIONARIO:
      return { ...state, statusQuestionario: action.payload };

    case ActionTypes.CHANGE_CLIENTE_QUESTIONARIO:
      return { ...state, clienteQuestionario: action.payload };

    case ActionTypes.CHANGE_ATUALIZANDO_QUESTIONARIO:
      return { ...state, atualizandoQuestionario: action.payload };

    case ActionTypes.SET_ID_QUESTIONARIO:
      return { ...state, idQuestionario: action.payload };

    case ActionTypes.DEFAULT_QUESTIONARIO:
      return {
        buttonFabEnabled: true,
        statusQuestionario: '',
        clienteQuestionario: '',
        idQuestionario: '',
        atualizandoQuestionario: false
      };

    default:
      return state;
  }
};

const initialStateRegras = {
  regrasData: [],
  alternativasAgrupadas: [],
  agruparRespostasSelecteds: [],
  editandoRegra: false,
  atualizandoRegra: false,
  regraSelected: '',
  questaoMarcosList: [],
  questaoAnaliseList: []
};

const tabRegrasQuestionarioReducer = (state = initialStateRegras, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_REGRA_SELECTED:
      const { questaoAnaliseList, ...others } = action.payload;
      return { ...state, regraSelected: { ...others }, questaoAnaliseList };

    case ActionTypes.EXIT_EDIT_REGRA:
      return {
        ...state,
        editandoRegra: false,
        atualizandoRegra: false,
        regraSelected: '',
        questaoMarcosList: [],
        questaoAnaliseList: [],
        alternativasAgrupadas: [],
        agruparRespostasSelecteds: []
      };

    case ActionTypes.GO_EDIT_REGRA:
      const idQuestaoSintetizadora = action.payload.questaoSintetizadora
        ? action.payload.questaoSintetizadora.idQuestao
        : '';
      const questaoAnaliseEvolutiva = (
        action.payload.questaoAnaliseList[0] || { questao: { idQuestao: '' } }
      ).questao.idQuestao;

      return {
        ...state,
        editandoRegra: true,
        atualizandoRegra: true,
        regraSelected: {
          ...action.payload,
          idQuestaoSintetizadora,
          questaoAnaliseEvolutiva,
          alternativasAgrupadas: undefined,
          questaoMarcosList: undefined
        },
        agruparRespostasSelecteds: action.payload.alternativasAgrupadas,
        questaoMarcosList: action.payload.questaoMarcosList,
        questaoAnaliseList: action.payload.questaoAnaliseList
      };

    case ActionTypes.GO_ADD_REGRA:
      return {
        ...state,
        editandoRegra: true,
        atualizandoRegra: false,
        regraSelected: {
          idQuestaoSintetizadora: '',
          tpItem: 0,
          tpVisualizacao: 0,
          tpGrafico: 1,
          tpOrientacao: 0,
          tpEmpilhamento: 0,
          dsTitulo: '',
          dsApoio: '',
          questaoAnaliseEvolutiva: ''
        },
        questaoMarcosList: [],
        alternativasAgrupadas: [],
        agruparRespostasSelecteds: [],
        questaoAnaliseList: [
          {
            idAnaliseItemAgrupadora: 1,
            questao: { idQuestao: '' },
            dsTitulo: '',
            stRegistro: 0
          }
        ]
      };

    case ActionTypes.CHANGE_LISTA_REGRAS:
      action.payload.forEach((item, i) => (item.nrOrdem = i));
      return { ...state, regrasData: action.payload };

    case ActionTypes.CHANGE_AGRUPAR_RESPOSTAS_SELECTEDS:
      return { ...state, agruparRespostasSelecteds: action.payload };

    case ActionTypes.CHANGE_AGRUPAR_RESPOSTAS_DATA:
      return { ...state, alternativasAgrupadas: action.payload };

    case ActionTypes.CHANGE_MARCOS_REGRAS:
      action.payload.forEach((item, i) => (item.index = i));
      return { ...state, questaoMarcosList: action.payload };

    case ActionTypes.DEFAULT_REGRAS:
      return {
        regrasData: [],
        alternativasAgrupadas: [],
        agruparRespostasSelecteds: [],
        editandoRegra: false,
        atualizandoRegra: false,
        regraSelected: '',
        questaoMarcosList: [],
        questaoAnaliseList: []
      };

    default:
      return state;
  }
};

const initialStateEntrevistadores = {
  entrevistadoresData: [],
  editandoEntrevistador: false,
  atualizandoEntrevistador: false,
  entrevistadorSelected: ''
};

const tabEntrevistadoresQuestionarioReducer = (state = initialStateEntrevistadores, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_ENTREVISTADOR_SELECTED:
      return { ...state, entrevistadorSelected: action.payload };

    case ActionTypes.EXIT_EDIT_ENTREVISTADOR:
      return {
        ...state,
        editandoEntrevistador: false,
        atualizandoEntrevistador: false,
        entrevistadorSelected: ''
      };

    case ActionTypes.GO_ADD_ENTREVISTADOR:
      return {
        ...state,
        editandoEntrevistador: true,
        atualizandoEntrevistador: false,
        entrevistadorSelected: {
          usuario: '',
          nrEntrevistas: 0,
          stQuestionarioUsuario: 1
        }
      };

    case ActionTypes.GO_EDIT_ENTREVISTADOR:
      return {
        ...state,
        atualizandoEntrevistador: true,
        editandoEntrevistador: true,
        entrevistadorSelected: action.payload
      };

    case ActionTypes.CHANGE_LISTA_ENTREVISTADORES:
      action.payload.forEach((item, i) => (item.index = i));
      return { ...state, entrevistadoresData: action.payload };

    case ActionTypes.DEFAULT_ENTREVISTADORES:
      return {
        entrevistadoresData: [],
        editandoEntrevistador: false,
        atualizandoEntrevistador: false,
        entrevistadorSelected: ''
      };

    default:
      return state;
  }
};

const tiposRespostaDefault = [
  {
    nome: 'Fechada',
    stValoresInteiros: 0,
    alternativaArredondamento: '',
    stOutros: 0,
    stFluxoExcecao: 0,
    stFinalizaQuestionario: 0,
    stDescartaEntrevista: 0,
    alternativaExcecao: '',
    questaoAvanco: null
  },
  {
    nome: 'Múltipla escolha',
    stValoresInteiros: 0,
    alternativaArredondamento: '',
    stOutros: 0
  },
  {
    nome: 'Escala linear',
    valorInicial: { value: 0, marcador: '', label: 'Não gostei :(', touched: false },
    valorFinal: { value: 10, marcador: '', label: 'Gostei muito :)', touched: false }
  },
  { nome: 'Aberta' }
];

const alternativasDefault = (idAlternativa = 1) => [
  {
    idAlternativa,
    dsAlternativa: '',
    stResidual: 0,
    label: 'Opção A',
    stArredondamento: 0,
    stFluxoExcecao: 0,
    tpAlternativa: 0,
    stRegistro: 0,
    touched: false
  },
  {
    idAlternativa: idAlternativa + 1,
    dsAlternativa: '',
    stResidual: 0,
    label: 'Opção B',
    stArredondamento: 0,
    stFluxoExcecao: 0,
    tpAlternativa: 0,
    stRegistro: 0,
    touched: false
  }
];

const initialStatePerguntas = {
  editandoPergunta: false,
  atualizandoPergunta: false,
  perguntaSelected: '',
  tiposResposta: clone(tiposRespostaDefault),
  perguntasData: [],
  importandoPerguntas: false,
  importarPergunta: '',
  maiorIdAlternativa: 1
};

const tabPerguntasQuestionarioReducer = (state = initialStatePerguntas, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_CONFIG_FECHADA:
      state.tiposResposta[0] = action.payload;
      return { ...state };

    case ActionTypes.CHANGE_CONFIG_MULTIPLA_ESCOLHA:
      state.tiposResposta[1] = action.payload;
      return { ...state };

    case ActionTypes.CHANGE_CONFIG_ESCALA_LINEAR:
      state.tiposResposta[2] = action.payload;
      return { ...state };

    case ActionTypes.SYNC_ST_PERGUNTA:
      state.tiposResposta[1].stOutros = action.payload.stOutros;
      state.tiposResposta[1].stValoresInteiros = action.payload.stValoresInteiros;
      state.tiposResposta[1].alternativaArredondamento = action.payload.alternativaArredondamento;
      state.tiposResposta[0].stOutros = action.payload.stOutros;
      state.tiposResposta[0].stValoresInteiros = action.payload.stValoresInteiros;
      state.tiposResposta[0].alternativaArredondamento = action.payload.alternativaArredondamento;
      return { ...state };

    case ActionTypes.CHANGE_OPCOES_PERGUNTAS:
      return {
        ...state,
        perguntaSelected: { ...state.perguntaSelected, alternativaList: action.payload }
      };

    case ActionTypes.NEW_ID_ALTERNATIVA:
      const novoMaiorId = state.maiorIdAlternativa + 1;
      action.payload(novoMaiorId);
      return { ...state, maiorIdAlternativa: novoMaiorId };

    case ActionTypes.SET_INITIAL_ID_ALTERNATIVA:
      return { ...state, maiorIdAlternativa: action.payload };

    case ActionTypes.CHANGE_PERGUNTA_SELECTED:
      return {
        ...state,
        perguntaSelected: {
          ...action.payload,
          config: state.tiposResposta[+action.payload.tpQuestao]
        }
      };

    case ActionTypes.CHANGE_IMPORTAR_PERGUNTA:
      return { ...state, importarPergunta: action.payload };

    case ActionTypes.CHANGE_LISTA_PERGUNTAS:
      action.payload.forEach((item, i) => {
        item.nrOrdem = i;
        item.nrOrdemUI = item.nrOrdem + 1;
      });

      action.payload.forEach((item, i, array) => {
        if (item.stRegistro !== 2 && item.tpQuestao === 0 && item.stFluxoExcecao === 1) {
          item.alternativaExcecao = item.alternativaList.find(item => item.stFluxoExcecao === 1);

          if (item.stFinalizaQuestionario === 1) {
            item.questaoAvanco = null;
          }

          if (item.questaoAvanco) {
            item.questaoAvanco = array.find(
              quest => quest.stRegistro !== 2 && quest.idQuestao === item.questaoAvanco.idQuestao
            );

            if (item.questaoAvanco.nrOrdem <= item.nrOrdem) {
              item.questaoAvanco = null;
            }
          }
        }

        item.alternativaList &&
          item.alternativaList.forEach(
            subItem =>
              (subItem.tpAlternativa = subItem.tpAlternativa === undefined ? 0 : subItem.tpAlternativa)
          );

        if (item.stRegistro !== 2 && item.alternativaList) {
          const alternativaOutrosIndex = item.alternativaList.findIndex(
            subItem => subItem.stRegistro !== 2 && subItem.tpAlternativa === 1
          );

          if (alternativaOutrosIndex !== -1) {
            moveItem(item.alternativaList, alternativaOutrosIndex, item.alternativaList.length - 1);
          }
        }
      });

      return { ...state, perguntasData: action.payload };

    case ActionTypes.GO_ADD_PERGUNTA:
      const novoMaiorIdAlternativa = state.maiorIdAlternativa + 1;
      return {
        ...state,
        importandoPerguntas: false,
        editandoPergunta: true,
        atualizandoPergunta: false,
        tiposResposta: clone(tiposRespostaDefault),
        maiorIdAlternativa: novoMaiorIdAlternativa + 2,
        perguntaSelected: {
          dsTitulo: '',
          dsApoio: '',
          tpQuestao: 0,
          nrOrdem: state.perguntasData.length,
          tpAuditoria: 0,
          alternativaList: clone(alternativasDefault(novoMaiorIdAlternativa))
        }
      };

    case ActionTypes.GO_IMPORTAR_PERGUNTAS:
      return {
        ...state,
        importandoPerguntas: true,
        editandoPergunta: false,
        atualizandoPergunta: false,
        importarPergunta: {
          clienteSelected: '',
          questionarios: [],
          perguntasSelecionadas: [],
          questionariosExpandeds: []
        }
      };

    case ActionTypes.GO_EDIT_PERGUNTA:
      const questao = action.payload;
      let config = {};
      switch (+questao.tpQuestao) {
        case 2:
          config = {
            nome: state.tiposResposta[+questao.tpQuestao].nome,
            valorInicial: { value: questao.nrLinearInicio, marcador: questao.dsLinearInicio },
            valorFinal: { value: questao.nrLinearFinal, marcador: questao.dsLinearFinal }
          };
          break;

        case 3:
          config = { nome: state.tiposResposta[+questao.tpQuestao].nome };
          break;

        default:
          questao.alternativaList.forEach(item => {
            item.touched = true;
          });
          config = {
            nome: state.tiposResposta[+questao.tpQuestao].nome,
            stValoresInteiros: questao.stValoresInteiros,
            stOutros: questao.stOutros,
            alternativaArredondamento: (
              questao.alternativaList.find(item => item.stArredondamento === 1) || {
                idAlternativa: ''
              }
            ).idAlternativa
          };

          if (+questao.tpQuestao === 0) {
            config = {
              ...config,
              stFluxoExcecao: questao.stFluxoExcecao,
              stFinalizaQuestionario: questao.stFinalizaQuestionario,
              stDescartaEntrevista: questao.stDescartaEntrevista,
              questaoAvanco: questao.questaoAvanco,
              alternativaExcecao: (
                questao.alternativaList.find(item => item.stFluxoExcecao === 1) || {
                  idAlternativa: ''
                }
              ).idAlternativa
            };
          }
      }
      state.tiposResposta[+questao.tpQuestao] = config;
      if (+questao.tpQuestao < 2) {
        state.tiposResposta[0].stOutros = config.stOutros;
        state.tiposResposta[0].stValoresInteiros = config.stValoresInteiros;
        state.tiposResposta[0].alternativaArredondamento = config.alternativaArredondamento;
        state.tiposResposta[1].stOutros = config.stOutros;
        state.tiposResposta[1].stValoresInteiros = config.stValoresInteiros;
        state.tiposResposta[1].alternativaArredondamento = config.alternativaArredondamento;
      }

      let alternativaList = questao.alternativaList || [];
      if (
        questao.alternativaList &&
        !questao.alternativaList.filter(item => item.stRegistro !== 2).length
      ) {
        alternativaList = [
          ...clone(alternativasDefault(state.maiorIdAlternativa)),
          ...questao.alternativaList
        ];
      }

      return {
        ...state,
        importandoPerguntas: false,
        atualizandoPergunta: true,
        editandoPergunta: true,
        perguntaSelected: {
          idQuestao: questao.idQuestao,
          dsTitulo: questao.dsTitulo,
          dsApoio: questao.dsApoio,
          tpQuestao: questao.tpQuestao,
          tpAuditoria: questao.tpAuditoria || 0,
          nrOrdem: questao.nrOrdem,
          stRegistro: questao.stRegistro || 0,
          alternativaList: questao.tpQuestao <= 2 ? alternativaList : []
        }
      };

    case ActionTypes.EXIT_EDIT_PERGUNTA:
      return {
        ...state,
        importandoPerguntas: false,
        editandoPergunta: false,
        atualizandoPergunta: false,
        perguntaSelected: '',
        importarPergunta: '',
        tiposResposta: clone(tiposRespostaDefault)
      };

    case ActionTypes.DEFAULT_PERGUNTAS:
      return {
        editandoPergunta: false,
        atualizandoPergunta: false,
        perguntaSelected: '',
        tiposResposta: clone(tiposRespostaDefault),
        perguntasData: [],
        importandoPerguntas: false,
        importarPergunta: '',
        maiorIdAlternativa: 1
      };

    default:
      return state;
  }
};

const initialStateExcecoes = {
  editandoExcecao: false,
  atualizandoExcecao: false,
  excecaoSelected: ''
};

const tabExcecoesQuestionarioReducer = (state = initialStateExcecoes, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_EXCECAO_SELECTED:
      return { ...state, excecaoSelected: action.payload };

    case ActionTypes.EXIT_EDIT_EXCECAO:
      return {
        ...state,
        editandoExcecao: false,
        atualizandoExcecao: false,
        excecaoSelected: ''
      };

    case ActionTypes.GO_EDIT_EXCECAO:
      return {
        ...state,
        atualizandoExcecao: true,
        editandoExcecao: true,
        excecaoSelected: action.payload
      };

    case ActionTypes.DEFAULT_EXCECOES:
      return {
        editandoExcecao: false,
        atualizandoExcecao: false,
        excecaoSelected: ''
      };

    default:
      return state;
  }
};

const questionarioReducer = combineReducers({
  mainQuestionarioReducer,
  tabPerguntasQuestionarioReducer,
  tabEntrevistadoresQuestionarioReducer,
  tabRegrasQuestionarioReducer,
  tabExcecoesQuestionarioReducer
});

export default questionarioReducer;
