/*
    Autor: Bruno Eduardo
*/

import React from 'react';
import CheckboxTree from 'react-checkbox-tree';
import {
  ArrowRight,
  ArrowDropDown,
  CheckBox,
  CheckBoxOutlineBlank,
  IndeterminateCheckBox
} from '@material-ui/icons';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import './CheckBoxTreeOwn.css';
import PropTypes from 'prop-types';

/*const exampleData = [
  {
    value: Math.random(),  // Nenhum valor pode ser igual, vale pra todos, "filhos" e "pais"
    label: 'Mars',
    children: [{ value: Math.random(), label: 'Phobos' }, { value: Math.random(), label: 'Deimos' }]
  }
];*/

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2BA69F'
    }
  },
  typography: {
    useNextVariants: true
  }
});

const CheckBoxTreeOwn = props => (
  <div style={{ color: 'rgba(0,0,0,0.54)', fontFamily: 'Roboto', fontSize: 14, ...props.styleContainer }}>
    <MuiThemeProvider theme={theme}>
      <CheckboxTree
        nodes={props.data}
        checked={props.checkeds}
        expanded={props.expandeds}
        onCheck={props.onCheck}
        onExpand={props.onExpand}
        icons={{
          check: <CheckBox color='primary' style={{ marginLeft: 10, marginRight: 8 }} />,
          uncheck: (
            <CheckBoxOutlineBlank
              opacity={0.54}
              style={{ marginLeft: 10, marginRight: 8, color: 'black' }}
            />
          ),
          halfCheck: (
            <IndeterminateCheckBox color='primary' style={{ marginLeft: 10, marginRight: 8 }} />
          ),
          expandClose: <ArrowRight opacity={0.54} style={{ color: 'black' }} />,
          expandOpen: <ArrowDropDown opacity={0.54} style={{ color: 'black' }} />,
          expandAll: null,
          collapseAll: null,
          parentClose: null,
          parentOpen: null,
          leaf: null
        }}
        {...props}
      />
    </MuiThemeProvider>
  </div>
);

CheckBoxTreeOwn.propTypes = {
  data: PropTypes.array.isRequired,
  checkeds: PropTypes.array.isRequired,
  expandeds: PropTypes.array.isRequired,
  onCheck: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired
};

export default CheckBoxTreeOwn;
