/*
    Autor: Bruno Eduardo
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import FormSelectOwn from '../../../../components/FormSelectOwn/FormSelectOwn';
import { changeConfigEscalaLinear } from '../../../../states/actions/QuestionarioActions';
import { bindActionCreators } from 'redux';
import AutoControlledTextField from '../../../../components/AutoControlledTextField/AutoControlledTextField';

class RespostaEscalaLinear extends Component {
  constructor(props) {
    super(props);

    this.state = { config: this.props.config };

    this.onChangesValorFinal = this.onChangesValorFinal.bind(this);
    this.onChangesValorInicial = this.onChangesValorInicial.bind(this);
  }

  componentWillUnmount() {
    this.props.changeConfigEscalaLinear(this.state.config);
  }

  onChangesValorFinal(campo, e) {
    if (e.target.value.length <= 20) {
      const aux = this.state.config;
      aux.valorFinal[campo] = e.target.value;
      campo === 'marcador' && (aux.valorFinal.touched = true);
      this.setState({ config: aux });
    }
  }

  onChangesValorInicial(campo, e) {
    if (e.target.value.length <= 20) {
      const aux = this.state.config;
      aux.valorInicial[campo] = e.target.value;
      campo === 'marcador' && (aux.valorInicial.touched = true);
      this.setState({ config: aux });
    }
  }

  render() {
    const { config } = this.state;

    return (
      <form autoComplete='off' style={{ color: 'black' }}>
        <Grid container spacing={0}>
          <Grid item xs={2} style={{ height: 70, paddingRight: 15 }}>
            <FormSelectOwn
              error={+config.valorInicial.value >= +config.valorFinal.value}
              name='dsValorInicial'
              label='Valor inicial'
              value={config.valorInicial.value}
              onChange={e => this.onChangesValorInicial('value', e)}
              options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
              optionLabel={opt => opt + ''}
              optionValue={opt => opt}
            />
          </Grid>

          <Grid item xs={2} style={{ height: 70 }}>
            <AutoControlledTextField
              label={config.valorInicial.label}
              name='dsMarcadorInicial'
              value={config.valorInicial.marcador}
              onBlur={e => this.onChangesValorInicial('marcador', e)}
              onFocus={() => {
                const aux = this.state.config;
                if (!aux.valorInicial.touched) {
                  aux.valorInicial.label = 'Marcador inicial';
                  this.setState({ config: aux });
                }
              }}
              error={
                !config.valorInicial.marcador && (config.valorInicial.touched || this.props.triedSubmit)
              }
              helperText={
                !config.valorInicial.marcador && (config.valorInicial.touched || this.props.triedSubmit)
                  ? 'O campo é obrigatório'
                  : null
              }
              inputProps={{ maxLength: 20 }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={2} style={{ height: 70, paddingRight: 15 }}>
            <FormSelectOwn
              error={+config.valorInicial.value >= +config.valorFinal.value}
              name='dsValorFinal'
              label='Valor final'
              value={config.valorFinal.value}
              onChange={e => this.onChangesValorFinal('value', e)}
              options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
              optionLabel={opt => opt + ''}
              optionValue={opt => opt}
              errorText='O valor inicial tem que ser menor que o valor final'
            />
          </Grid>

          <Grid item xs={2} style={{ height: 70 }}>
            <AutoControlledTextField
              label={config.valorFinal.label}
              name='dsMarcadorFinal'
              value={config.valorFinal.marcador}
              onBlur={e => this.onChangesValorFinal('marcador', e)}
              onFocus={() => {
                const aux = this.state.config;
                if (!aux.valorFinal.touched) {
                  aux.valorFinal.label = 'Marcador final';
                  this.setState({ config: aux });
                }
              }}
              error={
                !config.valorFinal.marcador && (config.valorFinal.touched || this.props.triedSubmit)
              }
              helperText={
                !config.valorFinal.marcador && (config.valorFinal.touched || this.props.triedSubmit)
                  ? 'O campo é obrigatório'
                  : null
              }
              inputProps={{ maxLength: 20 }}
            />
          </Grid>
        </Grid>
      </form>
    );
  }
}

RespostaEscalaLinear.propTypes = {
  config: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  config: state.questionarioReducer.tabPerguntasQuestionarioReducer.tiposResposta[2]
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ changeConfigEscalaLinear }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RespostaEscalaLinear);
