//@author Gabriela Farias
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import '../../assets/styles/CompTab.css';

function TabContainer({ children, dir }) {
  return (
    <div
      component='div'
      dir={dir}
      style={{
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: '100%',
      }}
    >
      {children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const styles = {
  swipeableViews: {
    display: 'flex',
    width: '100%',
  },
  tab: {
    width: '100%',
    height: 45,
    backgroundColor: '#F5F5F5',
    color: '#999999',
  },
  appBar: {
    boxShadow: 'none',
  },
  tabSelected: {
    '&$tabSelected': {
      backgroundColor: '#2BA69F !important',
      color: '#FFFFFF !important',
      borderRadius: '5px !important',
      height: '55px !important',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    },
  },
  tabsIndicator: {
    display: 'none',
  },
  spanLabel: {
    fontFamily: 'Roboto',
    textTransform: 'none',
    fontSize: 18,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
};

const muiTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTabs: {
      root: {
        minHeight: 0,
        height: '100%',
      },
      flexContainer: {
        alignItems: 'center',
      },
    },
    MuiTab: {
      labelIcon: {
        minHeight: 0,
        paddingTop: 0,
      },
      wrapper: {
        flexDirection: 'row',
      },
      labelContainer: {
        width: 'auto',
      },
    },
  },
});

class CompTab extends React.Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value }, () => this.props.onChangeTab && this.props.onChangeTab(value));
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  render() {
    const { classes, theme } = this.props;
    const tabValues = this.props.tab || [];

    return (
      <MuiThemeProvider theme={muiTheme}>
        <div className={classes.root} style={this.props.style}>
          <div className={classes.appBar}>
            <Tabs
              value={this.props.tabSelected === undefined ? this.state.value : this.props.tabSelected}
              onChange={this.handleChange}
              variant='fullWidth'
              indicatorColor='primary'
              style={{ marginLeft: 15, marginRight: 15, marginBottom: 15, borderRadius: 10 }}
              classes={{ indicator: classes.tabsIndicator }}
              id='tab'
            >
              {tabValues.map((tab, i) => (
                <Tab
                  style={{
                    display: tab.disabled ? 'none' : 'flex',
                    borderTopRightRadius: tabValues[tabValues.length - 1] === tab ? 10 : 0,
                    borderBottomRightRadius: tabValues[tabValues.length - 1] === tab ? 10 : 0,
                    borderTopLeftRadius: tabValues[0] === tab ? 10 : 0,
                    borderBottomLeftRadius: tabValues[0] === tab ? 10 : 0,
                  }}
                  disabled={tab.disabledItem}
                  className={classes.tab}
                  id='tab'
                  key={tab.value || i}
                  classes={{ selected: classes.tabSelected }}
                  icon={tab.icon}
                  label={<span className={classes.spanLabel}>{tab.title}</span>}
                  value={tab.value || i}
                />
              ))}
            </Tabs>
          </div>

          <SwipeableViews
            data={this.props.data}
            index={this.props.tabSelected === undefined ? this.state.value : this.props.tabSelected}
            onChangeIndex={this.handleChangeIndex}
            style={{ width: '100%', display: 'flex', flex: 1, height: '100%' }}
            containerStyle={{
              flexDirection: 'column !important',
              flex: 1,
              height: '100%',
              width: '100%',
            }}
            slideStyle={{ display: 'flex', width: '100%', height: '100%' }}
            ignoreNativeScroll={false}
          >
            {tabValues.map((tab, i) => {
              const value = tab.value || i;
              const tabSelected =
                this.props.tabSelected === undefined ? this.state.value : this.props.tabSelected;
              return (
                <div key={value} className={classes.swipeableViews}>
                  {(value === tabSelected || tab.withoutUnmount) && (
                    <TabContainer data={this.props.data} key={value} dir={theme.direction}>
                      {tab.content}
                    </TabContainer>
                  )}
                </div>
              );
            })}
          </SwipeableViews>
        </div>
      </MuiThemeProvider>
    );
  }
}

CompTab.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(CompTab);
