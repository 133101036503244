import axios from 'axios';
import { URL_API } from '../common';

const URL_API_DEMONSTRATIVO = `${URL_API}/demonstrativos`;

const getDemonstrativoById = async (id) => {
  return await axios.get(URL_API_DEMONSTRATIVO + '/' + id);
};

const getDowloandDemonstrativoById = async (id) => {
  return await axios.get(URL_API_DEMONSTRATIVO + '/slides/' + id);
};

const getDowloandDemonstrativoAbertoById = async (id) => {
  return await axios.get(URL_API_DEMONSTRATIVO + '/resposta-aberta/' + id);
};

export { getDemonstrativoById, getDowloandDemonstrativoById, getDowloandDemonstrativoAbertoById };
