//Autor: Gabriela Farias

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CardIcon from '../../components/CardIcon/CardIcon';
import Grid from '@material-ui/core/Grid';
import { Formik, FastField } from 'formik';
import * as Yup from 'yup';
import EmailIcon from '@material-ui/icons/EmailRounded';
import ExcluirIcon from '@material-ui/icons/DeleteRounded';
import ButtonFABMenu from './../../components/ButtonFABMenu/ButtonFABMenu';
import DatePicker from './../../components/DatePicker/DatePicker';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import FormSelectOwn from '../../components/FormSelectOwn/FormSelectOwn';
import { updateToolbar, enableLoading } from '../../states/actions/ToolbarActions';
import { bindActionCreators } from 'redux';
import { changeListaContatos } from '../../states/actions/ClienteActions';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import SwalMessage from '../../utils/functions/SwalMessage/SwalMessage';
import FormattedInput from '../../components/FormattedInputs/FormattedInputs';
import TextField from '../../components/TextFieldOwn/TextFieldOwn';
import clone from '../../utils/functions/Clone/Clone';
import Authentication from '../../resources/oauth/Authentication';
import SwalConfirmacao from '../../utils/functions/SwalConfirmacao/SwalConfirmacao';
import loadingSwal from '../../utils/functions/LoadingSwal/LoadingSwal';
import AutoControlledTextField from '../../components/AutoControlledTextField/AutoControlledTextField';
import {
  insertUsuario,
  updateUsuario,
  getUsuarioById,
  deleteUsuario,
} from '../../resources/api/usuario';

const style = () => ({
  labelSwitch: {
    color: 'black',
    fontFamily: 'Roboto',
    fontSize: 14,
  },
});

const tpPerfilOptions = [
  { value: 0, name: 'Entrevistador' },
  { value: 1, name: 'Administrador' },
];

class EditarUsuarios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      key: '',
      enableReinitialize: this.props.match.params.id && true,
      enableInativacao: false,

      usuario: {
        idUsuario: this.props.match.params.id || null,
        nmUsuario: '',
        dsEmail: '',
        nrTelefone: '',
        tpUsuario: 1,
        stUsuario: 1,
        dsInativacao: '',
        dtInativacao: null,
        dtCadastro: !this.props.match.params.id ? new Date() : null,
      },
    };

    const title = 'Cadastro de usuário';
    if (this.props.itensState.name !== title) {
      this.props.updateToolbar({ toolbar: title, name: title });
    }
    this.props.enableLoading(true);

    this._isMounted = false;

    this.iniciarAtributos = this.iniciarAtributos.bind(this);
    this.submit = this.submit.bind(this);
    this.exit = this.exit.bind(this);
    this.reenviarEmail = this.reenviarEmail.bind(this);
    this.deleteUsuario = this.deleteUsuario.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.iniciarAtributos();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  submit(values) {
    this.props.enableLoading(true);

    loadingSwal({ title: !this.state.usuario.idUsuario ? 'Salvando' : 'Atualizando' });

    values.stUsuario = +values.stUsuario;
    values.tpUsuario = +values.tpUsuario;

    if (values.stUsuario === 1) {
      values = { ...values, dsInativacao: null, dtInativacao: null };
    }

    values.dtInativacao =
      values.dtInativacao !== null ? moment(values.dtInativacao).format('YYYY-MM-DDTHH:mm:ssZZ') : null;
    values.dtCadastro =
      values.dtCadastro !== null ? moment(values.dtCadastro).format('YYYY-MM-DDTHH:mm:ssZZ') : null;

    if (!this.state.usuario.idUsuario) {
      this._isMounted &&
        insertUsuario(values)
          .then(() => {
            SwalMessage({
              title: 'Sucesso',
              text:
                'Usuário cadastrado com sucesso, confirme o usuário pelo link que foi enviado ao E-mail',
              icon: 'success',
              callback: () => {
                this.props.enableLoading(false);
                this.props.history.push('/app/usuario');
              },
            });
          })
          .catch((err) => {
            let msg;
            if (err.response && err.response.data.codigo === 'EMAIL_DUPLICADO') {
              msg = 'E-mail duplicado';
            } else {
              msg = 'Não foi possível validar seu cadastro, tente novamente';
            }
            SwalMessage({
              title: 'Falha ao validar cadastro',
              text: msg,
              err,
              callback: () => this.props.enableLoading(false),
            });
          });
    } else {
      this._isMounted &&
        updateUsuario(values)
          .then(() => {
            SwalMessage({
              title: 'Sucesso',
              text: 'Usuário atualizado com sucesso',
              icon: 'success',
              callback: () => {
                this.props.enableLoading(false);
                this.props.history.push('/app/usuario');
              },
            });
          })
          .catch((err) => {
            SwalMessage({
              title: 'Falha ao validar edição',
              text: 'Não foi possível validar sua edição, tente novamente',
              err,
              callback: () => this.props.enableLoading(false),
            });
          });
    }
  }

  iniciarAtributos() {
    if (this.state.usuario.idUsuario) {
      this.props.enableLoading(true);

      this._isMounted &&
        getUsuarioById(this.state.usuario.idUsuario)
          .then((res) => {
            this._isMounted &&
              this.setState(
                {
                  key: Math.random(),
                  usuario: {
                    ...res.data,
                    dsInativacao: res.data.dsInativacao || '',
                    dtInativacao: res.data.dtInativacao || null,
                  },
                  enableInativacao: res.data.stUsuario === 0,
                },
                () => {
                  this.props.changeListaContatos(res.data.clienteContatoList || []);
                  this._isMounted && this.setState({ enableReinitialize: false });
                  this.props.enableLoading(false);
                }
              );
          })
          .catch((err) => {
            SwalMessage({
              title: 'Falha ao carregar usuário',
              text: 'Não foi possível carregar o usuário para edição, tente novamente',
              err,
              callback: () => {
                this.props.enableLoading(false);
                this._isMounted && this.setState({ enableReinitialize: false });
              },
            });
          });
    } else {
      this.props.enableLoading(false);
    }
  }

  deleteUsuario() {
    SwalConfirmacao({ text: 'Deseja mesmo excluir este usuário?' }).then((res) => {
      if (res) {
        this.props.enableLoading(true);

        this._isMounted &&
          deleteUsuario(this.state.usuario.idUsuario)
            .then(() => {
              SwalMessage({
                text: 'Usuário excluído',
                icon: 'success',
                callback: () => {
                  this.props.enableLoading(false);
                  this.props.history.push('/app/usuario');
                },
              });
            })
            .catch((err) => {
              SwalMessage({
                title: 'Falha ao excluir usuário',
                text: 'Não foi possível excluir o usuário, tente novamente',
                err,
              });
            });
      }
    });
  }

  reenviarEmail() {
    this.props.enableLoading(true);

    this._isMounted &&
      Authentication.recuperarSenha(this.state.usuario.dsEmail)
        .then((res) => {
          SwalMessage({
            title: 'Sucesso',
            text: 'O e-mail foi enviado para o usuário com sucesso',
            icon: 'success',
            callback: () => this.props.enableLoading(false),
          });
        })
        .catch((err) => {
          let msg;
          if (err.response && err.response.data.codigo === 'USUARIO_NAO_ENCONTRADO') {
            msg = 'Não foi encontrado um usuário com este e-mail';
          } else {
            msg = 'Não foi possível enviar o e-mail de confirmação, tente novamente';
          }
          SwalMessage({
            title: 'Falha ao reenviar e-mail',
            text: msg,
            err,
            callback: () => this.props.enableLoading(false),
          });
        });
  }

  exit() {
    this.props.history.push('/app/usuario');
  }

  render() {
    const { classes } = this.props;

    return (
      <Formik
        key={this.state.key}
        initialValues={clone(this.state.usuario)}
        onSubmit={this.submit}
        validateOnBlur
        enableReinitialize={this.state.enableReinitialize}
        validateOnChange={false}
        validationSchema={Yup.lazy((values) =>
          Yup.object().shape({
            nmUsuario: Yup.string().max(100, 'Máximo de 100 caracteres').required('Campo obrigatório'),
            dsEmail: Yup.string().email('Digite um E-mail válido').required('Campo obrigatório'),
            nrTelefone: Yup.string()
              .min(14, 'Mínimo de 10 caracteres')
              .max(15, 'Máximo de 11 caracteres')
              .required('Campo obrigatório'),
            tpUsuario: Yup.number().required('Campo obrigatório'),
            stUsuario: Yup.number().required('Campo obrigatório'),
            dsInativacao: Yup.string().test('test-name', 'Campo obrigatório', function (value) {
              if (values.stUsuario === 0) {
                const { path, createError } = this;
                return value && value.trim()
                  ? true
                  : createError({ path, message: 'Campo obrigatório' });
              } else {
                return true;
              }
            }),
            dtInativacao: Yup.date()
              .max(new Date(), 'Data maior que o dia atual')
              .test('test-name', 'Data menor que data de cadastro', function (value) {
                if (!value || !values.dtCadastro) return true;

                if (
                  moment(value).format('YYYY-MM-DD') === moment(values.dtCadastro).format('YYYY-MM-DD')
                ) {
                  return true;
                } else if (moment(value).isBefore(moment(values.dtCadastro))) {
                  const { path, createError } = this;
                  return createError({ path, message: 'Data menor que data de cadastro' });
                }
                return true;
              })
              .typeError('Digite uma data válida')
              .nullable(true),
          })
        )}
        render={({ values, handleSubmit, errors, touched, handleBlur, setFieldValue }) => (
          <div style={{ width: '100%' }}>
            <CardIcon titulo='Identificação de usuário'>
              <form autoComplete='off'>
                <Grid container spacing={0} style={{ marginTop: 15 }}>
                  <Grid item xs={4} style={{ height: 70, paddingRight: 15 }}>
                    <FastField name='nmUsuario'>
                      {({ field, form }) => (
                        <TextField
                          {...field}
                          label='Nome'
                          id='nmUsuario'
                          value={form.values.nmUsuario}
                          onBlur={form.handleBlur}
                          onChange={(e) => form.setFieldValue('nmUsuario', e.target.value)}
                          inputProps={{ maxLength: 100 }}
                          error={form.errors.nmUsuario && form.touched.nmUsuario}
                          helperText={
                            form.errors.nmUsuario && form.touched.nmUsuario
                              ? form.errors.nmUsuario
                              : null
                          }
                        />
                      )}
                    </FastField>
                  </Grid>

                  <Grid item xs={4} style={{ height: 70, paddingRight: 15 }}>
                    <FastField name='dsEmail'>
                      {({ field, form }) => (
                        <TextField
                          {...field}
                          label='E-mail'
                          id='dsEmail'
                          name='dsEmail'
                          type='email'
                          value={form.values.dsEmail}
                          onBlur={form.handleBlur}
                          onChange={(e) =>
                            !this.state.usuario.idUsuario &&
                            form.setFieldValue('dsEmail', e.target.value)
                          }
                          disabled={!!this.state.usuario.idUsuario}
                          inputProps={{ maxLength: 100, autoComplete: 'off' }}
                          autoComplete='off'
                          error={form.errors.dsEmail && form.touched.dsEmail}
                          helperText={
                            form.errors.dsEmail && form.touched.dsEmail ? form.errors.dsEmail : null
                          }
                        />
                      )}
                    </FastField>
                  </Grid>

                  <Grid item xs={2} style={{ height: 70, paddingRight: 15, color: 'black' }}>
                    <FastField name='nrTelefone'>
                      {({ field, form }) => (
                        <FormattedInput
                          other={field}
                          label='Telefone'
                          name='nrTelefone'
                          id='nrTelefone'
                          tipoMascara={1}
                          value={values.nrTelefone}
                          onChange={(e) => {
                            e.target.name = 'nrTelefone';
                            form.setFieldValue('nrTelefone', e.target.value);
                          }}
                          onBlur={form.handleBlur}
                          error={form.errors.nrTelefone && form.touched.nrTelefone}
                          helperText={
                            form.errors.nrTelefone && form.touched.nrTelefone
                              ? form.errors.nrTelefone
                              : null
                          }
                        />
                      )}
                    </FastField>
                  </Grid>

                  <Grid item xs={2} style={{ height: 70 }}>
                    <FormSelectOwn
                      label='Perfil'
                      name='tpUsuario'
                      error={errors.tpUsuario && touched.tpUsuario}
                      value={values.tpUsuario}
                      onChange={(e) => setFieldValue('tpUsuario', e.target.value)}
                      options={tpPerfilOptions}
                      optionLabel={(opt) => opt.name}
                      optionValue={(opt) => opt.value}
                      errorText={errors.tpUsuario}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={0} style={{ marginTop: 0, marginBottom: 15 }}>
                  <Grid item xs={2} style={{ height: 70, paddingRight: 15, marginTop: 10 }}>
                    <Typography noWrap>
                      <label className={classes.labelSwitch}>Ativo</label>
                      <Switch
                        color='primary'
                        name='stUsuario'
                        id='stUsuario'
                        value={values.stUsuario === 1}
                        checked={values.stUsuario === 1}
                        onChange={() => {
                          setFieldValue('stUsuario', values.stUsuario === 1 ? 0 : 1);
                          !values.dtInativacao && setFieldValue('dtInativacao', new Date());
                          this.setState({ enableInativacao: values.stUsuario === 1 });
                        }}
                      />
                    </Typography>
                  </Grid>

                  <Grid item xs={8} style={{ height: 70, paddingRight: 15 }}>
                    <AutoControlledTextField
                      id='dsInativacao'
                      name='dsInativacao'
                      label='Motivo da inativação'
                      value={values.dsInativacao}
                      onBlur={async (e) => {
                        e.persist();
                        await setFieldValue('dsInativacao', e.target.value);
                        handleBlur(e);
                      }}
                      disabled={!this.state.enableInativacao}
                      error={errors.dsInativacao && touched.dsInativacao && this.state.enableInativacao}
                      helperText={
                        errors.dsInativacao && touched.dsInativacao && this.state.enableInativacao
                          ? errors.dsInativacao
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={2} style={{ height: 70 }}>
                    <DatePicker
                      label='Data de inativação'
                      value={values.dtInativacao}
                      maxDate={new Date()}
                      minDate={this.state.usuario.dtCadastro}
                      disabled={!this.state.enableInativacao}
                      onChange={(value) => setFieldValue('dtInativacao', value)}
                      onBlur={handleBlur}
                      error={errors.dtInativacao && touched.dtInativacao}
                      errorText={errors.dtInativacao}
                      id='dtInativacao'
                      name='dtInativacao'
                    />
                  </Grid>
                </Grid>
              </form>
            </CardIcon>

            <ButtonFABMenu
              actionSalvar={{ onClick: handleSubmit }}
              actionCancelar={
                this.state.usuario.idUsuario
                  ? {
                      onClick: this.deleteUsuario,
                      label: 'Excluir',
                      icon: <ExcluirIcon style={{ color: '#2BA69F' }} />,
                    }
                  : { onClick: this.exit }
              }
              additionalActions={
                this.state.usuario.idUsuario
                  ? [
                      {
                        icon: <EmailIcon style={{ color: '#2BA69F' }} />,
                        label: 'Re-enviar e-mail',
                        onClick: this.reenviarEmail,
                      },
                    ]
                  : []
              }
              rootStyle={this.state.usuario.idUsuario ? { bottom: 270 } : {}}
            />
          </div>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  itensState: state.toolbarReducer,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ updateToolbar, enableLoading, changeListaContatos }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(style)(EditarUsuarios)));
