import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { updateToolbar, enableLoading } from '../../states/actions/ToolbarActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DataTable from './../../components/DataTable/DataTable';
import CardFilters from './../../components/CardFilters/CardFilters';
import ButtonFABMenu from './../../components/ButtonFABMenu/ButtonFABMenu';
import { Assessment, ArrowDownward, RateReview } from '@material-ui/icons';
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import swal from '@sweetalert/with-react';
import loadingSwal from '../../utils/functions/LoadingSwal/LoadingSwal';
import { getAllToDemonstrativos, getQuestionarioById } from '../../resources/api/questionario';
import { getDowloandDemonstrativoById, getDowloandDemonstrativoAbertoById } from '../../resources/api/demonstrativo';
import SwalMessage from '../../utils/functions/SwalMessage/SwalMessage';
import FilterUtil from '../filterUtil';
import * as moment from 'moment';

const style = theme => ({
  containerTable: {
    display: 'flex',
    height: '100%',
    marginTop: 15
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2BA69F'
    }
  },
  typography: {
    useNextVariants: true
  }
});

class Demonstrativos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemSelect: '',
      disabledEdit: true,
      disabledDelete: true,
      dataInTable: [],
      dataAppSync: [],
      head: [
        { field: 'dsTitulo', headerName: 'Título', col: 4 },
        {
          field: 'dataInicio',
          headerName: 'Data inicial',
          col: 2
        },
        { field: 'dataFinal', headerName: 'Data final', col: 2 },
        { field: 'situacao', headerName: 'Situação', col: 2 },
        { field: 'nrTotalRespostas', headerName: 'Total entrevistas', col: 2 }
      ],
      operationValues: [
        {
          label: 'Título',
          field: 'dsTitulo',
          type: 'string'
        },
        {
          label: 'Data inicial',
          field: 'dataInicio',
          type: 'date'
        },
        {
          label: 'Data final',
          field: 'dataFinal',
          type: 'date'
        },
        {
          label: 'Situação',
          field: 'stQuestionario',
          type: 'enum',
          options: [
            { label: 'Aberto', field: 'Aberto', value: '0' },
            { label: 'Em andamento', field: 'Em andamento', value: '1' },
            { label: 'Fechado', field: 'Fechado', value: '2' }
          ]
        },
        {
          label: 'Total entrevistas',
          field: 'nrTotalRespostas',
          type: 'number'
        }
      ]
    };

    const title = 'Demonstrativos';
    if (this.props.itensState.name !== title) {
      this.props.updateToolbar({ toolbar: title, name: title });
    }

    this.props.enableLoading(true);

    this._isMounted = false;

    this.selectChange = this.selectChange.bind(this);
    this.goAdd = this.goAdd.bind(this);
    this.goDownloadAbertas = this.goDownloadAbertas.bind(this);
    this.downloadPPTX = this.downloadPPTX.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.baixarArquivo = this.baixarArquivo.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.onFilter();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  selectChange(select) {
    if (select) {
      this.setState({
        itemSelect: select,
        disabledEdit: false,
        disabledDelete: false
      });
    } else {
      this.setState({
        itemSelect: '',
        disabledEdit: true,
        disabledDelete: true
      });
    }
  }

  goAdd() {
    loadingSwal({ title: 'Carregando' });
    getQuestionarioById(this.state.itemSelect.idQuestionario).then(res => {
      if (res.data.analiseItemList.length > 0) {
        this.props.history.push('/app/demonstrativos/analise/' + this.state.itemSelect.idQuestionario);
        swal.close();
      } else {
        SwalMessage({
          title: 'Questionário sem analises',
          text: 'Este questionário não contém análises cadastradas'
        });
      }
    });
  }

  goDownloadAbertas() {
    loadingSwal({ title: 'Carregando' });
    
    getDowloandDemonstrativoAbertoById(this.state.itemSelect.idQuestionario)
      .then(res => this.baixarArquivo(res.data))
      .catch(err => {
        SwalMessage({ title: 'Ocorreu um erro', text: 'Falha ao fazer o download das respostas abertas', err });
      });
  }


  downloadPPTX() {
    loadingSwal({ title: 'Carregando' });

    getDowloandDemonstrativoById(this.state.itemSelect.idQuestionario)
      .then(res => this.baixarArquivo(res.data.urlPptx))
      .catch(err => {
        if (err.response && err.response.data.codigo === 'QUESTIONARIO_SEM_ANALISES') {
          SwalMessage({
            title: 'Questionário sem analises',
            text: 'Este questionário não contém análises cadastradas'
          });
          return;
        }

        SwalMessage({ title: 'Ocorreu um erro', text: 'Falha ao fazer o download das análises', err });
      });
  }

  baixarArquivo(arquivo) {
    loadingSwal({ title: 'Baixando' });

    fetch(arquivo)
      .then(async res => {
        const data = await res.blob();

        const anchor = document.createElement('a');
        anchor.href = window.URL.createObjectURL(data);
        anchor.download = arquivo.split('.com.br/')[1];
        anchor.click();

        swal.close();
      })
      .catch(err =>
        SwalMessage({ title: 'Ocorreu um erro', text: 'Falha ao fazer o download das análises', err })
      );
  }

  onFilterChanged(dataChips) {
    this.setState({
      dataInTable: FilterUtil.applyFilters(this.state.dataAppSync, dataChips)
    });
  }

  onFilter(dataChips) {
    dataChips = dataChips || [];
    this.props.enableLoading(true);
    this._isMounted &&
      getAllToDemonstrativos()
        .then(res => {
          const data = res.data.map(item => ({
            ...item,
            situacao: ['Aberto', 'Em andamento', 'Fechado'][item.stQuestionario],
            dataInicio: moment(item.dtInicio).format('DD/MM/YYYY'),
            dataFinal: moment(item.dtTermino).format('DD/MM/YYYY')
          }));

          this._isMounted &&
            this.setState(
              {
                dataInTable: FilterUtil.applyFilters(data, dataChips),
                dataAppSync: data
              },
              () => this.props.enableLoading(false)
            );
        })
        .catch(err => {
          SwalMessage({
            title: 'Falha ao carregar página',
            text: 'Não foi possível carregar a página, tente novamente',
            err,
            callback: () => {
              this._isMounted && this.setState({ dataAppSync: [] });
              this.props.enableLoading(false);
            }
          });
        });
  }

  render() {
    const { classes } = this.props;
    const pagination = { length: this.state.dataInTable.length, page: 0 };

    return (
      <MuiThemeProvider theme={theme}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <CardFilters
            filtros={this.state.operationValues}
            onFilter={this.onFilter}
            onFilterChanged={this.onFilterChanged}
            usePaddings={false}
            viewKey='demonstrativos'
          />

          <div className={classes.containerTable}>
            <DataTable
              data={this.state.dataInTable}
              columns={this.state.head}
              selectChange={this.selectChange}
              showPagination={true}
              pagination={pagination}
              handleChangePage={() => {}}
              handleChangeRowsPerPage={() => {}}
            />
          </div>

          <ButtonFABMenu
            disabled={this.state.disabledEdit}
            rootStyle={{bottom: 270}}
            retireActions
            additionalActions={[
              {
                icon: <Assessment style={{ color: '#2BA69F' }} />,
                label: 'Visualizar',
                onClick: this.goAdd
              },
              {
                icon: <ArrowDownward style={{ color: '#2BA69F' }} />,
                label: 'Baixar',
                onClick: this.downloadPPTX
              },
              {
                icon: <RateReview style={{ color: '#2BA69F' }} />,
                label: 'Respostas abertas',
                onClick: this.goDownloadAbertas
              },
            ]}
          />
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbarReducer,
  chipData: state.cardFiltersReducer.chipData.filter(date => {
    return date.id === 'demonstrativos';
  })[0].content
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ updateToolbar, enableLoading }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(style)(Demonstrativos)));
