import ActionTypes from '../actionTypes/ClienteActionTypes';

const showButtonFAB = payload => ({ type: ActionTypes.SHOW_BUTTON_FAB, payload });

const changeListaContatos = payload => ({ type: ActionTypes.CHANGE_LISTA_CONTATOS_CLIENTE, payload });

const changeContatoSelected = payload => ({
  type: ActionTypes.CHANGE_CONTATO_SELECTED_CLIENTE,
  payload
});

const goAddContato = () => ({ type: ActionTypes.GO_ADD_CONTATO_CLIENTE });

const goEditContato = payload => ({ type: ActionTypes.GO_EDIT_CONTATO_CLIENTE, payload });

const exitEditContato = () => ({ type: ActionTypes.EXIT_EDIT_CONTATO_CLIENTE });

const defaultCliente = () => ({ type: ActionTypes.DEFAULT_CLIENTE });

export {
  showButtonFAB,
  changeListaContatos,
  changeContatoSelected,
  goAddContato,
  goEditContato,
  exitEditContato,
  defaultCliente
};
