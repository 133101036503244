import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormSelectOwn from '../../../components/FormSelectOwn/FormSelectOwn';
import CardIcon from '../../../components/CardIcon/CardIcon';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Checkbox from '../../../components/CheckboxOwn/CheckboxOwn';
import DataTable from '../../../components/DataTable/DataTable';
import clone from '../../../utils/functions/Clone/Clone';
import {
  showButtonFAB,
  hiddenButtonFAB,
  changeExcecaoSelected,
  changeListaPerguntas,
  exitEditExcecao
} from '../../../states/actions/QuestionarioActions';

const styles = theme => ({
  containerCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 290px)',
    marginBottom: 65,
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: 30
  },
  containerButtons: {
    position: 'absolute',
    bottom: 15,
    left: 0,
    paddingRight: 30,
    paddingTop: 15,
    borderTop: '1px solid rgba(0,0,0,0.12)'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  buttonActions: {
    boxShadow: '0px 3px 6px 0px rgba(0,0,0,0.24)'
  },
  containerCheck: {
    height: 50,
    color: 'black',
    fontSize: 14,
    fontFamily: '"Roboto"',
    paddingTop: 5
  },
  containerTable: {
    display: 'flex',
    minHeight: '28vh',
    maxWidth: '100vw',
    width: '100%'
  },
  containerTituloTable: {
    fontSize: 16,
    fontFamily: 'Roboto',
    color: 'gray',
    width: '100%',
    textAlign: 'left',
    borderBottom: '1px solid #cccccc',
    lineHeight: '0.1em',
    margin: '34px 0 15px'
  },
  tituloTable: {
    backgroundColor: 'white',
    padding: '0 10px',
    marginLeft: 15
  }
});

class EditarExcecao extends Component {
  constructor(props) {
    super(props);

    let questao = props.perguntasData.find(
      q => q.stRegistro !== 2 && q.idQuestao === props.excecaoSelected.idQuestao
    );

    if (!questao) {
      questao = {};
      props.exitEditExcecao();
    } else {
      const alternativaExcecao =
        questao.alternativaList.find(
          item =>
            item.stRegistro !== 2 &&
            item.idAlternativa === props.excecaoSelected.alternativaExcecao.idAlternativa
        ) || '';
      questao = {
        ...questao,
        alternativaExcecao,
        questaoAvanco: props.excecaoSelected.questaoAvanco,
        stFinalizaQuestionario: props.excecaoSelected.stFinalizaQuestionario,
        stDescartaEntrevista: props.excecaoSelected.stDescartaEntrevista
      };
    }

    const questaoAvanco =
      questao.questaoAvanco &&
      props.perguntasToAvanco.find(item => item.idQuestao === +questao.questaoAvanco.idQuestao)
        ? questao.questaoAvanco
        : null;

    this.state = {
      key: '',
      questoesAvanco: [],
      pergunta: {
        ...questao,
        questaoAvanco
      }
    };

    this.values = null;

    this.submit = this.submit.bind(this);
    this.exitEditing = this.exitEditing.bind(this);
    this.onChangeQuestaoAvanco = this.onChangeQuestaoAvanco.bind(this);
  }

  componentDidMount() {
    this.props.hiddenButtonFAB();
    if (this.state.pergunta.questaoAvanco) {
      this.setState({
        questoesAvanco: this.props.perguntasData.filter(
          item =>
            item.nrOrdem < this.state.pergunta.questaoAvanco.nrOrdem &&
            item.nrOrdem > this.values.nrOrdem
        )
      });
    }
  }

  componentWillUnmount() {
    this.props.changeExcecaoSelected({
      ...this.state.pergunta,
      alternativaExcecao: this.values.alternativaExcecao,
      questaoAvanco: this.values.questaoAvanco,
      stFinalizaQuestionario: this.values.stFinalizaQuestionario,
      stDescartaEntrevista: this.values.stDescartaEntrevista
    });
  }

  submit(values) {
    const auxLista = clone(this.props.perguntasData);
    const selected = this.state.pergunta;
    auxLista.forEach(item => {
      if (item.stRegistro !== 2 && item.idQuestao === selected.idQuestao) {
        selected.nrOrdem = item.nrOrdem;
      }
    });

    auxLista[selected.nrOrdem].alternativaList.forEach(opt => {
      opt.stFluxoExcecao = opt.idAlternativa === +values.alternativaExcecao.idAlternativa ? 1 : 0;
    });

    auxLista[selected.nrOrdem] = {
      ...auxLista[selected.nrOrdem],
      questaoAvanco: values.questaoAvanco,
      stFinalizaQuestionario: values.stFinalizaQuestionario,
      stDescartaEntrevista: values.stDescartaEntrevista
    };

    this.props.changeListaPerguntas(auxLista);

    this.exitEditing();
  }

  exitEditing() {
    this.props.exitEditExcecao();
    this.props.showButtonFAB();
  }

  onChangeQuestaoAvanco(value, callback) {
    const questaoAvanco = this.props.perguntasToAvanco.find(item => item.idQuestao === value.idQuestao);
    if (questaoAvanco) {
      this.setState({
        questoesAvanco: this.props.perguntasData.filter(
          item => item.nrOrdem < questaoAvanco.nrOrdem && item.nrOrdem > this.values.nrOrdem
        )
      });
    } else {
      this.setState({ questoesAvanco: [] });
    }

    callback();
  }

  render() {
    const { classes } = this.props;

    return (
      <CardIcon titulo='Editar exceção' styleCollapse={{ paddingRight: 0 }}>
        <div className={classes.containerCard}>
          <Formik
            key={this.state.key}
            initialValues={clone(this.state.pergunta)}
            onSubmit={this.submit}
            validateOnBlur
            enableReinitialize={false}
            validateOnChange={false}
            validationSchema={Yup.lazy(values =>
              Yup.object().shape({
                alternativaExcecao: Yup.object().required('Campo obrigatório'),
                questaoAvanco:
                  values.stFinalizaQuestionario === 0
                    ? Yup.object()
                        .typeError('Campo obrigatório')
                        .required('Campo obrigatório')
                    : Yup.object()
                        .typeError('Campo obrigatório')
                        .nullable(true),
                stFinalizaQuestionario: Yup.number(),
                stDescartaEntrevista: Yup.number()
              })
            )}
            render={({ errors, touched, handleBlur, handleSubmit, values, setFieldValue }) => {
              this.values = values;

              return (
                <>
                  <Grid container spacing={0} style={{ marginTop: 15, height: 70 }}>
                    <Grid item xs>
                      <FormSelectOwn
                        label='Questão com fluxo de exceção'
                        name='dsTitulo'
                        value={values.idQuestao || ''}
                        disabled={true}
                        onChange={() => {}}
                        options={[values]}
                        optionLabel={opt => `${opt.nrOrdem + 1} - ${opt.dsTitulo}`}
                        optionValue={opt => opt.idQuestao}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={0} style={{ height: 70 }}>
                    <Grid item xs>
                      <FormSelectOwn
                        label='Alternativa que dispara exceção'
                        name='alternativaExcecao'
                        value={
                          values.alternativaExcecao ? `${values.alternativaExcecao.idAlternativa}` : ''
                        }
                        onChange={e =>
                          setFieldValue('alternativaExcecao', { idAlternativa: +e.target.value })
                        }
                        onBlur={handleBlur}
                        options={(values.alternativaList || []).filter(item => item.stRegistro !== 2)}
                        optionLabel={(opt, i) => `${i + 1}) ${opt.dsAlternativa}`}
                        optionValue={opt => opt.idAlternativa}
                        error={errors.alternativaExcecao && touched.alternativaExcecao}
                        errorText={errors.alternativaExcecao}
                      />
                    </Grid>
                  </Grid>

                  <Grid container style={{ minHeight: 50 }}>
                    <Grid item xs={4} className={classes.containerCheck}>
                      <Checkbox
                        checked={values.stFinalizaQuestionario === 1}
                        name='stFinalizaQuestionario'
                        onChange={e => {
                          setFieldValue(
                            'stFinalizaQuestionario',
                            values.stFinalizaQuestionario === 0 ? 1 : 0
                          );
                          setFieldValue('questaoAvanco', null);
                          this.setState({ questoesAvanco: [] });
                          setFieldValue('stDescartaEntrevista', 0);
                        }}
                        label='Encerrar questionário ao disparar exceção'
                      />
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      className={classes.containerCheck}
                      style={{ opacity: values.stFinalizaQuestionario === 0 ? 0.54 : 1 }}
                    >
                      <Checkbox
                        checked={values.stDescartaEntrevista === 1}
                        name='stDescartaEntrevista'
                        onChange={e =>
                          values.stFinalizaQuestionario === 1 &&
                          setFieldValue(
                            'stDescartaEntrevista',
                            values.stDescartaEntrevista === 0 ? 1 : 0
                          )
                        }
                        disabled={values.stFinalizaQuestionario === 0}
                        label='Descartar entrevista'
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={0} style={{ height: 70 }}>
                    <Grid item xs>
                      <FormSelectOwn
                        label='Questão de avanço para outras alternativas'
                        name='questaoAvanco'
                        value={values.questaoAvanco ? values.questaoAvanco.idQuestao : ''}
                        onChange={e =>
                          values.stFinalizaQuestionario === 0 &&
                          this.onChangeQuestaoAvanco({ idQuestao: +e.target.value }, () =>
                            setFieldValue('questaoAvanco', { idQuestao: +e.target.value })
                          )
                        }
                        onBlur={handleBlur}
                        options={this.props.perguntasToAvanco}
                        optionLabel={opt => `${opt.nrOrdem + 1} - ${opt.dsTitulo}`}
                        optionValue={opt => opt.idQuestao}
                        disabled={values.stFinalizaQuestionario === 1}
                        error={
                          errors.questaoAvanco &&
                          touched.questaoAvanco &&
                          values.stFinalizaQuestionario !== 1
                        }
                        errorText={errors.questaoAvanco}
                      />
                    </Grid>
                  </Grid>

                  <Grid container style={{ marginTop: 10, marginBottom: 15 }}>
                    <Grid item xs>
                      <div className={classes.containerTituloTable}>
                        <span className={classes.tituloTable}>
                          {'Questões obrigatórias para a alternativa '}
                          <span style={{ fontStyle: 'italic' }}>{`${
                            values.alternativaExcecao
                              ? values.alternativaList.findIndex(
                                  item => item.idAlternativa === values.alternativaExcecao.idAlternativa
                                ) + 1
                              : ''
                          }) ${
                            values.alternativaExcecao
                              ? values.alternativaList.find(
                                  item => item.idAlternativa === values.alternativaExcecao.idAlternativa
                                ).dsAlternativa
                              : ''
                          }`}</span>
                        </span>
                      </div>
                      <div className={classes.containerTable}>
                        <DataTable
                          data={this.state.questoesAvanco}
                          columns={[
                            {
                              valueGetter: args =>
                                `${args.node.data.nrOrdem + 1} - ${args.node.data.dsTitulo}`,
                              headerName: '# - Título',
                              col: 12
                            }
                          ]}
                          defaultColDef={{ sortable: false }}
                          noRowSelection
                          borderRadius
                          noMargin
                          noPaddingHorizontal
                          noBox
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={0} className={classes.containerButtons}>
                    <Grid item xs>
                      <div className={classes.buttons}>
                        <Button
                          variant='contained'
                          color='secondary'
                          className={classes.buttonActions}
                          style={{ marginRight: 15, backgroundColor: '#124268' }}
                          onClick={this.exitEditing}
                        >
                          CANCELAR
                        </Button>

                        <Button
                          variant='contained'
                          color='secondary'
                          className={classes.buttonActions}
                          style={{ backgroundColor: '#2BA69F' }}
                          onClick={handleSubmit}
                        >
                          CONFIRMAR
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </>
              );
            }}
          />
        </div>
      </CardIcon>
    );
  }
}

const mapStateToProps = state => {
  const { tabExcecoesQuestionarioReducer, tabPerguntasQuestionarioReducer } = state.questionarioReducer;

  return {
    excecaoSelected: tabExcecoesQuestionarioReducer.excecaoSelected,
    atualizandoExcecao: tabExcecoesQuestionarioReducer.atualizandoExcecao,
    editandoExcecao: tabExcecoesQuestionarioReducer.editandoExcecao,
    perguntasData: tabPerguntasQuestionarioReducer.perguntasData,
    perguntasToAvanco: tabPerguntasQuestionarioReducer.perguntasData.filter(
      item =>
        item.stRegistro !== 2 &&
        item.idQuestao !== tabExcecoesQuestionarioReducer.excecaoSelected.idQuestao &&
        item.nrOrdem > tabExcecoesQuestionarioReducer.excecaoSelected.nrOrdem
    )
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      hiddenButtonFAB,
      showButtonFAB,
      changeExcecaoSelected,
      changeListaPerguntas,
      exitEditExcecao
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EditarExcecao));
