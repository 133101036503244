/*
    Autor: Bruno Eduardo
*/

import React from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { InlineDateTimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/moment';
import * as moment from 'moment';
import 'moment/locale/pt-br';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2BA69F'
    }
  },
  overrides: {
    MuiPrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: 'transparent !important'
      }
    },
    MuiInput: {
      formControl: {
        marginTop: '16px !important'
      },
      underline: {
        '&:hover:not($disabled):not($focused):not($error):before': { borderBottomColor: '#2BA69F' }
      }
    },
    MuiFormLabel: {
      root: {
        fontFamily: 'Roboto',
        fontSize: '14px !important'
      }
    },
    MuiInputBase: {
      input: {
        height: '20px !important'
      }
    }
  },
  typography: {
    useNextVariants: true
  }
});

const DateTimePicker = props => {
  const format = props.format || 'DD/MM/YYYY HH:mm';

  const onInputChange = value => {
    value = value.target.value;
    if (value && value.split('_').join('').length === 17 && moment(value, format).isValid()) {
      value = moment(value, format);
      props.onChange(value);
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={props.locale || 'pt-BR'}>
        <InlineDateTimePicker
          style={{ width: '100%' }}
          mask={[
            /\d/,
            /\d/,
            '/',
            /\d/,
            /\d/,
            '/',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            ' ',
            ' ',
            /\d/,
            /\d/,
            ':',
            /\d/,
            /\d/
          ]}
          keyboard
          ampm={false}
          format={format}
          locale={props.locale || 'pt-BR'}
          onInputChange={onInputChange}
          autoComplete='off'
          {...props}
        />
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};

export default DateTimePicker;
