import axios from 'axios';
import { URL_API } from '../common';

const URL_API_USUARIO = `${URL_API}/usuario`;

const getAllUsuarios = async () => {
  return await axios.get(URL_API_USUARIO);
};

const getUsuarioById = async id => {
  return await axios.get(URL_API_USUARIO + '/' + id);
};

const insertUsuario = async data => {
  return await axios.post(URL_API_USUARIO, data);
};

const updateUsuario = async data => {
  return await axios.put(URL_API_USUARIO, data);
};

const deleteUsuario = async id => {
  return await axios.delete(URL_API_USUARIO + '/' + id);
};

export { getAllUsuarios, getUsuarioById, insertUsuario, updateUsuario, deleteUsuario };
