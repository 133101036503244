import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { updateToolbar, enableLoading } from '../../states/actions/ToolbarActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    const title = 'Dashboard';
    if (this.props.itensState.name !== title) {
      this.props.updateToolbar({ toolbar: title, name: title });
    }
  }

  componentDidMount() {
    this.props.enableLoading(false);
  }

  render() {
    return <div />;
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbarReducer
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ updateToolbar, enableLoading }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Dashboard));
