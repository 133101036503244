//Autor: Gabriela Farias

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter, Prompt } from 'react-router-dom';
import CardIcon from '../../components/CardIcon/CardIcon';
import Grid from '@material-ui/core/Grid';
import { Formik, FastField } from 'formik';
import * as Yup from 'yup';
import ButtonFABMenu from '../../components/ButtonFABMenu/ButtonFABMenu';
import DatePicker from '../../components/DatePicker/DatePicker';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import * as moment from 'moment';
import Autocomplete from '../../components/AutoComplete/AutoComplete';
import CombTab from '../../components/CombTab/CombTab';
import HistoricoContatos from './historicoContatos/HistoricoContatos';
import { bindActionCreators } from 'redux';
import { updateToolbar, enableLoading } from '../../states/actions/ToolbarActions';
import { showButtonFAB, defaultCliente, changeListaContatos } from '../../states/actions/ClienteActions';
import { getMunicipiosByUf } from '../../resources/api/municipio';
import { getAllEstados } from '../../resources/api/estado';
import { insertCliente, updateCliente, getClienteById } from '../../resources/api/cliente';
import SwalMessage from '../../utils/functions/SwalMessage/SwalMessage';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormattedInput from '../../components/FormattedInputs/FormattedInputs';
import SwalConfirmacao from '../../utils/functions/SwalConfirmacao/SwalConfirmacao';
import TextField from '../../components/TextFieldOwn/TextFieldOwn';
import clone from '../../utils/functions/Clone/Clone';
import AutoControlledTextField from '../../components/AutoControlledTextField/AutoControlledTextField';
import loadingSwal from '../../utils/functions/LoadingSwal/LoadingSwal';

const style = () => ({
  containerScroll: {
    overflowY: 'auto',
    height: 'calc(100vh - 150px)'
  },
  labelSwitch: {
    color: 'black',
    fontFamily: 'Roboto',
    fontSize: 14
  }
});

class EditarClientes extends Component {
  constructor(props) {
    super(props);

    this.initialCliente = {
      idCliente: this.props.match.params.id || null,
      nmFantasia: '',
      tpPessoa: 0,
      nrCnpj: '',
      nmContato: '',
      dsEmail: '',
      nrTelefone: '',
      dsInativacao: '',
      dtInativacao: null,
      dtCadastro: !this.props.match.params.id ? new Date() : null,
      nrCep: '',
      estado: '',
      municipio: '',
      dsBairro: '',
      dsLogradouro: '',
      nrEndereco: '',
      dsComplemento: '',
      stCliente: 1
    };

    this.initialContatos = clone(this.props.contatosData || []);

    this.state = {
      key: '',
      keyAutocompleteEstado: '',
      keyAutocompleteCidade: '',
      tabSelected: 0,
      enableInativacao: false,
      enableReinitialize: !!this.props.match.params.id,
      canLeave: false,
      estados: [],
      cidades: [],
      cliente: clone(this.initialCliente)
    };

    const title = 'Cadastro de cliente';
    if (this.props.itensState.name !== title) {
      this.props.updateToolbar({ toolbar: title, name: title });
    }
    this.props.enableLoading(true);

    this.isDirty = function() {
      return (
        JSON.stringify(this.initialCliente) !== JSON.stringify(this.values) ||
        JSON.stringify(this.initialContatos) !== JSON.stringify(this.props.contatosData) ||
        this.props.editandoContato
      );
    }.bind(this);

    window.onbeforeunload = function() {
      if (this.isDirty()) {
        return true;
      }
    }.bind(this);

    const stateAux = this.state;
    this.validaDsInativacao = function(value) {
      const { path, createError } = this;
      if (stateAux.cliente.stCliente === 0) {
        return value && value.trim() ? true : createError({ path, message: 'Campo obrigatório' });
      } else {
        return true;
      }
    };

    this._isMounted = false;

    this.handleSubmit = null;
    this.values = null;

    this.iniciarAtributos = this.iniciarAtributos.bind(this);
    this.submit = this.submit.bind(this);
    this.onTryExit = this.onTryExit.bind(this);
    this.loadSuggestionsAutocompleteEstado = this.loadSuggestionsAutocompleteEstado.bind(this);
    this.loadSuggestionsAutocompleteCidade = this.loadSuggestionsAutocompleteCidade.bind(this);
    this.valueAutocompleteEstado = this.valueAutocompleteEstado.bind(this);
    this.valueAutocompleteCidade = this.valueAutocompleteCidade.bind(this);
    this.onChangeAutocompleteEstado = this.onChangeAutocompleteEstado.bind(this);
    this.onChangeAutocompleteCidade = this.onChangeAutocompleteCidade.bind(this);
    this.onTrySubmit = this.onTrySubmit.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.showButtonFAB(true);
    this.iniciarAtributos();
    this.loadSuggestionsAutocompleteEstado();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.defaultCliente();
    window.onbeforeunload = undefined;
  }

  submit() {
    loadingSwal({ title: !this.state.cliente.idCliente ? 'Salvando' : 'Atualizando' });

    this.values.dtInativacao =
      this.values.dtInativacao !== null
        ? moment(this.values.dtInativacao).format('YYYY-MM-DDTHH:mm:ssZZ')
        : null;
    this.values.dtCadastro =
      this.values.dtCadastro !== null
        ? moment(this.values.dtCadastro).format('YYYY-MM-DDTHH:mm:ssZZ')
        : null;
    this.values.clienteContatoList = this.props.contatosData;
    this.values.municipio = this.values.municipio || null;

    if (!this.state.cliente.idCliente) {
      this._isMounted &&
        insertCliente(this.values)
          .then(() => {
            SwalMessage({
              title: 'Sucesso',
              text: 'Cliente cadastrado com sucesso',
              icon: 'success',
              callback: () =>
                this._isMounted &&
                this.setState({ canLeave: true }, () => this.props.history.push('/app/cliente'))
            });
          })
          .catch(err => {
            SwalMessage({
              title: 'Falha ao validar cadastro',
              text: 'Não foi possível validar seu cadastro, tente novamente',
              err
            });
          });
    } else {
      this._isMounted &&
        updateCliente(this.values)
          .then(() => {
            SwalMessage({
              title: 'Sucesso',
              text: 'Cliente atualizado com sucesso',
              icon: 'success',
              callback: () =>
                this._isMounted &&
                this.setState({ canLeave: true }, () => this.props.history.push('/app/cliente'))
            });
          })
          .catch(err => {
            SwalMessage({
              title: 'Falha ao validar cadastro',
              text: 'Não foi possível validar sua edição, tente novamente',
              err
            });
          });
    }
  }

  iniciarAtributos() {
    if (this.state.cliente.idCliente) {
      this._isMounted &&
        getClienteById(this.state.cliente.idCliente)
          .then(res => {
            const cliente = {
              idCliente: res.data.idCliente,
              nmFantasia: res.data.nmFantasia,
              tpPessoa: res.data.tpPessoa,
              nrCnpj: res.data.nrCnpj,
              nmContato: res.data.nmContato,
              dsEmail: res.data.dsEmail,
              nrTelefone: res.data.nrTelefone,
              dsInativacao: res.data.dsInativacao || '',
              dtInativacao: res.data.dtInativacao || null,
              dtCadastro: res.data.dtCadastro,
              nrCep: res.data.nrCep,
              municipio: res.data.municipio,
              dsBairro: res.data.dsBairro,
              dsLogradouro: res.data.dsLogradouro,
              nrEndereco: res.data.nrEndereco,
              dsComplemento: res.data.dsComplemento,
              stCliente: res.data.stCliente,
              estado: (res.data.municipio || { uf: '' }).uf
            };

            this._isMounted &&
              this.setState(
                {
                  key: Math.random(),
                  keyAutocompleteCidade: Math.random(),
                  keyAutocompleteEstado: Math.random(),
                  cliente,
                  enableInativacao: res.data.stCliente === 0,
                  enableReinitialize: false
                },
                () => {
                  const contatos = res.data.clienteContatoList.map((item, index) => ({
                    ...item,
                    dtContatoUI: moment(item.dtContato).format('DD/MM/YYYY'),
                    hrContato: moment(item.dtContato).format('HH:mm'),
                    index,
                    nmUsuario: item.usuarioDTO.nmUsuario,
                    clienteAnexoList: item.clienteAnexoList || []
                  }));

                  this.initialCliente = clone(cliente);
                  this.initialContatos = clone(contatos);

                  this.props.changeListaContatos(contatos);
                  this.props.enableLoading(false);
                }
              );
          })
          .catch(err => {
            SwalMessage({
              title: 'Falha ao carregar usuário',
              text: 'Não foi possível carregar o usuário para edição, tente novamente',
              err,
              callback: () => this.props.enableLoading(false)
            });
          });
    } else {
      this.props.enableLoading(false);
    }
  }

  onTrySubmit() {
    if (this.props.editandoContato) {
      SwalMessage({
        text: 'Termine a edição do contato',
        callback: () => this.setState({ tabSelected: 1 })
      });
      return;
    }

    this.setState({ tabSelected: 0 });
    this.handleSubmit && this.handleSubmit();
  }

  loadSuggestionsAutocompleteEstado() {
    this._isMounted &&
      getAllEstados()
        .then(res => this._isMounted && this.setState({ estados: res.data }))
        .catch(err =>
          SwalMessage({
            title: 'Falha ao carregar estados',
            text: 'Não foi possível carregar os estados, tente novamente',
            err,
            callback: () => this._isMounted && this.setState({ estados: [] })
          })
        );
  }

  valueAutocompleteEstado() {
    if (this.state.cliente.estado && this.state.cliente.estado.nmUf !== undefined) {
      return this.state.cliente.estado;
    }
    return '';
  }

  onChangeAutocompleteEstado(value, setFieldValue) {
    this.setState(
      {
        keyAutocompleteEstado: Math.random(),
        keyAutocompleteCidade: Math.random(),
        cliente: { ...this.state.cliente, estado: value, municipio: '' }
      },
      () => this.loadSuggestionsAutocompleteCidade(value)
    );
    setFieldValue('municipio', '');
    setFieldValue('estado', value);
  }

  loadSuggestionsAutocompleteCidade(estado) {
    if (estado && estado.idUf) {
      this._isMounted &&
        getMunicipiosByUf(estado.idUf)
          .then(res => this._isMounted && this.setState({ cidades: res.data }))
          .catch(err =>
            SwalMessage({
              title: 'Falha ao carregar cidades',
              text: 'Não foi possível carregar as cidades, selecione o estado novamente',
              err,
              callback: () => this._isMounted && this.setState({ cidades: [] })
            })
          );
    }
  }

  valueAutocompleteCidade() {
    if (this.state.cliente.municipio && this.state.cliente.municipio.nmMunicipio !== undefined) {
      return this.state.cliente.municipio;
    }
    return '';
  }

  onChangeAutocompleteCidade(value, setFieldValue) {
    this.setState({
      keyAutocompleteCidade: Math.random(),
      cliente: { ...this.state.cliente, municipio: value }
    });
    setFieldValue('municipio', value);
  }

  onTryExit(location) {
    if (this.isDirty()) {
      SwalConfirmacao({
        text: 'Existem alterações não salvas, deseja sair e descarta-las?',
        textConfirm: 'Sair',
        textCancel: 'Voltar',
        config: { closeOnClickOutside: false }
      }).then(res => {
        if (res) {
          this.setState(
            { canLeave: true },
            () => this.state.canLeave && this.props.history.push(location.pathname)
          );
        }
      });
      return false;
    }

    this.setState(
      { canLeave: true },
      () => this.state.canLeave && this.props.history.push(location.pathname)
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Prompt when={!this.state.canLeave} message={this.onTryExit} />

        <Formik
          key={this.state.key}
          initialValues={clone(this.state.cliente)}
          onSubmit={this.submit}
          validateOnBlur
          enableReinitialize={this.state.enableReinitialize}
          validateOnChange={false}
          validationSchema={Yup.lazy(values =>
            Yup.object().shape({
              nmFantasia: Yup.string()
                .max(200, 'O máximo é 200 caracteres')
                .required('Campo obrigatório'),
              nmContato: Yup.string().max(200, 'O máximo é 200 caracteres'),
              nrCnpj:
                +values.tpPessoa === 1
                  ? Yup.string().length(18, 'Digite um CNPJ válido')
                  : Yup.string().min(14, 'Digite um CPF válido'),
              tpPessoa: Yup.number(),
              dsEmail: Yup.string()
                .email('Digite um E-mail válido')
                .max(100, 'O máximo é 100 caracteres'),
              nrTelefone: Yup.string()
                .min(14, 'Mínimo de 10 caracteres')
                .max(15, 'Máximo de 11 caracteres'),
              dsInativacao: Yup.string().test('test-name', 'Campo obrigatório', function(value) {
                if (values.stCliente === 0) {
                  const { path, createError } = this;
                  return value && value.trim()
                    ? true
                    : createError({ path, message: 'Campo obrigatório' });
                } else {
                  return true;
                }
              }),
              dtInativacao: Yup.date()
                .max(new Date(), 'Data maior que o dia atual')
                .test('test-name', 'Data menor que data de cadastro', function(value) {
                  if (!value || !values.dtCadastro) return true;

                  if (
                    moment(value).format('YYYY-MM-DD') === moment(values.dtCadastro).format('YYYY-MM-DD')
                  ) {
                    return true;
                  } else if (moment(value).isBefore(moment(values.dtCadastro))) {
                    const { path, createError } = this;
                    return createError({ path, message: 'Data menor que data de cadastro' });
                  }
                  return true;
                })
                .typeError('Digite uma data válida')
                .nullable(),
              dtCadastro: Yup.date()
                .required('Digite uma data válida')
                .typeError('Digite uma data válida'),
              nrCep: Yup.string().max(10, 'O máximo é 10 caracteres'),
              dsBairro: Yup.string().max(40, 'O máximo é 40 caracteres'),
              dsLogradouro: Yup.string().max(40, 'O máximo é 40 caracteres'),
              nrEndereco: Yup.number()
                .typeError('Digite apenas números')
                .min(0, 'Digite um número válido')
                .max(9999999999, 'Digite um número válido'),
              dsComplemento: Yup.string().max(100, 'O máximo é 100 caracteres'),
              stCliente: Yup.number()
            })
          )}
          render={({ values, errors, touched, handleBlur, setFieldValue, handleChange, submitForm }) => {
            this.handleSubmit = submitForm;
            this.values = { ...values, tpPessoa: +values.tpPessoa };

            return (
              <div style={{ width: '100%' }}>
                <CombTab
                  onChangeTab={tab =>
                    this.setState(
                      { tabSelected: tab },
                      () => tab === 0 && this.props.showButtonFAB(true)
                    )
                  }
                  tabSelected={this.state.tabSelected}
                  tab={[
                    {
                      title: 'Identificação',
                      value: 0,
                      content: (
                        <div className={classes.containerScroll}>
                          <form autoComplete='off'>
                            <CardIcon titulo='Identificação de cliente'>
                              <Grid container style={{ marginTop: 15, marginBottom: 5 }}>
                                <Grid item xs={4} style={{ height: 70, paddingRight: 15 }}>
                                  <FormControl
                                    component='fieldset'
                                    error={errors.tpPessoa && touched.tpPessoa}
                                  >
                                    <FormLabel
                                      component='legend'
                                      style={{ fontFamily: 'Roboto', fontSize: 12 }}
                                    >
                                      Tipo de pessoa
                                    </FormLabel>
                                    <RadioGroup
                                      name='tpPessoa'
                                      id='tpPessoa'
                                      value={values.tpPessoa + ''}
                                      onChange={handleChange}
                                      style={{ paddingTop: 0 }}
                                      row
                                    >
                                      <FormControlLabel
                                        value='0'
                                        control={<Radio color='primary' />}
                                        label='Pessoa física'
                                        labelPlacement='end'
                                        style={{
                                          opacity: this.values.tpPessoa === 1 ? 0.54 : 1
                                        }}
                                      />
                                      <FormControlLabel
                                        value='1'
                                        control={<Radio color='primary' />}
                                        label='Pessoa jurídica'
                                        labelPlacement='end'
                                        style={{
                                          opacity: this.values.tpPessoa === 0 ? 0.54 : 1
                                        }}
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>

                                <Grid item xs={4} style={{ height: 70, paddingRight: 15 }}>
                                  <AutoControlledTextField
                                    id='nmFantasia'
                                    name='nmFantasia'
                                    label={this.values.tpPessoa === 1 ? 'Nome fantasia' : 'Nome'}
                                    value={values.nmFantasia}
                                    onBlur={async e => {
                                      e.persist();
                                      await setFieldValue('nmFantasia', e.target.value);
                                      handleBlur(e);
                                    }}
                                    error={errors.nmFantasia && touched.nmFantasia}
                                    helperText={
                                      errors.nmFantasia && touched.nmFantasia ? errors.nmFantasia : null
                                    }
                                    inputProps={{ maxLength: 200 }}
                                  />
                                </Grid>

                                <Grid item xs={2} style={{ height: 70, paddingRight: 15 }}>
                                  {this.values.tpPessoa === 1 ? (
                                    <FormattedInput
                                      label='CNPJ'
                                      name='nrCnpj'
                                      id='nrCnpj'
                                      tipoMascara={2}
                                      onChange={e => {
                                        e.target.name = 'nrCnpj';
                                        handleChange(e);
                                      }}
                                      onBlur={handleBlur}
                                      error={errors.nrCnpj && touched.nrCnpj}
                                      helperText={errors.nrCnpj && touched.nrCnpj ? errors.nrCnpj : null}
                                    />
                                  ) : (
                                    <FormattedInput
                                      label='CPF'
                                      name='nrCnpj'
                                      id='nrCnpj'
                                      tipoMascara={5}
                                      onChange={e => {
                                        e.target.name = 'nrCnpj';
                                        handleChange(e);
                                      }}
                                      onBlur={handleBlur}
                                      error={errors.nrCnpj && touched.nrCnpj}
                                      helperText={errors.nrCnpj && touched.nrCnpj ? errors.nrCnpj : null}
                                    />
                                  )}
                                </Grid>
                              </Grid>

                              <Grid container style={{ marginTop: 0 }}>
                                <Grid item xs={4} style={{ height: 70, paddingRight: 15 }}>
                                  <FastField name='nmContato'>
                                    {({ form, field }) => (
                                      <TextField
                                        {...field}
                                        id='nmContato'
                                        label='Pessoa para contato'
                                        onBlur={form.handleBlur}
                                        error={form.errors.nmContato && form.touched.nmContato}
                                        helperText={
                                          form.errors.nmContato && form.touched.nmContato
                                            ? form.errors.nmContato
                                            : null
                                        }
                                        inputProps={{ maxLength: 200 }}
                                      />
                                    )}
                                  </FastField>
                                </Grid>

                                <Grid item xs={4} style={{ height: 70, paddingRight: 15 }}>
                                  <FastField name='dsEmail'>
                                    {({ form, field }) => (
                                      <TextField
                                        {...field}
                                        id='dsEmail'
                                        label='E-mail'
                                        type='email'
                                        onBlur={form.handleBlur}
                                        error={form.errors.dsEmail && form.touched.dsEmail}
                                        helperText={
                                          form.errors.dsEmail && form.touched.dsEmail
                                            ? form.errors.dsEmail
                                            : null
                                        }
                                        inputProps={{
                                          maxLength: 100
                                        }}
                                      />
                                    )}
                                  </FastField>
                                </Grid>

                                <Grid item xs={2} style={{ height: 70, paddingRight: 15 }}>
                                  <FastField name='nrTelefone'>
                                    {({ form, field }) => (
                                      <FormattedInput
                                        {...field}
                                        label='Telefone'
                                        id='nrTelefone'
                                        tipoMascara={1}
                                        onChange={e => {
                                          e.target.name = 'nrTelefone';
                                          form.handleChange(e);
                                        }}
                                        onBlur={form.handleBlur}
                                        error={form.errors.nrTelefone && form.touched.nrTelefone}
                                        helperText={
                                          form.errors.nrTelefone && form.touched.nrTelefone
                                            ? form.errors.nrTelefone
                                            : null
                                        }
                                      />
                                    )}
                                  </FastField>
                                </Grid>

                                <Grid item xs={2} style={{ height: 70 }}>
                                  <DatePicker
                                    label='Data de cadastro'
                                    disabled={true}
                                    value={values.dtCadastro}
                                    onChange={() => {}}
                                    id='dtCadastro'
                                    name='dtCadastro'
                                  />
                                </Grid>
                              </Grid>

                              <Grid container style={{ marginTop: 0, marginBottom: 15 }}>
                                <Grid
                                  item
                                  xs={2}
                                  style={{ height: 70, paddingRight: 15, marginTop: 10 }}
                                >
                                  <Typography noWrap>
                                    <label className={classes.labelSwitch}>Ativo</label>
                                    <Switch
                                      color='primary'
                                      name='stCliente'
                                      id='stCliente'
                                      value={values.stCliente === 1}
                                      checked={values.stCliente === 1}
                                      onChange={() => {
                                        setFieldValue('stCliente', values.stCliente === 1 ? 0 : 1);
                                        !values.dtInativacao &&
                                          setFieldValue('dtInativacao', new Date());
                                        this.setState({ enableInativacao: values.stCliente === 1 });
                                      }}
                                    />
                                  </Typography>
                                </Grid>

                                <Grid item xs={8} style={{ height: 70, paddingRight: 15 }}>
                                  <AutoControlledTextField
                                    id='dsInativacao'
                                    name='dsInativacao'
                                    label='Motivo da inativação'
                                    value={values.dsInativacao}
                                    onBlur={async e => {
                                      e.persist();
                                      await setFieldValue('dsInativacao', e.target.value);
                                      handleBlur(e);
                                    }}
                                    disabled={!this.state.enableInativacao}
                                    error={
                                      errors.dsInativacao &&
                                      touched.dsInativacao &&
                                      this.state.enableInativacao
                                    }
                                    helperText={
                                      errors.dsInativacao &&
                                      touched.dsInativacao &&
                                      this.state.enableInativacao
                                        ? errors.dsInativacao
                                        : null
                                    }
                                  />
                                </Grid>

                                <Grid item xs={2} style={{ height: 70 }}>
                                  <DatePicker
                                    label='Data de inativação'
                                    value={values.dtInativacao}
                                    maxDate={new Date()}
                                    minDate={this.values.dtCadastro}
                                    disabled={!this.state.enableInativacao}
                                    onChange={value => setFieldValue('dtInativacao', value)}
                                    onBlur={handleBlur}
                                    error={errors.dtInativacao && touched.dtInativacao && true}
                                    errorText={errors.dtInativacao}
                                    id='dtInativacao'
                                    name='dtInativacao'
                                  />
                                </Grid>
                              </Grid>
                            </CardIcon>

                            <CardIcon titulo='Endereço' rootStyles={{ marginTop: 15 }}>
                              <Grid container style={{ marginTop: 5 }}>
                                <Grid item xs={2} style={{ height: 70, paddingRight: 15 }}>
                                  <FastField name='nrCep'>
                                    {({ form, field }) => (
                                      <FormattedInput
                                        {...field}
                                        label='CEP'
                                        id='nrCep'
                                        tipoMascara={4}
                                        onChange={e => {
                                          e.target.name = 'nrCep';
                                          form.handleChange(e);
                                        }}
                                        onBlur={form.handleBlur}
                                        error={form.errors.nrCep && touched.nrCep}
                                        helperText={
                                          form.errors.nrCep && touched.nrCep ? form.errors.nrCep : null
                                        }
                                      />
                                    )}
                                  </FastField>
                                </Grid>

                                <Grid item xs={2} style={{ height: 70, paddingRight: 15 }}>
                                  <Autocomplete
                                    itens={this.state.estados}
                                    campoOp='nmUf'
                                    campoChave='idUf'
                                    label='Estado'
                                    name='estado'
                                    id='estado'
                                    error={errors.estado && touched.estado && true}
                                    helperText={
                                      errors.estado && touched.estado && true ? errors.estado : null
                                    }
                                    onBlurOwn={handleBlur}
                                    onChangeOwn={value =>
                                      this.onChangeAutocompleteEstado(value, setFieldValue)
                                    }
                                    onChangeAutoComplete={this.loadSuggestionsAutocompleteEstado}
                                    value={this.valueAutocompleteEstado()}
                                    key={this.state.keyAutocompleteEstado}
                                    valueAutoComplete={this.valueAutocompleteEstado()}
                                    valueSelect={this.valueAutocompleteEstado().nmUf}
                                    maxHeight='calc(100vh - 600px)'
                                  />
                                </Grid>

                                <Grid item xs={4} style={{ height: 70, paddingRight: 15 }}>
                                  <Autocomplete
                                    itens={this.state.cidades}
                                    campoOp='nmMunicipio'
                                    campoChave='idMunicipio'
                                    label='Cidade'
                                    name='municipio'
                                    id='municipio'
                                    error={errors.municipio && touched.municipio && true}
                                    helperText={
                                      errors.municipio && touched.municipio && true
                                        ? errors.municipio
                                        : null
                                    }
                                    onBlurOwn={handleBlur}
                                    onChangeOwn={value =>
                                      this.onChangeAutocompleteCidade(value, setFieldValue)
                                    }
                                    onChangeAutoComplete={() =>
                                      this.loadSuggestionsAutocompleteCidade(
                                        this.valueAutocompleteEstado()
                                      )
                                    }
                                    value={this.valueAutocompleteCidade()}
                                    key={this.state.keyAutocompleteCidade}
                                    valueAutoComplete={this.valueAutocompleteCidade()}
                                    valueSelect={this.valueAutocompleteCidade().nmMunicipio}
                                    maxHeight='calc(100vh - 600px)'
                                  />
                                </Grid>

                                <Grid item xs={4} style={{ height: 70 }}>
                                  <FastField name='dsBairro'>
                                    {({ form, field }) => (
                                      <TextField
                                        {...field}
                                        id='dsBairro'
                                        label='Bairro'
                                        onBlur={form.handleBlur}
                                        error={form.errors.dsBairro && form.touched.dsBairro}
                                        helperText={
                                          form.errors.dsBairro && form.touched.dsBairro
                                            ? form.errors.dsBairro
                                            : null
                                        }
                                        inputProps={{ maxLength: 40 }}
                                      />
                                    )}
                                  </FastField>
                                </Grid>
                              </Grid>

                              <Grid container style={{ marginTop: 0, marginBottom: 20 }}>
                                <Grid item xs={4} style={{ height: 70, paddingRight: 15 }}>
                                  <FastField name='dsLogradouro'>
                                    {({ form, field }) => (
                                      <TextField
                                        {...field}
                                        id='dsLogradouro'
                                        label='Rua'
                                        onBlur={form.handleBlur}
                                        error={form.errors.dsLogradouro && form.touched.dsLogradouro}
                                        helperText={
                                          form.errors.dsLogradouro && form.touched.dsLogradouro
                                            ? form.errors.dsLogradouro
                                            : null
                                        }
                                        inputProps={{ maxLength: 40 }}
                                      />
                                    )}
                                  </FastField>
                                </Grid>

                                <Grid item xs={1} style={{ height: 70, paddingRight: 15 }}>
                                  <FastField name='nrEndereco'>
                                    {({ form, field }) => (
                                      <TextField
                                        {...field}
                                        id='nrEndereco'
                                        label='Número'
                                        onBlur={form.handleBlur}
                                        error={form.errors.nrEndereco && form.touched.nrEndereco}
                                        helperText={
                                          form.errors.nrEndereco && form.touched.nrEndereco
                                            ? form.errors.nrEndereco
                                            : null
                                        }
                                        inputProps={{ maxLength: 10 }}
                                      />
                                    )}
                                  </FastField>
                                </Grid>

                                <Grid item xs={3} style={{ height: 70, paddingRight: 15 }}>
                                  <FastField name='dsComplemento'>
                                    {({ form, field }) => (
                                      <TextField
                                        {...field}
                                        id='dsComplemento'
                                        label='Complemento'
                                        onBlur={form.handleBlur}
                                        error={form.errors.dsComplemento && form.touched.dsComplemento}
                                        helperText={
                                          form.errors.dsComplemento && form.touched.dsComplemento
                                            ? form.errors.dsComplemento
                                            : null
                                        }
                                        inputProps={{ maxLength: 100 }}
                                      />
                                    )}
                                  </FastField>
                                </Grid>
                              </Grid>
                            </CardIcon>
                          </form>
                        </div>
                      )
                    },
                    {
                      title: 'Histórico de contato',
                      value: 1,
                      content: <HistoricoContatos />
                    }
                  ]}
                />

                {this.props.buttonFabEnabled && (
                  <ButtonFABMenu
                    actionSalvar={{ onClick: this.onTrySubmit }}
                    actionCancelar={{ onClick: () => this.props.history.push('/app/cliente') }}
                  />
                )}
              </div>
            );
          }}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbarReducer,
  buttonFabEnabled: state.clienteReducer.buttonFabEnabled,
  contatosData: state.clienteReducer.contatosData,
  editandoContato: state.clienteReducer.editandoContato
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { updateToolbar, enableLoading, showButtonFAB, defaultCliente, changeListaContatos },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(style)(EditarClientes)));
