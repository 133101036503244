/*
    Autor: Bruno Eduardo
*/

import React from 'react';
import Grid from '@material-ui/core/Grid';
import AutoControlledTextField from '../../components/AutoControlledTextField/AutoControlledTextField';

const RespostaAlternativas = props => {
  const { questaoResposta, indexQuestao, onChange } = props;

  return (
    <div style={{ marginTop: 25 }}>
      {questaoResposta.questao.tpQuestao === 2 && (
        <Grid container style={{ fontWeight: 'bold' }}>
          <Grid item xs={1} />

          <Grid item xs>
            {questaoResposta.questao.dsLinearInicio}
          </Grid>
        </Grid>
      )}

      <div>
        {questaoResposta.sinteseList.map((item, indexSintese) => (
          <Grid container key={`sintese${item.key}`} style={{ height: 70 }}>
            <Grid item xs={1} style={{ paddingRight: 15 }}>
              <AutoControlledTextField
                label='Respostas'
                name={`sintese${item.key}`}
                value={`${item.nrTotalRespostas !== undefined ? item.nrTotalRespostas : ''}`}
                onBlur={e => onChange(e, indexQuestao, indexSintese)}
                type='number'
                inputProps={{ maxLength: 10, min: 0 }}
              />
            </Grid>

            <Grid item xs style={{ fontSize: 16, display: 'flex', alignItems: 'center' }}>
              {`${(questaoResposta.questao.tpQuestao !== 2 ? indexSintese + 1 + ') ' : '') +
                item.alternativa.dsAlternativa}`}
            </Grid>
          </Grid>
        ))}
      </div>

      {questaoResposta.questao.tpQuestao === 2 && (
        <Grid container style={{ fontWeight: 'bold' }}>
          <Grid item xs={1} />

          <Grid item xs style={{ marginBottom: 15 }}>
            {questaoResposta.questao.dsLinearFinal}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default RespostaAlternativas;
