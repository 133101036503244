//@author Katia Miglioli
//string,number,date
import React from 'react';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '../SelectOwn/SelectOwn';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '../TextFieldOwn/TextFieldOwn';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import iconeAddFiltro from '../../assets/images/icon_add_filtros.png';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import '../../assets/styles/CardFilter.css';
import Chips from './FilterChip';
import * as moment from 'moment';
import DatePicker from '../DatePicker/DatePicker';
import { cleanChips, updatePreviousPage, addChips } from '../../states/actions/CardFiltersActions';
import { bindActionCreators } from 'redux';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '200%',
    marginTop: 15
  },
  formControl: {
    marginRight: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
    width: 204
  },
  divTitulo: {
    fontSize: 18,
    color: '#666666',
    position: 'relative',
    marginLeft: '65px'
  },
  divSelects: {
    display: 'flex'
  },
  divChips: {
    display: 'flex',
    minHeight: 50,
    marginBottom: 15
  },
  button: {
    marginTop: 20,
    marginBottom: 15,
    margin: theme.spacing.unit,
    minWight: 34,
    maxWight: 34,
    minHeight: 34,
    maxHeight: 34,
    marginRight: '25px',
    zIndex: 1
  },
  iconButton: {
    marginLeft: 0,
    marginRight: 0
  },
  divChipsText: {
    alignItems: 'center',
    color: '#7F7F7F',
    fontSize: 14,
    display: 'flex'
  },
  divButtonFiltrar: {
    position: 'relative',
    float: 'right',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    left: '25px'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    fontWeight: '300 !important'
  },
  textField: {
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    marginTop: 0,
    width: 204
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2BA69F'
    }
  },
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiInput: {
      underline: {
        '&:hover:not($disabled):not($error):not($focused):before': {
          borderBottom: '1px solid #2BA69F'
        }
      }
    }
  }
});

class CardFiltersOpen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mensagemChip: false,
      typeTextField: 'string',
      valueDate: null,
      filterDefault: true,
      relationshipSelects: 0,
      selectValue: '',
      selectFilter: '',
      currentEvent: '',
      operationTypeNumbers: [
        {
          label: 'Igual',
          field: 'Igual',
          type: '=='
        },
        {
          label: 'Maior ou igual',
          field: 'Maior ou igual',
          type: '>='
        },
        {
          label: 'Maior',
          field: 'Maior',
          type: '>'
        },
        {
          label: 'Menor ou igual',
          field: 'Menor ou igual',
          type: '<='
        },
        {
          label: 'Menor',
          field: 'Menor',
          type: '<'
        },
        {
          label: 'Diferente',
          field: 'Diferente',
          type: '!='
        }
      ],
      operationTypeString: [
        {
          label: 'Contendo',
          field: 'Contendo',
          type: '%%'
        }
      ],
      operationTypeEnum: [
        {
          label: 'Igual',
          field: 'Igual',
          type: '=='
        }
      ]
    };

    this.handleSubmit = this.handleSubmit.bind(this);

    this.updateStatePreviousPage();
  }

  validarEntrada(newChip) {
    var i;
    var a = 0;
    var chipDataSelf = this.treatChipsOutput(this.props.chipData);
    if (chipDataSelf[0] !== undefined) {
      for (i = 0; i < chipDataSelf.length; i++) {
        if (
          chipDataSelf[i].operation === newChip.operation &&
          chipDataSelf[i].filter.label === newChip.filter.label
        )
          a++;
      }
    }
    return a;
  }

  handleSubmit(values, { resetForm }) {
    var newChip = {
      filter: values.filter,
      operation: values.operation,
      value: values.value
    };

    var a = this.validarEntrada(newChip);

    if (a === 0) {
      this.setState({ mensagemChip: false });
      this.props.addChips({ id: this.props.viewKey, newChip });
    } else {
      this.setState({ mensagemChip: true });
    }
    if (!this.state.mensagemChip) {
      resetForm({ value: '', filter: '', operation: '' });
      this.setState({
        valueDate: null,
        selectValue: '',
        selectFilter: '',
        typeTextField: 'string'
      });
    }
    if (values.isSecondButton) {
      this.props.onFilter(this.treatChipsOutput(this.props.chipData));
    } else {
      this.props.onFilterChanged(this.treatChipsOutput(this.props.chipData));
    }
  }

  labelTypeDate(label) {
    const tempTipoDeFiltro = this.props.filtros;
    var i;
    for (i = 0; i < this.props.filtros.length; i++) {
      if (tempTipoDeFiltro[i].field === label) {
        return tempTipoDeFiltro[i].type;
      }
    }
  }

  dateSelects(label) {
    var type = this.labelTypeDate(label);
    if (type === 'number' || type === 'date') {
      this.TypeSelect = 1;
    } else if (type === 'string') {
      this.TypeSelect = 0;
    } else if (type === 'enum') {
      this.TypeSelect = 2;
    }
    this.setState(() => ({
      relationshipSelects: this.TypeSelect
    }));
    return this.TypeSelect;
  }

  verificarInput(label) {
    // INSERIR O SELECT PARA ENUM
    var type = this.labelTypeDate(label);
    this.setState(() => ({
      typeTextField: type
    }));
  }

  operationType() {
    switch (this.state.relationshipSelects) {
      case 1:
        return this.state.operationTypeNumbers;
      case 2:
        return this.state.operationTypeEnum;
      default:
        return this.state.operationTypeString;
    }
  }

  selectOptionsEnum() {
    var tempFiltros = this.props.filtros;
    var i = 0;
    for (i; i < tempFiltros.length; i++) {
      if (tempFiltros[i].field === this.state.currentEvent) {
        return tempFiltros[i].options;
      }
    }
    return null;
  }

  validarEnum(nameEnum) {
    //retorna o obj options a partir do field do select
    var i;
    var tempSelectOptionsEnum = this.selectOptionsEnum();
    for (i = 0; i < tempSelectOptionsEnum.length; i++) {
      if (tempSelectOptionsEnum[i].field === nameEnum) {
        return tempSelectOptionsEnum[i];
      }
    }
    return null;
  }

  validarFilter(filter) {
    var i;
    var tempFiltros = this.props.filtros;
    for (i = 0; i < tempFiltros.length; i++) {
      if (tempFiltros[i].field === filter) {
        return tempFiltros[i];
      }
    }
    return null;
  }

  updateStatePreviousPage() {
    //alterar a pagina anterior no redux
    const pagAnterior = this.props.paginaAnterior;
    if (!(pagAnterior === this.props.viewKey || pagAnterior === 'paginaAnterior')) {
      this.props.cleanChips({ id: this.props.paginaAnterior });
    }
    this.props.updatePreviousPage({ idClean: this.props.viewKey });
  }

  treatChipsOutput(dataChips) {
    var filterChips = [];
    for (var i = 0; i < dataChips.length; i++) {
      if (dataChips[i].id === this.props.viewKey) {
        //ALTERA PARA O ID DOS FILTROS DA TELA
        filterChips = dataChips[i].content;
      }
    }
    return filterChips;
  }

  render() {
    const { classes } = this.props;
    const filtros = this.props.filtros;
    const typeTextField = this.state.typeTextField;

    return (
      <MuiThemeProvider theme={theme}>
        <Formik
          initialValues={{
            filter: '',
            operation: '',
            value: '',
            isSecondButton: false
          }}
          validateOnChange={true}
          validateOnBlur
          onSubmit={this.handleSubmit}
          validationSchema={Yup.object().shape({
            value: Yup.string().required('Campo obrigatório'),
            filter: Yup.string().required('Campo obrigatório'),
            operation: Yup.string().required('Campo obrigatório')
          })}
          render={({
            values,
            handleSubmit,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue
          }) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                color: 'black'
              }}
            >
              <div>
                <div>
                  <div className={classes.divSelects}>
                    <div className={classes.divSelectsText}>
                      <form className={classes.root} autoComplete='off' onSubmit={handleSubmit}>
                        <FormControl
                          className={classes.formControl}
                          error={errors.filter && touched.filter}
                        >
                          <InputLabel style={{ fontSize: 14 }} htmlFor='tipo-filtro'>
                            Tipo de filtro
                          </InputLabel>
                          <Select
                            SelectDisplayProps={{
                              style: { backgroundColor: 'transparent' }
                            }}
                            input={<Input id='tipo-filtro' />}
                            value={this.state.selectFilter}
                            name='filter'
                            error={errors.filter && touched.filter}
                            onChange={event => {
                              handleChange(event);
                              this.verificarInput(event.target.value);
                              if (this.dateSelects(event.target.value) === 1) {
                                setFieldValue('operation', '');
                              } else if (this.dateSelects(event.target.value) === 0) {
                                setFieldValue('operation', '%%');
                              } else if (this.dateSelects(event.target.value) === 2) {
                                setFieldValue('operation', '==');
                              }
                              setFieldValue('filter', this.validarFilter(event.target.value));
                              setFieldValue('value', '');
                              this.setState(() => ({
                                mensagemChip: false,
                                valueDate: null,
                                currentEvent: event.target.value,
                                selectFilter: event.target.value
                              }));
                            }}
                            onBlur={handleBlur}
                          >
                            {filtros.map(filtro => (
                              <MenuItem key={filtros.indexOf(filtro)} value={filtro.field}>
                                {filtro.label}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText
                            error={true}
                            style={{
                              position: 'absolute',
                              marginTop: '30%',
                              display: this.state.mensagemChip ? 'flex' : 'none'
                            }}
                          >
                            Filtro duplicado
                          </FormHelperText>
                          <FormHelperText>
                            {errors.filter && touched.filter ? errors.filter : null}
                          </FormHelperText>
                        </FormControl>
                        <FormControl
                          className={classes.formControl}
                          error={errors.operation && touched.operation}
                        >
                          <InputLabel style={{ fontSize: 14 }} htmlFor='tipo-comparacao'>
                            Tipo de comparação
                          </InputLabel>
                          <Select
                            SelectDisplayProps={{
                              style: { backgroundColor: 'transparent' }
                            }}
                            name='operation'
                            value={values.operation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            input={<Input id='tipo-comparacao' />}
                          >
                            {this.operationType().map(operation => (
                              <MenuItem
                                key={this.operationType().indexOf(operation)}
                                value={operation.type}
                              >
                                {operation.label}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>
                            {errors.operation && touched.operation ? errors.operation : null}
                          </FormHelperText>
                        </FormControl>
                        <div className={classes.container} noValidate>
                          {(typeTextField === 'date' && (
                            <DatePicker
                              style={{ marginTop: 1 }}
                              label='Pesquisa'
                              value={this.state.valueDate}
                              error={errors.value && touched.value}
                              errorText={errors.value}
                              onBlur={date => {
                                let validation = moment(date.target.value, 'DD/MM/YYYY').isValid();

                                if (date.target.value !== '' && !validation) {
                                  setFieldValue('value', null);
                                }
                              }}
                              onChange={date => {
                                setFieldValue('value', date._d);
                                this.setState(() => ({
                                  valueDate: date._d
                                }));
                              }}
                            />
                          )) ||
                            (typeTextField === 'enum' && (
                              <FormControl
                                className={classes.formControl}
                                error={errors.value && touched.value}
                              >
                                <InputLabel style={{ fontSize: 14 }} htmlFor='tipo-pesquisa'>
                                  Pesquisa
                                </InputLabel>
                                <Select
                                  SelectDisplayProps={{
                                    style: { backgroundColor: 'transparent' }
                                  }}
                                  name='value'
                                  error={errors.value && touched.value}
                                  value={this.state.selectValue}
                                  onChange={event => {
                                    handleChange(event);

                                    this.setState(state => ({
                                      selectValue: event.target.value
                                    }));

                                    setFieldValue('value', this.validarEnum(event.target.value));
                                  }}
                                  onBlur={handleBlur}
                                  input={<Input id='tipo-pesquisa' />}
                                >
                                  {this.selectOptionsEnum().map(select => (
                                    <MenuItem
                                      key={this.selectOptionsEnum().indexOf(select)}
                                      value={select.field}
                                    >
                                      {select.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText>
                                  {errors.value && touched.value ? errors.value : null}
                                </FormHelperText>
                              </FormControl>
                            )) ||
                            ((typeTextField === 'string' || typeTextField === 'number') && (
                              <TextField
                                id='date'
                                label='Pesquisa'
                                type={typeTextField}
                                name='value'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.value}
                                error={errors.value && touched.value}
                                helperText={errors.value && touched.value ? 'Campo obrigatório' : null}
                                margin='normal'
                                className={classes.textField}
                              />
                            ))}
                        </div>
                        <Button className={classes.button} id='button' onClick={handleSubmit}>
                          <img className={classes.iconButton} src={iconeAddFiltro} alt=' ' />
                        </Button>
                      </form>
                    </div>
                  </div>
                  <div className={classes.divChips}>
                    <div className={classes.divChipsText}>Filtros adicionados:</div>
                    <Chips
                      marginBottomImg={false}
                      viewKey={this.props.viewKey}
                      onFilterChanged={this.props.onFilterChanged}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.divButtonFiltrar}>
                <div>
                  <Button
                    color='primary'
                    className={classes.button}
                    onClick={e => {
                      setFieldValue('isSecondButton', true);
                      handleSubmit(e);
                    }}
                  >
                    <div style={{ position: 'relative' }}>Filtrar</div>
                  </Button>
                </div>
              </div>
            </div>
          )}
        />
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  chipData: state.cardFiltersReducer.chipData,
  paginaAnterior: state.cardFiltersReducer.histPaginas.paginaAnterior
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ cleanChips, updatePreviousPage, addChips }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CardFiltersOpen));
