/*  
    Autor : Gabriela Farias 
*/
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Card from '@material-ui/core/Card';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './DataTable.css';
import clone from '../../utils/functions/Clone/Clone';

const styles = {
  card: {
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  }
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2BA69F'
    }
  },
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiTablePagination: {
      toolbar: {
        paddingRight: 0,
        paddingLeft: 0
      },
      caption: {
        color: '#000000',
        fontSize: 12
      },
      selectRoot: {
        color: '#000000',
        fontSize: 12,
        marginLeft: 8,
        marginRight: 15
      },
      actions: {
        marginLeft: 0
      },
      select: {
        paddingRight: 20,
        paddingLeft: 0
      }
    },
    MuiInputBase: {
      input: {
        padding: '7px 0 7px'
      }
    },
    MuiSelect: {
      selectMenu: {
        minHeight: 0
      }
    },
    MuiTableCell: {
      root: {
        padding: 0
      }
    }
  }
});

/*
    Componente de Tabela customizado
    Métodos:
    data: registro da tabela
    orderChange: método chamado ao solicitar ordenaçao das colunas
    selectChange: método chamado ao clicar em um registro
    columns: atributo com o nome das colunas, 
    deve conter field( nome da coluna ), headerName( nome apresentavel da coluna ), 
    sort ( para a coluna conter desc/asc ao iniciar ), pinned ( coluna fixa left/right ), 
    cellClass, headerClass, suppressSizeToFit ( tamanho da grid fixo )
    showPagination: apresentar o rodapé (true ou false )
    pagination: objeto contendo dados da paginação da tabela,
    deve conter length, rowsPerPage, page, rowsPerPageOptions, onChangePage( row ) => row.rowsPerPage, row.page), 
  */

class DataTable extends Component {
  constructor(props) {
    super(props);

    this.props.columns.map((column, i) => {
      column.cellClass = 'col-grid-' + column.col + ' grid-padding-left';
      column.headerClass = 'col-grid-' + column.col + ' grid-padding-left';
      if (this.props.enableDrag) {
        delete column.sort;
        if (i === 0) column.headerClass += ' padding-column-drag';
      }
      return column;
    });

    this.state = {
      currentSelection: [],
      sortColumn: [],
      page: this.props.pagination ? this.props.pagination.page : 0,
      rowsPerPage: 25,
      gridOptions: {
        accentedSort: true,
        suppressMovableColumns: true,
        headerHeight: 56,
        columnDefs: this.props.columns,
        colResizeDefault: 'shift',
        autoHeight: true,
        suppressPropertyNamesCheck: true,
        rowSelection: this.props.noRowSelection ? undefined : 'single',
        rowDeselection: true,
        rowMultiSelectWithClick: true,
        suppressCellSelection: true,
        suppressClickEdit: true,
        suppressMultiSort: !this.props.enableDrag,
        suppressColumnVirtualisation: true,
        localeText: { noRowsToShow: ' ' },
        defaultColDef: {
          resizable: false,
          sortable: !this.props.enableDrag,
          ...this.props.defaultColDef
        },
        onGridSizeChanged: () => {
          this.state.gridOptions.api &&
            this.state.gridOptions.api.sizeColumnsToFit();
        },
        onRowClicked: event => this.onSelect(event.data, event),
        onSortChanged: event => {
          this.sort(event.api.getSortModel());
          this.state.gridOptions.api.setRowData(this.props.data);
          this.onSelect(this.state.currentSelection);
        },
        onRowDragMove: event => {
          if (!event.overNode) return;
          this.overIndex = event.overIndex;
          let movingNode = event.node;
          let overNode = event.overNode;
          let rowNeedsToMove = movingNode !== overNode;
          if (rowNeedsToMove) {
            let movingData = movingNode.data;
            this.fromIndex = getIndex(this.props.data, movingData);
            let toIndex = event.overIndex;
            let newStore = clone(this.props.data);
            moveInArray(newStore, this.fromIndex, toIndex);
            this.newOrder = newStore;
          }

          function getIndex(array, data) {
            return array.findIndex(
              value => JSON.stringify(value) === JSON.stringify(data)
            );
          }

          function moveInArray(arr, fromIndex, toIndex) {
            var element = arr[fromIndex];
            arr.splice(fromIndex, 1);
            arr.splice(toIndex, 0, element);
          }
        },
        onDragStopped: e => {
          if (this.newOrder) {
            this.props.onRowDragEnd(
              this.newOrder,
              e,
              this.overIndex,
              this.fromIndex
            );
          }
        },
        ...this.props.config
      }
    };

    this.onSelect = this.onSelect.bind(this);
    this.sort = this.sort.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }

  componentDidMount() {
    this.state.gridOptions.onGridSizeChanged();
  }

  handleChangePage(event, page) {
    this.state.gridOptions.api.setRowData(this.props.data);
    this.onSelect(this.state.currentSelection);
    if (this.state.page < page) {
      this.state.gridOptions.api.paginationGoToNextPage();
    } else if (this.state.page > page) {
      this.state.gridOptions.api.paginationGoToPage(page);
    }
    this.setState({ page: page });

    this.props.handleChangePage(page);
  }

  handleChangeRowsPerPage(event) {
    this.state.gridOptions.api.setRowData(this.props.data);
    this.onSelect(this.state.currentSelection);
    this.setState({ rowsPerPage: event.target.value });
    this.state.gridOptions.api.paginationSetPageSize(event.target.value);

    this.props.handleChangeRowsPerPage(event.target.value);
  }

  sort(select) {
    if (this.props.orderChange) {
      this.props.orderChange(select);
    }
  }

  onSelect(select, event) {
    var selection = this.state.currentSelection === select;
    if (selection) {
      this.setState({
        currentSelection: undefined
      });
    } else {
      this.setState({
        currentSelection: select
      });
    }

    if (this.props.selectChange) {
      this.props.selectChange(selection ? undefined : select, event);
    }
  }

  shouldComponentUpdate(newProps, newState) {
    if (newProps.data !== this.props.data) {
      this.state.gridOptions.api.setRowData(this.props.data);
      this.onSelect(undefined);
      if (this.props.showPagination) {
        //this.handleChangePage( null, 0 )
      }
      return false;
    }

    return true;
  }

  render() {
    const { classes } = this.props;
    const { rowsPerPage, page } = this.state;

    const usePaddings = this.props.usePaddings ? 15 : 0;
    const borderRadius =
      this.props.usePaddings || this.props.borderRadius ? 5 : 0;

    return (
      <MuiThemeProvider theme={theme}>
        <Card
          style={{
            marginLeft: usePaddings,
            marginRight: usePaddings,
            paddingLeft: this.props.noPaddingHorizontal ? 0 : 30,
            paddingRight: this.props.noPaddingHorizontal ? 0 : 30,
            borderRadius: borderRadius,
            marginBottom: this.props.noMargin ? 0 : 15,
            paddingBottom: 0,
            boxShadow: this.props.noBox
              ? 'none'
              : '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12) !important'
          }}
          className={classes.card}
        >
          <div className='ag-theme-material' style={{ flex: 1 }}>
            <AgGridReact
              key={this.props.key || ''}
              getRowClass={this.props.getRowClass}
              gridOptions={this.state.gridOptions}
              rowData={this.props.data}
              pagination={this.props.showPagination && !this.props.enableDrag}
              paginationPageSize={rowsPerPage}
              rowStyle={{ cursor: 'pointer', overflow: 'hidden' }}
              rowDragManaged={this.props.enableDrag}
              animateRows={this.props.enableDrag}
              onGridReady={e => {
                this.gridApi = e.api;
                this.props.getRefreshTable &&
                  this.props.getRefreshTable(() =>
                    this.gridApi.setRowData(clone(this.props.data))
                  );
                this.props.onGridReady && this.props.onGridReady(e);
              }}
            />
          </div>

          {this.props.showPagination && !this.props.enableDrag && (
            <Table
              style={{ display: 'flex', alignItems: 'center', height: 50 }}
            >
              <TableFooter>
                <TableRow>
                  <TablePagination
                    className={classes.rodape}
                    count={this.props.pagination.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage='Itens por página'
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from} - ${to} ${'de'} ${count} `
                    }
                    rowsPerPageOptions={[25, 50, 100]}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          )}
        </Card>
      </MuiThemeProvider>
    );
  }
}

DataTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DataTable);
