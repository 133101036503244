/*
    Autor: Bruno Eduardo
*/

import React, { Component } from 'react';
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import DataTable from '../../../components/DataTable/DataTable';
import GroupButtonGrid from '../../../components/GroupButtonGrid/GroupButtonGrid';
import { connect } from 'react-redux';
import SwalConfirmacao from '../../../utils/functions/SwalConfirmacao/SwalConfirmacao';
import EditarEntrevistador from './EditarEntrevistador';
import { bindActionCreators } from 'redux';
import SwalMessage from '../../../utils/functions/SwalMessage/SwalMessage';
import clone from '../../../utils/functions/Clone/Clone';
import {
  hiddenButtonFAB,
  showButtonFAB,
  changeEntrevistadores,
  goEditEntrevistador,
  goAddEntrevistador,
  exitEditEntrevistador
} from '../../../states/actions/QuestionarioActions';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2BA69F'
    }
  },
  typography: {
    useNextVariants: true
  }
});

const styles = theme => ({
  containerTable: {
    display: 'flex',
    height: 'calc(100vh - 186px)'
  }
});

class Entrevistadores extends Component {
  constructor(props) {
    super(props);

    this.state = {
      head: [
        { field: 'usuario.nmUsuario', headerName: 'Entrevistador', col: 6 },
        { field: 'nrEntrevistas', headerName: 'Número de entrevistas', col: 3 },
        { field: 'dsBloqueado', headerName: 'Bloqueado', col: 3 }
      ],
      selected: '',
      disabledEdit: true,
      disabledDelete: true
    };

    this.selectChange = this.selectChange.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.goAddEntrevistador = this.goAddEntrevistador.bind(this);
    this.goEditEntrevistador = this.goEditEntrevistador.bind(this);
    this.exitEditing = this.exitEditing.bind(this);
  }

  componentDidMount() {
    if (!this.props.editandoEntrevistador) {
      this.props.showButtonFAB();
    }
  }

  onRemove() {
    const { selected } = this.state;

    if (selected && this.props.statusQuestionario !== 2) {
      if (selected.nrEntrevistas > 0) {
        SwalMessage({
          text: 'Não é possivel remover um entrevistador com uma ou mais entrevistas feitas'
        });
        return;
      }
      if (selected.stQuestionarioUsuario === 0) {
        SwalMessage({ text: 'Não é possivel remover um entrevistador bloqueado' });
        return;
      }

      SwalConfirmacao({ text: 'Deseja mesmo remover este entrevistador da lista?' }).then(res => {
        if (res) {
          const auxLista = clone(this.props.entrevistadoresData);
          auxLista.splice(this.state.selected.index, 1);
          this.props.changeEntrevistadores(auxLista);
          this.exitEditing();
        }
      });
    }
  }

  goAddEntrevistador() {
    if (this.props.statusQuestionario !== 2) {
      this.selectChange('');
      this.props.goAddEntrevistador();
      this.props.hiddenButtonFAB();
    }
  }

  goEditEntrevistador() {
    if (this.state.selected && this.props.statusQuestionario !== 2) {
      this.props.goEditEntrevistador(this.state.selected);
      this.selectChange('');
    }
  }

  selectChange(select) {
    if (select) {
      this.setState({ selected: select, disabledEdit: false, disabledDelete: false });
    } else {
      this.setState({ selected: '', disabledEdit: true, disabledDelete: true });
    }
  }

  exitEditing() {
    this.props.exitEditEntrevistador();
    this.props.showButtonFAB();
  }

  render() {
    const { classes } = this.props;
    const pagination = { length: this.props.entrevistadoresData.length, page: 0 };

    return (
      <MuiThemeProvider theme={theme}>
        {!this.props.editandoEntrevistador && (
          <>
            <GroupButtonGrid
              showAdd={true}
              showEdit={true}
              showDelete={true}
              disableEdit={this.state.disabledEdit || this.props.statusQuestionario === 2}
              disableDelete={this.state.disabledDelete || this.props.statusQuestionario === 2}
              disableAdd={this.props.statusQuestionario === 2}
              onClickEdit={this.goEditEntrevistador}
              onClickAdd={this.goAddEntrevistador}
              onClickDelete={this.onRemove}
              disableMarginTop
            />

            <div className={classes.containerTable}>
              <DataTable
                data={this.props.entrevistadoresData}
                columns={this.state.head}
                selectChange={this.selectChange}
                showPagination={true}
                pagination={pagination}
                handleChangePage={() => {}}
                handleChangeRowsPerPage={() => {}}
              />
            </div>
          </>
        )}

        {this.props.editandoEntrevistador && <EditarEntrevistador />}
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  const { tabEntrevistadoresQuestionarioReducer, mainQuestionarioReducer } = state.questionarioReducer;
  return {
    entrevistadoresData: tabEntrevistadoresQuestionarioReducer.entrevistadoresData,
    editandoEntrevistador: tabEntrevistadoresQuestionarioReducer.editandoEntrevistador,
    statusQuestionario: mainQuestionarioReducer.statusQuestionario
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      hiddenButtonFAB,
      showButtonFAB,
      changeEntrevistadores,
      goEditEntrevistador,
      goAddEntrevistador,
      exitEditEntrevistador
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Entrevistadores));
