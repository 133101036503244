/*
    Autor: Bruno Eduardo
*/

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { updateToolbar, enableLoading } from '../../states/actions/ToolbarActions';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import DataTable from './../../components/DataTable/DataTable';
import CardFilters from './../../components/CardFilters/CardFilters';
import ButtonFAB from './../../components/ButtonFab/ButtonFab';
import iconSetaDireita from './../../assets/images/seta-direita.png';
import { getAllQuestionarios } from '../../resources/api/questionario';
import FilterUtil from '../filterUtil';
import * as moment from 'moment';
import SwalMessage from '../../utils/functions/SwalMessage/SwalMessage';

const style = theme => ({
  containerTable: {
    display: 'flex',
    height: '100%',
    marginTop: 20
  }
});

class Respostas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataInTable: [],
      dataAppSync: [],
      disableButton: true,
      itemSelect: '',
      head: [
        { field: 'cliente.nmFantasia', headerName: 'Cliente', sort: 'asc', col: 3 },
        { field: 'dsTitulo', headerName: 'Título', col: 3 },
        { field: 'inicio', headerName: 'Data inicial', col: 2 },
        { field: 'fim', headerName: 'Data final', col: 2 },
        { field: 'situacao', headerName: 'Situação', col: 2 }
      ],
      operationValues: [
        {
          label: 'Cliente',
          field: 'nmCliente',
          type: 'string'
        },
        {
          label: 'Título',
          field: 'dsTitulo',
          type: 'string'
        },
        {
          label: 'Data inicial',
          field: 'inicio',
          type: 'date'
        },
        {
          label: 'Data final',
          field: 'fim',
          type: 'date'
        },
        {
          label: 'Situação',
          field: 'stQuestionario',
          type: 'enum',
          options: [
            { label: 'Encerrado', field: 'Encerrado', value: '0' },
            { label: 'Aguardando', field: 'Aguardando', value: '1' },
            { label: 'Em andamento', field: 'andamento', value: '2' }
          ]
        }
      ]
    };

    const title = 'Respostas';
    if (this.props.itensState.name !== title) {
      this.props.updateToolbar({ toolbar: title, name: title });
    }
    this.props.enableLoading(true);

    this._isMounted = false;

    this.selectChange = this.selectChange.bind(this);
    this.goLancarRespostas = this.goLancarRespostas.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onFilter = this.onFilter.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.onFilter();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  goLancarRespostas() {
    if (this.state.itemSelect) {
      this.props.history.push('/app/respostas/' + this.state.itemSelect.idQuestionario);
    }
  }

  selectChange(select) {
    if (select) {
      this.setState({ itemSelect: select, disableButton: false });
    } else {
      this.setState({ itemSelect: '', disableButton: true });
    }
  }

  onFilterChanged(dataChips) {
    this.setState({
      dataInTable: FilterUtil.applyFilters(this.state.dataAppSync, dataChips)
    });
  }

  onFilter(dataChips) {
    dataChips = dataChips || [];

    this.props.enableLoading(true);
    this._isMounted &&
      getAllQuestionarios()
        .then(res => {
          const data = res.data.map(item => ({
            ...item,
            situacao: ['Aberto', 'Em andamento', 'Fechado'][item.stQuestionario],
            inicio: moment(item.dtInicio).format('DD/MM/YYYY'),
            fim: moment(item.dtTermino).format('DD/MM/YYYY'),
            nmCliente: item.cliente.nmFantasia
          }));
          this._isMounted &&
            this.setState(
              {
                dataInTable: FilterUtil.applyFilters(data, dataChips),
                dataAppSync: data
              },
              () => this.props.enableLoading(false)
            );
        })
        .catch(err => {
          SwalMessage({
            title: 'Falha ao carregar página',
            text: 'Não foi possível carregar a página, tente novamente',
            err,
            callback: () => {
              this._isMounted && this.setState({ dataAppSync: [] });
              this.props.enableLoading(false);
            }
          });
        });
  }

  render() {
    const { classes } = this.props;
    const pagination = { length: this.state.dataInTable.length, page: 0 };

    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <CardFilters
          filtros={this.state.operationValues}
          onFilter={this.onFilter}
          onFilterChanged={this.onFilterChanged}
          usePaddings={false}
          viewKey='questionarios'
        />

        <div className={classes.containerTable}>
          <DataTable
            data={this.state.dataInTable}
            columns={this.state.head}
            selectChange={this.selectChange}
            showPagination={true}
            pagination={pagination}
            handleChangePage={() => {}}
            handleChangeRowsPerPage={() => {}}
          />
        </div>

        <ButtonFAB
          icon={iconSetaDireita}
          positionDefault={true}
          onClick={this.goLancarRespostas}
          disabled={this.state.disableButton}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbarReducer,
  chipData: state.cardFiltersReducer.chipData.filter(date => {
    return date.id === 'questionarios';
  })[0].content
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ updateToolbar, enableLoading }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(style)(Respostas)));
