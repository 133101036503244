//@author Gabriela Farias
import React from 'react';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Logo from '../../assets/images/novo_logo_RADAR.png';
import CardContent from '@material-ui/core/CardContent';

const Styles = {
  card: {
    display: 'flex',
    width: 320,
    height: 147,
    flex: 1,
    flexDirection: 'column',
    borderRadius: 10,
    boxShadow: '0px 6px 6px 0px rgba(0,0,0,0.2)'
  },
  logo: {
    width: 270,
    height: 78
  },
  cabecalho: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    height: '100%'
  }
};

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  }
});

function CabecalhoLogin(props) {
  const { classes } = props;

  return (
    <MuiThemeProvider theme={theme}>
      <Card className={classes.card}>
        <CardContent className={classes.cabecalho}>
          <img src={Logo} className={classes.logo} alt='Logomarca Radar' />
        </CardContent>
      </Card>
    </MuiThemeProvider>
  );
}

export default withStyles(Styles, { withTheme: true })(CabecalhoLogin);
