import axios from 'axios';
import { URL_API } from '../common';

const URL_API_RESPOSTA = `${URL_API}/resposta`;

const getRespostasByQuestionario = async id => {
  return await axios.get(URL_API_RESPOSTA + '/' + id);
};

const insertRespostas = async data => {
  return await axios.post(URL_API_RESPOSTA, data);
};

export { getRespostasByQuestionario, insertRespostas };
