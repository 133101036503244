import React from 'react';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2BA69F'
    }
  },
  typography: {
    useNextVariants: true
  }
});

const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: 24
  },
  checkbox: {
    padding: 0,
    marginRight: 10,
    height: 24
  },
  labelCheckbox: {
    color: 'black',
    fontFamily: 'Roboto',
    fontSize: 14,
    height: 24,
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  }
});

const CheckboxOwn = props => {
  const {
    classes,
    label,
    onChange,
    className,
    checked,
    containerProps = {},
    labelProps = {},
    ...others
  } = props;

  return (
    <MuiThemeProvider theme={theme}>
      <div {...containerProps} className={classNames(classes.container, containerProps.className)}>
        <Checkbox
          {...others}
          onChange={onChange}
          checked={checked}
          color='primary'
          className={classNames(classes.checkbox, className)}
        />
        <label
          onClick={onChange}
          {...labelProps}
          style={{ cursor: props.disabled ? 'auto' : 'pointer', ...labelProps.style }}
          className={classNames(classes.labelCheckbox, labelProps.className)}
        >
          {label}
        </label>
      </div>
    </MuiThemeProvider>
  );
};

CheckboxOwn.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  checked: PropTypes.bool,
  containerProps: PropTypes.object,
  labelProps: PropTypes.object
};

export default withStyles(styles)(CheckboxOwn);
