import ActionTypes from '../actionTypes/LoginActionTypes';

const initialState = {
  usuario: ''
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_INFOUSUARIO:
      return { ...state, usuario: action.payload };

    case ActionTypes.CLEAR_INFOUSUARIO:
      return { ...state, usuario: '' };

    default:
      return state;
  }
};

export default loginReducer;
