/*
    Autor: Bruno Eduardo
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import FormSelectOwn from '../../../../components/FormSelectOwn/FormSelectOwn';
import { bindActionCreators } from 'redux';
import AutoControlledTextField from '../../../../components/AutoControlledTextField/AutoControlledTextField';
import SwalMessage from '../../../../utils/functions/SwalMessage/SwalMessage';
import Button from '@material-ui/core/Button';
import Checkbox from '../../../../components/CheckboxOwn/CheckboxOwn';
import clone from '../../../../utils/functions/Clone/Clone';
import moveItem from '../../../../utils/functions/MoveItem/MoveItem';
import {
  changeConfigMultiplaEscolha,
  newIdAlternativa,
  changeOpcoesPerguntas,
  syncStPergunta
} from '../../../../states/actions/QuestionarioActions';

const styles = theme => ({
  containerFlex: {
    width: '100%',
    display: 'flex'
  },
  colLetra: {
    maxWidth: 40,
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center'
  },
  colRemove: {
    maxWidth: 35,
    flexGrow: 1
  },
  colTextField: {
    flexGrow: 1,
    height: 70
  },
  checkbox: {
    marginTop: 6,
    paddingRight: 0,
    paddingLeft: 0,
    marginRight: 15
  },
  selectArredondamento: {
    marginTop: '13px !important'
  },
  containerAdicionais: {
    color: 'black',
    fontSize: 14,
    fontFamily: 'Roboto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 70
  },
  letraAlternativa: {
    marginTop: 5,
    fontSize: 20,
    fontFamily: 'Roboto',
    color: 'gray'
  },
  cardResidual: {
    display: 'flex',
    justifyContent: 'space-between'
  }
});

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      main: '#2BA69F'
    }
  }
});

class RespostaMultiplaEscolha extends Component {
  constructor(props) {
    super(props);

    this.state = {
      config: clone(this.props.config),
      opcoes: clone(this.props.opcoes),
      selectArredondamentoTouched: false
    };

    this.onChangeTextField = this.onChangeTextField.bind(this);
    this.onChangeResidual = this.onChangeResidual.bind(this);
    this.onChangeArredondamento = this.onChangeArredondamento.bind(this);
    this.onChangeSomenteInteiros = this.onChangeSomenteInteiros.bind(this);
    this.onChangeMostrarOutros = this.onChangeMostrarOutros.bind(this);
    this.onRemoveOption = this.onRemoveOption.bind(this);
    this.onAddOption = this.onAddOption.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.state.config) !== JSON.stringify(prevProps.config) ||
      JSON.stringify(this.state.opcoes) !== JSON.stringify(prevProps.opcoes)
    ) {
      this.props.changeConfigMultiplaEscolha(clone(this.state.config));
      this.props.changeOpcoesPerguntas(clone(this.state.opcoes));
    }
  }

  onChangeTextField(value, index) {
    if (value.length <= 100) {
      const auxOpcoes = this.state.opcoes;
      if (!auxOpcoes[index]) return;

      auxOpcoes[index].dsAlternativa = value;
      auxOpcoes[index].touched = true;
      delete auxOpcoes[index].new;
      this.setState({ opcoes: auxOpcoes });
    }
  }

  onChangeResidual(value, index) {
    const auxOpcoes = this.state.opcoes;
    if (!auxOpcoes[index]) return;

    auxOpcoes[index].stResidual = value;
    delete auxOpcoes[index].new;
    this.setState({ opcoes: auxOpcoes });
  }

  onChangeMostrarOutros() {
    const auxOpcoes = this.state.opcoes;
    const auxConfig = this.state.config;
    const indexOutros = auxOpcoes.findIndex(item => item.tpAlternativa === 1);

    if (auxConfig.stOutros === 0) {
      if (indexOutros !== -1) {
        auxOpcoes[indexOutros].stRegistro = 1;
        moveItem(auxOpcoes, indexOutros, auxOpcoes.length - 1);
      } else {
        auxOpcoes.push({
          dsAlternativa: 'Outros',
          stResidual: 0,
          stArredondamento: 0,
          stRegistro: 0,
          stFluxoExcecao: 0,
          tpAlternativa: 1,
          touched: true
        });
        this.props.newIdAlternativa(
          novoMaiorId => (auxOpcoes[auxOpcoes.length - 1].idAlternativa = novoMaiorId)
        );
      }
    } else if (auxConfig.stOutros === 1) {
      if (indexOutros !== -1) {
        this.onRemoveOption(indexOutros);
      }
    }

    auxConfig.stOutros = auxConfig.stOutros === 0 ? 1 : 0;
    this.props.syncStPergunta({
      stOutros: auxConfig.stOutros,
      stValoresInteiros: auxConfig.stValoresInteiros,
      alternativaArredondamento: auxConfig.alternativaArredondamento
    });
    this.setState({ opcoes: auxOpcoes, config: auxConfig });
  }

  onChangeSomenteInteiros() {
    const auxConfig = this.state.config;
    const auxOpcoes = this.state.opcoes;
    auxConfig.stValoresInteiros = auxConfig.stValoresInteiros === 0 ? 1 : 0;
    auxConfig.alternativaArredondamento = '';
    auxOpcoes.forEach(opt => (opt.stArredondamento = 0));
    this.props.syncStPergunta({
      stOutros: auxConfig.stOutros,
      stValoresInteiros: auxConfig.stValoresInteiros,
      alternativaArredondamento: auxConfig.alternativaArredondamento
    });
    this.setState({ config: auxConfig, opcoes: auxOpcoes });
  }

  onChangeArredondamento(e) {
    const auxConfig = this.state.config;
    const auxOpcoes = this.state.opcoes;
    auxConfig.alternativaArredondamento = e.target.value;

    +e.target.value &&
      auxOpcoes.forEach(opt => {
        opt.stArredondamento = opt.idAlternativa === +e.target.value ? 1 : 0;
      });
    this.setState({ config: auxConfig, opcoes: auxOpcoes });
  }

  onRemoveOption(index) {
    const auxConfig = this.state.config;
    const auxOpcoes = this.state.opcoes;

    if (+auxConfig.alternativaExcecao === auxOpcoes[index].idAlternativa) {
      auxConfig.alternativaExcecao = '';
    }
    if (+auxConfig.alternativaArredondamento === auxOpcoes[index].idAlternativa) {
      auxConfig.alternativaArredondamento = '';
    }

    if (this.props.atualizandoQuestionario && auxOpcoes[index].stRegistro === 1) {
      auxOpcoes[index].stRegistro = 2;
      auxOpcoes[index].stArredondamento = 0;
      auxOpcoes[index].stFluxoExcecao = 0;
    } else {
      auxOpcoes.splice(index, 1);
    }
    this.setState({ config: auxConfig, opcoes: auxOpcoes });
  }

  onAddOption() {
    const auxConfig = this.state.config;
    const auxOpcoes = this.state.opcoes;
    if (auxOpcoes.length < 26) {
      const indexOutros = auxOpcoes.findIndex(item => item.stRegistro !== 2 && item.tpAlternativa === 1);
      let indexToPush;
      if (indexOutros === -1) {
        indexToPush = auxOpcoes.length;
      } else {
        indexToPush = indexOutros;
        auxOpcoes[indexOutros + 1] = auxOpcoes[indexOutros];
      }

      auxOpcoes[indexToPush] = {
        dsAlternativa: '',
        stResidual: 0,
        stArredondamento: 0,
        stRegistro: 0,
        stFluxoExcecao: 0,
        tpAlternativa: 0,
        checked: false,
        touched: false,
        new: true
      };
      this.props.newIdAlternativa(novoMaiorId => (auxOpcoes[indexToPush].idAlternativa = novoMaiorId));

      this.setState({ config: auxConfig, opcoes: auxOpcoes });
    } else {
      SwalMessage({ text: 'Número máximo de alternativas atingido' });
    }
  }

  render() {
    const { classes, triedSubmit } = this.props;
    const { config, opcoes } = this.state;
    let count = -1;

    return (
      <MuiThemeProvider theme={theme}>
        <form autoComplete='off'>
          {opcoes.map((item, i) => {
            item.stRegistro !== 2 && !item.tpAlternativa && count++;

            return item.stRegistro !== 2 && !item.tpAlternativa ? (
              <Grid container key={item.idAlternativa}>
                <div className={classes.containerFlex} key={item.idAlternativa}>
                  <div className={classes.colLetra}>
                    <label className={classes.letraAlternativa}>{count + 1 + ')'}</label>
                  </div>

                  <div className={classes.colTextField}>
                    <AutoControlledTextField
                      name='dsAlternativa'
                      label={item.label}
                      value={item.dsAlternativa}
                      onBlur={e => this.onChangeTextField(e.target.value, i)}
                      onFocus={() => {
                        if (!item.touched) {
                          const aux = this.state.config;
                          opcoes[i].label = '';
                          this.setState({ config: aux });
                        }
                      }}
                      inputProps={{ maxLength: 100 }}
                      error={!item.dsAlternativa && !item.new && (item.touched || triedSubmit)}
                      helperText={
                        !item.dsAlternativa && !item.new && (item.touched || triedSubmit)
                          ? 'O campo é obrigatório'
                          : null
                      }
                      textFieldProps={{ autoFocus: item.new }}
                    />
                  </div>

                  <div style={{ width: 13 }} />

                  <Grid item xs={2} className={classes.cardResidual}>
                    <div className={classes.colRemove} style={{ minWidth: 147, marginTop: 18 }}>
                      <Checkbox
                        checked={item.stResidual === 1}
                        onChange={e => this.onChangeResidual(item.stResidual === 1 ? 0 : 1, i)}
                        label='Residual'
                      />
                    </div>

                    <div className={classes.colRemove}>
                      <IconButton onClick={() => this.onRemoveOption(i)} style={{ marginTop: 6 }}>
                        <CloseIcon style={{ color: '#F33A30' }} />
                      </IconButton>
                    </div>
                  </Grid>
                </div>
              </Grid>
            ) : (
              <div key={item.idAlternativa} />
            );
          })}

          <Button color='primary' onClick={this.onAddOption} style={{ paddingLeft: 0, paddingRight: 2 }}>
            <AddIcon style={{ color: '#2BA69F' }} /> Alternativa
          </Button>

          <Grid
            container
            className={classes.containerAdicionais}
            style={{ justifyContent: 'flex-start' }}
          >
            <Grid item xs={3}>
              <Checkbox
                checked={config.stOutros === 1}
                onChange={this.onChangeMostrarOutros}
                label='Exibir opção "Outros"'
              />
            </Grid>
          </Grid>

          <Grid container className={classes.containerAdicionais}>
            <Grid item xs={3}>
              <Checkbox
                checked={config.stValoresInteiros === 1}
                onChange={this.onChangeSomenteInteiros}
                label='Somente valores inteiros'
              />
            </Grid>

            <Grid item xs style={{ height: 70 }}>
              <FormSelectOwn
                error={
                  (this.props.triedSubmit || this.state.selectArredondamentoTouched) &&
                  !config.alternativaArredondamento &&
                  config.stValoresInteiros
                    ? true
                    : false
                }
                name='dsAlternativaArredondamento'
                label='Alternativa de arredondamento das demais alternativas'
                value={config.alternativaArredondamento}
                onChange={this.onChangeArredondamento}
                onBlur={() =>
                  config.stValoresInteiros && this.setState({ selectArredondamentoTouched: true })
                }
                options={opcoes.filter(item => item.stRegistro !== 2)}
                optionLabel={(opt, i) => `${i + 1}) ${opt.dsAlternativa}`}
                optionValue={opt => opt.idAlternativa}
                errorText='O campo é obrigatório'
                displayEmpty={false}
                selectProps={{
                  disabled: !config.stValoresInteiros,
                  className: classes.selectArredondamento
                }}
              />
            </Grid>
          </Grid>
        </form>
      </MuiThemeProvider>
    );
  }
}

RespostaMultiplaEscolha.propTypes = {
  config: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    config: state.questionarioReducer.tabPerguntasQuestionarioReducer.tiposResposta[1],
    opcoes: state.questionarioReducer.tabPerguntasQuestionarioReducer.perguntaSelected.alternativaList,
    atualizandoQuestionario: state.questionarioReducer.mainQuestionarioReducer.atualizandoQuestionario
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { changeConfigMultiplaEscolha, newIdAlternativa, changeOpcoesPerguntas, syncStPergunta },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RespostaMultiplaEscolha));
