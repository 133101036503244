// Autor: Gabriela Farias
import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import TextField from '../TextFieldOwn/TextFieldOwn';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import PropTypes from 'prop-types';
import { Formik, FastField } from 'formik';
import * as Yup from 'yup';
import Botao from './../Botao/Botao';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withRouter } from 'react-router-dom';
import iconEmail from '../../assets/images/icon_email.png';
import iconSenha from '../../assets/images/icon_senha.png';
import iconKey from '../../assets/images/icon_key.png';
import Authentication from '../../resources/oauth/Authentication';
import SwalMessage from '../../utils/functions/SwalMessage/SwalMessage';

const styles = {
  card: {
    backgroundColor: '#F8F8F8',
    width: 330,
    height: 508,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'center',
    padding: 20,
    borderRadius: 10
  },
  label: {
    height: 70,
    display: 'flex'
  },
  button: {
    marginTop: 40,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  cxDialogo: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center'
  },
  containerCxDialogo: {
    width: 300,
    position: 'relative'
  },
  link: {
    textDecoration: 'none',
    color: '#26B767',
    cursor: 'pointer',
    width: '30%',
    display: 'flex',
    position: 'relative',
    flexDirection: 'row-reverse',
    fontSize: 14,
    marginTop: 8,
    marginBottom: 10
  },
  divLink: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    flexDirection: 'row-reverse'
  }
};

const themeFocus = createMuiTheme({
  palette: {
    primary: blue,
    type: 'light'
  },
  typography: {
    useNextVariants: true
  }
});

/*
    Tela de confirmação de cadastro Customizada
*/
class TelaConfirmarCadastro extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);

    const data = this.getUrlParams();
    this.state = {
      loading: false,
      key: data.code,
      email: data.email
    };

    this._isMounted = false;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.getUrlParams = this.getUrlParams.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.logarUsuario = this.logarUsuario.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSubmit(values) {
    this.setState({ loading: true });

    this._isMounted &&
      Authentication.confirmarCadastro(values.email, values.password, values.key)
        .then(() => this.logarUsuario(values))
        .catch(err => {
          let msg;
          if (err.response && err.response.data.codigo === 'CODIGO_INVALIDO') {
            msg = 'Código de ativação inválido';
          } else if (err.response && err.response.data.codigo === 'USUARIO_NAO_ENCONTRADO') {
            msg = 'Usuário não encontrado';
          } else {
            msg = 'Não foi possível validar seu cadastro, tente novamente';
          }

          SwalMessage({
            title: 'Falha ao validar cadastro',
            text: msg,
            err,
            callback: () => this.setState({ loading: false })
          });
        });
  }

  logarUsuario(values) {
    SwalMessage({
      title: 'Sucesso',
      text: 'Cadastro confirmado com sucesso',
      icon: 'success',
      callback: () => this.setState({ loading: false })
    }).then(() => {
      this._isMounted &&
        Authentication.login(values.email, values.password).then(() => {
          this.props.history.push('/app');
        });
    });
  }

  getUrlParams() {
    let data = {};
    const query = window.location.search.slice(1);
    let partes = query.split('&');
    partes.forEach(function(parte) {
      let chaveValor = parte.split('=');
      let chave = chaveValor[0];
      let valor = chaveValor[1];
      data[chave] = valor;
    });
    return data;
  }

  handleKeyDown(e, handleSubmit) {
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault();
      handleSubmit();
    }
  }

  render() {
    const { classes } = this.props;
    const inputProps = { disabled: true, style: { opacity: 0.75 } };

    return (
      <Formik
        initialValues={{
          email: this.state.email,
          password: '',
          confirmPassword: '',
          key: this.state.key
        }}
        onSubmit={this.handleSubmit}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('Email inválido')
            .required('Campo obrigatório'),
          password: Yup.string()
            .min(6, 'A senha deve ter no mínimo 6 caracteres')
            .required('Campo obrigatório'),
          confirmPassword: Yup.string()
            .min(6, 'A senha deve ter no mínimo 6 caracteres')
            .oneOf([Yup.ref('password')], 'Senhas Incompatíveis')
            .required('Campo obrigatório'),
          key: Yup.string().required('Campo obrigatório')
        })}
        render={({ values, handleSubmit, errors }) => (
          <Card className={classes.card}>
            <form autoComplete='off' onKeyDown={e => this.handleKeyDown(e, handleSubmit)}>
              <TextField
                name='email'
                label='Email'
                value={values.email}
                error={errors.email && true}
                style={{ marginTop: '40%' }}
                className={classes.label}
                helperText={errors.email && true ? errors.email : null}
                InputProps={{
                  ...inputProps,
                  endAdornment: (
                    <InputAdornment position='end'>
                      <img src={iconEmail} alt='icone que representa senha' />
                    </InputAdornment>
                  )
                }}
              />

              <TextField
                label='Código'
                name='key'
                value={this.state.key}
                error={errors.key && true}
                className={classes.label}
                helperText={errors.key && true ? errors.key : null}
                InputProps={{
                  ...inputProps,
                  endAdornment: (
                    <InputAdornment position='end'>
                      <img src={iconKey} alt='icone que representa senha' />
                    </InputAdornment>
                  )
                }}
              />

              <MuiThemeProvider theme={themeFocus}>
                <FastField name='password'>
                  {({ form, field }) => (
                    <TextField
                      {...field}
                      id='password'
                      label='Senha'
                      type='password'
                      error={form.errors.password && form.touched.password}
                      helperText={
                        form.errors.password && form.touched.password ? form.errors.password : null
                      }
                      className={classes.label}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <img src={iconSenha} alt='icone que representa senha' />
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                </FastField>
              </MuiThemeProvider>

              <MuiThemeProvider theme={themeFocus}>
                <FastField name='confirmPassword'>
                  {({ form, field }) => (
                    <TextField
                      {...field}
                      id='confirmPassword'
                      label='Confirmação de senha'
                      type='password'
                      error={form.errors.confirmPassword && form.touched.confirmPassword}
                      helperText={
                        form.errors.confirmPassword && form.touched.confirmPassword
                          ? form.errors.confirmPassword
                          : null
                      }
                      className={classes.label}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <img src={iconSenha} alt='icone que representa senha' />
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                </FastField>
              </MuiThemeProvider>

              <div className={classes.button}>
                <Botao
                  onClickButton={handleSubmit}
                  loadingButton={this.state.loading}
                  statusConfirmarCadatro={true}
                  text='CONFIRMAR'
                />
              </div>
            </form>
          </Card>
        )}
      />
    );
  }
}

export default withRouter(withStyles(styles)(TelaConfirmarCadastro));
