import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import iconClose from "./../../assets/images/icon_close.svg";

const style = theme => ({
  card: {
    display: "flex",
    height: "100%",
    overflow: "hidden",
    backgroundColor: "#FFFFFF",
    padding: 40,
    flexDirection: "column"
  },
  cardText: {
    display: "flex",
    height: 50,
    width: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: 30
  },
  title: {
    fontSize: 18,
    color: "#666666",
    fontWeight: 400,
    display: "flex",
    justifyContent: "space-between"
  },
  description: {
    fontSize: 16,
    color: "#666666",
    display: "flex",
    justifyContent: "space-between"
  },
  iconClose: {
    height: 24,
    width: 24,
    cursor: "pointer"
  }
});

class CardAnalise extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.cardText}>
        <div className={classes.title}>
          <div>{this.props.title}</div>
          <img
            onClick={this.props.onClickClose}
            src={iconClose}
            className={classes.iconClose}
            alt="Icone close"
          />
        </div>
        <div className={classes.description}>
          <div>{this.props.description}</div>
          <div>{this.props.pageInitial + " / " + this.props.pageFinal}</div>
        </div>
      </div>
    );
  }
}

export default withStyles(style)(CardAnalise);
