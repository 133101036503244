/*
    Autor: Gabriela Farias
*/
import React, { Component } from 'react';
import DataTable from './../../components/DataTable/DataTable';
import CardFilters from './../../components/CardFilters/CardFilters';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { updateToolbar, enableLoading } from '../../states/actions/ToolbarActions';
import * as moment from 'moment';
import SwalMessage from '../../utils/functions/SwalMessage/SwalMessage';
import FilterUtil from '../filterUtil';
import { getAllQuestionarios } from '../../resources/api/questionario';
import iconSetaDireita from './../../assets/images/icon_lupa_fab.png';
import ButtonFAB from './../../components/ButtonFab/ButtonFab';

const style = {
  containerTable: {
    display: 'flex',
    height: '100%',
    marginTop: 15,
  },
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2BA69F',
    },
  },
  typography: {
    useNextVariants: true,
  },
});

/**
 * Tela para pesquisa de questionários
 *
 * @author Gabriela Farias
 * @class Auditoria
 */
class Auditoria extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataInTable: [],
      dataAppSync: [],
      itemSelect: '',
      disableButtons: true,
      head: [
        { field: 'dsTitulo', headerName: 'Título', col: 6 },
        { field: 'inicio', headerName: 'Data inicial', sort: 'desc', col: 2 },
        { field: 'fim', headerName: 'Data final', col: 2 },
        { field: 'situacao', headerName: 'Situação', col: 2 },
      ],
      operationValues: [
        {
          label: 'Título',
          field: 'dsTitulo',
          type: 'string',
        },
        {
          label: 'Data inicial',
          field: 'inicio',
          type: 'date',
        },
        {
          label: 'Data final',
          field: 'fim',
          type: 'date',
        },
        {
          label: 'Situação',
          field: 'stQuestionario',
          type: 'enum',
          options: [
            { label: 'Aberto', field: 'Aberto', value: '0' },
            { label: 'Em andamento', field: 'Em andamento', value: '1' },
            { label: 'Fechado', field: 'Fechado', value: '2' },
          ],
        },
      ],
    };

    const title = 'Auditoria';
    if (this.props.itensState.name !== title) {
      this.props.updateToolbar({ toolbar: title, name: title });
    }
    this.props.enableLoading(true);

    this._isMounted = false;

    this.selectChange = this.selectChange.bind(this);
    this.goEdit = this.goEdit.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onFilter = this.onFilter.bind(this);
  }

  /**
   * Inicializa a classe
   */
  componentDidMount() {
    this._isMounted = true;
    this.onFilter();
  }

  /**
   * Desmonsta a classe
   */
  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Salva o item selecionado na grid
   */
  selectChange(select) {
    if (select) {
      this.setState({ itemSelect: select, disableButtons: false });
    } else {
      this.setState({ itemSelect: '', disableButtons: true });
    }
  }

  /**
   * Redireciona para a tela de auditoria
   */
  goEdit() {
    if (this.state.itemSelect) {
      this.props.history.push('/app/auditoria/' + this.state.itemSelect.idQuestionario);
    }
  }

  /**
   * Atualiza a lista de questionários com o filtro inserido
   */
  onFilterChanged(dataChips) {
    this.setState({
      dataInTable: FilterUtil.applyFilters(this.state.dataAppSync, dataChips),
    });
  }

  /**
   * Busca a lista de questionários
   */
  onFilter(dataChips) {
    dataChips = dataChips || [];

    this.props.enableLoading(true);
    this._isMounted &&
      getAllQuestionarios()
        .then((res) => {
          const data = res.data.map((item) => ({
            ...item,
            situacao: ['Aberto', 'Em andamento', 'Fechado'][item.stQuestionario],
            inicio: moment(item.dtInicio).format('DD/MM/YYYY'),
            fim: moment(item.dtTermino).format('DD/MM/YYYY'),
            nmCliente: item.cliente.nmFantasia,
          }));

          this._isMounted &&
            this.setState(
              {
                dataInTable: FilterUtil.applyFilters(data, dataChips),
                dataAppSync: data,
              },
              () => this.props.enableLoading(false)
            );
        })
        .catch((err) => {
          SwalMessage({
            title: 'Falha ao carregar página',
            text: 'Não foi possível carregar a página, tente novamente',
            err,
            callback: () => {
              this._isMounted && this.setState({ dataAppSync: [] });
              this.props.enableLoading(false);
            },
          });
        });
  }

  render() {
    const { classes } = this.props;
    const pagination = { length: this.state.dataInTable.length, page: 0 };

    return (
      <MuiThemeProvider theme={theme}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <CardFilters
            filtros={this.state.operationValues}
            onFilter={this.onFilter}
            onFilterChanged={this.onFilterChanged}
            usePaddings={false}
            viewKey='questionarios'
          />

          <div className={classes.containerTable}>
            <DataTable
              data={this.state.dataInTable}
              columns={this.state.head}
              selectChange={this.selectChange}
              showPagination={true}
              pagination={pagination}
              handleChangePage={() => {}}
              handleChangeRowsPerPage={() => {}}
            />
          </div>

          <ButtonFAB
            icon={iconSetaDireita}
            positionDefault={true}
            onClick={this.goEdit}
            disabled={this.state.disableButtons}
          />
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  itensState: state.toolbarReducer,
  loading: state.toolbarReducer.loading,
  chipData: state.cardFiltersReducer.chipData.filter((date) => {
    return date.id === 'auditoria';
  })[0].content,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ updateToolbar, enableLoading }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(style)(Auditoria)));
