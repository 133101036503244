import axios from 'axios';
import { URL_API } from '../common';

const URL_API_QUESTIONARIO = `${URL_API}/questionario`;

const getAllQuestionarios = async () => {
  return await axios.get(URL_API_QUESTIONARIO);
};

const getAllToDemonstrativos = async () => {
  return await axios.get(URL_API_QUESTIONARIO + '/demonstrativos');
};

const getQuestionarioById = async id => {
  return await axios.get(URL_API_QUESTIONARIO + '/' + id);
};

const getQuestionariosByCliente = async id => {
  return await axios.get(URL_API_QUESTIONARIO + '/cliente/' + id);
};

const exportQuestionario = async id => {
  return await axios.get(URL_API_QUESTIONARIO + '/export-pdf/' + id);
};

const insertQuestionario = async data => {
  return await axios.post(URL_API_QUESTIONARIO, data);
};

const updateQuestionario = async data => {
  return await axios.put(URL_API_QUESTIONARIO, data);
};

const deleteQuestionario = async id => {
  return await axios.delete(URL_API_QUESTIONARIO + '/' + id);
};

export {
  getAllQuestionarios,
  getAllToDemonstrativos,
  getQuestionarioById,
  getQuestionariosByCliente,
  exportQuestionario,
  insertQuestionario,
  updateQuestionario,
  deleteQuestionario
};
