import ActionTypes from '../actionTypes/CardFiltersActionTypes';

const addChips = payload => ({
  type: ActionTypes.ADD_CHIPS,
  payload
});

const deleteChip = payload => ({
  type: ActionTypes.DELETE_CHIP,
  payload
});

const cleanChips = payload => ({
  type: ActionTypes.CLEAN_CHIPS,
  payload
});

const updatePreviousPage = payload => ({
  type: ActionTypes.UPDATE_PREVIOUS_PAGE,
  payload
});

export { addChips, deleteChip, cleanChips, updatePreviousPage };
