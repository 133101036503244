import axios from 'axios';
import { URL_API } from '../common';

const URL_API_AUDITORIA = `${URL_API}/auditoria`;

const getAllAuditoria = async (id) => {
  return await axios.get(URL_API_AUDITORIA + '/' + id);
};

const updateAuditoria = async (data) => {
  return await axios.put(URL_API_AUDITORIA, data);
};

export { getAllAuditoria, updateAuditoria };
