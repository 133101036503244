import ActionTypes from '../actionTypes/CardFiltersActionTypes';

const initialState = {
  chipData: [
    { id: 'usuarios', content: [] },
    { id: 'clientes', content: [] },
    { id: 'questionarios', content: [] },
    { id: 'demonstrativos', content: [] },
    { id: 'auditoria', content: [] },
  ],
  histPaginas: {
    paginaAnterior: 'paginaAnterior',
  },
};

const cardFiltersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_CHIPS:
      const updatedItems = state.chipData.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            content: [...item.content, action.payload.newChip],
          };
        }
        return item;
      });
      return { ...state, chipData: updatedItems };

    case ActionTypes.DELETE_CHIP:
      const deleteItems = state.chipData.map((item) => {
        if (item.id === action.payload.idOrigin) {
          return {
            ...item,
            content: item.content.filter((item, index) => action.payload.index !== index),
          };
        }
        return item;
      });
      return { ...state, chipData: deleteItems };

    case ActionTypes.CLEAN_CHIPS:
      const cleanItems = state.chipData.map((item) => {
        if (item.idClean === action.payload.idOrigin) {
          return { ...item, content: [] };
        }
        return item;
      });
      return { ...state, chipData: cleanItems };

    case ActionTypes.UPDATE_PREVIOUS_PAGE:
      return {
        ...state,
        histPaginas: { paginaAnterior: action.payload.idClean },
      };

    default:
      return state;
  }
};

export default cardFiltersReducer;
