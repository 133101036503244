import React, { Component } from 'react';
import Plot from 'react-plotly.js';

class Resultado extends Component {
  render() {
    return (
      <Plot
        onClick={this.props.clickItemPeriodo}
        data={[
          {
            name: '',
            hoverinfo: 'x',
            hovertemplate: '%{y}',
            x:
              this.props.indexVariacao === 1
                ? this.props.horaList.dhRespostas
                : this.props.periodoList.dhRespostas,
            y:
              this.props.indexVariacao === 1
                ? this.props.horaList.nrTotalVotos
                : this.props.periodoList.nrTotalVotos,
            type: 'scatter',
            mode: 'lines',
            marker: { color: 'blue' }
          }
        ]}
        style={{
          width: 'calc(50% - 15px)',
          height: 'calc(50% - 55px)',
          position: 'absolute'
        }}
        config={{ responsive: true, displayModeBar: false }}
        layout={{
          hoverlabel: {
            align: 'right'
          },
          margin: {
            l: 30,
            r: 30,
            b: 30,
            t: 15
          },
          hovermode: 'closest',
          orientation: 'v',
          barmode: 'group',
          xaxis: {
            tickangle: this.props.indexVariacao === 1 ? -45 : 'auto',
            automargin: true,
            fixedrange: true
          },
          yaxis: {
            automargin: true,
            fixedrange: true
          }
        }}
      />
    );
  }
}

export default Resultado;
