/*
    Autor: Bruno Eduardo
*/

import React, { Component } from 'react';
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import DataTable from '../../../components/DataTable/DataTable';
import GroupButtonGrid from './../../../components/GroupButtonGrid/GroupButtonGrid';
import { connect } from 'react-redux';
import SwalConfirmacao from '../../../utils/functions/SwalConfirmacao/SwalConfirmacao';
import clone from '../../../utils/functions/Clone/Clone';
import EditarRegra from './EditarRegra';
import { bindActionCreators } from 'redux';
import {
  hiddenButtonFAB,
  showButtonFAB,
  changeListaRegras,
  goAddRegra,
  goEditRegra,
  exitEditRegra
} from '../../../states/actions/QuestionarioActions';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2BA69F'
    }
  },
  typography: {
    useNextVariants: true
  }
});

const styles = theme => ({
  containerTable: {
    display: 'flex',
    height: 'calc(100vh - 186px)'
  }
});

class Regras extends Component {
  constructor(props) {
    super(props);

    this.state = {
      head: [
        { field: 'dsTitulo', headerName: 'Título', col: 9, rowDrag: true },
        { field: 'dsTipoAnaliseUI', headerName: 'Tipo de análise', col: 3 }
      ],
      selected: '',
      disabledEdit: true,
      disabledDelete: true
    };

    this.selectChange = this.selectChange.bind(this);
    this.onRowDragEnd = this.onRowDragEnd.bind(this);
    this.goAddRegra = this.goAddRegra.bind(this);
    this.goEditRegra = this.goEditRegra.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  componentDidMount() {
    if (!this.props.editandoRegra) {
      this.props.showButtonFAB();
    }
  }

  onRemove() {
    const { selected } = this.state;

    if (selected) {
      SwalConfirmacao({ text: 'Deseja mesmo remover esta regra da lista?' }).then(res => {
        if (res) {
          const auxLista = clone(this.props.regrasDataAll);
          if (selected.stRegistro === 1) {
            auxLista[selected.nrOrdem].stRegistro = 2;
          } else {
            auxLista.splice(selected.nrOrdem, 1);
          }
          this.props.changeListaRegras(auxLista);
          this.props.exitEditRegra();
          this.props.showButtonFAB();
        }
      });
    }
  }

  goAddRegra() {
    this.selectChange('');
    this.props.goAddRegra();
    this.props.hiddenButtonFAB();
  }

  goEditRegra() {
    if (this.state.selected) {
      this.props.goEditRegra(this.state.selected);
      this.selectChange('');
    }
  }

  selectChange(select) {
    if (select) {
      this.setState({ selected: select, disabledEdit: false, disabledDelete: false });
    } else {
      this.setState({ selected: '', disabledEdit: true, disabledDelete: true });
    }
  }

  onRowDragEnd(newOrder) {
    newOrder && this.props.changeListaRegras(newOrder);
  }

  render() {
    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        {!this.props.editandoRegra && (
          <>
            <GroupButtonGrid
              showAdd={true}
              showEdit={true}
              showDelete={true}
              disableEdit={this.state.disabledEdit}
              disableDelete={this.state.disabledDelete}
              onClickEdit={this.goEditRegra}
              onClickAdd={this.goAddRegra}
              onClickDelete={this.onRemove}
              disableMarginTop
            />

            <div className={classes.containerTable}>
              <DataTable
                data={this.props.regrasData}
                columns={this.state.head}
                selectChange={this.selectChange}
                onRowDragEnd={this.onRowDragEnd}
                enableDrag
              />
            </div>
          </>
        )}

        {this.props.editandoRegra && <EditarRegra />}
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  const { tabRegrasQuestionarioReducer, mainQuestionarioReducer } = state.questionarioReducer;
  return {
    regrasDataAll: tabRegrasQuestionarioReducer.regrasData,
    regrasData: tabRegrasQuestionarioReducer.regrasData.filter(item => item.stRegistro !== 2),
    editandoRegra: tabRegrasQuestionarioReducer.editandoRegra,
    atualizandoQuestionario: mainQuestionarioReducer.atualizandoQuestionario
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { hiddenButtonFAB, showButtonFAB, changeListaRegras, goAddRegra, goEditRegra, exitEditRegra },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Regras));
