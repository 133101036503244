import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import FormSelectOwn from '../../../components/FormSelectOwn/FormSelectOwn';
import AutoControlledTextField from '../../../components/AutoControlledTextField/AutoControlledTextField';

const QuestaoAnaliseList = props => {
  const { questaoAnaliseList, perguntasData, onChange, onRemove, triedSubmit } = props;

  return questaoAnaliseList.map((item, i, array) =>
    item.stRegistro !== 2 ? (
      <Grid container spacing={0} key={item.idAnaliseItemAgrupadora || i}>
        <Grid item xs={8} style={{ height: 70, paddingRight: 10 }}>
          <FormSelectOwn
            name='questaoAgrupamento'
            label='Questão de análise'
            value={item.questao.idQuestao + ''}
            onChange={e => onChange({ ...item, questao: { idQuestao: +e.target.value } }, i)}
            options={perguntasData.filter(
              questao =>
                questao.idQuestao === item.questao.idQuestao ||
                !array.find(
                  subItem => subItem.questao.idQuestao === questao.idQuestao && subItem.stRegistro !== 2
                )
            )}
            optionLabel={opt => `${opt.nrOrdem + 1} - ${opt.dsTitulo}`}
            optionValue={opt => opt.idQuestao}
            error={!item.questao.idQuestao && triedSubmit}
            errorText='Campo obrigatório'
          />
        </Grid>

        <Grid item xs style={{ height: 70, paddingLeft: 5 }}>
          <AutoControlledTextField
            name='dsTituloQuestaoAgrupamento'
            label='Título'
            value={item.dsTitulo}
            onBlur={e => onChange({ ...item, dsTitulo: e.target.value }, i)}
            inputProps={{ maxLength: 60 }}
            error={!item.dsTitulo && triedSubmit}
            helperText={!item.dsTitulo && triedSubmit ? 'Campo obrigatório' : null}
          />
        </Grid>

        <Grid item xs style={{ height: 70, maxWidth: 35 }}>
          <IconButton onClick={() => onRemove(i)} style={{ marginTop: 6 }}>
            <CloseIcon style={{ color: '#F33A30' }} />
          </IconButton>
        </Grid>
      </Grid>
    ) : (
      <div key={item.idAnaliseItemAgrupadora || i} />
    )
  );
};

export default QuestaoAnaliseList;
