/*
    Autor: Bruno Eduardo
*/

import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const styles = {
  iconDeleteFile: {
    position: 'relative',
    color: '#F33A30',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    width: 200
  },
  fileName: {
    color: '#42ADE8',
    fontSize: 14,
    fontFamily: 'Roboto',
    cursor: 'pointer'
  },
  nameResponsive: qtdLines => ({
    textAlign: 'center',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: qtdLines,
    WebkitBoxOrient: 'vertical',
    width: 190
  })
};

const CardFile = props => {
  const { classes, file } = props;

  const { nmAnexo, dsLinks3 } = file;

  const extensionFile =
    nmAnexo.split('.').length > 1
      ? nmAnexo
          .split('.')
          .pop()
          .toUpperCase()
      : 'Unknown';

  return (
    <div className={classes.cardDragDrop}>
      <div style={styles.iconDeleteFile}>
        <IconButton aria-label='Excluir' color='inherit' onClick={props.onDeleteFile}>
          <DeleteIcon />
        </IconButton>
      </div>

      <div className={classes.cardFlex}>
        <Typography
          style={{ fontSize: 34, color: '#707070', fontFamily: 'Roboto', ...styles.nameResponsive(1) }}
        >
          {extensionFile}
        </Typography>

        <Typography style={{ ...styles.fileName, ...styles.nameResponsive(2) }}>
          <Link color='inherit' target='_blank' href={dsLinks3} rel='noopener'>
            {nmAnexo}
          </Link>
        </Typography>
      </div>
    </div>
  );
};

CardFile.propTypes = {
  classes: PropTypes.object.isRequired,
  file: PropTypes.object.isRequired,
  onDeleteFile: PropTypes.func.isRequired
};

export default CardFile;
