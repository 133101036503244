import ActionTypes from '../actionTypes/ToolbarActionTypes';

const initialState = {
  label: 'Dashboard',
  name: 'toolbar',
  loading: false
};

const toolbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_TOOLBAR:
      return { ...state, label: action.payload.toolbar, name: action.payload.name };

    case ActionTypes.ENABLE_LOADING:
      return { ...state, loading: action.payload };

    default:
      return state;
  }
};

export default toolbarReducer;
