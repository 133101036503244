/*
Autor: Katia Miglioli
Props especificas:
tipoMascara = {1/2/3/4/5}-(telefone/cnpj/dinheiro/CEP/CPF)
&&
mascaraPropria = {{Funcao-mascara(Vide 'mascaraTelefone')}/nao declaracao}
demais props sao passados normalmente
*/
import React from 'react';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2BA69F'
    }
  },
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiInput: {
      underline: {
        '&:hover:not($disabled):not($focused):not($error):before': { borderBottomColor: '#2BA69F' }
      }
    }
  }
});

class FormattedInputs extends React.Component {
  constructor(props) {
    super(props);

    this.controleMascara = this.controleMascara.bind(this);
    this.mascaraTelefone = this.mascaraTelefone.bind(this);
    this.mascaraDinheiro = this.mascaraDinheiro.bind(this);
    this.mascaraCEP = this.mascaraCEP.bind(this);
  }

  mascaraTelefone(props) {
    const { inputRef, onChange, ...other } = props;
    const maskTelefone = props.value.length === 15 ? '(##) #####-####' : '(##) ####-#####';
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values =>
          onChange({
            target: {
              value: values.formattedValue.trim()
            }
          })
        }
        format={maskTelefone}
      />
    );
  }

  mascaraCNPJ(props) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              value: values.formattedValue.trim()
            }
          });
        }}
        format='##.###.###/####-##'
      />
    );
  }

  mascaraCPF(props) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              value: values.formattedValue.trim()
            }
          });
        }}
        format='###.###.###-##'
      />
    );
  }

  mascaraDinheiro(props) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              value: values.value
            }
          });
        }}
        thousandSeparator=','
        decimalSeparator='.'
        decimalScale={2}
      />
    );
  }

  mascaraCEP(props) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              value: values.formattedValue.trim()
            }
          });
        }}
        format='#####-###'
      />
    );
  }

  controleMascara(props) {
    switch (parseInt(this.props.tipoMascara)) {
      case 1:
        return this.mascaraTelefone(props);
      case 2:
        return this.mascaraCNPJ(props);
      case 3:
        return this.mascaraDinheiro(props);
      case 4:
        return this.mascaraCEP(props);
      case 5:
        return this.mascaraCPF(props);
      default:
        //OPCAO INDISPONIVEL(VIDE "mascaraPropria"
        break;
    }

    return this.mascaraTelefone(props);
  }

  render() {
    const { tipoMascara, mascaraPropria, ...other } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <TextField
          style={{ width: '100%', position: 'relative' }}
          autoComplete='off'
          {...other}
          InputProps={{
            inputComponent: mascaraPropria === undefined ? this.controleMascara : mascaraPropria
          }}
        />
      </MuiThemeProvider>
    );
  }
}

export default FormattedInputs;
