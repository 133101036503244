/*
    Autor: Bruno Eduardo
*/

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Formik, FastField } from 'formik';
import * as Yup from 'yup';
import CardIcon from '../../../components/CardIcon/CardIcon';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import SwalConfirmacao from '../../../utils/functions/SwalConfirmacao/SwalConfirmacao';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FormSelectOwn from '../../../components/FormSelectOwn/FormSelectOwn';
import Autocomplete from '../../../components/AutoComplete/AutoComplete';
import SwipeableViews from 'react-swipeable-views';
import DataTable from './../../../components/DataTable/DataTable';
import GroupButtonGrid from '../../../components/GroupButtonGrid/GroupButtonGrid';
import SwalMessage from '../../../utils/functions/SwalMessage/SwalMessage';
import clone from '../../../utils/functions/Clone/Clone';
import { bindActionCreators } from 'redux';
import newId from '../../../utils/functions/NewId/NewId';
import { getQuestionariosByCliente } from '../../../resources/api/questionario';
import TextField from '../../../components/TextFieldOwn/TextFieldOwn';
import AddIcon from '@material-ui/icons/Add';
import QuestaoAnaliseList from './QuestaoAnaliseList';
import TabAgruparRespostas from './TabAgruparRespostas';
import {
  showButtonFAB,
  hiddenButtonFAB,
  changeRegraSelected,
  changeListaRegras,
  exitEditRegra,
  changeAgruparRespostasSelecteds,
  changeAgruparRespostasData,
  changeMarcosRegras
} from '../../../states/actions/QuestionarioActions';

const CustomizedTabs = withStyles({
  indicator: { backgroundColor: 'transparent' }
})(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const CustomizedTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    color: '#999999',
    fontWeight: 'normal',
    fontSize: 18,
    marginRight: 15,
    borderRadius: 30,
    paddingRight: 30,
    paddingLeft: 30,
    opacity: 1
  },
  selected: {
    backgroundColor: '#2BA69F',
    color: 'white'
  }
}))(props => <Tab {...props} />);

const styles = theme => ({
  containerCard: {
    height: 'calc(100vh - 390px)',
    display: 'flex',
    overflowY: 'auto',
    overflowX: 'hidden',
    marginBottom: 75,
    paddingRight: 30,
    flexDirection: 'column'
  },
  containerTable: {
    display: 'flex',
    minHeight: '43.5vh',
    border: '1px solid rgba(102, 102, 102, 0.34)',
    borderRadius: 5,
    maxWidth: 'calc(100vw - 115px)'
  },
  containerButtons: {
    position: 'absolute',
    bottom: 15,
    left: 0,
    paddingRight: 30,
    paddingTop: 15,
    borderTop: '1px solid rgba(0,0,0,0.12)'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  buttonActions: {
    boxShadow: '0px 3px 6px 0px rgba(0,0,0,0.24)'
  },
  containerTabs: {
    marginTop: 15,
    borderBottom: '1px solid rgba(0,0,0,0.12)',
    paddingBottom: 10,
    marginBottom: 15,
    marginRight: 30
  },
  disabledButton: {
    opacity: 0.5,
    color: 'white !important'
  },
  buttonAddMarcos: {
    paddingLeft: 0,
    paddingRight: 2,
    width: '100%',
    marginRight: 30
  },
  containerTableMarcos: {
    display: 'flex',
    minHeight: 'calc(100vh - 500px)',
    maxWidth: 'calc(100vw - 115px)',
    border: '1px solid rgba(102, 102, 102, 0.34)',
    borderRadius: 5
  }
});

const tipoAnaliseOptions = ['Simplificada', 'Combinatória', 'Evolutiva'];

class EditarRegra extends Component {
  constructor(props) {
    super(props);

    this.state = {
      key: '',
      tabSelected: 0,
      showAgruparRespostas: false,
      regraSelected: clone(props.regraSelected),
      questaoAnaliseList: clone(props.questaoAnaliseList),
      agruparRespostasSelecteds: clone(props.agruparRespostasSelecteds),
      questionarios: [],
      perguntaSelectMarcos: '',
      questionarioSelected: '',
      perguntasMarcos: [],
      disabledDeleteMarcos: true,
      questionarioSelectedError: null,
      perguntaSelectMarcosError: null,
      questaoMarcosList: clone(props.questaoMarcosList),
      marcoSelected: '',
      keyAutocomplete: ''
    };

    this.values = clone(props.regraSelected);
    this.setFieldValue = null;
    this._isMounted = false;

    this.submit = this.submit.bind(this);
    this.onRemoveRegra = this.onRemoveRegra.bind(this);
    this.exitEditing = this.exitEditing.bind(this);
    this.carregarLinhasSelecionadas = this.carregarLinhasSelecionadas.bind(this);
    this.onChangeRespostasAgrupadasSelecteds = this.onChangeRespostasAgrupadasSelecteds.bind(this);
    this.enableAgruparRespostas = this.enableAgruparRespostas.bind(this);
    this.onChangeAutocomplete = this.onChangeAutocomplete.bind(this);
    this.tratarValueAutocomplete = this.tratarValueAutocomplete.bind(this);
    this.loadSuggestionsAutocomplete = this.loadSuggestionsAutocomplete.bind(this);
    this.addMarco = this.addMarco.bind(this);
    this.onChangePerguntaSelecMarcos = this.onChangePerguntaSelecMarcos.bind(this);
    this.removeMarco = this.removeMarco.bind(this);
    this.onSelectMarcosChange = this.onSelectMarcosChange.bind(this);
    this.onChangeTpAnalise = this.onChangeTpAnalise.bind(this);
    this.onAddQuestaoAnalise = this.onAddQuestaoAnalise.bind(this);
    this.onRemoveQuestaoAnalise = this.onRemoveQuestaoAnalise.bind(this);
    this.onChangeQuestaoAnalise = this.onChangeQuestaoAnalise.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    if (this.props.editandoRegra) {
      this.props.hiddenButtonFAB();
    }

    // this.state.regraSelected &&
    //   this.enableAgruparRespostas(this.state.regraSelected.idQuestaoAgrupamento, true);
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.changeRegraSelected({
      ...this.values,
      questaoAnaliseList: this.state.questaoAnaliseList
    });
    this.props.changeAgruparRespostasSelecteds(this.state.agruparRespostasSelecteds);
    this.props.changeMarcosRegras(this.state.questaoMarcosList);
  }

  submit() {
    if (this.values.tpItem === 2 && this.state.questaoMarcosList.length === 0) {
      SwalMessage({
        text: this.props.clienteQuestionario
          ? 'Adicione pelo menos uma variação'
          : 'Selecione o cliente para adicionar variações',
        callback: () => this.setState({ tabSelected: 2 })
      });
      return;
    }

    if (this.state.questaoAnaliseList.length === 0) {
      SwalMessage({
        text: 'Adicione uma questão de análise',
        callback: () => this.setState({ tabSelected: 0 })
      });
      return;
    }

    const auxLista = clone(this.props.regrasData);

    if (this.values.tpItem === 1) {
      this.values.questaoSintetizadora = { idQuestao: this.values.idQuestaoSintetizadora };
    } else {
      this.values = { ...this.values, questaoSintetizadora: null, tpEmpilhamento: 0 };
    }

    if (this.values.tpVisualizacao !== 0 || this.values.tpGrafico !== 1) {
      this.values = { ...this.values, tpOrientacao: 0, tpEmpilhamento: 0 };
    }

    if (this.values.tpVisualizacao !== 0) {
      this.values.tpGrafico = 1;
    }

    if (this.values.tpItem !== 2) {
      this.values.alternativasAgrupadas = this.state.agruparRespostasSelecteds;

      if (!this.props.atualizandoRegra) {
        this.values.questaoMarcosList = [];
      } else {
        this.values.questaoMarcosList = this.state.questaoMarcosList.map(item => ({
          ...item,
          stRegistro: 2
        }));
      }
    } else {
      this.values.questaoMarcosList = this.state.questaoMarcosList;

      if (!this.props.atualizandoRegra) {
        this.values.alternativasAgrupadas = [];
      } else {
        this.values.alternativasAgrupadas = this.state.agruparRespostasSelecteds.map(item => ({
          ...item,
          stRegistro: 2
        }));
      }
    }

    if (this.values.tpItem !== 2) {
      const valid = this.state.questaoAnaliseList.reduce(
        (prev, curr) => prev && (!!curr.dsTitulo && !!curr.questao.idQuestao),
        true
      );

      if (!valid) return;

      const auxQestaoAnaliseList = this.state.questaoAnaliseList.map(item => {
        const questao = this.props.perguntasData.find(
          subItem => subItem.idQuestao === item.questao.idQuestao
        );
        if (questao) {
          return { ...item, questao };
        }
        return item;
      });

      this.values.questaoAnaliseList = auxQestaoAnaliseList;
    } else {
      let auxQestaoAnaliseList = this.state.questaoAnaliseList;

      const questao = this.props.perguntasData.find(
        item => item.idQuestao === this.values.questaoAnaliseEvolutiva
      );

      if (this.values.stRegistro === 1) {
        auxQestaoAnaliseList[0].stRegistro = 1;
        auxQestaoAnaliseList[0].questao = questao;
      } else {
        auxQestaoAnaliseList = [{ stRegistro: 0, dsTitulo: '', idAnaliseItemAgrupadora: null, questao }];
      }

      this.values.questaoAnaliseList = auxQestaoAnaliseList;
    }

    if (this.props.atualizandoRegra) {
      auxLista[this.values.nrOrdem] = {
        ...this.values,
        dsTipoAnaliseUI: tipoAnaliseOptions[this.values.tpItem]
      };
    } else {
      auxLista.push({
        ...this.values,
        idAnaliseItem: newId(auxLista, p => p.idAnaliseItem),
        stRegistro: 0,
        dsTipoAnaliseUI: tipoAnaliseOptions[this.values.tpItem]
      });
    }

    this.props.changeListaRegras(auxLista);

    this.exitEditing();
  }

  onRemoveRegra() {
    const { atualizandoRegra, regraSelected } = this.props;

    if (atualizandoRegra && regraSelected) {
      SwalConfirmacao({ text: 'Deseja mesmo remover esta regra da lista?' }).then(res => {
        if (res) {
          const auxLista = clone(this.props.regrasData);
          if (regraSelected.stRegistro === 1) {
            auxLista[regraSelected.nrOrdem].stRegistro = 2;
          } else {
            auxLista.splice(regraSelected.nrOrdem, 1);
          }
          this.props.changeListaRegras(auxLista);
          this.exitEditing();
        }
      });
    }
  }

  onAddQuestaoAnalise() {
    const auxQuestaoAnaliseList = this.state.questaoAnaliseList;

    auxQuestaoAnaliseList.sort((a, b) => a.questao.nrOrdem - b.questao.nrOrdem);

    auxQuestaoAnaliseList.push({
      idAnaliseItemAgrupadora: newId(auxQuestaoAnaliseList, item => item.idAnaliseItemAgrupadora),
      questao: { idQuestao: '' },
      dsTitulo: '',
      stRegistro: 0
    });

    this.setState({ questaoAnaliseList: auxQuestaoAnaliseList });
  }

  onChangeQuestaoAnalise(questaoAnalise, index) {
    const auxQuestaoAnaliseList = this.state.questaoAnaliseList;

    auxQuestaoAnaliseList[index] = questaoAnalise;

    this.setState({ questaoAnaliseList: auxQuestaoAnaliseList });
  }

  onRemoveQuestaoAnalise(index) {
    const auxQuestaoAnaliseList = this.state.questaoAnaliseList;

    if (auxQuestaoAnaliseList[index]) {
      if (auxQuestaoAnaliseList[index].stRegistro === 1) {
        auxQuestaoAnaliseList[index].stRegistro = 2;
      } else {
        auxQuestaoAnaliseList.splice(index, 1);
      }

      this.setState({ questaoAnaliseList: auxQuestaoAnaliseList });
    }
  }

  carregarLinhasSelecionadas(params) {
    params.api.forEachNode(node => {
      this.state.agruparRespostasSelecteds.forEach(item => {
        if (item.idAlternativa === node.data.idAlternativa) node.setSelected(true);
      });
    });
  }

  onChangeRespostasAgrupadasSelecteds(selected, e) {
    this.setState({ agruparRespostasSelecteds: e.api.getSelectedRows() });
  }

  exitEditing() {
    this.props.exitEditRegra();
    this.props.showButtonFAB();
  }

  onChangePerguntaSelecMarcos(e, callback) {
    this.setState({ perguntaSelectMarcos: +e.target.value });
    callback(e);
  }

  onChangeTpAnalise(e, handleChange) {
    if (this.values.stRegistro === 1 && this.values.tpItem === 2) {
      SwalMessage({
        text: 'Não é possivel mudar o tipo da análise na edição'
      });
      return;
    }

    handleChange(e);

    this.values.tpItem = +e.target.value;

    // if (this.values.idQuestaoAgrupamento) {
    //   this.enableAgruparRespostas(+this.values.idQuestaoAgrupamento, true);
    // }

    if (this.values.tpItem !== 0 && this.values.tpGrafico === 2) {
      this.setFieldValue('tpGrafico', 1);
    }

    if (this.values.tpItem === 1) {
      const questaoAnaliseList = this.state.questaoAnaliseList;

      this.state.questaoAnaliseList.forEach((item, i) => {
        if (item.questao.idQuestao === this.values.idQuestaoSintetizadora) {
          questaoAnaliseList[i].questao.idQuestao = '';
          this.setFieldValue('idQuestaoSintetizadora', '');
          this.setState({ questaoAnaliseList });
        }
      });
    }
  }

  enableAgruparRespostas(idPergunta, manterSelecteds = false) {
    const pergunta = this.props.perguntasData.find(item => item.idQuestao === idPergunta);
    if (!pergunta) return;

    let showAgruparRespostas = false;

    if (this.values.tpItem !== 2 && (+pergunta.tpQuestao === 0 || +pergunta.tpQuestao === 1)) {
      showAgruparRespostas = true;
      this.props.changeAgruparRespostasData(pergunta.alternativaList);
    }

    this.setState(prevState => ({
      showAgruparRespostas,
      agruparRespostasSelecteds: !manterSelecteds ? [] : prevState.agruparRespostasSelecteds
    }));
  }

  tratarValueAutocomplete() {
    if (this.state.questionarioSelected && this.state.questionarioSelected.dsTitulo !== undefined) {
      return this.state.questionarioSelected;
    }
    return '';
  }

  loadSuggestionsAutocomplete() {
    if (this.props.clienteQuestionario) {
      this._isMounted &&
        getQuestionariosByCliente(this.props.clienteQuestionario.idCliente)
          .then(
            res =>
              this._isMounted &&
              this.setState({
                questionarios: res.data.filter(
                  item =>
                    item.questaoList.length > 0 && item.idQuestionario !== this.props.idQuestionario
                )
              })
          )
          .catch(err => {
            SwalMessage({
              title: 'Falha ao carregar questionários',
              text: 'Não foi possível carregar os questionários, tente novamente',
              err
            });
          });
    } else {
      SwalMessage({
        title: 'Falha ao carregar questionários',
        text: 'Selecione um cliente para poder selecionar um questionário'
      });
    }
  }

  onChangeAutocomplete(value = this.state.questionarioSelected) {
    this.setState({ keyAutocomplete: Math.random(), questionarioSelected: value }, () => {
      if (value) {
        this.setState(prevState => ({
          perguntasMarcos: value.questaoList.filter(
            item =>
              item.tpQuestao !== 3 &&
              !prevState.questaoMarcosList.find(subItem => subItem.idQuestao === item.idQuestao)
          )
        }));
      }
    });
  }

  addMarco() {
    const {
      questionarioSelected,
      perguntaSelectMarcos,
      perguntasMarcos,
      questaoMarcosList
    } = this.state;

    this.setState({ questionarioSelectedError: null, perguntaSelectMarcosError: null });
    if (!this.tratarValueAutocomplete()) {
      this.setState({ questionarioSelectedError: 'Campo obrigatório' });
      return;
    }
    if (!perguntaSelectMarcos) {
      this.setState({ perguntaSelectMarcosError: 'Campo obrigatório' });
      return;
    }

    const auxLista = clone(questaoMarcosList);
    const dsTitulo = perguntasMarcos.find(p => p.idQuestao === +perguntaSelectMarcos).dsTitulo;

    auxLista.push({
      idQuestao: +perguntaSelectMarcos,
      questionario: questionarioSelected,
      dsTitulo,
      stRegistro: 0,
      nrOrdem: auxLista.length
    });

    this.setState({ perguntaSelectMarcos: '', questaoMarcosList: auxLista }, this.onChangeAutocomplete);
  }

  removeMarco() {
    if (this.state.marcoSelected) {
      SwalConfirmacao({ text: 'Deseja mesmo remover esta variação da lista?' }).then(res => {
        if (res) {
          const auxLista = clone(this.state.questaoMarcosList);
          auxLista.splice(this.state.marcoSelected.index, 1);
          this.setState({ questaoMarcosList: auxLista }, this.onChangeAutocomplete);
        }
      });
    }
  }

  onSelectMarcosChange(select) {
    if (select) {
      this.setState({ marcoSelected: select, disabledDeleteMarcos: false });
    } else {
      this.setState({ marcoSelected: '', disabledDeleteMarcos: true });
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <CardIcon titulo='Montar análise' styleCollapse={{ paddingRight: 0 }}>
        <Formik
          key={this.state.key}
          initialValues={{
            ...this.state.regraSelected,
            perguntaSelectMarcos: '',
            questionarioSelected: '',
            triedSubmit: false,
            changeQuestaoAnalise: false
          }}
          onSubmit={this.submit}
          validateOnBlur
          enableReinitialize={true}
          validateOnChange={false}
          validationSchema={Yup.lazy(values =>
            Yup.object().shape({
              tpItem: Yup.number().required('Campo obrigatório'),
              idQuestaoSintetizadora:
                +values.tpItem === 1 ? Yup.number().required('Campo obrigatório') : Yup.number(),
              questaoAnaliseEvolutiva:
                +values.tpItem !== 2 ? Yup.number() : Yup.number().required('Campo obrigatório'),
              tpVisualizacao: Yup.number().required('Campo obrigatório'),
              tpGrafico:
                +values.tpVisualizacao !== 0 ? Yup.number().required('Campo obrigatório') : Yup.number(),
              tpOrientacao:
                +values.tpVisualizacao === 0 && +values.tpGrafico === 1
                  ? Yup.number().required('Campo obrigatório')
                  : Yup.number(),
              tpEmpilhamento:
                +values.tpVisualizacao === 0 && +values.tpGrafico === 1 && +values.tpItem === 1
                  ? Yup.number().required('Campo obrigatório')
                  : Yup.number(),
              dsTitulo: Yup.string()
                .required('Campo obrigatório')
                .max(100, 'O campo deve ter no máximo 100 caracteres'),
              dsApoio: Yup.string().max(100, 'O campo deve ter no máximo 100 caracteres'),
              questionarioSelected: Yup.object(),
              perguntaSelectMarcos: Yup.number()
            })
          )}
          render={({
            errors,
            touched,
            handleBlur,
            handleSubmit,
            values,
            handleChange,
            setFieldValue
          }) => {
            this.values = {
              ...values,
              tpItem: +values.tpItem,
              idQuestaoSintetizadora: values.idQuestaoSintetizadora
                ? +values.idQuestaoSintetizadora
                : '',
              tpVisualizacao: +values.tpVisualizacao,
              tpGrafico: +values.tpGrafico,
              tpOrientacao: +values.tpOrientacao,
              tpEmpilhamento: +values.tpEmpilhamento,
              perguntaSelectMarcos: +values.perguntaSelectMarcos,
              questaoAnaliseEvolutiva: +values.questaoAnaliseEvolutiva
            };
            this.setFieldValue = setFieldValue;

            return (
              <form autoComplete='off'>
                <Grid container spacing={0} style={{ marginBottom: 5 }}>
                  <Grid item xs className={classes.containerTabs}>
                    <CustomizedTabs
                      value={this.state.tabSelected}
                      onChange={(e, tabSelected) => this.setState({ tabSelected })}
                    >
                      <CustomizedTab label='Identificação' value={0} />
                      {false && this.state.showAgruparRespostas && +values.tpItem !== 2 && (
                        <CustomizedTab label='Agrupar respostas' value={1} />
                      )}
                      {+values.tpItem === 2 && <CustomizedTab label='Variações' value={2} />}
                    </CustomizedTabs>
                  </Grid>
                </Grid>

                <SwipeableViews
                  index={this.state.tabSelected}
                  ignoreNativeScroll={false}
                  slideStyle={{ overflow: 'hidden', paddingBottom: 2 }}
                >
                  {/* \/ TAB IDENTIFICAÇÃO \/ */}
                  <div className={classes.containerCard}>
                    {this.state.tabSelected === 0 && (
                      <div>
                        <Grid container spacing={0}>
                          <Grid item xs={3} style={{ height: 70 }}>
                            <FormSelectOwn
                              name='tpItem'
                              label='Tipo de análise'
                              value={values.tpItem + ''}
                              onChange={e => this.onChangeTpAnalise(e, handleChange)}
                              onBlur={handleBlur}
                              options={tipoAnaliseOptions}
                              optionLabel={opt => opt}
                              optionValue={(opt, i) => i}
                              error={errors.tpItem && touched.tpItem}
                              errorText={errors.tpItem}
                            />
                          </Grid>
                        </Grid>

                        {+values.tpItem === 2 && (
                          <>
                            <Grid container spacing={0}>
                              <Grid item xs style={{ height: 70 }}>
                                <FastField name='dsTitulo'>
                                  {({ form, field }) => (
                                    <TextField
                                      {...field}
                                      id='dsTitulo'
                                      label='Título'
                                      error={form.errors.dsTitulo && form.touched.dsTitulo}
                                      helperText={
                                        form.errors.dsTitulo && form.touched.dsTitulo
                                          ? form.errors.dsTitulo
                                          : null
                                      }
                                      inputProps={{ maxLength: 100 }}
                                    />
                                  )}
                                </FastField>
                              </Grid>
                            </Grid>

                            <Grid container spacing={0}>
                              <Grid item xs style={{ height: 90 }}>
                                <FastField name='dsApoio'>
                                  {({ form, field }) => (
                                    <TextField
                                      {...field}
                                      id='dsApoio'
                                      label='Texto de apoio'
                                      error={form.errors.dsApoio && form.touched.dsApoio}
                                      helperText={
                                        form.errors.dsApoio && form.touched.dsApoio
                                          ? form.errors.dsApoio
                                          : null
                                      }
                                      multiline={true}
                                      rowsMax={2}
                                      inputProps={{ maxLength: 100 }}
                                    />
                                  )}
                                </FastField>
                              </Grid>
                            </Grid>
                          </>
                        )}

                        {+values.tpItem === 1 && (
                          <Grid container spacing={0} style={{ marginBottom: 15 }}>
                            <Grid item xs style={{ height: 70 }}>
                              <FormSelectOwn
                                name='idQuestaoSintetizadora'
                                label='Questão do eixo Y'
                                value={values.idQuestaoSintetizadora + ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                options={this.props.perguntasData.filter(
                                  item =>
                                    !this.state.questaoAnaliseList.filter(
                                      subItem => subItem.questao.idQuestao === item.idQuestao
                                    ).length
                                )}
                                optionLabel={opt => `${opt.nrOrdem + 1} - ${opt.dsTitulo}`}
                                optionValue={opt => opt.idQuestao}
                                error={errors.idQuestaoSintetizadora && touched.idQuestaoSintetizadora}
                                errorText={errors.idQuestaoSintetizadora}
                              />
                            </Grid>
                          </Grid>
                        )}

                        <Grid container spacing={0}>
                          <Grid item xs style={{ height: 70, paddingRight: 15 }}>
                            <FormSelectOwn
                              name='tpVisualizacao'
                              label='Tipo de visualização'
                              value={values.tpVisualizacao + ''}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              options={['Gráfico', 'Tabela']}
                              optionLabel={opt => opt}
                              optionValue={(opt, i) => i}
                              error={errors.tpVisualizacao && touched.tpVisualizacao}
                              errorText={errors.tpVisualizacao}
                            />
                          </Grid>

                          <Grid item xs style={{ height: 70, paddingRight: 15 }}>
                            {+values.tpVisualizacao === 0 && (
                              <FormSelectOwn
                                name='tpGrafico'
                                label='Tipo do gráfico'
                                value={values.tpGrafico + ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                options={['Linhas', 'Colunas'].concat(
                                  +values.tpItem === 0 ? ['Pizza'] : []
                                )}
                                optionLabel={opt => opt}
                                optionValue={(opt, i) => i}
                                error={errors.tpGrafico && touched.tpGrafico}
                                errorText={errors.tpGrafico}
                              />
                            )}
                          </Grid>

                          <Grid item xs style={{ height: 70 }}>
                            {+values.tpVisualizacao === 0 && +values.tpGrafico === 1 && (
                              <FormSelectOwn
                                name='tpOrientacao'
                                label='Orientação'
                                value={values.tpOrientacao + ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                options={[
                                  { name: 'Horizontal', value: 1 },
                                  { name: 'Vertical', value: 0 }
                                ]}
                                optionLabel={opt => opt.name}
                                optionValue={opt => opt.value}
                                error={errors.tpOrientacao && touched.tpOrientacao}
                                errorText={errors.tpOrientacao}
                              />
                            )}
                          </Grid>

                          {/* <Grid item xs style={{ height: 70 }}>
                            <FormSelectOwn
                              name='tpEmpilhamento'
                              label='Empilhamento'
                              value={values.tpEmpilhamento + ''}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              options={['Nenhum', 'Quantidade', 'Percentual']}
                              optionLabel={opt => opt}
                              optionValue={(opt, i) => i}
                              error={errors.tpEmpilhamento && touched.tpEmpilhamento}
                              errorText={errors.tpEmpilhamento}
                              disabled={
                                +values.tpVisualizacao !== 0 ||
                                +values.tpGrafico !== 1 ||
                                +values.tpItem !== 1
                              }
                            />
                          </Grid> */}
                        </Grid>

                        {+values.tpItem !== 2 && (
                          <>
                            <Grid container spacing={0}>
                              <Grid item xs style={{ height: 70 }}>
                                <FastField name='dsTitulo'>
                                  {({ form, field }) => (
                                    <TextField
                                      {...field}
                                      id='dsTitulo'
                                      label='Título'
                                      error={form.errors.dsTitulo && form.touched.dsTitulo}
                                      helperText={
                                        form.errors.dsTitulo && form.touched.dsTitulo
                                          ? form.errors.dsTitulo
                                          : null
                                      }
                                      inputProps={{ maxLength: 100 }}
                                    />
                                  )}
                                </FastField>
                              </Grid>
                            </Grid>

                            <Grid container spacing={0}>
                              <Grid item xs style={{ height: 90 }}>
                                <FastField name='dsApoio'>
                                  {({ form, field }) => (
                                    <TextField
                                      {...field}
                                      id='dsApoio'
                                      label='Texto de apoio'
                                      error={form.errors.dsApoio && form.touched.dsApoio}
                                      helperText={
                                        form.errors.dsApoio && form.touched.dsApoio
                                          ? form.errors.dsApoio
                                          : null
                                      }
                                      multiline={true}
                                      rowsMax={2}
                                      inputProps={{ maxLength: 100 }}
                                    />
                                  )}
                                </FastField>
                              </Grid>
                            </Grid>
                          </>
                        )}

                        <Grid container style={{ marginBottom: 10 }}>
                          <Grid item xs>
                            {+values.tpItem !== 2 ? (
                              <QuestaoAnaliseList
                                questaoAnaliseList={this.state.questaoAnaliseList}
                                perguntasData={
                                  +values.tpItem === 1
                                    ? this.props.perguntasData.filter(
                                        item => +values.idQuestaoSintetizadora !== item.idQuestao
                                      )
                                    : this.props.perguntasData
                                }
                                onChange={this.onChangeQuestaoAnalise}
                                onRemove={this.onRemoveQuestaoAnalise}
                                triedSubmit={values.triedSubmit}
                              />
                            ) : (
                              <FormSelectOwn
                                name='questaoAnaliseEvolutiva'
                                label='Questão de análise'
                                value={values.questaoAnaliseEvolutiva + ''}
                                onChange={e => {
                                  this.setFieldValue('changeQuestaoAnalise', true);
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                options={this.props.perguntasData}
                                optionLabel={opt => `${opt.nrOrdem + 1} - ${opt.dsTitulo}`}
                                optionValue={opt => opt.idQuestao}
                                error={errors.questaoAnaliseEvolutiva && touched.questaoAnaliseEvolutiva}
                                errorText={errors.questaoAnaliseEvolutiva}
                              />
                            )}
                          </Grid>
                        </Grid>

                        {+values.tpItem !== 2 && (
                          <Button color='primary' onClick={this.onAddQuestaoAnalise}>
                            <AddIcon style={{ color: '#2BA69F' }} /> Questão de análise
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                  {/* /\ TAB IDENTIFICAÇÃO /\ */}

                  {/* \/ TAB AGRUPAR RESPOSTAS \/ */}
                  <div style={{ width: '100%', minHeight: '40vh', height: '100%' }}>
                    {this.state.tabSelected === 1 && (
                      <TabAgruparRespostas
                        carregarLinhasSelecionadas={this.carregarLinhasSelecionadas}
                        onChangeRespostasAgrupadasSelecteds={this.onChangeRespostasAgrupadasSelecteds}
                      />
                    )}
                  </div>
                  {/* /\ TAB AGRUPAR RESPOSTAS /\ */}

                  {/* \/ TAB MARCOS \/ */}
                  <div>
                    {this.state.tabSelected === 2 && (
                      <>
                        <Grid container spacing={0} style={{ marginBottom: 15 }}>
                          <Grid item xs={5} style={{ height: 70, paddingRight: 15 }}>
                            <Autocomplete
                              itens={this.state.questionarios}
                              campoOp='dsTitulo'
                              campoChave='idQuestionario'
                              label='Questionário'
                              name='questionarioSelected'
                              id='questionarioSelected'
                              error={!!this.state.questionarioSelectedError}
                              helperText={
                                this.state.questionarioSelectedError
                                  ? this.state.questionarioSelectedError
                                  : null
                              }
                              onBlurOwn={handleBlur}
                              onChangeOwn={this.onChangeAutocomplete}
                              onChangeAutoComplete={this.loadSuggestionsAutocomplete}
                              value={this.tratarValueAutocomplete()}
                              key={this.state.keyAutocomplete}
                              valueAutoComplete={this.tratarValueAutocomplete()}
                              valueSelect={this.tratarValueAutocomplete().dsTitulo}
                              maxHeight='calc(100vh - 450px)'
                            />
                          </Grid>

                          <Grid item xs={5} style={{ height: 70, paddingRight: 15 }}>
                            <FormSelectOwn
                              name='perguntaSelectMarcos'
                              label='Pergunta'
                              value={this.state.perguntaSelectMarcos + ''}
                              onChange={e => this.onChangePerguntaSelecMarcos(e, handleChange)}
                              onBlur={handleBlur}
                              options={this.state.perguntasMarcos}
                              optionLabel={opt => `${opt.nrOrdem + 1} - ${opt.dsTitulo}`}
                              optionValue={opt => opt.idQuestao}
                              error={!!this.state.perguntaSelectMarcosError}
                              errorText={this.state.perguntaSelectMarcosError}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={2}
                            style={{
                              height: 70,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            <Button
                              color='primary'
                              onClick={this.addMarco}
                              className={classes.buttonAddMarcos}
                            >
                              ADICIONAR
                            </Button>
                          </Grid>
                        </Grid>

                        <GroupButtonGrid
                          showAdd={false}
                          showEdit={false}
                          showDelete={true}
                          disableEdit={this.state.disabledEditMarcos}
                          disableDelete={this.state.disabledDeleteMarcos}
                          onClickDelete={this.removeMarco}
                          disableMarginTop
                          style={{ marginRight: 60 }}
                        />

                        <div className={classes.containerTableMarcos}>
                          <DataTable
                            data={this.state.questaoMarcosList}
                            columns={[
                              { field: 'questionario.dsTitulo', headerName: 'Questionário', col: 6 },
                              {
                                valueGetter: args =>
                                  `${args.node.data.nrOrdem + 1} - ${args.node.data.dsTitulo}`,
                                headerName: '# - Pergunta',
                                col: 6
                              }
                            ]}
                            selectChange={this.onSelectMarcosChange}
                            onGridReady={this.loadSuggestionsAutocomplete}
                            borderRadius
                            noMargin
                            noBox
                          />
                        </div>
                      </>
                    )}
                  </div>
                  {/* /\ TAB MARCOS /\ */}
                </SwipeableViews>

                <Grid container spacing={0} className={classes.containerButtons}>
                  <Grid item xs>
                    <div className={classes.buttons}>
                      <Button
                        variant='contained'
                        color='secondary'
                        className={classes.buttonActions}
                        style={{ marginRight: 15, backgroundColor: '#F33A30' }}
                        onClick={this.onRemoveRegra}
                        classes={{ disabled: classes.disabledButton }}
                        disabled={!this.props.atualizandoRegra}
                      >
                        REMOVER
                      </Button>

                      <Button
                        variant='contained'
                        color='secondary'
                        className={classes.buttonActions}
                        style={{ marginRight: 15, backgroundColor: '#124268' }}
                        onClick={this.exitEditing}
                      >
                        CANCELAR
                      </Button>

                      <Button
                        variant='contained'
                        color='secondary'
                        className={classes.buttonActions}
                        style={{ backgroundColor: '#2BA69F' }}
                        onClick={e => {
                          this.setState({ tabSelected: 0 }, () => {
                            this.setFieldValue('triedSubmit', true);
                            errors.dsTitulo && this.tituloRef && this.tituloRef.focus();
                          });
                          handleSubmit(e);
                        }}
                      >
                        CONFIRMAR
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        />
      </CardIcon>
    );
  }
}

const mapStateToProps = state => {
  const {
    tabRegrasQuestionarioReducer,
    tabPerguntasQuestionarioReducer,
    mainQuestionarioReducer
  } = state.questionarioReducer;

  return {
    regrasData: tabRegrasQuestionarioReducer.regrasData,
    editandoRegra: tabRegrasQuestionarioReducer.editandoRegra,
    atualizandoRegra: tabRegrasQuestionarioReducer.atualizandoRegra,
    regraSelected: tabRegrasQuestionarioReducer.regraSelected,
    questaoAnaliseList: tabRegrasQuestionarioReducer.questaoAnaliseList,
    alternativasAgrupadas: tabRegrasQuestionarioReducer.alternativasAgrupadas,
    agruparRespostasSelecteds: tabRegrasQuestionarioReducer.agruparRespostasSelecteds,
    questaoMarcosList: tabRegrasQuestionarioReducer.questaoMarcosList,
    clienteQuestionario: mainQuestionarioReducer.clienteQuestionario,
    idQuestionario: mainQuestionarioReducer.idQuestionario,
    perguntasData: tabPerguntasQuestionarioReducer.perguntasData.filter(
      item => item.stRegistro !== 2 && item.tpQuestao !== 3
    )
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      showButtonFAB,
      hiddenButtonFAB,
      changeRegraSelected,
      changeListaRegras,
      exitEditRegra,
      changeAgruparRespostasSelecteds,
      changeAgruparRespostasData,
      changeMarcosRegras
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EditarRegra));
