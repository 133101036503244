//@author Katia Miglioli
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Chips from './FilterChip';

const styles = () => ({
  divChips: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%',
    alignItems: 'center'
  },
  divChipsText: {
    marginLeft: '-3px',
    color: '#7F7F7F',
    fontSize: 18
  },
  divChipsArray: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 14
  },
  chip: {
    backgroundColor: '#EAEAEA',
    borderRadius: 10,
    padding: 10,
    display: 'flex',
    height: 17,
    margin: '5px',
    marginTop: 0
  },
  chipFiltro: {
    color: 'black',
    fontSize: 12,
    fontWeight: 900
  },
  chipComparacao: {
    fontSize: 12,
    color: '#727272',
    marginLeft: 6
  },
  chipPesquisa: {
    fontSize: 12,
    color: '#727272',
    marginLeft: 4
  },
  chipIcone: {
    marginTop: '-11px',
    marginLeft: '8px'
  }
});

const CardFilterClosed = props => {
  const { classes } = props;

  return (
    <div style={{ height: '100%' }}>
      <div className={classes.divChips}>
        <div className={classes.divChipsText}>Filtros adicionados:</div>
        <Chips
          marginBottomImg={true}
          identData={props.identData}
          viewKey={props.viewKey}
          onFilterChanged={props.onFilterChanged}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(CardFilterClosed);
