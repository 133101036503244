//@author Katia Miglioli
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import iconeClip from '../../assets/images/icone_chip.png';
import * as moment from 'moment';
import { connect } from 'react-redux';
import '../../assets/styles/FilterChip.css';
import { deleteChip } from '../../states/actions/CardFiltersActions';
import { bindActionCreators } from 'redux';

const styles = theme => ({
  divChipsArray: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  chip: {
    backgroundColor: '#EAEAEA',
    borderRadius: 10,
    padding: 10,
    display: 'flex',
    margin: '5px',
    marginBottom: 0,
    height: 18
  },
  chipContent: {
    display: 'flex',
    flexWrap: 'nowrap'
  },
  chipFiltro: {
    color: 'black',
    fontSize: 13,
    fontWeight: 'bolder',
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center'
  },
  chipComparacao: {
    fontSize: 13,
    color: '#727272',
    marginLeft: '1px',
    display: 'flex',
    alignItems: 'center'
  },
  chipPesquisa: {
    fontSize: 13,
    color: '#727272',
    marginLeft: '2px',
    display: 'flex',
    alignItems: 'center'
  }
});

class FilterChip extends Component {
  validarOperation(operation) {
    switch (operation) {
      case '%%':
        return 'contendo';
      case '==':
        return 'igual';
      case '>=':
        return 'maior ou igual';
      case '>':
        return 'maior';
      case '<=':
        return 'menor ou igual';
      case '<':
        return 'menor';
      case '!=':
        return 'diferente';
      default:
    }
  }

  validarTypeValue(chip) {
    switch (typeof chip.value) {
      case 'string':
        return chip.value;
      case 'number':
        return chip.value;
      default:
        if (chip.value.field === undefined) {
          return moment(chip.value).format('DD/MM/YYYY');
        } else {
          return chip.value.label;
        }
    }
  }

  closeChip(chip) {
    const chipData = this.props.chipDataInt.find(item => {
      if (this.props.viewKey === item.id) {
        return item;
      } else {
        return null;
      }
    });
    var index = chipData.content.indexOf(chip);
    this.props.deleteChip({ index, idOrigin: this.props.viewKey });
    return chipData.content.filter(data => {
      if (data !== chip) {
        return data;
      } else {
        return null;
      }
    });
  }

  render() {
    const { classes } = this.props;
    const chipData = this.props.chipDataInt.filter(item => {
      return this.props.viewKey === item.id;
    })[0].content;
    const stylesImg = {
      cursor: 'pointer',
      marginLeft: '5px',
      position: 'relative',
      bottom: this.props.marginBottomImg ? '6px' : -2
    };

    return (
      <div className={classes.divChipsArray}>
        {Object.keys(chipData).map(chip => (
          <div className={classes.chip} key={chipData.indexOf(chipData[chip])}>
            <div className={classes.chipContent}>
              <div className={classes.chipFiltro}>{chipData[chip].filter.label}:</div>
              <div className={classes.chipComparacao}>
                {this.validarOperation(chipData[chip].operation)}
              </div>
              <div className={classes.chipPesquisa}>"{this.validarTypeValue(chipData[chip])}"</div>
              <div>
                <img
                  src={iconeClip}
                  alt=' '
                  onClick={() => {
                    this.props.onFilterChanged(this.closeChip(chipData[chip]));
                  }}
                  style={stylesImg}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  chipDataInt: state.cardFiltersReducer.chipData,
  pagAnterior: state.cardFiltersReducer.histPaginas.previousPage
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ deleteChip }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FilterChip));
