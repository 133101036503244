/*
    Autor: Bruno Eduardo
*/

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import SwalConfirmacao from '../../../utils/functions/SwalConfirmacao/SwalConfirmacao';
import { bindActionCreators } from 'redux';
import { Formik, FastField } from 'formik';
import * as Yup from 'yup';
import CardIcon from '../../../components/CardIcon/CardIcon';
import Grid from '@material-ui/core/Grid';
import RespostaFechada from './respostas/RespostaFechada';
import RespostaMultiplaEscolha from './respostas/RespostaMultiplaEscolha';
import RespostaEscalaLinear from './respostas/RespostaEscalaLinear';
import Button from '@material-ui/core/Button';
import FormSelectOwn from '../../../components/FormSelectOwn/FormSelectOwn';
import newId from '../../../utils/functions/NewId/NewId';
import TextField from '../../../components/TextFieldOwn/TextFieldOwn';
import SwalMessage from '../../../utils/functions/SwalMessage/SwalMessage';
import clone from '../../../utils/functions/Clone/Clone';
import {
  showButtonFAB,
  changeListaPerguntas,
  hiddenButtonFAB,
  changePerguntaSelected,
  exitEditPergunta,
  changeConfigEscalaLinear,
  changeOpcoesPerguntas,
  newIdAlternativa,
  syncStPergunta
} from '../../../states/actions/QuestionarioActions';

const styles = theme => ({
  containerCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 290px)',
    marginBottom: 70,
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: 30
  },
  containerButtons: {
    position: 'absolute',
    bottom: 15,
    left: 0,
    paddingRight: 30,
    paddingTop: 15,
    borderTop: '1px solid rgba(0,0,0,0.12)'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  buttonActions: {
    boxShadow: '0px 3px 6px 0px rgba(0,0,0,0.24)'
  }
});

class EditarPergunta extends Component {
  constructor(props) {
    super(props);

    this.state = {
      perguntaSelected: this.props.perguntaSelected,
      triedSubmit: false
    };

    this.onRemove = this.onRemove.bind(this);
    this.exitEditing = this.exitEditing.bind(this);
    this.submitPergunta = this.submitPergunta.bind(this);
    this.onChangeState = this.onChangeState.bind(this);
    this.validaRemove = this.validaRemove.bind(this);
    this.onChangeTpQuestao = this.onChangeTpQuestao.bind(this);
  }

  componentDidMount() {
    if (this.props.editandoPergunta && !this.props.importandoPerguntas) {
      this.props.hiddenButtonFAB();
    }
  }

  componentWillUnmount() {
    this.props.changePerguntaSelected(this.state.perguntaSelected);
  }

  submitPergunta() {
    const perguntaSelected = {
      ...clone(this.state.perguntaSelected),
      tpQuestao: +this.state.perguntaSelected.tpQuestao
    };
    if (!this.validaSubmit(+perguntaSelected.tpQuestao)) return;

    if (parseInt(perguntaSelected.tpAuditoria) === 1 && perguntaSelected.tpQuestao !== 3) {
      SwalMessage({ text: 'Pergunta deve ser aberta para conter auditoria por telefone' });
      return false;
    }

    if (
      parseInt(perguntaSelected.tpAuditoria) === 2 &&
      !(perguntaSelected.tpQuestao === 3 || perguntaSelected.tpQuestao === 0)
    ) {
      SwalMessage({ text: 'Pergunta deve ser aberta ou fechada para conter auditoria por local' });
      return false;
    }

    const { tiposResposta, atualizandoPergunta } = this.props;
    const auxLista = clone(this.props.perguntasData);

    const config = tiposResposta[perguntaSelected.tpQuestao];

    const questaoInList = auxLista.find(item => item.idQuestao === perguntaSelected.idQuestao);

    if (perguntaSelected.tpQuestao === 3) {
      perguntaSelected.alternativaList = this.props.alternativaList.filter(
        item => item.stRegistro === 2
      );
    } else if (perguntaSelected.tpQuestao === 2) {
      if (questaoInList === undefined) {
        perguntaSelected.alternativaList = [];

        const auxFuncNewAlternativa = novoMaiorId =>
          (perguntaSelected.alternativaList[perguntaSelected.alternativaList.length - 1].idAlternativa =
            novoMaiorId);

        for (let escala = +config.valorInicial.value; escala <= +config.valorFinal.value; escala++) {
          perguntaSelected.alternativaList.push({
            dsAlternativa: `${escala}`,
            stResidual: 0,
            stArredondamento: 0,
            stFluxoExcecao: 0,
            tpAlternativa: 0,
            stRegistro: 0
          });

          this.props.newIdAlternativa(auxFuncNewAlternativa);
        }
      } else {
        const deleteExcess = (inicio, fim) => {
          for (let escala = inicio; escala < fim; escala++) {
            if (questaoInList.stRegistro === 1) {
              const alternativaIndex = perguntaSelected.alternativaList.findIndex(
                item => +item.dsAlternativa === escala
              );

              alternativaIndex !== -1 &&
                perguntaSelected.alternativaList[alternativaIndex] &&
                (perguntaSelected.alternativaList[alternativaIndex].stRegistro = 2);
            } else {
              const alternativaIndex = perguntaSelected.alternativaList.findIndex(
                item => +item.dsAlternativa === escala
              );

              alternativaIndex !== -1 && perguntaSelected.alternativaList.splice(alternativaIndex, 1);
            }
          }
        };

        const addNew = (inicio, fim) => {
          const auxFunc = novoMaiorId =>
            (perguntaSelected.alternativaList[
              perguntaSelected.alternativaList.length - 1
            ].idAlternativa = novoMaiorId);

          for (let escala = inicio; escala < fim; escala++) {
            const existente = perguntaSelected.alternativaList.findIndex(
              item => +item.dsAlternativa === escala
            );

            if (existente !== -1) {
              perguntaSelected.alternativaList[existente].stRegistro = 1;
            } else {
              perguntaSelected.alternativaList.push({
                dsAlternativa: `${escala}`,
                stResidual: 0,
                stArredondamento: 0,
                stFluxoExcecao: 0,
                tpAlternativa: 0,
                stRegistro: 0
              });

              this.props.newIdAlternativa(auxFunc);
            }
          }
        };

        if (questaoInList.nrLinearInicio < +config.valorInicial.value) {
          deleteExcess(questaoInList.nrLinearInicio, +config.valorInicial.value);
        }

        if (questaoInList.nrLinearFinal > +config.valorFinal.value) {
          deleteExcess(+config.valorFinal.value + 1, questaoInList.nrLinearFinal + 1);
        }

        if (questaoInList.nrLinearInicio > +config.valorInicial.value) {
          addNew(+config.valorInicial.value, questaoInList.nrLinearInicio);
        }

        if (questaoInList.nrLinearFinal < +config.valorFinal.value) {
          addNew(questaoInList.nrLinearFinal + 1, +config.valorFinal.value + 1);
        }
      }
    } else {
      perguntaSelected.alternativaList = this.props.alternativaList;
    }

    perguntaSelected.tpQuestao = +perguntaSelected.tpQuestao;

    let respostaProps = {
      stOutros: 0,
      stValoresInteiros: 0,
      stFluxoExcecao: 0,
      stFinalizaQuestionario: 0,
      stDescartaEntrevista: 0
    };

    if (perguntaSelected.tpQuestao === 2) {
      respostaProps = {
        ...respostaProps,
        nrLinearInicio: +config.valorInicial.value,
        dsLinearInicio: config.valorInicial.marcador,
        nrLinearFinal: +config.valorFinal.value,
        dsLinearFinal: config.valorFinal.marcador
      };
    } else if (perguntaSelected.tpQuestao < 2) {
      respostaProps = {
        ...respostaProps,
        stOutros: +config.stOutros,
        stValoresInteiros: +config.stValoresInteiros
      };

      if (perguntaSelected.tpQuestao === 0) {
        respostaProps = {
          ...respostaProps,
          stFluxoExcecao: +config.stFluxoExcecao,
          stFinalizaQuestionario: +config.stFinalizaQuestionario || 0,
          stDescartaEntrevista: +config.stDescartaEntrevista || 0,
          questaoAvanco: config.questaoAvanco || null
        };
      }
    }

    if (atualizandoPergunta) {
      auxLista[perguntaSelected.nrOrdem] = {
        ...auxLista[perguntaSelected.nrOrdem],
        ...perguntaSelected,
        ...respostaProps,
        tipoRespostaUI: config.nome
      };
    } else {
      auxLista.push({
        ...perguntaSelected,
        ...respostaProps,
        tipoRespostaUI: config.nome,
        stRegistro: 0,
        idQuestao: newId(auxLista, p => p.idQuestao)
      });
    }

    this.props.changeListaPerguntas(auxLista);

    this.exitEditing();
  }

  onRemove() {
    const { atualizandoPergunta, perguntaSelected, perguntasData, changeListaPerguntas } = this.props;

    if (atualizandoPergunta && perguntaSelected) {
      if (this.validaRemove()) {
        SwalMessage({
          text: 'Não é possivel remover esta pergunta porque ela esta sendo usada em uma regra de análise ou fluxo de exceção'
        });
        return;
      }

      SwalConfirmacao({ text: 'Deseja mesmo remover esta pergunta da lista?' }).then(res => {
        if (res) {
          const auxLista = clone(perguntasData);
          if (perguntaSelected.stRegistro === 1) {
            auxLista[perguntaSelected.nrOrdem].stRegistro = 2;
          } else {
            auxLista.splice(perguntaSelected.nrOrdem, 1);
          }
          changeListaPerguntas(auxLista);
          this.exitEditing();
        }
      });
    }
  }

  validaSubmit(tpQuestao) {
    if (tpQuestao === 3) return true;
    const config = this.props.tiposResposta[tpQuestao];

    if (this.props.alternativaList.length === 0) {
      SwalMessage({ text: 'Adicione no mínimo uma alternativa' });
      return false;
    }

    if (tpQuestao === 2) {
      config.valorInicial.label = 'Marcador inicial';
      config.valorInicial.touched = true;
      config.valorFinal.label = 'Marcador final';
      config.valorFinal.touched = true;
      this.props.changeConfigEscalaLinear(config);
      this.setState({ triedSubmit: true });

      return (
        config.valorInicial.marcador.trim() &&
        config.valorFinal.marcador.trim() &&
        config.valorInicial.value < config.valorFinal.value
      );
    } else {
      const alternativaList = clone(this.props.alternativaList);
      alternativaList.forEach(item => {
        item.label = '';
        item.touched = true;
      });
      this.props.changeOpcoesPerguntas(alternativaList);
      this.setState({ triedSubmit: true });

      return (
        alternativaList.reduce((prev, atu) => !!(prev && atu.dsAlternativa.trim()), true) &&
        (config.alternativaArredondamento || !config.stValoresInteiros) &&
        (tpQuestao === 0 ? config.alternativaExcecao || !config.stFluxoExcecao : true)
      );
    }
  }

  validaRemove() {
    const { perguntaSelected } = this.state;

    const validacoesAnalises = regra => {
      return (
        regra.questaoAnaliseList.find(item => item.questao.idQuestao === perguntaSelected.idQuestao) ||
        (regra.questaoSintetizadora &&
          regra.questaoSintetizadora.idQuestao === perguntaSelected.idQuestao)
      );
    };

    const validacoesQuestoes = quest => {
      return quest.questaoAvanco && quest.questaoAvanco.idQuestao === perguntaSelected.idQuestao;
    };

    return (
      perguntaSelected.stFluxoExcecao === 1 ||
      this.props.regrasData.find(validacoesAnalises) ||
      this.props.perguntasData.find(validacoesQuestoes)
    );
  }

  onChangeTpQuestao(e, handleChange) {
    const { perguntaSelected } = this.state;
    const { atualizandoPergunta, tiposResposta } = this.props;

    if (
      atualizandoPergunta &&
      +e.target.value !== perguntaSelected.tpQuestao &&
      (+e.target.value > 1 || perguntaSelected.tpQuestao > 1)
    ) {
      SwalMessage({
        text: 'Não é possivel alterar o tipo de uma questão depois de criada'
      });
      return;
    }

    const config = tiposResposta[perguntaSelected.tpQuestao];

    if (atualizandoPergunta && perguntaSelected.tpQuestao < 2) {
      this.props.syncStPergunta({
        stOutros: config.stOutros,
        stValoresInteiros: config.stValoresInteiros,
        alternativaArredondamento: config.alternativaArredondamento
      });
    }

    if (config.questaoAvanco || config.stFinalizaQuestionario === 1) {
      SwalConfirmacao({
        text: 'Esta questão tem um fluxo de exceção configurado, deseja alterar o tipo de resposta mesmo assim?'
      }).then(res => {
        if (res) {
          this.onChangeState('tpQuestao', e, handleChange);
        }
      });
    } else {
      this.onChangeState('tpQuestao', e, handleChange);
    }
  }

  exitEditing() {
    this.props.exitEditPergunta();
    this.props.showButtonFAB();
  }

  onChangeState(campo, e, callback) {
    const aux = this.state.perguntaSelected;
    aux[campo] = e.target.value;
    this.setState({ perguntaSelected: aux });
    callback(e);
  }

  render() {
    const { classes } = this.props;

    return (
      <CardIcon
        titulo={
          this.props.atualizandoPergunta
            ? `Editar pergunta #${this.state.perguntaSelected.nrOrdem + 1}`
            : `Adicionar pergunta #${this.state.perguntaSelected.nrOrdem + 1}`
        }
        styleCollapse={{ paddingRight: 0 }}
      >
        <div className={classes.containerCard}>
          <Formik
            key={this.state.key}
            initialValues={this.state.perguntaSelected}
            onSubmit={this.submitPergunta}
            validateOnBlur
            enableReinitialize={true}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              dsTitulo: Yup.string().required('Campo obrigatório'),
              tpQuestao: Yup.number().required('Campo obrigatório'),
              dsApoio: Yup.string().max(200, 'O campo deve ter no máximo 200 caracteres'),
              tpAuditoria: Yup.string().required('Campo obrigatório')
            })}
            render={({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => {
              return (
                <>
                  <form autoComplete='off'>
                    <Grid container spacing={0} style={{ marginTop: 25 }}>
                      <Grid item xs={10} style={{ height: 70, paddingRight: 15 }}>
                        <FastField name='dsTitulo'>
                          {({ form, field }) => (
                            <TextField
                              {...field}
                              id='dsTitulo'
                              label='Título'
                              onBlur={e => this.onChangeState('dsTitulo', e, form.handleBlur)}
                              autoFocus={true}
                              error={form.errors.dsTitulo && form.touched.dsTitulo}
                              helperText={
                                form.errors.dsTitulo && form.touched.dsTitulo
                                  ? form.errors.dsTitulo
                                  : null
                              }
                              inputProps={{ maxLength: 200 }}
                            />
                          )}
                        </FastField>
                      </Grid>

                      <Grid item xs={2} style={{ height: 70 }}>
                        <FormSelectOwn
                          error={errors.tpQuestao && touched.tpQuestao}
                          name='tpQuestao'
                          label='Tipo de resposta'
                          value={values.tpQuestao}
                          onChange={e => this.onChangeTpQuestao(e, handleChange)}
                          onBlur={handleBlur}
                          options={[
                            { name: 'Fechada', value: 0 },
                            { name: 'Múltipla escolha', value: 1 },
                            { name: 'Escala linear', value: 2 },
                            { name: 'Aberta', value: 3 }
                          ]}
                          optionLabel={opt => opt.name}
                          optionValue={opt => opt.value}
                          errorText={errors.tpQuestao}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={0}>
                      <Grid item xs style={{ height: 70 }}>
                        <FastField name='dsApoio'>
                          {({ form, field }) => (
                            <TextField
                              {...field}
                              id='dsApoio'
                              label='Texto de apoio'
                              onBlur={e => this.onChangeState('dsApoio', e, form.handleBlur)}
                              multiline={true}
                              rowsMax={2}
                              error={form.errors.dsApoio && form.touched.dsApoio}
                              helperText={
                                form.errors.dsApoio && form.touched.dsApoio ? form.errors.dsApoio : null
                              }
                              inputProps={{ maxLength: 200 }}
                            />
                          )}
                        </FastField>
                      </Grid>
                    </Grid>
                  </form>

                  {(() => {
                    switch (+values.tpQuestao) {
                      case 0:
                        return <RespostaFechada triedSubmit={this.state.triedSubmit} />;

                      case 1:
                        return <RespostaMultiplaEscolha triedSubmit={this.state.triedSubmit} />;

                      case 2:
                        return <RespostaEscalaLinear triedSubmit={this.state.triedSubmit} />;

                      default:
                        return <div />;
                    }
                  })()}

                  <Grid container spacing={0} style={{ marginTop: 20 }}>
                    <Grid item xs={2} style={{ height: 70 }}>
                      <FormSelectOwn
                        error={errors.tpAuditoria && touched.tpAuditoria}
                        name='tpAuditoria'
                        label='Tipo de auditoria'
                        value={values.tpAuditoria}
                        onChange={e => this.onChangeState('tpAuditoria', e, handleChange)}
                        onBlur={handleBlur}
                        options={[
                          { name: 'Nenhum', value: 0 },
                          { name: 'Telefone', value: 1 },
                          { name: 'Local', value: 2 }
                        ]}
                        optionLabel={opt => opt.name}
                        optionValue={opt => opt.value}
                        errorText={errors.tpAuditoria}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={0} className={classes.containerButtons}>
                    <Grid item xs>
                      <div className={classes.buttons}>
                        {this.props.atualizandoPergunta && (
                          <Button
                            variant='contained'
                            color='secondary'
                            className={classes.buttonActions}
                            style={{ marginRight: 15, backgroundColor: '#F33A30' }}
                            onClick={this.onRemove}
                          >
                            REMOVER
                          </Button>
                        )}

                        <Button
                          variant='contained'
                          color='secondary'
                          className={classes.buttonActions}
                          style={{ marginRight: 15, backgroundColor: '#124268' }}
                          onClick={this.exitEditing}
                        >
                          CANCELAR
                        </Button>

                        <Button
                          variant='contained'
                          color='secondary'
                          className={classes.buttonActions}
                          style={{ backgroundColor: '#2BA69F' }}
                          onClick={handleSubmit}
                        >
                          CONFIRMAR
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </>
              );
            }}
          />
        </div>
      </CardIcon>
    );
  }
}

const mapStateToProps = state => {
  const { tabPerguntasQuestionarioReducer, tabRegrasQuestionarioReducer } = state.questionarioReducer;

  return {
    perguntasData: tabPerguntasQuestionarioReducer.perguntasData,
    editandoPergunta: tabPerguntasQuestionarioReducer.editandoPergunta,
    atualizandoPergunta: tabPerguntasQuestionarioReducer.atualizandoPergunta,
    tiposResposta: tabPerguntasQuestionarioReducer.tiposResposta,
    importandoPerguntas: tabPerguntasQuestionarioReducer.importandoPerguntas,
    perguntaSelected: tabPerguntasQuestionarioReducer.perguntaSelected,
    alternativaList: tabPerguntasQuestionarioReducer.perguntaSelected.alternativaList,
    regrasData: tabRegrasQuestionarioReducer.regrasData
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      showButtonFAB,
      hiddenButtonFAB,
      changePerguntaSelected,
      changeListaPerguntas,
      exitEditPergunta,
      changeConfigEscalaLinear,
      changeOpcoesPerguntas,
      newIdAlternativa,
      syncStPergunta
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditarPergunta));
