/*
    Autor: Gabriela Farias
*/
import React, { Component } from 'react';
import DataTable from '../../components/DataTable/DataTable';
import CardFilters from '../../components/CardFilters/CardFilters';
import GroupButtonGrid from '../../components/GroupButtonGrid/GroupButtonGrid';
import ButtonFAB from '../../components/ButtonFab/ButtonFab';
import iconAdd from './../../assets/images/icon_add.png';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { updateToolbar, enableLoading } from '../../states/actions/ToolbarActions';
import { getAllUsuarios, deleteUsuario } from '../../resources/api/usuario';
import SwalConfirmacao from '../../utils/functions/SwalConfirmacao/SwalConfirmacao';
import SwalMessage from '../../utils/functions/SwalMessage/SwalMessage';
import FilterUtil from '../filterUtil';

const style = theme => ({
  containerTable: {
    display: 'flex',
    height: '100%'
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2BA69F'
    }
  },
  typography: {
    useNextVariants: true
  }
});

class Usuarios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemSelect: '',
      disabledEdit: true,
      disabledDelete: true,
      dataInTable: [],
      dataAppSync: [],
      head: [
        { field: 'nmUsuario', headerName: 'Nome', sort: 'asc', col: 3 },
        { field: 'dsEmail', headerName: 'E-mail', col: 3 },
        { field: 'nrTelefone', headerName: 'Telefone', col: 2 },
        { field: 'tipoPerfil', headerName: 'Perfil', col: 2 },
        { field: 'situacao', headerName: 'Situação', col: 2 }
      ],
      operationValues: [
        {
          label: 'Nome',
          field: 'nmUsuario',
          type: 'string'
        },
        {
          label: 'E-mail',
          field: 'dsEmail',
          type: 'string'
        },
        {
          label: 'Perfil',
          field: 'tpUsuario',
          type: 'enum',
          options: [
            { label: 'Entrevistador', field: 'entrevistador', value: '0' },
            { label: 'Administador', field: 'admin', value: '1' }
          ]
        },
        {
          label: 'Situação',
          field: 'stUsuario',
          type: 'enum',
          options: [
            { label: 'Ativo', field: 'ativo', value: '1' },
            { label: 'Inativo', field: 'inativo', value: '0' }
          ]
        }
      ]
    };

    const title = 'Usuários';
    if (this.props.itensState.name !== title) {
      this.props.updateToolbar({ toolbar: title, name: title });
    }
    this.props.enableLoading(true);

    this._isMounted = false;

    this.selectChange = this.selectChange.bind(this);
    this.goAdd = this.goAdd.bind(this);
    this.goEdit = this.goEdit.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.deleteUsuario = this.deleteUsuario.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.onFilter();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  deleteUsuario() {
    if (this.state.itemSelect) {
      SwalConfirmacao({ text: 'Deseja mesmo excluir este usuário?' }).then(res => {
        if (res) {
          this._isMounted &&
            deleteUsuario(this.state.itemSelect.idUsuario)
              .then(() => {
                SwalMessage({
                  text: 'Usuário excluído',
                  icon: 'success',
                  callback: this.onFilter
                });
              })
              .catch(err => {
                SwalMessage({
                  title: 'Falha ao excluir usuário',
                  text: 'Não foi possível excluir o usuário, tente novamente',
                  err
                });
              });
        }
      });
    }
  }

  goAdd() {
    this.props.history.push('/app/usuario/novo');
  }

  goEdit() {
    this.props.history.push('/app/usuario/editar/' + this.state.itemSelect.idUsuario);
  }

  selectChange(select) {
    if (select) {
      this.setState({ itemSelect: select, disabledEdit: false, disabledDelete: false });
    } else {
      this.setState({ itemSelect: '', disabledEdit: true, disabledDelete: true });
    }
  }

  onFilterChanged(dataChips) {
    this.setState({
      dataInTable: FilterUtil.applyFilters(this.state.dataAppSync, dataChips)
    });
  }

  onFilter(dataChips) {
    dataChips = dataChips || [];

    this.props.enableLoading(true);
    this._isMounted &&
      getAllUsuarios()
        .then(res => {
          const data = res.data.map(item => ({
            ...item,
            tipoPerfil: ['Entrevistador', 'Administrador'][item.tpUsuario],
            situacao: ['Inativo', 'Ativo'][item.stUsuario]
          }));

          this._isMounted &&
            this.setState(
              {
                dataInTable: FilterUtil.applyFilters(data, dataChips),
                dataAppSync: data
              },
              () => this.props.enableLoading(false)
            );
        })
        .catch(err => {
          SwalMessage({
            title: 'Falha ao carregar página',
            text: 'Não foi carregar a página, tente novamente',
            err,
            callback: () => {
              this._isMounted && this.setState({ dataAppSync: [] });
              this.props.enableLoading(false);
            }
          });
        });
  }

  render() {
    const { classes } = this.props;
    const pagination = { length: this.state.dataInTable.length, page: 0 };

    return (
      <MuiThemeProvider theme={theme}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <CardFilters
            filtros={this.state.operationValues}
            onFilter={this.onFilter}
            onFilterChanged={this.onFilterChanged}
            usePaddings={false}
            viewKey='usuarios'
          />

          <GroupButtonGrid
            showAdd={false}
            showEdit={true}
            showDelete={true}
            disableEdit={this.state.disabledEdit}
            disableDelete={this.state.disabledDelete}
            onClickEdit={this.goEdit}
            onClickDelete={this.deleteUsuario}
          />

          <div className={classes.containerTable}>
            <DataTable
              data={this.state.dataInTable}
              columns={this.state.head}
              selectChange={this.selectChange}
              showPagination={true}
              pagination={pagination}
              handleChangePage={() => {}}
              handleChangeRowsPerPage={() => {}}
            />
          </div>

          <ButtonFAB icon={iconAdd} positionDefault={true} onClick={this.goAdd} />
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbarReducer,
  chipData: state.cardFiltersReducer.chipData.filter(date => {
    return date.id === 'usuarios';
  })[0].content
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ updateToolbar, enableLoading }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(style)(Usuarios)));
