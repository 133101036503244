import axios from 'axios';
import { URL_OAUTH } from '../common';

const client_id = 'azagros';
const client_secret = 'azagros-secret';
const code = '1234';

function refreshTokenHandler() {
  const auth = JSON.parse(localStorage.getItem('token'));

  if (auth) {
    const param = new URLSearchParams();
    param.append('grant_type', 'refresh_token');
    param.append('client_id', client_id);
    param.append('client_secret', client_secret);
    param.append('code', code);
    param.append('username', auth.email);
    param.append('refresh_token', auth.refresh_token);

    return axios
      .post(URL_OAUTH + '/login', param)
      .then(res => {
        localStorage.setItem(
          'token',
          JSON.stringify({
            refresh_token: res.data.refresh_token,
            access_token: res.data.access_token,
            expires_in: res.data.expires_in,
            email: auth.email
          })
        );
      })
      .catch(err => {
        localStorage.setItem('token', null);

        throw err;
      });
  } else {
    return Promise.reject();
  }
}

function loginHandler(mail, password) {
  const param = new URLSearchParams();
  param.append('grant_type', 'password');
  param.append('client_id', client_id);
  param.append('client_secret', client_secret);
  param.append('code', code);
  param.append('username', mail);
  param.append('password', password);

  return axios
    .post(URL_OAUTH + '/login', param)
    .then(response => {
      localStorage.setItem(
        'token',
        JSON.stringify({
          refresh_token: response.data.refresh_token,
          access_token: response.data.access_token,
          expires_in: response.data.expires_in,
          email: mail
        })
      );
    })
    .catch(err => {
      throw err;
    });
}

function logoutHandler() {
  localStorage.setItem('token', null);
}

function recuperarSenhaHandler(mail) {
  return axios.put(URL_OAUTH + '/usuario/resetarsenha', {
    dsEmail: mail
  });
}

function resetarSenhaHandler(mail, novaSenha, token) {
  return axios.put(URL_OAUTH + '/usuario/recuperarsenha', {
    dsEmail: mail,
    dsSenha: novaSenha,
    dsToken: token
  });
}

function confirmarCadastroHandler(mail, senha, token) {
  return axios.put(URL_OAUTH + '/usuario/confirmarcadastro', {
    dsEmail: mail,
    dsSenha: senha,
    dsToken: token
  });
}

function verificarUsuarioHandler(email) {
  const auth = JSON.parse(localStorage.getItem('token'));

  if (auth) {
    return axios
      .get(URL_OAUTH + '/usuario/verificar-email/' + email)
      .then(response => {
        return response;
      })
      .catch(err => {
        throw err.response ? err.response : null;
      });
  }
}

function inicializarInterceptosHandler() {
  axios.interceptors.request.use(async query => {
    const auth = JSON.parse(localStorage.getItem('token'));

    if (auth) {
      query.headers.Authorization = 'Bearer ' + auth.access_token;
    }

    return query;
  });

  axios.interceptors.response.use(
    res => res,
    err => {
      if (err.config && err.response && err.response.status === 401 && !err.config._isRetry) {
        return refreshTokenHandler().then(() => {
          err.config._isRetry = true;
          const auth = JSON.parse(localStorage.getItem('token'));
          err.config.headers.Authorization = 'Bearer ' + auth.access_token;
          err.config.baseURL = undefined;

          return axios.request(err.config);
        });
      }

      return Promise.reject(err);
    }
  );
}

function findAllUsuariosHandler() {
  return axios.get(URL_OAUTH + '/usuario');
}

function alterarSenha(values) {
  return axios.put(URL_OAUTH + '/usuario/alterarsenha', values);
}

export default {
  logout: logoutHandler,
  login: loginHandler,
  refreshToken: refreshTokenHandler,
  confirmarCadastro: confirmarCadastroHandler,
  recuperarSenha: recuperarSenhaHandler,
  resetarSenha: resetarSenhaHandler,
  verificarUsuario: verificarUsuarioHandler,
  inicializarInterceptos: inicializarInterceptosHandler,
  findAllUsuarios: findAllUsuariosHandler,
  alterarSenha
};
