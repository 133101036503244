import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = {
  button: {
    minHeight: 34,
    maxHeight: 34,
    minWidth: 34,
    maxWidth: 34,
    zIndex: 1
  },
  buttonGrid: {
    marginBottom: 9,
    marginLeft: 15
  }
};

function ButtonGrid(props) {
  const { classes } = props;

  return props.show ? (
    <div className={classes.buttonGrid}>
      <Button
        variant='contained'
        className={classes.button}
        style={{ backgroundColor: props.disabled ? '#95989A' : props.color, cursor: 'pointer' }}
        disabled={props.disabled}
        onClick={props.onClick}
      >
        {props.iconJSX ? props.iconJSX : <img src={props.icon} alt=' ' />}
      </Button>
    </div>
  ) : null;
}

ButtonGrid.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ButtonGrid);
