//@author: Gabriela Farias
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Fab from '@material-ui/core/Fab';
import iconProfile from '../../assets/images/icon_profile.png';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToApp from '@material-ui/icons/ExitToApp';
import iconeMenuAberto from '../../assets/images/icon_menu_pontos.png';
import IconButton from '@material-ui/core/IconButton';
import { BrowserRouter, withRouter } from 'react-router-dom';
import '../../assets/styles/AppMainToolbar.css';
import { connect } from 'react-redux';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ItensMenu from '../MenuLateral/itensMenu';
import { bindActionCreators } from 'redux';
import { updateToolbar } from '../../states/actions/ToolbarActions';
import { openMenu, closeMenu } from '../../states/actions/MenuLateralActions';
import Authentication from '../../resources/oauth/Authentication';
import { clearInfoUsuario } from '../../states/actions/LoginActions';
import Admin from '../../resources/oauth/Admin';

const drawerWidth = 270;
const drawerWidthAppBar = 55;

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex'
  },
  appBar: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    display: 'inline',
    float: 'right',
    width: `calc(100% - ${drawerWidthAppBar}px)`,
    background: 'transparent',
    boxShadow: 'none'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 30,
    marginRight: 15,
    backgroundColor: '#FFFFFF',
    height: 30,
    width: 30
  },
  hide: {
    marginLeft: '0px'
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: drawerWidthAppBar
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    zIndex: -1,
    minHeight: '80px !important'
  },
  content: {
    overflowY: 'auto',
    flexGrow: 1,
    backgroundColor: '#EBEBEB',
    display: 'flex',
    flexDirection: 'column'
  },
  titulo: {
    color: '#747474',
    fontFamily: 'Roboto',
    fontSize: 24,
    fontWeight: 'bold !important'
  },
  toolBarLeft: {
    float: 'left',
    height: 80
  },
  toolBarRight: {
    float: 'right',
    height: 80
  },
  divMenuIcone: {
    height: 15,
    width: 15,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  }
});

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiFab: {
      root: {
        minHeight: 30
      }
    }
  }
});

class AppMainToolbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = { key: '' };

    this.handleDrawer = this.handleDrawer.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentDidUpdate() {
    var selecionado =
      typeof this.props.itemSelecionado.item === 'string'
        ? this.props.itemSelecionado.item
        : this.props.itemSelecionado.item[0];

    if (selecionado !== 'selecionado') {
      ItensMenu.map(item => {
        if (item.id === selecionado) {
          this.anularItensMenusInternos(item);
        } else {
          item.selecionado = false;
          this.anularDemaisItensDoMenus(item.children);
        }
        return item;
      });
    } else {
      this.fecharTodosMenus(ItensMenu);
    }
  }

  handleDrawer() {
    this.props.itensState.open ? this.props.closeMenu() : this.props.openMenu();
  }

  logout() {
    this.props.clearInfoUsuario();
    Authentication.logout();
    Admin.resetPerfil();
    this.props.history.push('/login');
  }

  fecharTodosMenus(ItensDoMenu) {
    ItensDoMenu.map(item => {
      item.selecionado = false;
      this.anularDemaisItensDoMenus(item.children);
      return item;
    });
  }

  anularDemaisItensDoMenus(children) {
    if (children !== undefined) {
      children.map(item => {
        item.selecionado = false;
        this.anularDemaisItensDoMenus(item.children);
        return item;
      });
    }
  }

  anularItensMenusInternos(item) {
    item.children.map(item => {
      if (item.route === undefined) {
        if (item.id !== this.props.itemSelecionado.item[1]) {
          item.selecionado = false;
        }
        this.anularItensMenusInternos(item);
      }
      return item;
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <AppBar
          position='absolute'
          className={classNames(classes.appBar, this.props.itensState.open && classes.appBarShift)}
        >
          <Toolbar disableGutters={!this.props.itensState.open} className={classes.toolBarLeft}>
            <MuiThemeProvider theme={theme}>
              <Fab
                aria-label='Open drawer'
                onClick={this.handleDrawer}
                className={classNames(classes.menuButton, this.props.itensState.open && classes.hide)}
              >
                <div
                  id='button'
                  className={classNames(
                    classes.divMenuIcone,
                    this.props.itensState.open ? '' : 'menuOpen'
                  )}
                >
                  {this.props.itensState.open ? (
                    <img src={iconeMenuAberto} className={classes.menuIcone} alt='Icone menu' />
                  ) : (
                    <MenuIcon style={{ height: 15, width: 15, color: '#969696' }} />
                  )}
                </div>
              </Fab>
            </MuiThemeProvider>
            <div variant='title'>
              <MuiThemeProvider theme={theme}>
                <Typography className={classes.titulo} noWrap>
                  {this.props.nameToolbar.label}
                </Typography>
              </MuiThemeProvider>
            </div>
          </Toolbar>

          <Toolbar className={classes.toolBarRight}>
            <IconButton
              onClick={() => {
                this.props.history.push('/app/perfil');
                this.setState({ key: Math.random() });
              }}
              disabled={window.location.pathname === '/app/perfil'}
            >
              <img src={iconProfile} alt='Icone de perfil' />
            </IconButton>

            <IconButton onClick={this.logout}>
              <ExitToApp />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          variant='permanent'
          classes={{
            paper: classNames(
              classes.drawerPaper,
              !this.props.itensState.open && classes.drawerPaperClose
            )
          }}
          open={this.props.itensState.open}
        />
        <main className={classes.content}>
          <div className={classes.toolbar} />

          <BrowserRouter key={this.state.key}>{this.props.children}</BrowserRouter>
        </main>
      </div>
    );
  }
}

AppMainToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  itensState: state.menuLateralReducer,
  nameToolbar: state.toolbarReducer,
  itemSelecionado: state.menuLateralReducer.itemMenuSelecionado
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ updateToolbar, closeMenu, openMenu, clearInfoUsuario }, dispatch);
};

let EnhacedComponent = withStyles(styles, { withTheme: true })(AppMainToolbar);
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EnhacedComponent)
);
