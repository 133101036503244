//@author Gabriela Farias
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ButtonGrid from '../../components/ButtonGrid/ButtonGrid';
import iconAdd from '../../assets/images/icon_add.png';
import iconEdit from '../../assets/images/icon_editar.png';
import iconDelete from '../../assets/images/icon_excluir.png';
import { connect } from 'react-redux';

const styles = theme => ({
  divButtonGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    marginBottom: '-22px',
    marginRight: '30px'
  }
});

class GroupButtonGrid extends Component {
  render() {
    const { classes } = this.props;
    const customButtons = this.props.customButtons || [];

    const listButtons = customButtons.map((customButton, i) => <div key={i}>{customButton}</div>);

    const marginTop = this.props.disableMarginTop ? {} : { marginTop: 15 };

    return (
      <div className={classes.divButtonGrid} style={{ ...marginTop, ...this.props.style }}>
        {listButtons}

        <ButtonGrid
          show={this.props.showAdd}
          icon={iconAdd}
          color='#00863B'
          disabled={this.props.disableAdd || this.props.loading}
          onClick={this.props.onClickAdd}
        />

        <ButtonGrid
          show={this.props.showEdit}
          icon={iconEdit}
          color='#42ADE8'
          disabled={this.props.disableEdit || this.props.loading}
          onClick={this.props.onClickEdit}
        />

        <ButtonGrid
          show={this.props.showDelete}
          icon={iconDelete}
          color='#F33A30'
          disabled={this.props.disableDelete || this.props.loading}
          onClick={this.props.onClickDelete}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.toolbarReducer.loading
});

export default connect(mapStateToProps)(withStyles(styles)(GroupButtonGrid));
