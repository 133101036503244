import ActionTypes from '../actionTypes/LoginActionTypes';

const addInfoUsuario = payload => ({
  type: ActionTypes.ADD_INFOUSUARIO,
  payload
});

const clearInfoUsuario = () => ({ type: ActionTypes.CLEAR_INFOUSUARIO });

export { addInfoUsuario, clearInfoUsuario };
