import React from 'react';
import DataTable from './../../../components/DataTable/DataTable';
import { connect } from 'react-redux';
import './Styles.css';

const TabAgruparRespostas = props => {
  const {
    carregarLinhasSelecionadas,
    onChangeRespostasAgrupadasSelecteds,
    alternativasAgrupadas
  } = props;

  const containerTableStyle = {
    display: 'flex',
    minHeight: '43.5vh',
    border: '1px solid rgba(102, 102, 102, 0.34)',
    borderRadius: 5,
    maxWidth: 'calc(100vw - 115px)'
  };

  return (
    <div style={{ minHeight: '40vh', height: '100%' }}>
      <div style={containerTableStyle}>
        <DataTable
          config={{ rowSelection: 'multiple', rowClass: 'row-selected-style' }}
          columns={[
            {
              valueGetter: args => `${args.node.rowIndex + 1}) ${args.node.data.dsAlternativa}`,
              headerName: 'Título',
              col: 12,
              checkboxSelection: true,
              headerComponentParams: {
                template:
                  '<div class="ag-cell-label-container ag-header-cell-sorted-none" style="font-family: Roboto; font-size: 14px !important;" role="presentation"> ' +
                  '  <div style="float: left;">Título</div>' +
                  '  <div style="float: right; margin-right: 10vw; opacity: 0.54;">*As respostas selecionadas serão agrupadas na opção “Demais” da análise</div>' +
                  '</div>'
              }
            }
          ]}
          onGridReady={carregarLinhasSelecionadas}
          selectChange={onChangeRespostasAgrupadasSelecteds}
          data={alternativasAgrupadas}
          borderRadius
          noMargin
          noBox
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { tabRegrasQuestionarioReducer } = state.questionarioReducer;

  return { alternativasAgrupadas: tabRegrasQuestionarioReducer.alternativasAgrupadas };
};

export default connect(mapStateToProps)(TabAgruparRespostas);
