/*
    Autor: Bruno Eduardo

    Um textField que se "auto-controla" e não depende do seu componente pai para o onChange
    Só muda/manda o valor para o componente pai no onBlur
*/

import React, { Component } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import clone from '../../utils/functions/Clone/Clone';

class AutoControlledTextField extends Component {
  constructor(props) {
    super(props);

    this.state = { value: '' };

    this.theme = createMuiTheme({
      palette: {
        primary: {
          main: '#2BA69F'
        }
      },
      typography: {
        useNextVariants: true
      },
      overrides: {
        MuiInputLabel: {
          root: {
            fontSize: '14px !important',
            fontFamily: 'Roboto'
          }
        },
        MuiInput: {
          underline: {
            '&:hover:not($disabled):not($focused):not($error):before': { borderBottomColor: '#2BA69F' }
          },
          formControl: {
            marginTop: '16px !important'
          }
        },
        MuiInputBase: {
          input: {
            height: props.multiline ? '' : '20px !important'
          }
        }
      }
    });

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.value) {
      this.setState({ value: clone(this.props.value) });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.value !== this.state.value || JSON.stringify(nextProps) !== JSON.stringify(this.props)
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value && nextProps.value !== this.state.value) {
      this.setState({ value: clone(this.props.value) });
    }
  }

  handleChange(e) {
    if (e.persist) {
      e.persist();
    }
    this.setState({ value: e.target.value });
  }

  render() {
    return (
      <MuiThemeProvider theme={this.theme}>
        <TextField
          {...this.props.textFieldProps}
          onChange={e => {
            if (this.props.type === 'number' && !isNaN(+e.target.value)) {
              e.target.value = +e.target.value
                .split('e')
                .join('')
                .split('E')
                .join('');
              if (e.target.value < this.props.inputProps.min) {
                e.target.value = this.props.inputProps.min;
              }
              this.handleChange(e);
            } else if (this.props.type !== 'number') {
              this.handleChange(e);
            }
            this.props.onChange && this.props.onChange(e);
          }}
          onBlur={this.props.onBlur}
          onFocus={this.props.onFocus}
          inputProps={{ autoComplete: 'off', ...this.props.inputProps }}
          autoComplete='off'
          value={this.state.value}
          name={this.props.name}
          id={this.props.id}
          style={{ width: '100%', ...this.props.style }}
          type={this.props.type === 'number' || !this.props.type ? 'text' : this.props.type}
          label={this.props.label}
          error={this.props.error && true}
          helperText={this.props.helperText}
          rowsMax={this.props.rowsMax}
          disabled={this.props.disabled}
          onKeyDown={e => {
            this.props.onKeyDown && this.props.onKeyDown(e);
            e.which === 13 && this.props.onEnterPress && this.props.onEnterPress(e, this.state.value);
          }}
        />
      </MuiThemeProvider>
    );
  }
}

AutoControlledTextField.propTypes = {
  value: PropTypes.any.isRequired,
  onBlur: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onFocus: PropTypes.func,
  style: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
  rowsMax: PropTypes.number,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  inputProps: PropTypes.object,
  textFieldProps: PropTypes.object,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  onEnterPress: PropTypes.func,
  onKeyDown: PropTypes.func
};

export default AutoControlledTextField;
