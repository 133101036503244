const clienteActionTypes = {
  CHANGE_LISTA_CONTATOS_CLIENTE: 'CHANGE_LISTA_CONTATOS_CLIENTE',
  GO_ADD_CONTATO_CLIENTE: 'GO_ADD_CONTATO_CLIENTE',
  GO_EDIT_CONTATO_CLIENTE: 'GO_EDIT_CONTATO_CLIENTE',
  EXIT_EDIT_CONTATO_CLIENTE: 'EXIT_EDIT_CONTATO_CLIENTE',
  CHANGE_CONTATO_SELECTED_CLIENTE: 'CHANGE_CONTATO_SELECTED_CLIENTE',
  SHOW_BUTTON_FAB: 'SHOW_BUTTON_FAB',
  DEFAULT_CLIENTE: 'DEFAULT_CLIENTE'
};

export default clienteActionTypes;
