//@author Gabriela Farias
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const styles = theme => ({
  actions: {
    display: 'flex',
    padding: 0
  },
  expand: {
    opacity: 1,
    float: 'right',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    }),
    [theme.breakpoints.up('sm')]: {
      marginTop: '-32px'
    }
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    marginTop: '-33px'
  },
  div: {
    position: 'relative',
    paddingTop: 100,
    marginTop: 0
  },
  divTitulo: {
    fontSize: 20,
    color: '#666666',
    fontWeight: 'bold',
    height: 'auto',
    marginTop: '-5px',
    marginLeft: 15
  },
  containerHeader: {
    height: 54,
    backgroundColor: '#f1f9f9',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2BA69F'
    }
  },
  typography: {
    useNextVariants: true
  }
});

class CardIcon extends React.Component {
  state = { expanded: true };

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  render() {
    const { classes } = this.props;
    const stylesDiv = {
      paddingLeft: this.props.usePaddings ? 15 : 0,
      paddingRight: this.props.usePaddings ? 15 : 0,
      paddingTop: 0,
      ...this.props.rootStyles
    };
    const stylesCard = {
      borderRadius: this.props.usePaddings ? 5 : 0,
      overflow: 'visible',
      padding: 0
    };

    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.div} style={stylesDiv}>
          <Card style={{ ...stylesCard, ...this.props.styleCard }}>
            {!this.props.disableHeader && (
              <CardContent className={classes.containerHeader}>
                <div className={classes.divTitulo}>
                  {this.state.expanded ? <>{this.props.titulo}</> : <>{this.props.header}</>}
                </div>
                {this.props.enableExpandButton && (
                  <IconButton
                    className={classnames(classes.expand, {
                      [classes.expandOpen]: this.state.expanded
                    })}
                    style={{ margin: 12 }}
                    onClick={this.handleExpandClick}
                    aria-expanded={this.state.expanded}
                  >
                    {this.props.stateCardblock ? <ExpandMoreIcon /> : ''}
                  </IconButton>
                )}
              </CardContent>
            )}

            <CardActions className={classes.actions} disableActionSpacing />

            <Collapse
              in={this.state.expanded}
              timeout='auto'
              unmountOnExit
              style={{
                overflow: this.props.filtros ? null : 'visible',
                padding: 30,
                paddingBottom: 0,
                paddingTop: 0,
                ...this.props.styleCollapse
              }}
              className={this.state.expanded ? 'run-animation-open' : 'run-animation-close'}
            >
              <CardContent style={{ padding: 0 }}>{this.props.children}</CardContent>
            </Collapse>
          </Card>
        </div>
      </MuiThemeProvider>
    );
  }
}

CardIcon.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CardIcon);
