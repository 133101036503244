import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import DataTable from '../../components/DataTable/DataTable';
import { Search } from '@material-ui/icons';
import GroupButtonGrid from './../../components/GroupButtonGrid/GroupButtonGrid';
import ButtonGrid from '../../components/ButtonGrid/ButtonGrid';

const styles = {
  containerTable: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: -15,
    height: 'calc(100vh - 135px)',
    maxWidth: '100%',
  },
};

const head = [
  { field: 'usuario.nmUsuario', headerName: 'Entrevistador', col: 6 },
  { field: 'nrEntrevistas', headerName: 'Número de entrevistas', col: 3 },
  {
    valueGetter: (args) => (args.node.data.stQuestionarioUsuario === 0 ? 'Sim' : 'Não'),
    headerName: 'Bloqueado',
    col: 3,
  },
];

/**
 * Componente para lista de entrevistadores
 *
 * @author Gabriela Farias
 * @class Entrevistadores
 */
function Entrevistadores(props) {
  const { classes } = props;
  const [selected, setSelected] = useState(null);

  /**
   * Armazena o item selecionado
   */
  const selectChange = (select) => {
    if (select) {
      setSelected(select);
    } else {
      setSelected(null);
    }
  };

  return (
    <div className={classes.containerTable}>
      <GroupButtonGrid
        showAdd={false}
        showEdit={false}
        showDelete={false}
        customButtons={[
          <ButtonGrid
            show
            disabled={!selected}
            iconJSX={
              <div style={{ color: 'white', display: 'flex' }}>
                <Search color='inherit' />
              </div>
            }
            color='#42ADE8'
            onClick={() => props.entrevistadorSelect(selected)}
          />,
        ]}
      />

      <DataTable
        data={props.entrevistadoresList}
        columns={head}
        selectChange={(select) => selectChange(select)}
        showPagination={true}
        pagination={{ length: props.entrevistadoresList.length, page: 0 }}
        handleChangePage={() => {}}
        handleChangeRowsPerPage={() => {}}
      />
    </div>
  );
}

export default withStyles(styles)(Entrevistadores);
