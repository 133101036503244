import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import DataTable from '../../components/DataTable/DataTable';
import GroupButtonGrid from './../../components/GroupButtonGrid/GroupButtonGrid';
import { Grid, Button } from '@material-ui/core';
import SwalConfirmacao from '../../utils/functions/SwalConfirmacao/SwalConfirmacao';
import CardIcon from './../../components/CardIcon/CardIcon';
import TextField from './../../components/TextFieldOwn/TextFieldOwn';
import FormSelectOwn from './../../components/FormSelectOwn/FormSelectOwn';
import { Radio, Checkbox } from '@material-ui/core';

const styles = {
  containerCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 322px)',
    marginBottom: 15,
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: 30,
  },
  text: {
    height: 70,
  },
  containerTable: {
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 135px)',
    marginTop: -15,
  },
  containerButtons: {
    paddingTop: 15,
    paddingBottom: 15,
    borderTop: '1px solid rgba(0,0,0,0.12)',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  buttonActions: {
    marginRight: 20,
    width: 110,
    boxShadow: '0px 3px 6px 0px rgba(0,0,0,0.24)',
  },
  option: {
    height: 30,
    color: 'rgb(0, 0, 0, 0.87)',
    alignItems: 'center',
    display: 'flex',
    borderBottom: '1px solid rgb(0, 0, 0, 0.12)',
  },
  containerOptions: {
    display: 'flex',
    alignItems: 'center',
    height: 70,
  },
};

const head = [
  { field: 'dsTitulo', headerName: 'Título', col: 8 },
  { field: 'dsTpQuestao', headerName: 'Tipo de resposta', col: 4 },
];

/**
 * Componente para listagem de auditorias e edição de auditoria
 *
 * @author Gabriela Farias
 * @class AuditoriaDetalhamento
 */
function AuditoriaDetalhamento(props) {
  const { classes } = props;
  const [selected, setSelected] = useState(null);
  const [editing, setEditing] = useState(false);
  const [idCheck, setIdCheck] = useState('');
  const [initialRespostas, setInitialRespostas] = useState([]);
  const [valueSelect, setValueSelect] = useState(0);
  const [numberList, setNumberList] = useState([]);

  const adjustLinearItem = () => {
    if (selected.tpQuestao === 2) {
      let result = selected.alternativaList.find((item) => item.usuarioAnswer === true);
      setValueSelect(valueSelect || result.dsAlternativa);

      var addNumber = [];
      selected.alternativaList.forEach((item) => {
        addNumber.push(item.dsAlternativa);
      });

      setNumberList(addNumber);
    }
  };

  /**
   * Envia o item selecionado para a classe de auditoria
   */
  const selectChange = (select) => {
    if (select) {
      props.auditoriaSelecionada(select);
      setSelected(select);
      if (select.tpQuestao === 3) {
        setInitialRespostas({ ...select });
      } else if (select.tpQuestao === 2) {
        setInitialRespostas(select.alternativaList[0].dsAlternativa);
      } else {
        const selectedInitial = select.alternativaList.filter((doc) => {
          return doc.usuarioAnswer === true;
        });

        setInitialRespostas([...selectedInitial]);
      }
    } else {
      props.auditoriaSelecionada(null);
      setSelected(null);
      setInitialRespostas([]);
    }
  };

  /**
   * Apresenta a tela de detalhamento e retira o FAB
   */
  const editMode = () => {
    if (!editing) {
      selected.alternativaList.forEach((doc) => {
        if (doc.usuarioAnswer) {
          setIdCheck(doc.idAlternativa);
        }
      });
    }
    props.editing(editing);
    setEditing(!editing);
    adjustLinearItem();
  };

  /*
   * Trata salvar auditoria
   */
  const clickSalvar = () => {
    setSelected(null);
    setInitialRespostas([]);
    if (selected.tpQuestao === 0) {
      selected.alternativaList.forEach((doc) => {
        doc.usuarioAnswer = idCheck === doc.idAlternativa;
      });
    }

    editMode();
    if (selected.tpQuestao === 3) {
      props.clickSalvar(selected, selected.dsResposta);
    } else {
      props.clickSalvar(selected, valueSelect);
    }
  };

  /*
   * Trata voltar e descarta alteração
   */
  const clickVoltar = () => {
    SwalConfirmacao({ text: 'Ao voltar as alterações serão perdidas, deseja voltar?' }).then((res) => {
      if (res) {
        editMode();
        setSelected(null);
        setInitialRespostas([]);
        resetItem();
      }
    });
  };

  /*
   * Trata cancelar e voltar alterações
   */
  const clickCancelar = () => {
    SwalConfirmacao({ text: 'Ao cancelar as alterações serão perdidas, deseja cancelar?' }).then(
      (res) => {
        if (res) {
          resetItem();
        }
      }
    );
  };

  /*
   * Limpa as informações após voltar ou cancelar
   */
  const resetItem = () => {
    if (selected.tpQuestao === 3) {
      selected.dsResposta = initialRespostas.dsResposta;
    } else if (selected.tpQuestao === 2) {
      selected.alternativaList[0].dsAlternativa = initialRespostas;
      setIdCheck(Math.random());
    } else {
      selected.alternativaList.forEach((doc) => {
        doc.usuarioAnswer = false;
        initialRespostas.forEach((initial) => {
          if (doc.idAlternativa === initial.idAlternativa) {
            setIdCheck(doc.idAlternativa);
            doc.usuarioAnswer = true;
          }
        });
      });

      if (selected.tpQuestao === 1) {
        setIdCheck(Math.random());
      }
    }
  };

  /*
   * Trata mundaça do radio
   */
  const changeRadio = (idResultado) => {
    setIdCheck(idResultado);
  };

  return (
    <React.Fragment>
      {!editing && (
        <div className={classes.containerTable}>
          <React.Fragment>
            <GroupButtonGrid
              showAdd={false}
              showEdit={true}
              showDelete={false}
              disableEdit={!selected}
              onClickEdit={editMode}
            />

            <DataTable
              data={props.auditoriaList}
              columns={head}
              selectChange={(select) => selectChange(select)}
              showPagination={true}
              pagination={{ length: props.auditoriaList.length, page: 0 }}
              handleChangePage={() => {}}
              handleChangeRowsPerPage={() => {}}
            />
          </React.Fragment>
        </div>
      )}
      {editing && editAuditoria()}
    </React.Fragment>
  );

  /**
   * Card detalhado de cada auditoria
   */
  function editAuditoria() {
    return (
      <CardIcon
        titulo='Título do questionário'
        rootStyles={{ height: 'calc(100vh - 185px)', marginTop: 20, maxWidth: '100%' }}
      >
        <div className={classes.containerCard}>
          <Grid container>
            <Grid item xs={10}>
              <TextField
                id='dsTitulo'
                label='Título'
                value={selected.dsTitulo}
                disabled={true}
                style={{ marginTop: 20 }}
                className={classes.text}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id='dsApoio'
                label='Texto de apoio'
                value={selected.dsApoio}
                disabled={true}
                className={classes.text}
              />
            </Grid>
          </Grid>

          {selected.tpQuestao === 3 && (
            <TextField
              id='dsResposta'
              label='Resposta'
              value={selected.dsResposta}
              style={{ marginTop: 20 }}
              className={classes.text}
              onChange={(value) => {
                selected.dsResposta = value.target.value;
                setIdCheck(value.target.value);
              }}
            />
          )}
          {selected.tpQuestao !== 2 &&
            selected.tpQuestao !== 3 &&
            selected.alternativaList.map((doc) => (
              <Grid key={doc.idAlternativa} container className={classes.containerOptions}>
                <Grid item xs={tipoGrid(selected.tpQuestao)}>
                  {selected.tpQuestao !== 2 && tipoPergunta(selected.tpQuestao, doc)}
                </Grid>
                {selected.tpQuestao !== 2 && selected.tpQuestao !== 3 && (
                  <Grid item xs={11} className={classes.option}>
                    {doc.dsAlternativa}
                  </Grid>
                )}
              </Grid>
            ))}
          {selected.tpQuestao === 2 && (
            <FormSelectOwn
              label='Resultado'
              name='resposta'
              value={valueSelect}
              onChange={(e) => {
                setValueSelect(e.target.value);
                setIdCheck(e.target.value);
                const result = selected.alternativaList.find(
                  (item) => item.dsAlternativa === e.target.value
                );
                result.usuarioAnswer = true;
              }}
              options={numberList}
              optionLabel={(opt) => opt}
              optionValue={(opt) => opt}
            />
          )}
        </div>
        <Grid container spacing={0} className={classes.containerButtons}>
          <Grid item xs>
            <div className={classes.buttons}>
              <Button
                variant='contained'
                color='secondary'
                className={classes.buttonActions}
                style={{ backgroundColor: '#124268' }}
                onClick={clickVoltar}
              >
                VOLTAR
              </Button>

              <Button
                variant='contained'
                color='secondary'
                className={classes.buttonActions}
                style={{ backgroundColor: '#F33A30' }}
                onClick={clickCancelar}
              >
                CANCELAR
              </Button>

              <Button
                variant='contained'
                color='secondary'
                className={classes.buttonActions}
                style={{ backgroundColor: '#2BA69F' }}
                onClick={clickSalvar}
              >
                ALTERAR
              </Button>
            </div>
          </Grid>
        </Grid>
      </CardIcon>
    );
  }

  /*
   * Retorna o tamanho da grid de acordo com o tipo de resposta
   */
  function tipoGrid(tpQuestao) {
    switch (tpQuestao) {
      case 3:
        return 10;
      case 2:
        return 5;
      default:
        return 1;
    }
  }

  /**
   * Retorna campo de acordo com o tipo da pergunda
   */
  function tipoPergunta(tpQuestao, doc) {
    switch (tpQuestao) {
      case 0:
        return (
          <Radio
            checked={doc.idAlternativa === idCheck}
            onChange={() => {
              selected.alternativaList.forEach((questao) => {
                if (doc.idQuestao === questao.idQuestao) {
                  if (questao.idAlternativa === doc.idAlternativa) {
                    questao.usuarioAnswer = true;
                  } else {
                    questao.usuarioAnswer = false;
                  }
                }
              });

              changeRadio(doc.idAlternativa);
            }}
            color='primary'
            value={doc.idAlternativa}
          />
        );
      case 1:
        return (
          <Checkbox
            style={{ padding: 0 }}
            color='primary'
            checked={doc.usuarioAnswer}
            onChange={() => {
              doc.usuarioAnswer = !doc.usuarioAnswer;
              setIdCheck(Math.random());
            }}
          />
        );
      default:
        return <div />;
    }
  }
}

export default withStyles(styles)(AuditoriaDetalhamento);
