import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import DataTable from '../../components/DataTable/DataTable';
import GroupButtonGrid from './../../components/GroupButtonGrid/GroupButtonGrid';
import ButtonGrid from '../../components/ButtonGrid/ButtonGrid';
import { Search } from '@material-ui/icons';
import { Grid, Button } from '@material-ui/core';
import SwalMessage from '../../utils/functions/SwalMessage/SwalMessage';
import SwalConfirmacao from '../../utils/functions/SwalConfirmacao/SwalConfirmacao';
import * as moment from 'moment';

const styles = {
  containerTable: {
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 135px)',
    marginTop: -15,
  },
  scrolling: {
    overflowY: 'scroll',
    height: '100%',
  },
  card: {
    minHeight: 'calc(100vh - 200px)',
    display: 'flex',
    height: 'calc(100% - 120px)',
    marginTop: 35,
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
    paddingRight: 30,
    paddingLeft: 30,
    paddingBottom: 15,
    overflowY: 'hidden',
  },
  titleHeight: {
    height: 60,
    display: 'flex',
    alignItems: 'center',
    marginLeft: 15,
  },
  title: {
    height: 60,
    fontWeight: 500,
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    borderBottom: '2px solid rgb(0, 0, 0, 0.12)',
    color: 'rgb(0, 0, 0, 0.75)',
  },
  line: {
    height: 50,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid rgb(0, 0, 0, 0.12)',
    color: 'rgb(0, 0, 0, 0.75)',
  },
  containerButtons: {
    bottom: 15,
    left: 0,
    paddingTop: 15,
    borderTop: '1px solid rgba(0,0,0,0.12)',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  buttonActions: {
    marginRight: 20,
    width: 110,
    boxShadow: '0px 3px 6px 0px rgba(0,0,0,0.24)',
  },
};

const head = [
  { valueGetter: (args) => args.node.data.nrTelefone, headerName: 'Telefone', col: 3 },
  { valueGetter: (args) => args.node.data.dsLocal, headerName: 'Local', col: 3 },
  { valueGetter: (args) => convertSituacao(args.node.data.stVoto), headerName: 'Situação', col: 2 },
  {
    valueGetter: (args) => moment(args.node.data.dtInicio).format('DD/MM/YYYY'),
    headerName: 'Data',
    col: 2,
  },
  { valueGetter: (args) => moment(args.node.data.dtInicio).format('HH:mm'), headerName: 'Hora', col: 2 },
];

/**
 * Retorna label da situação na grid de entrevistas
 */
const convertSituacao = (value) => {
  if (value === 1) {
    return 'Verificado';
  } else if (value === 2) {
    return 'Descartado';
  } else {
    return 'Não verificado';
  }
};

/**
 * Componente para listagem de entrevistas
 *
 * @author Gabriela Farias
 * @class Entrevistas
 */
function Entrevistas(props) {
  const { classes, clickEditarAuditoria } = props;
  const [listFiltered, setListFiltered] = useState([]);
  const [selected, setSelected] = useState(null);
  const [mesclagemItem, setMesclagemItem] = useState([]);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    setListFiltered(props.entrevistasList.filter((doc) => doc.stRegistro !== 2));

    if (props.entrevistaSelected) {
      setSelected(props.entrevistaSelected);
      setEditing(!editing);
      editMode(props.entrevistaSelected);
    }
  }, [props.entrevistasList]);

  /**
   * Envia o item selecionado para a classe de auditoria
   */
  const selectChange = (select) => {
    if (select) {
      props.entrevistaSelecionada(select);
      setSelected(select);
    } else {
      props.entrevistaSelecionada(null);
      setSelected(null);
    }
  };

  /**
   * Apresenta a tela de detalhamento e retira o FAB
   */
  const editMode = (itemSelected) => {
    let listFinal = [];
    props.quantitativoList.forEach((quantitativo) => {
      let item = {};
      itemSelected.votoItemList.forEach((votoItem) => {
        if (quantitativo.idQuestao === votoItem.questao.idQuestao) {
          if (quantitativo.tpQuestao === 3) {
            quantitativo.dsResposta = votoItem.dsResposta;
            quantitativo.votoItem = votoItem;
            item = quantitativo;
          } else {
            quantitativo.alternativaList.forEach((alternativa) => {
              if (alternativa.usuarioAnswer === undefined || votoItem.stRegistro === 2) {
                alternativa.usuarioAnswer = false;
              }

              if (
                alternativa.idAlternativa === votoItem.alternativa.idAlternativa &&
                votoItem.stRegistro !== 2
              ) {
                alternativa.usuarioAnswer = true;
              }
            });

            item = quantitativo;
          }
        }
      });

      if (item.idQuestao !== undefined) {
        listFinal.push(item);
      }
    });

    setMesclagemItem(listFinal);

    props.editing(editing);
    setEditing(!editing);
  };

  /*
   * Trata excluir entrevista
   */
  const clickDescartar = () => {
    SwalConfirmacao({ text: 'Deseja descartar entrevista?' }).then((res) => {
      if (res) {
        selected.stRegistro = 2;
        selected.stVoto = 2;
        editMode(selected);
        setSelected(null);
        setListFiltered(props.entrevistasList);
        props.descartarEntrevista();
      }
    });
  };

  /*
   * Trata salvar entrevista
   */
  const clickSalvar = () => {
    SwalConfirmacao({ text: 'Deseja salvar entrevista?' }).then((res) => {
      if (res) {
        selected.stRegistro = 1;
        selected.stVoto = 1;
        SwalMessage({
          text: 'Entrevista confirmada',
          icon: 'success',
          callback: () => editMode(selected),
        });
        setSelected(null);
        setListFiltered(props.entrevistasList);
        props.clickConfirmar();
      }
    });
  };

  return (
    <div className={classes.containerTable}>
      {!editing && (
        <React.Fragment>
          <GroupButtonGrid
            showAdd={false}
            showEdit={false}
            showDelete={false}
            customButtons={[
              <ButtonGrid
                show
                disabled={!selected}
                iconJSX={
                  <div style={{ color: 'white', display: 'flex' }}>
                    <Search color='inherit' />
                  </div>
                }
                color='#42ADE8'
                onClick={() => editMode(selected)}
              />,
            ]}
          />

          <DataTable
            data={listFiltered}
            columns={head}
            selectChange={(select) => selectChange(select)}
            showPagination={true}
            pagination={{ length: listFiltered.length, page: 0 }}
            handleChangePage={() => {}}
            handleChangeRowsPerPage={() => {}}
          />
        </React.Fragment>
      )}
      {editing && editEntrevista()}
    </div>
  );

  /**
   * Card detalhado de cada entrevista
   */
  function editEntrevista() {
    return (
      <div className={classes.card}>
        <Grid container className={classes.title}>
          <Grid xs={10} item>
            <div className={classes.titleHeight}>Pergunta</div>
          </Grid>
        </Grid>

        <div className={classes.scrolling}>
          {mesclagemItem.map((doc) => (
            <div
              key={doc.dsTitulo + Math.random()}
              style={{ borderBottom: '1px solid rgb(0, 0, 0, 0.12)' }}
            >
              <Grid className={classes.line} style={{ fontWeight: 500 }} container>
                <div style={{ marginLeft: 15 }}>{doc.dsTitulo}</div>
              </Grid>
              {doc.alternativaList &&
                doc.alternativaList.map((resposta) => (
                  <Grid
                    key={resposta.idAlternativa + resposta.dsAlternativa}
                    style={{
                      backgroundColor: resposta.usuarioAnswer ? '#EBEBEB' : 'transparent',
                    }}
                    className={classes.line}
                    container
                  >
                    <Grid xs={10} item>
                      <div style={{ marginLeft: 15 }}>{resposta.dsAlternativa}</div>
                    </Grid>
                  </Grid>
                ))}
              {doc.tpQuestao === 3 && (
                <Grid
                  key={doc.votoItem.idVotoItem}
                  style={{
                    backgroundColor: 'transparent',
                  }}
                  className={classes.line}
                  container
                >
                  <Grid xs={10} item>
                    <div style={{ marginLeft: 15 }}>{doc.dsResposta}</div>
                  </Grid>
                </Grid>
              )}
            </div>
          ))}
        </div>

        <Grid container spacing={0} className={classes.containerButtons}>
          <Grid item xs>
            <div className={classes.buttons}>
              <Button
                variant='contained'
                color='secondary'
                className={classes.buttonActions}
                style={{ backgroundColor: '#124268' }}
                onClick={() => {
                  SwalConfirmacao({
                    text: 'Ao voltar as alterações serão perdidas, deseja voltar?',
                  }).then((res) => {
                    if (res) {
                      setEditing(!editing);
                      setSelected(null);
                      props.quantitativoList.forEach((quantitativo) => {
                        quantitativo.alternativaList.forEach((alternativa) => {
                          alternativa.usuarioAnswer = false;
                        });
                      });

                      props.clickVoltar();
                      editMode(selected);
                    }
                  });
                }}
              >
                VOLTAR
              </Button>

              <Button
                variant='contained'
                color='secondary'
                className={classes.buttonActions}
                style={{ backgroundColor: '#F33A30' }}
                onClick={clickDescartar}
              >
                DESCARTAR
              </Button>

              <Button
                variant='contained'
                color='secondary'
                className={classes.buttonActions}
                style={{ backgroundColor: '#2BA69F' }}
                onClick={() => clickEditarAuditoria(mesclagemItem)}
              >
                EDITAR
              </Button>

              <Button
                variant='contained'
                color='secondary'
                className={classes.buttonActions}
                style={{ backgroundColor: '#2B2BA6', marginRight: 0 }}
                onClick={clickSalvar}
              >
                CONFIRMAR
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Entrevistas);
