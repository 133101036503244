import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DataTable from '../../../components/DataTable/DataTable';
import SwalConfirmacao from '../../../utils/functions/SwalConfirmacao/SwalConfirmacao';
import GroupButtonGrid from '../../../components/GroupButtonGrid/GroupButtonGrid';
import EditarExcecao from './EditarExcecao';
import clone from '../../../utils/functions/Clone/Clone';
import {
  showButtonFAB,
  goEditExcecao,
  changeListaPerguntas
} from '../../../states/actions/QuestionarioActions';

const styles = theme => ({
  containerTable: {
    display: 'flex',
    height: 'calc(100vh - 186px)'
  }
});

class Excecoes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      head: [
        {
          valueGetter: args => `${args.node.data.nrOrdem + 1} - ${args.node.data.dsTitulo}`,
          headerName: 'Questão',
          col: 4
        },
        {
          valueGetter: args =>
            `${args.node.data.alternativaList.findIndex(
              item => item.idAlternativa === args.node.data.alternativaExcecao.idAlternativa
            ) + 1}) ${args.node.data.alternativaExcecao.dsAlternativa}`,
          headerName: 'Alternativa que dispara a exceção',
          col: 4
        },
        { field: 'questaoAvancoUI', headerName: 'Fluxo de exceção', col: 4 }
      ],
      selected: '',
      disabledEdit: true,
      disabledDelete: true
    };

    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.goEdit = this.goEdit.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  componentDidMount() {
    !this.props.editandoExcecao && this.props.showButtonFAB();
  }

  onChangeSelect(select) {
    if (select) {
      this.setState({ selected: select, disabledEdit: false, disabledDelete: false });
    } else {
      this.setState({ selected: '', disabledEdit: true, disabledDelete: true });
    }
  }

  goEdit() {
    if (this.state.selected) {
      this.props.goEditExcecao(this.state.selected);
      this.onChangeSelect('');
    }
  }

  onRemove() {
    if (this.state.selected) {
      SwalConfirmacao({ text: 'Deseja mesmo remover este fluxo de exceção?' }).then(res => {
        if (res) {
          const auxLista = clone(this.props.perguntasData);
          auxLista[this.state.selected.nrOrdem].stFluxoExcecao = 0;
          auxLista[this.state.selected.nrOrdem].questaoAvanco = null;
          auxLista[this.state.selected.nrOrdem].stFinalizaQuestionario = 0;
          auxLista[this.state.selected.nrOrdem].stDescartaEntrevista = 0;
          auxLista[this.state.selected.nrOrdem].alternativaList.forEach(opt => (opt.stFluxoExcecao = 0));
          this.props.changeListaPerguntas(auxLista);
          this.onChangeSelect('');
        }
      });
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        {!this.props.editandoExcecao && (
          <>
            <GroupButtonGrid
              showAdd={false}
              showEdit={true}
              showDelete={true}
              disableEdit={this.state.disabledEdit || this.props.statusQuestionario !== 0}
              disableDelete={this.state.disabledDelete || this.props.statusQuestionario !== 0}
              onClickEdit={this.goEdit}
              onClickDelete={this.onRemove}
              disableMarginTop
            />

            <div className={classes.containerTable}>
              <DataTable
                data={this.props.perguntasComExcecao}
                columns={this.state.head}
                selectChange={this.onChangeSelect}
                defaultColDef={{ sortable: false }}
              />
            </div>
          </>
        )}

        {this.props.editandoExcecao && <EditarExcecao />}
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    tabPerguntasQuestionarioReducer,
    tabExcecoesQuestionarioReducer,
    mainQuestionarioReducer
  } = state.questionarioReducer;

  return {
    statusQuestionario: mainQuestionarioReducer.statusQuestionario,
    editandoExcecao: tabExcecoesQuestionarioReducer.editandoExcecao,
    perguntasData: tabPerguntasQuestionarioReducer.perguntasData,
    perguntasComExcecao: tabPerguntasQuestionarioReducer.perguntasData
      .filter(item => item.stRegistro !== 2 && item.stFluxoExcecao === 1)
      .map(item => ({
        ...item,
        questaoAvancoUI:
          !item.questaoAvanco || item.stFinalizaQuestionario === 1
            ? item.stFinalizaQuestionario === 1
              ? `Encerra o questionário ${
                  item.stDescartaEntrevista === 1 ? '(descarta a entrevista)' : ''
                }`
              : ''
            : `${item.questaoAvanco.nrOrdem + 1} - ${item.questaoAvanco.dsTitulo}`
      }))
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ showButtonFAB, goEditExcecao, changeListaPerguntas }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Excecoes));
