/*
    Autor: Gabriela Farias
*/
import React from 'react';
import Card from '@material-ui/core/Card';
import iconEmail from '../../assets/images/icon_email.png';
import iconSenha from '../../assets/images/icon_senha.png';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import '../../assets/styles/Login.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Botao from '../Botao/Botao';
import { withRouter } from 'react-router-dom';
import Authentication from '../../resources/oauth/Authentication';
import Admin from '../../resources/oauth/Admin';
import TextFieldOwn from '../TextFieldOwn/TextFieldOwn';
import { connect } from 'react-redux';
import { addInfoUsuario, clearInfoUsuario } from '../../states/actions/LoginActions';
import { bindActionCreators } from 'redux';
import SwalMessage from '../../utils/functions/SwalMessage/SwalMessage';

const styles = theme => ({
  card: {
    backgroundColor: '#F8F8F8',
    borderRadius: 10,
    transition: ' height 220ms'
  },
  containerInputEmail: {
    position: 'relative',
    top: 160,
    marginLeft: 20,
    marginRight: 20
  },
  inputEmail: {
    width: '100%',
    height: 70,
    display: 'flex'
  },
  containerInputSenha: {
    position: 'relative',
    marginLeft: 20,
    marginRight: 20,
    top: 170
  },
  loginSenha: {
    textDecoration: 'none',
    color: '#124268',
    cursor: 'pointer',
    width: '100%',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    fontSize: 14
  },
  informations: {
    display: 'flex',
    marginTop: 200,
    marginRight: 20,
    marginLeft: 20
  }
});

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiInputLabel: {
      formControl: {
        fontSize: '14px !important'
      }
    }
  }
});

class TelaLogin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      estaLogando: false,
      showPassword: false,
      formaLogin: false,
      loading: false,
      success: false
    };

    this._isMounted = false;

    this.handleLoginClick = this.handleLoginClick.bind(this);
    this.handleVoltarLogin = this.handleVoltarLogin.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleRecuperarSenha = this.handleRecuperarSenha.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleBotaoProcesso = this.handleBotaoProcesso.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSubmit(values) {
    this.handleBotaoProcesso();

    this._isMounted &&
      Authentication.login(values.email, values.password)
        .then(() => {
          this._isMounted &&
            Admin.findPerfil()
              .then(user => {
                this.handleBotaoProcesso();
                if (user && user.tpUsuario === 1) {
                  this.props.addInfoUsuario(user);
                  this.props.history.push('/app');
                } else {
                  SwalMessage({
                    title: 'Falha no login',
                    text: 'Apenas usuários do tipo Administrador podem fazer login',
                    callback: () => {
                      this.props.clearInfoUsuario();
                      Admin.resetPerfil();
                    }
                  });
                }
              })
              .catch(err => {
                let msg;
                if (err.response && err.response.data.codigo === 'CLIENTE_INATIVO') {
                  msg = 'O usuário está inativo';
                } else if (err.response && err.response.data.codigo === 'USUARIO_INEXISTENTE') {
                  msg = 'Login ou senha incorretos';
                } else {
                  msg = 'Ocorreu uma falha de autenticação';
                }

                SwalMessage({
                  title: 'Falha no login',
                  text: msg,
                  err,
                  callback: () => {
                    this.props.clearInfoUsuario();
                    Admin.resetPerfil();
                    this.handleBotaoProcesso();
                  }
                });
              });
        })
        .catch(err => {
          let msg;
          if (err.response && err.response.status === 401) {
            msg = 'O usuário está inativo';
          } else if (err.response && err.response.status === 400) {
            msg = 'Login ou senha incorretos';
          } else {
            msg = 'Ocorreu uma falha de autenticação';
          }

          SwalMessage({
            title: 'Falha no login',
            text: msg,
            err,
            callback: () => {
              this.props.clearInfoUsuario();
              Admin.resetPerfil();
              this.handleBotaoProcesso();
            }
          });
        });
  }

  handleRecuperarSenha(values) {
    this.handleBotaoProcesso();

    this._isMounted &&
      Authentication.recuperarSenha(values.email)
        .then(res => {
          SwalMessage({
            title: 'E-mail enviado',
            text: 'Foi enviado um E-mail com um link para você trocar sua senha',
            icon: 'success',
            callback: this.handleBotaoProcesso
          });
        })
        .catch(err => {
          let msg;
          if (err.response && err.response.data.codigo === 'USUARIO_NAO_ENCONTRADO') {
            msg = 'Usuário não encontrado';
          } else {
            msg = 'Ocorreu uma falha ao recuperar a senha';
          }

          SwalMessage({
            title: 'Falha ao recuperar senha',
            text: msg,
            err,
            callback: this.handleBotaoProcesso
          });
        });
  }

  handleLoginClick() {
    this.setState(prev => ({ formaLogin: !prev.formaLogin, estaLogando: false }));
  }

  handleVoltarLogin() {
    this.setState(prev => ({ formaLogin: !prev.formaLogin, estaLogando: true }));
  }

  handleClickShowPassword() {
    this.setState(state => ({ howPassword: !state.showPassword }));
  }

  handleBotaoProcesso() {
    this.setState(state => ({ loading: !state.loading, success: !state.success }));
  }

  handleKeyDown(e, handleSubmit) {
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault();
      handleSubmit();
    }
  }

  render() {
    const { classes } = this.props;
    const { loading, estaLogando } = this.state;

    const styleCard = { width: 360, height: this.state.formaLogin ? '360px' : '420px' };

    return (
      <MuiThemeProvider theme={theme}>
        <Formik
          initialValues={{ email: '', password: '' }}
          onSubmit={this.state.formaLogin ? this.handleRecuperarSenha : this.handleSubmit}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email('Email inválido')
              .required('Campo obrigatório'),
            password: this.state.formaLogin
              ? Yup.string()
              : Yup.string()
                  .min(6, 'A senha deve ter no mínimo 6 caracteres')
                  .required('Campo obrigatório')
          })}
          render={({ values, handleSubmit, errors, touched, handleChange, handleBlur }) => (
            <Card className={classes.card} style={styleCard}>
              <form onKeyDown={e => this.handleKeyDown(e, handleSubmit)}>
                <div className={classes.containerInputEmail}>
                  <TextFieldOwn
                    className={classes.inputEmail}
                    label='E-mail'
                    type='email'
                    name='email'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    error={errors.email && touched.email}
                    helperText={errors.email && touched.email ? errors.email : null}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <img src={iconEmail} alt='icone que representa email' />
                        </InputAdornment>
                      )
                    }}
                  />
                </div>

                <div className={classes.containerInputSenha}>
                  <div id='navbar' className={this.state.formaLogin ? 'slideIn' : 'slideOut'}>
                    <TextFieldOwn
                      name='password'
                      label='Senha'
                      className={classes.inputEmail}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={errors.password && touched.password}
                      helperText={errors.password && touched.password ? errors.password : null}
                      value={values.password}
                      type={this.state.showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <img src={iconSenha} alt='icone que representa senha' />
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                </div>

                <div className={classes.informations}>
                  {estaLogando ? (
                    <label className={classes.loginSenha} onClick={this.handleLoginClick}>
                      Retornar para o Login
                    </label>
                  ) : (
                    <label className={classes.loginSenha} onClick={this.handleVoltarLogin}>
                      Esqueceu sua senha?
                    </label>
                  )}

                  <div>
                    <Botao
                      text={this.state.formaLogin ? 'Enviar' : 'Entrar'}
                      onClickButton={handleSubmit}
                      loadingButton={loading}
                    />
                  </div>
                </div>
              </form>
            </Card>
          )}
        />
      </MuiThemeProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ addInfoUsuario, clearInfoUsuario }, dispatch);
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(withStyles(styles)(TelaLogin)));
