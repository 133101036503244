import React from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core';

const styles = {
  titleHeight: {
    height: 50,
    display: 'flex',
    alignItems: 'center',
    marginLeft: 15,
  },
  card: {
    maxWidth: '100%',
    display: 'flex',
    height: 'calc(100vh - 200px)',
    marginTop: 20,
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
    paddingRight: 30,
    paddingLeft: 30,
    paddingBottom: 15,
    overflowY: 'scroll',
  },
  title: {
    height: 60,
    fontWeight: 500,
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    borderBottom: '2px solid rgb(0, 0, 0, 0.12)',
    color: 'rgb(0, 0, 0, 0.75)',
  },
  line: {
    height: 50,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid rgb(0, 0, 0, 0.12)',
    color: 'rgb(0, 0, 0, 0.75)',
  },
};

/**
 * Componente para lista de quantitativos
 *
 * @author Gabriela Farias
 * @class Quantitativo
 */
function Quantitativo(props) {
  const { classes, quantitativoList } = props;

  return (
    <div className={classes.card}>
      <Grid container className={classes.title}>
        <Grid xs={10} item>
          <div className={classes.titleHeight}>Título</div>
        </Grid>
        <Grid xs={2} item>
          Média
        </Grid>
      </Grid>

      {quantitativoList.map((doc) => (
        <div key={doc.dsTitulo} style={{ borderBottom: '1px solid rgb(0, 0, 0, 0.12)' }}>
          <Grid className={classes.line} style={{ fontWeight: 500 }} container>
            <div style={{ marginLeft: 15 }}>{doc.dsTitulo}</div>
          </Grid>
          {doc.alternativaList &&
            doc.alternativaList.map((resposta) => (
              <Grid
                key={resposta.idAlternativa}
                style={{
                  backgroundColor: resposta.moreResult ? 'rgb(43, 166, 159, 0.1)' : 'transparent',
                }}
                className={classes.line}
                container
              >
                <Grid xs={10} item>
                  <div style={{ marginLeft: 15 }}>{resposta.dsAlternativa}</div>
                </Grid>
                <Grid xs={2} item>
                  <div style={{ marginRight: 15 }}>{resposta.nrMediaGeral + ' %'}</div>
                </Grid>
              </Grid>
            ))}
        </div>
      ))}
    </div>
  );
}

export default withStyles(styles)(Quantitativo);
