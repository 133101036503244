import ActionTypes from '../actionTypes/MenuLateralActionTypes';

const openMenu = () => ({
  type: ActionTypes.OPEN_MENU
});

const closeMenu = () => ({
  type: ActionTypes.CLOSE_MENU
});

export { openMenu, closeMenu };
