//@author Gabriela Farias

import React from 'react';
import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles';
import '../../App.css';

const styles = {
  fab: {
    position: 'fixed',
    right: '30px',
    bottom: '30px'
  }
};

const ButtonFAB = props => {
  const { classes, disabled, positionDefault, onClick, iconJSX, icon } = props;

  return (
    <Fab
      disabled={disabled}
      style={{ backgroundColor: disabled ? '#95989a' : '#2BA69F', zIndex: 3 }}
      className={positionDefault ? classes.fab : ''}
      onClick={onClick}
    >
      {iconJSX ? iconJSX : <img src={icon} alt=' ' />}
    </Fab>
  );
};

export default withStyles(styles)(ButtonFAB);
