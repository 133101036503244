import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { updateToolbar, enableLoading } from '../../states/actions/ToolbarActions';
import { Formik, FastField } from 'formik';
import * as Yup from 'yup';
import CardIcon from '../../components/CardIcon/CardIcon';
import TextField from '../../components/TextFieldOwn/TextFieldOwn';
import Grid from '@material-ui/core/Grid';
import FormattedInput from '../../components/FormattedInputs/FormattedInputs';
import FormSelectOwn from '../../components/FormSelectOwn/FormSelectOwn';
import ButtonFABMenu from './../../components/ButtonFABMenu/ButtonFABMenu';
import SwalMessage from '../../utils/functions/SwalMessage/SwalMessage';
import { updateUsuario, getUsuarioById } from '../../resources/api/usuario';
import Authentication from '../../resources/oauth/Authentication';
import clone from '../../utils/functions/Clone/Clone';
import loadingSwal from '../../utils/functions/LoadingSwal/LoadingSwal';

const tpPerfilOptions = [{ value: 0, name: 'Entrevistador' }, { value: 1, name: 'Administrador' }];

class Perfil extends Component {
  constructor(props) {
    super(props);

    this.state = {
      key: '',
      enableReinitialize: true,
      usuario: {
        nmUsuario: '',
        nrTelefone: '',
        dsEmail: '',
        tpUsuario: 0,
        dsSenhaAntiga: '',
        dsNovaSenha: '',
        confirmNewPassword: ''
      }
    };

    const title = 'Perfil do usuário';
    if (this.props.itensState.name !== title) {
      this.props.updateToolbar({ toolbar: title, name: title });
    }
    this.props.enableLoading(true);

    this._isMounted = false;

    this.submit = this.submit.bind(this);
    this.iniciarAtributos = this.iniciarAtributos.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.iniciarAtributos();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async submit(values) {
    this.props.enableLoading(true);
    loadingSwal();

    let error = null;

    if (values.dsNovaSenha && values.dsSenhaAntiga) {
      this._isMounted &&
        (await Authentication.alterarSenha({
          dsNovaSenha: values.dsNovaSenha,
          dsSenhaAntiga: values.dsSenhaAntiga
        }).catch(err => (error = err)));
    }

    this._isMounted && (await updateUsuario(values).catch(err => (error = err)));

    if (!error) {
      SwalMessage({
        title: 'Sucesso',
        text: 'Usuário atualizado com sucesso',
        icon: 'success',
        callback: () => {
          this.props.enableLoading(false);
          this.props.history.push('/app');
        }
      });
    } else if (error.response && error.response.data.codigo === 'SENHA_INCORRETA') {
      SwalMessage({
        title: 'Falha ao validar edição',
        text: 'Senha atual incorreta.',
        err: error,
        callback: () => this.props.enableLoading(false)
      });
    } else {
      SwalMessage({
        title: 'Falha ao validar edição',
        text: 'A edição não pode ser totalmente concluída, tente novamente.',
        err: error,
        callback: () => this.props.enableLoading(false)
      });
    }
  }

  iniciarAtributos() {
    this._isMounted &&
      getUsuarioById(this.props.userLogged.idUsuario)
        .then(res => {
          this._isMounted &&
            this.setState(
              {
                key: Math.random(),
                usuario: { ...res.data, dsSenhaAntiga: '', dsNovaSenha: '', confirmNewPassword: '' }
              },
              () => {
                this.props.enableLoading(false);
                this._isMounted && this.setState({ enableReinitialize: false });
              }
            );
        })
        .catch(err => {
          SwalMessage({
            title: 'Falha ao carregar usuário',
            text: 'Não foi possível carregar o usuário para edição, tente novamente',
            err,
            callback: () => {
              this.props.enableLoading(false);
              this._isMounted && this.setState({ enableReinitialize: false });
            }
          });
        });
  }

  render() {
    return (
      <Formik
        key={this.state.key}
        initialValues={clone(this.state.usuario)}
        onSubmit={this.submit}
        validateOnBlur
        enableReinitialize={this.state.enableReinitialize}
        validateOnChange={false}
        validationSchema={Yup.lazy(values =>
          Yup.object().shape({
            nmUsuario: Yup.string()
              .max(100, 'Máximo de 100 caracteres')
              .required('Campo obrigatório'),
            dsEmail: Yup.string()
              .email('Digite um E-mail válido')
              .required('Campo obrigatório'),
            nrTelefone: Yup.string()
              .min(14, 'Mínimo de 10 caracteres')
              .max(15, 'Máximo de 11 caracteres')
              .required('Campo obrigatório'),
            tpUsuario: Yup.number().required('Campo obrigatório'),
            dsSenhaAntiga: Yup.string().min(6, 'A senha deve ter no mínimo 6 caracteres'),
            dsNovaSenha: values.dsSenhaAntiga
              ? Yup.string()
                  .min(6, 'A senha deve ter no mínimo 6 caracteres')
                  .required('Campo obrigatório')
              : Yup.string().min(6, 'A senha deve ter no mínimo 6 caracteres'),
            confirmNewPassword:
              values.dsNovaSenha || values.dsSenhaAntiga
                ? Yup.string()
                    .min(6, 'A senha deve ter no mínimo 6 caracteres')
                    .test('passwords-match', 'Senhas Incompatíveis', function(value) {
                      return values.dsNovaSenha === value;
                    })
                    .required('Campo obrigatório')
                : Yup.string()
                    .min(6, 'A senha deve ter no mínimo 6 caracteres')
                    .test('passwords-match', 'Senhas Incompatíveis', function(value) {
                      return values.dsNovaSenha === value;
                    })
          })
        )}
        render={({ values, handleSubmit, errors, touched, setFieldValue }) => (
          <div style={{ width: '100%' }}>
            <form autoComplete='off'>
              <CardIcon titulo='Identificação de usuário'>
                <Grid container spacing={0} style={{ marginTop: 15, marginBottom: 15 }}>
                  <Grid item xs={4} style={{ height: 70, paddingRight: 15 }}>
                    <FastField name='nmUsuario'>
                      {({ field, form }) => (
                        <TextField
                          {...field}
                          label='Nome'
                          id='nmUsuario'
                          value={form.values.nmUsuario}
                          onBlur={form.handleBlur}
                          onChange={e => form.setFieldValue('nmUsuario', e.target.value)}
                          inputProps={{ maxLength: 100 }}
                          error={form.errors.nmUsuario && form.touched.nmUsuario}
                          helperText={
                            form.errors.nmUsuario && form.touched.nmUsuario
                              ? form.errors.nmUsuario
                              : null
                          }
                        />
                      )}
                    </FastField>
                  </Grid>

                  <Grid item xs={2} style={{ height: 70, paddingRight: 15, color: 'black' }}>
                    <FastField name='nrTelefone'>
                      {({ field, form }) => (
                        <FormattedInput
                          other={field}
                          label='Telefone'
                          name='nrTelefone'
                          id='nrTelefone'
                          tipoMascara={1}
                          value={values.nrTelefone + ''}
                          onChange={e => {
                            e.target.name = 'nrTelefone';
                            form.setFieldValue('nrTelefone', e.target.value);
                          }}
                          onBlur={form.handleBlur}
                          error={form.errors.nrTelefone && form.touched.nrTelefone}
                          helperText={
                            form.errors.nrTelefone && form.touched.nrTelefone
                              ? form.errors.nrTelefone
                              : null
                          }
                        />
                      )}
                    </FastField>
                  </Grid>

                  <Grid item xs={4} style={{ height: 70, paddingRight: 15 }}>
                    <TextField
                      label='E-mail'
                      id='dsEmail'
                      name='dsEmail'
                      type='email'
                      value={values.dsEmail}
                      disabled={true}
                      onChange={() => {}}
                      inputProps={{ autoComplete: 'off' }}
                      autoComplete='off'
                    />
                  </Grid>

                  <Grid item xs={2} style={{ height: 70 }}>
                    <FormSelectOwn
                      label='Perfil'
                      name='tpUsuario'
                      error={errors.tpUsuario && touched.tpUsuario}
                      value={values.tpUsuario}
                      onChange={e => setFieldValue('tpUsuario', e.target.value)}
                      options={tpPerfilOptions}
                      optionLabel={opt => opt.name}
                      optionValue={opt => opt.value}
                      errorText={errors.tpUsuario}
                    />
                  </Grid>
                </Grid>
              </CardIcon>

              <CardIcon titulo='Trocar senha' rootStyles={{ marginTop: 15 }}>
                <Grid container spacing={0} style={{ marginTop: 15, marginBottom: 15, height: 70 }}>
                  <Grid item xs={3} style={{ paddingRight: 15 }}>
                    <FastField name='dsSenhaAntiga'>
                      {({ field, form }) => (
                        <TextField
                          {...field}
                          label='Senha atual'
                          id='dsSenhaAntiga'
                          type='password'
                          value={form.values.dsSenhaAntiga}
                          onBlur={form.handleBlur}
                          onChange={e => form.setFieldValue('dsSenhaAntiga', e.target.value)}
                          inputProps={{ maxLength: 100 }}
                          error={form.errors.dsSenhaAntiga && form.touched.dsSenhaAntiga}
                          helperText={
                            form.errors.dsSenhaAntiga && form.touched.dsSenhaAntiga
                              ? form.errors.dsSenhaAntiga
                              : null
                          }
                        />
                      )}
                    </FastField>
                  </Grid>

                  <Grid item xs={3} style={{ paddingRight: 15 }}>
                    <FastField name='dsNovaSenha'>
                      {({ field, form }) => (
                        <TextField
                          {...field}
                          label='Nova senha'
                          id='dsNovaSenha'
                          type='password'
                          value={form.values.dsNovaSenha}
                          onBlur={form.handleBlur}
                          onChange={e => form.setFieldValue('dsNovaSenha', e.target.value)}
                          inputProps={{ maxLength: 100 }}
                          error={form.errors.dsNovaSenha && form.touched.dsNovaSenha}
                          helperText={
                            form.errors.dsNovaSenha && form.touched.dsNovaSenha
                              ? form.errors.dsNovaSenha
                              : null
                          }
                        />
                      )}
                    </FastField>
                  </Grid>

                  <Grid item xs={3}>
                    <FastField name='confirmNewPassword'>
                      {({ field, form }) => (
                        <TextField
                          {...field}
                          label='Confirmação da nova senha'
                          id='confirmNewPassword'
                          type='password'
                          value={form.values.confirmNewPassword}
                          onBlur={form.handleBlur}
                          onChange={e => form.setFieldValue('confirmNewPassword', e.target.value)}
                          inputProps={{ maxLength: 100 }}
                          error={form.errors.confirmNewPassword && form.touched.confirmNewPassword}
                          helperText={
                            form.errors.confirmNewPassword && form.touched.confirmNewPassword
                              ? form.errors.confirmNewPassword
                              : null
                          }
                        />
                      )}
                    </FastField>
                  </Grid>
                </Grid>
              </CardIcon>
            </form>

            <ButtonFABMenu
              actionSalvar={{ onClick: handleSubmit }}
              actionCancelar={{ onClick: () => this.props.history.push('/app') }}
            />
          </div>
        )}
      />
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbarReducer,
  userLogged: state.loginReducer.usuario
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ updateToolbar, enableLoading }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Perfil));
