/*
    Autor: Bruno Eduardo
*/

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CardIcon from '../../../components/CardIcon/CardIcon';
import Grid from '@material-ui/core/Grid';
import TextField from '../../../components/TextFieldOwn/TextFieldOwn';
import { connect } from 'react-redux';
import Autocomplete from '../../../components/AutoComplete/AutoComplete';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import SwalConfirmacao from '../../../utils/functions/SwalConfirmacao/SwalConfirmacao';
import { bindActionCreators } from 'redux';
import { getAllUsuarios } from '../../../resources/api/usuario';
import newId from '../../../utils/functions/NewId/NewId';
import clone from '../../../utils/functions/Clone/Clone';
import SwalMessage from '../../../utils/functions/SwalMessage/SwalMessage';
import {
  hiddenButtonFAB,
  changeEntrevistadorSelected,
  changeEntrevistadores,
  exitEditEntrevistador,
  showButtonFAB
} from '../../../states/actions/QuestionarioActions';

const styles = theme => ({
  containerCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 290px)',
    marginBottom: 65,
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: 30
  },
  containerButtons: {
    position: 'absolute',
    bottom: 15,
    left: 0,
    paddingRight: 30,
    paddingTop: 15,
    borderTop: '1px solid rgba(0,0,0,0.12)'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  disabledButton: {
    opacity: 0.5,
    color: 'white !important'
  },
  buttonActions: {
    boxShadow: '0px 3px 6px 0px rgba(0,0,0,0.24)'
  }
});

class EditarEntrevistador extends Component {
  constructor(props) {
    super(props);

    this.state = {
      key: '',
      keyAutocomplete: '',
      entrevistador: this.props.entrevistadorSelected,
      usuarios: []
    };

    this._isMounted = false;

    this.submit = this.submit.bind(this);
    this.tratarValueAutocomplete = this.tratarValueAutocomplete.bind(this);
    this.loadSuggestionsAutocomplete = this.loadSuggestionsAutocomplete.bind(this);
    this.onChangeUsuario = this.onChangeUsuario.bind(this);
    this.onChangeCheck = this.onChangeCheck.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.exitEditing = this.exitEditing.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    if (this.props.editandoEntrevistador) {
      this.props.hiddenButtonFAB();
    }
    this.loadSuggestionsAutocomplete();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.changeEntrevistadorSelected(this.state.entrevistador);
  }

  submit() {
    const auxLista = clone(this.props.entrevistadoresData);
    const { entrevistador } = this.state;

    if (!this.props.atualizandoEntrevistador) {
      const jaExiste = !!auxLista.find(
        item => item.usuario.idUsuario === entrevistador.usuario.idUsuario
      );
      if (jaExiste) {
        SwalMessage({ text: 'Este entrevistador ja foi adicionado' });
        return;
      }
    }

    entrevistador.dsBloqueado = entrevistador.stQuestionarioUsuario === 0 ? 'Sim' : 'Não';
    entrevistador.nmUsuario = entrevistador.usuario.nmUsuario;

    if (this.props.atualizandoEntrevistador) {
      auxLista[entrevistador.index] = entrevistador;
    } else {
      auxLista.push({
        ...entrevistador,
        idQuestionarioUsuario: newId(auxLista, p => p.idQuestionarioUsuario)
      });
    }

    this.props.changeEntrevistadores(auxLista);

    this.exitEditing();
  }

  onRemove() {
    const { atualizandoEntrevistador, entrevistadorSelected } = this.props;

    if (
      atualizandoEntrevistador &&
      entrevistadorSelected &&
      entrevistadorSelected.nrEntrevistas === 0 &&
      entrevistadorSelected.stQuestionarioUsuario === 1
    ) {
      SwalConfirmacao({ text: 'Deseja mesmo remover este entrevistador da lista?' }).then(res => {
        if (res) {
          const auxLista = clone(this.props.entrevistadoresData);
          auxLista.splice(this.state.entrevistador.index, 1);
          this.props.changeEntrevistadores(auxLista);
          this.exitEditing();
        }
      });
    }
  }

  tratarValueAutocomplete() {
    if (this.state.entrevistador.usuario && this.state.entrevistador.usuario.nmUsuario !== undefined) {
      return this.state.entrevistador.usuario;
    }
    return '';
  }

  loadSuggestionsAutocomplete() {
    this._isMounted &&
      getAllUsuarios()
        .then(
          res =>
            this._isMounted &&
            this.setState({
              usuarios: res.data.filter(item => item.tpUsuario === 0 && item.stUsuario === 1)
            })
        )
        .catch(err => {
          SwalMessage({
            title: 'Falha ao carregar entrevistadores',
            text: 'Não foi possível carregar os entrevistadores, tente novamente',
            err
          });
        });
  }

  onChangeUsuario(value) {
    if (!this.props.atualizandoEntrevistador) {
      this.setState({
        keyAutocomplete: Math.random(),
        entrevistador: {
          ...this.state.entrevistador,
          usuario: value
        }
      });
    }
  }

  onChangeCheck() {
    const aux = this.state.entrevistador;
    aux.stQuestionarioUsuario = this.state.entrevistador.stQuestionarioUsuario === 0 ? 1 : 0;
    this.setState({ entrevistador: aux });
  }

  exitEditing() {
    this.props.exitEditEntrevistador();
    this.props.showButtonFAB();
  }

  render() {
    const { classes } = this.props;

    return (
      <CardIcon
        titulo={this.props.atualizandoEntrevistador ? 'Editar entrevistador' : 'Adicionar entrevistador'}
      >
        <div className={classes.containerCard}>
          <Formik
            key={this.state.key}
            initialValues={{
              usuario: this.state.entrevistador.usuario,
              nrEntrevistas: this.state.entrevistador.nrEntrevistas,
              stQuestionarioUsuario: this.state.entrevistador.stQuestionarioUsuario
            }}
            onSubmit={this.submit}
            validateOnBlur
            enableReinitialize={true}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              usuario: Yup.object()
                .required('Campo obrigatório')
                .typeError('Campo obrigatório')
            })}
            render={({ errors, touched, handleBlur, handleSubmit, values }) => (
              <>
                <Grid container spacing={0} style={{ marginTop: 15 }}>
                  <Grid item xs={6} style={{ height: 70, paddingRight: 15 }}>
                    <Autocomplete
                      itens={this.state.usuarios}
                      campoOp='nmUsuario'
                      campoChave='idUsuario'
                      label='Nome'
                      name='usuario'
                      id='usuario'
                      error={this.tratarValueAutocomplete() === '' && errors.usuario && touched.usuario}
                      helperText={
                        this.tratarValueAutocomplete() === '' && errors.usuario && touched.usuario
                          ? errors.usuario
                          : null
                      }
                      onBlurOwn={handleBlur}
                      onChangeOwn={this.onChangeUsuario}
                      onChangeAutoComplete={this.loadSuggestionsAutocomplete}
                      value={this.tratarValueAutocomplete()}
                      key={this.state.keyAutocomplete}
                      valueAutoComplete={this.tratarValueAutocomplete()}
                      valueSelect={this.tratarValueAutocomplete().nmUsuario}
                      maxHeight='calc(100vh - 400px)'
                      disabled={this.props.atualizandoEntrevistador}
                    />
                  </Grid>

                  <Grid item xs={3} style={{ height: 70, paddingRight: 15 }}>
                    <TextField
                      label='Número de entrevistas'
                      name='nrEntrevistas'
                      value={values.nrEntrevistas + ''}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={3} style={{ height: 70, marginTop: 10 }}>
                    <Typography noWrap>
                      <label
                        style={{ color: 'black', fontFamily: 'Roboto', fontSize: 14, opacity: 0.54 }}
                      >
                        Bloqueado
                      </label>
                      <Switch
                        value={values.stQuestionarioUsuario === 0}
                        checked={values.stQuestionarioUsuario === 0}
                        color='primary'
                        onChange={this.onChangeCheck}
                      />
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={0} className={classes.containerButtons}>
                  <Grid item xs>
                    <div className={classes.buttons}>
                      <Button
                        variant='contained'
                        color='secondary'
                        className={classes.buttonActions}
                        style={{ marginRight: 15, backgroundColor: '#F33A30' }}
                        onClick={this.onRemove}
                        disabled={
                          values.nrEntrevistas > 0 ||
                          this.state.entrevistador.stQuestionarioUsuario === 0 ||
                          !this.props.atualizandoEntrevistador
                        }
                        classes={{ disabled: classes.disabledButton }}
                      >
                        REMOVER
                      </Button>

                      <Button
                        variant='contained'
                        color='secondary'
                        className={classes.buttonActions}
                        style={{ marginRight: 15, backgroundColor: '#124268' }}
                        onClick={this.exitEditing}
                      >
                        CANCELAR
                      </Button>

                      <Button
                        variant='contained'
                        color='secondary'
                        className={classes.buttonActions}
                        style={{ backgroundColor: '#2BA69F' }}
                        onClick={handleSubmit}
                      >
                        CONFIRMAR
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </>
            )}
          />
        </div>
      </CardIcon>
    );
  }
}

const mapStateToProps = state => {
  const { tabEntrevistadoresQuestionarioReducer } = state.questionarioReducer;
  return {
    entrevistadoresData: tabEntrevistadoresQuestionarioReducer.entrevistadoresData,
    editandoEntrevistador: tabEntrevistadoresQuestionarioReducer.editandoEntrevistador,
    atualizandoEntrevistador: tabEntrevistadoresQuestionarioReducer.atualizandoEntrevistador,
    entrevistadorSelected: tabEntrevistadoresQuestionarioReducer.entrevistadorSelected
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      hiddenButtonFAB,
      showButtonFAB,
      changeEntrevistadorSelected,
      changeEntrevistadores,
      exitEditEntrevistador
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EditarEntrevistador));
