/*
    Autor: Gabriela Farias
*/
import React, { Component } from 'react';
import DataTable from './../../components/DataTable/DataTable';
import CardFilters from './../../components/CardFilters/CardFilters';
import GroupButtonGrid from './../../components/GroupButtonGrid/GroupButtonGrid';
import ButtonFAB from './../../components/ButtonFab/ButtonFab';
import iconAdd from './../../assets/images/icon_add.png';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { updateToolbar, enableLoading } from '../../states/actions/ToolbarActions';
import { bindActionCreators } from 'redux';
import { getAllClientes } from '../../resources/api/cliente';
import SwalMessage from '../../utils/functions/SwalMessage/SwalMessage';
import FilterUtil from '../filterUtil';

const style = theme => ({
  containerTable: {
    display: 'flex',
    height: '100%'
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2BA69F'
    }
  },
  typography: {
    useNextVariants: true
  }
});

class Cliente extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemSelect: '',
      disabledEdit: true,
      disabledDelete: true,
      dataInTable: [],
      dataAppSync: [],
      head: [
        { field: 'nmFantasia', headerName: 'Nome', sort: 'asc', col: 4 },
        { field: 'nrTelefone', headerName: 'Telefone', col: 2 },
        { field: 'nmContato', headerName: 'Contato', col: 2 },
        { field: 'municipio', headerName: 'Município', col: 2 },
        { field: 'sgUf', headerName: 'UF', col: 1 },
        { field: 'situacao', headerName: 'Situação', col: 1 }
      ],
      operationValues: [
        {
          label: 'Nome',
          field: 'nmFantasia',
          type: 'string'
        },
        {
          label: 'Contato',
          field: 'nmContato',
          type: 'string'
        },
        {
          label: 'Município',
          field: 'municipio',
          type: 'string'
        },
        {
          label: 'UF',
          field: 'sgUf',
          type: 'string'
        },
        {
          label: 'Situação',
          field: 'stCliente',
          type: 'enum',
          options: [
            { label: 'Ativo', field: 'ativo', value: '1' },
            { label: 'Inativo', field: 'inativo', value: '0' }
          ]
        }
      ]
    };

    const title = 'Clientes';
    if (this.props.itensState.name !== title) {
      this.props.updateToolbar({ toolbar: title, name: title });
    }
    this.props.enableLoading(true);

    this._isMounted = false;

    this.selectChange = this.selectChange.bind(this);
    this.goAdd = this.goAdd.bind(this);
    this.goEdit = this.goEdit.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onFilter = this.onFilter.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.onFilter();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  selectChange(select) {
    if (select) {
      this.setState({ itemSelect: select, disabledEdit: false, disabledDelete: false });
    } else {
      this.setState({ itemSelect: '', disabledEdit: true, disabledDelete: true });
    }
  }

  goAdd() {
    this.props.history.push('/app/cliente/novo');
  }

  goEdit() {
    this.props.history.push('/app/cliente/editar/' + this.state.itemSelect.idCliente);
  }

  onFilterChanged(dataChips) {
    this.setState({
      dataInTable: FilterUtil.applyFilters(this.state.dataAppSync, dataChips)
    });
  }

  onFilter(dataChips) {
    dataChips = dataChips || [];

    this.props.enableLoading(true);
    this._isMounted &&
      getAllClientes()
        .then(res => {
          const data = res.data.map(item => ({
            ...item,
            situacao: item.stCliente === 1 ? 'Ativo' : 'Inativo',
            sgUf: item.municipio && item.municipio.uf ? item.municipio.uf.sgUf : '',
            municipio: item.municipio ? item.municipio.nmMunicipio : ''
          }));
          this._isMounted &&
            this.setState(
              { dataInTable: FilterUtil.applyFilters(data, dataChips), dataAppSync: data },
              () => this.props.enableLoading(false)
            );
        })
        .catch(err => {
          SwalMessage({
            title: 'Falha ao carregar página',
            text: 'Não foi carregar a página, tente novamente',
            err,
            callback: () => {
              this._isMounted && this.setState({ dataAppSync: [] });
              this.props.enableLoading(false);
            }
          });
        });
  }

  render() {
    const { classes } = this.props;
    const pagination = { length: this.state.dataInTable.length, page: 0 };

    return (
      <MuiThemeProvider theme={theme}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <CardFilters
            filtros={this.state.operationValues}
            onFilter={this.onFilter}
            onFilterChanged={this.onFilterChanged}
            usePaddings={false}
            viewKey='clientes'
          />

          <GroupButtonGrid
            showAdd={false}
            showEdit={true}
            showDelete={false}
            disableEdit={this.state.disabledEdit}
            onClickEdit={this.goEdit}
          />

          <div className={classes.containerTable}>
            <DataTable
              data={this.state.dataInTable}
              columns={this.state.head}
              selectChange={this.selectChange}
              showPagination={true}
              pagination={pagination}
              handleChangePage={() => {}}
              handleChangeRowsPerPage={() => {}}
            />
          </div>

          <ButtonFAB icon={iconAdd} positionDefault={true} onClick={this.goAdd} />
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbarReducer,
  chipData: state.cardFiltersReducer.chipData.filter(date => {
    return date.id === 'clientes';
  })[0].content
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ updateToolbar, enableLoading }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(style)(Cliente)));
