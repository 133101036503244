import ActionTypes from '../actionTypes/MenuLateralActionTypes';

const initialState = {
  open: false,
  itemMenuSelecionado: {
    item: 'selecionado'
  }
};

const menuLateralReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.OPEN_MENU:
      return { ...state, open: true };

    case ActionTypes.CLOSE_MENU:
      return { ...state, open: false };

    case ActionTypes.SELECIONAR_ITEM:
      return { ...state, itemMenuSelecionado: { item: action.payload } };

    default:
      return state;
  }
};

export default menuLateralReducer;
