/*
    Autor: Bruno Eduardo
*/

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CardIcon from '../../../components/CardIcon/CardIcon';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Autocomplete from '../../../components/AutoComplete/AutoComplete';
import CheckBoxTreeOwn from '../../../components/CheckBoxTreeOwn/CheckBoxTreeOwn';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { getQuestionariosByCliente } from '../../../resources/api/questionario';
import { getAllClientes } from '../../../resources/api/cliente';
import { bindActionCreators } from 'redux';
import newId from '../../../utils/functions/NewId/NewId';
import { enableLoading } from '../../../states/actions/ToolbarActions';
import SwalMessage from '../../../utils/functions/SwalMessage/SwalMessage';
import {
  showButtonFAB,
  hiddenButtonFAB,
  changeImportarPergunta,
  changeListaPerguntas,
  exitEditPergunta,
  newIdAlternativa
} from '../../../states/actions/QuestionarioActions';
import clone from '../../../utils/functions/Clone/Clone';

const styles = theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column'
  },
  containerButtons: {
    position: 'absolute',
    bottom: 15,
    left: 0,
    paddingRight: 30,
    paddingTop: 15,
    borderTop: '1px solid rgba(0,0,0,0.12)'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  containerCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 290px)',
    marginBottom: 65,
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: 30,
    paddingBottom: 15
  }
});

class ImportarPerguntas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientes: [],
      key: '',
      keyAutocomplete: '',
      clienteSelected: this.props.clienteSelected,
      questionarios: this.props.questionarios,
      perguntasSelecionadas: this.props.perguntasSelecionadas,
      questionariosExpandeds: this.props.questionariosExpandeds
    };

    this._isMounted = false;

    this.tratarValueAutocomplete = this.tratarValueAutocomplete.bind(this);
    this.loadSuggestionsAutocomplete = this.loadSuggestionsAutocomplete.bind(this);
    this.exitImporting = this.exitImporting.bind(this);
    this.setQuestionarios = this.setQuestionarios.bind(this);
    this.submit = this.submit.bind(this);
    this.onChangeAutocomplete = this.onChangeAutocomplete.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    if (this.props.importandoPerguntas) {
      this.props.hiddenButtonFAB();
    }
    this.loadSuggestionsAutocomplete();
  }

  componentWillUnmount() {
    this._isMounted = false;

    this.props.enableLoading(false);

    this.props.changeImportarPergunta({
      clienteSelected: this.state.clienteSelected,
      questionarios: this.state.questionarios,
      perguntasSelecionadas: this.state.perguntasSelecionadas,
      questionariosExpandeds: this.state.questionariosExpandeds
    });
  }

  submit() {
    if (this.state.perguntasSelecionadas.length === 0) {
      SwalMessage({ text: 'Selecione uma pergunta para importar' });
      return;
    }

    const auxLista = clone(this.props.perguntasData);
    this.state.perguntasSelecionadas.forEach(value => {
      let selected = {};
      for (const item of this.state.questionarios) {
        const encontrado = item.questaoList.find(subItem => subItem.value === +value);
        if (encontrado) {
          selected = clone(encontrado);
          break;
        }
      }
      delete selected.label;
      delete selected.value;

      if (selected.alternativaList) {
        selected.alternativaList.forEach(item => {
          this.props.newIdAlternativa(novoMaiorId => (item.idAlternativa = novoMaiorId));
          item.stRegistro = 0;
        });
      }

      if (selected.questaoAvanco) selected.questaoAvanco = null;

      auxLista.push({
        ...selected,
        idQuestao: newId(auxLista, p => p.idQuestao),
        stRegistro: 0,
        tipoRespostaUI: ['Fechada', 'Múltipla escolha', 'Escala linear', 'Aberta'][selected.tpQuestao]
      });
    });

    this.props.changeListaPerguntas(auxLista);

    this.exitImporting();
  }

  onChangeAutocomplete(value) {
    this.props.enableLoading(true);
    this.setState({ keyAutocomplete: Math.random(), clienteSelected: value }, () => {
      if (value && value.idCliente) {
        this.setQuestionarios(value.idCliente);
      } else {
        this.setState({ questionarios: [], perguntasSelecionadas: [], questionariosExpandeds: [] });
        this.props.enableLoading(false);
      }
    });
  }

  setQuestionarios(idCliente) {
    const convertData = data => {
      let count = 1;
      return data
        .filter(item => item.questaoList.length > 0 && item.idQuestionario !== this.props.idQuestionario)
        .map(item => {
          item.label = item.dsTitulo;
          item.value = count;
          count++;
          item.children = item.questaoList.map(subItem => {
            subItem.label = subItem.dsTitulo;
            subItem.value = count;
            count++;
            return subItem;
          });
          return item;
        });
    };

    this._isMounted &&
      getQuestionariosByCliente(idCliente)
        .then(
          res =>
            this._isMounted &&
            this.setState(
              {
                questionarios: convertData(res.data),
                perguntasSelecionadas: [],
                questionariosExpandeds: []
              },
              () => this.props.enableLoading(false)
            )
        )
        .catch(err => {
          SwalMessage({
            title: 'Falha ao carregar questionários',
            text: 'Não foi possível carregar os questionários, tente novamente',
            err,
            callback: () => this.props.enableLoading(false)
          });
        });
  }

  tratarValueAutocomplete() {
    if (this.state.clienteSelected && this.state.clienteSelected.nmFantasia !== undefined) {
      return this.state.clienteSelected;
    }
    return '';
  }

  loadSuggestionsAutocomplete() {
    this._isMounted &&
      getAllClientes()
        .then(res => this._isMounted && this.setState({ clientes: res.data }))
        .catch(err => {
          SwalMessage({
            title: 'Falha ao carregar clientes',
            text: 'Não foi possível carregar os clientes, tente novamente',
            err
          });
        });
  }

  exitImporting() {
    this.props.exitEditPergunta();
    this.props.showButtonFAB();
  }

  render() {
    const { classes } = this.props;

    return (
      <CardIcon titulo='Importar perguntas' styleCollapse={{ paddingRight: 0 }}>
        <div className={classes.containerCard}>
          <Formik
            key={this.state.key}
            initialValues={{ clienteSelected: this.state.clienteSelected }}
            onSubmit={this.submit}
            validateOnBlur
            enableReinitialize={true}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              clienteSelected: Yup.object()
                .required('Campo obrigatório')
                .typeError('Campo obrigatório')
            })}
            render={({ touched, handleSubmit, errors, handleBlur }) => (
              <>
                <Grid container style={{ minHeight: 70, maxHeight: 70 }}>
                  <Grid item xs={6} style={{ height: 70, marginTop: 15, marginBottom: 15 }}>
                    <Autocomplete
                      itens={this.state.clientes}
                      campoOp='nmFantasia'
                      campoChave='idCliente'
                      label='Cliente'
                      name='clienteSelected'
                      id='clienteSelected'
                      error={this.tratarValueAutocomplete() === '' && touched.clienteSelected}
                      helperText={
                        this.tratarValueAutocomplete() === '' && touched.clienteSelected
                          ? errors.clienteSelected
                          : null
                      }
                      onBlurOwn={handleBlur}
                      onChangeOwn={this.onChangeAutocomplete}
                      onChangeAutoComplete={this.loadSuggestionsAutocomplete}
                      value={this.tratarValueAutocomplete()}
                      key={this.state.keyAutocomplete}
                      valueAutoComplete={this.tratarValueAutocomplete()}
                      valueSelect={this.tratarValueAutocomplete().nmFantasia}
                      maxHeight='calc(100vh - 380px)'
                    />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs>
                    <CheckBoxTreeOwn
                      data={this.state.questionarios}
                      checkeds={this.state.perguntasSelecionadas}
                      expandeds={this.state.questionariosExpandeds}
                      onCheck={checks => this.setState({ perguntasSelecionadas: checks })}
                      onExpand={expandeds => this.setState({ questionariosExpandeds: expandeds })}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={0} className={classes.containerButtons}>
                  <Grid item xs>
                    <div className={classes.buttons}>
                      <Button
                        variant='contained'
                        color='secondary'
                        className={classes.button}
                        style={{ marginRight: 15, backgroundColor: '#124268' }}
                        onClick={this.exitImporting}
                      >
                        CANCELAR
                      </Button>

                      <Button
                        variant='contained'
                        color='secondary'
                        className={classes.button}
                        style={{ backgroundColor: '#2BA69F' }}
                        onClick={handleSubmit}
                      >
                        IMPORTAR
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </>
            )}
          />
        </div>
      </CardIcon>
    );
  }
}

const mapStateToProps = state => {
  const { tabPerguntasQuestionarioReducer, mainQuestionarioReducer } = state.questionarioReducer;
  return {
    perguntasData: tabPerguntasQuestionarioReducer.perguntasData,
    importandoPerguntas: tabPerguntasQuestionarioReducer.importandoPerguntas,
    idQuestionario: mainQuestionarioReducer.idQuestionario,
    clienteSelected: tabPerguntasQuestionarioReducer.importarPergunta.clienteSelected,
    questionarios: tabPerguntasQuestionarioReducer.importarPergunta.questionarios,
    perguntasSelecionadas: tabPerguntasQuestionarioReducer.importarPergunta.perguntasSelecionadas,
    questionariosExpandeds: tabPerguntasQuestionarioReducer.importarPergunta.questionariosExpandeds
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      showButtonFAB,
      hiddenButtonFAB,
      changeImportarPergunta,
      changeListaPerguntas,
      exitEditPergunta,
      newIdAlternativa,
      enableLoading
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ImportarPerguntas));
