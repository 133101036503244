import ActionTypes from '../actionTypes/QuestionarioActionTypes';

const changeRegraSelected = payload => ({ type: ActionTypes.CHANGE_REGRA_SELECTED, payload });

const exitEditRegra = () => ({ type: ActionTypes.EXIT_EDIT_REGRA });

const goAddRegra = () => ({ type: ActionTypes.GO_ADD_REGRA });

const goEditRegra = payload => ({ type: ActionTypes.GO_EDIT_REGRA, payload });

const changeListaRegras = payload => ({ type: ActionTypes.CHANGE_LISTA_REGRAS, payload });

const changeAgruparRespostasSelecteds = payload => ({
  type: ActionTypes.CHANGE_AGRUPAR_RESPOSTAS_SELECTEDS,
  payload
});

const changeAgruparRespostasData = payload => ({
  type: ActionTypes.CHANGE_AGRUPAR_RESPOSTAS_DATA,
  payload
});

const changeMarcosRegras = payload => ({ type: ActionTypes.CHANGE_MARCOS_REGRAS, payload });

const defaultRegras = () => ({ type: ActionTypes.DEFAULT_REGRAS });

const changeOpcoesPerguntas = payload => ({ type: ActionTypes.CHANGE_OPCOES_PERGUNTAS, payload });

//

const changeEntrevistadorSelected = payload => ({
  type: ActionTypes.CHANGE_ENTREVISTADOR_SELECTED,
  payload
});

const exitEditEntrevistador = () => ({ type: ActionTypes.EXIT_EDIT_ENTREVISTADOR });

const goAddEntrevistador = () => ({ type: ActionTypes.GO_ADD_ENTREVISTADOR });

const goEditEntrevistador = payload => ({ type: ActionTypes.GO_EDIT_ENTREVISTADOR, payload });

const changeEntrevistadores = payload => ({ type: ActionTypes.CHANGE_LISTA_ENTREVISTADORES, payload });

const defaultEntrevistadores = () => ({ type: ActionTypes.DEFAULT_ENTREVISTADORES });

//

const changeConfigFechada = payload => ({ type: ActionTypes.CHANGE_CONFIG_FECHADA, payload });

const changeConfigMultiplaEscolha = payload => ({
  type: ActionTypes.CHANGE_CONFIG_MULTIPLA_ESCOLHA,
  payload
});

const changeConfigEscalaLinear = payload => ({ type: ActionTypes.CHANGE_CONFIG_ESCALA_LINEAR, payload });

const changePerguntaSelected = payload => ({ type: ActionTypes.CHANGE_PERGUNTA_SELECTED, payload });

const changeImportarPergunta = payload => ({ type: ActionTypes.CHANGE_IMPORTAR_PERGUNTA, payload });

const exitEditPergunta = () => ({ type: ActionTypes.EXIT_EDIT_PERGUNTA });

const goAddPergunta = () => ({ type: ActionTypes.GO_ADD_PERGUNTA });

const goImportarPerguntas = () => ({ type: ActionTypes.GO_IMPORTAR_PERGUNTAS });

const goEditPergunta = payload => ({ type: ActionTypes.GO_EDIT_PERGUNTA, payload });

const changeListaPerguntas = payload => ({ type: ActionTypes.CHANGE_LISTA_PERGUNTAS, payload });

const syncStPergunta = payload => ({ type: ActionTypes.SYNC_ST_PERGUNTA, payload });

const newIdAlternativa = payload => ({ type: ActionTypes.NEW_ID_ALTERNATIVA, payload });

const setInitialIdAlternativa = payload => ({ type: ActionTypes.SET_INITIAL_ID_ALTERNATIVA, payload });

const defaultPerguntas = () => ({ type: ActionTypes.DEFAULT_PERGUNTAS });

//

const changeExcecaoSelected = payload => ({ type: ActionTypes.CHANGE_EXCECAO_SELECTED, payload });

const goEditExcecao = payload => ({ type: ActionTypes.GO_EDIT_EXCECAO, payload });

const exitEditExcecao = () => ({ type: ActionTypes.EXIT_EDIT_EXCECAO });

const defaultExcecao = () => ({ type: ActionTypes.DEFAULT_EXCECOES });

//

const showButtonFAB = () => ({ type: ActionTypes.SHOW_BUTTON_FAB });

const hiddenButtonFAB = () => ({ type: ActionTypes.HIDDEN_BUTTON_FAB });

const changeStatusQuestionario = payload => ({ type: ActionTypes.CHANGE_STATUS_QUESTIONARIO, payload });

const setIdQuestionario = payload => ({ type: ActionTypes.SET_ID_QUESTIONARIO, payload });

const changeClienteQuestionario = payload => ({
  type: ActionTypes.CHANGE_CLIENTE_QUESTIONARIO,
  payload
});

const changeAtualizandoQuestionario = payload => ({
  type: ActionTypes.CHANGE_ATUALIZANDO_QUESTIONARIO,
  payload
});

const defaultQuestionario = () => ({ type: ActionTypes.DEFAULT_QUESTIONARIO });

export {
  hiddenButtonFAB,
  showButtonFAB,
  changeStatusQuestionario,
  changeClienteQuestionario,
  setIdQuestionario,
  changeAtualizandoQuestionario,
  defaultQuestionario,
  //
  changeConfigEscalaLinear,
  changeConfigMultiplaEscolha,
  changeConfigFechada,
  changePerguntaSelected,
  changeImportarPergunta,
  exitEditPergunta,
  goAddPergunta,
  goImportarPerguntas,
  goEditPergunta,
  changeListaPerguntas,
  newIdAlternativa,
  changeOpcoesPerguntas,
  syncStPergunta,
  setInitialIdAlternativa,
  defaultPerguntas,
  //
  changeExcecaoSelected,
  goEditExcecao,
  exitEditExcecao,
  defaultExcecao,
  //
  changeEntrevistadores,
  goEditEntrevistador,
  goAddEntrevistador,
  exitEditEntrevistador,
  changeEntrevistadorSelected,
  defaultEntrevistadores,
  //
  changeListaRegras,
  changeAgruparRespostasSelecteds,
  changeAgruparRespostasData,
  goEditRegra,
  goAddRegra,
  exitEditRegra,
  defaultRegras,
  changeRegraSelected,
  changeMarcosRegras
};
