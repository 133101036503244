import React, { useState, useEffect } from 'react';
import CombTab from '../../components/CombTab/CombTab';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { updateToolbar, enableLoading } from '../../states/actions/ToolbarActions';
import { withStyles } from '@material-ui/core/styles';
import Quantitativo from './Quantitativo';
import Entrevistadores from './Entrevistadores';
import Comparativo from './Comparativo';
import AuditoriaDetalhamento from './AuditoriaDetalhamento';
import { getAllAuditoria, updateAuditoria } from './../../resources/api/auditoria';
import Entrevistas from './Entrevistas';
import ButtonFAB from './../../components/ButtonFab/ButtonFab';
import { Check } from '@material-ui/icons';
import SwalMessage from '../../utils/functions/SwalMessage/SwalMessage';
import SwalConfirmacao from '../../utils/functions/SwalConfirmacao/SwalConfirmacao';
import swal from '@sweetalert/with-react';
import loadingSwal from '../../utils/functions/LoadingSwal/LoadingSwal';

const style = (theme) => ({
  btnConfirmSwal: {
    backgroundColor: '#2BA69F !important',
    '&:hover': {
      backgroundColor: '#2BA69F !important',
    },
  },
});

/**
 * Tela para análise de auditoria
 *
 * @author Gabriela Farias
 * @class EditarAuditoria
 */
function EditarAuditoria(props) {
  const [tabSelected, setTabSelected] = useState(0);
  const [quantitativoList, setQuantitativoList] = useState([]);
  const [quantitativoListWithoutEdit, setQuantitativoListWithoutEdit] = useState([]);
  const [entrevistadoresList, setEntrevistadoresList] = useState([]);
  const [entrevistasList, setEntrevistasList] = useState([]);
  const [entrevistasListWithoutEdit, setEntrevistasListWithoutEdit] = useState([]);
  const [comparativoList, setComparativoList] = useState([]);
  const [auditoriaList, setAuditoriaList] = useState([]);
  const [entrevistadorSelected, setEntrevistadorSelected] = useState(null);
  const [entrevistadorSelectedWithoutEdit, setEntrevistadorSelectedWithoutEdit] = useState(null);
  const [entrevistaSelected, setEntrevistaSelected] = useState(null);
  const [entrevistaSelectedWithoutEdit, setEntrevistaSelectedWithoutEdit] = useState(null);
  const [auditoriaSelected, setAuditoriaSelected] = useState(null);
  const [auditoriaSelectedWithoutEdit, setAuditoriaSelectedWithoutEdit] = useState(null);
  const [showFAB, setShowFAB] = useState(true);
  const [enableAuditoria, setEnableAuditoria] = useState(false);

  const getAuditorias = (updateItem) => {
    getAllAuditoria(props.match.params.id)
      .then((auditoria) => {
        let item = auditoria.data.questaoList;
        item.forEach((doc) => {
          if (doc.alternativaList) {
            let maxMedia = 0;
            let position = [];

            doc.alternativaList.forEach((resposta) => {
              if (maxMedia < resposta.nrMediaGeral) {
                maxMedia = resposta.nrMediaGeral;
                position = [doc.alternativaList.indexOf(resposta)];
              } else if (maxMedia === resposta.nrMediaGeral) {
                maxMedia = resposta.nrMediaGeral;
                position.push(doc.alternativaList.indexOf(resposta));
              }
            });

            position.forEach((index) => {
              if (index >= 0) {
                doc.alternativaList[index].moreResult = true;
              }
            });
          }
        });

        setQuantitativoList(item);
        setQuantitativoListWithoutEdit(JSON.parse(JSON.stringify(item)));
        setEntrevistadoresList(auditoria.data.questionarioUsuarioList);

        if (updateItem) {
          let entrevistaAtual = auditoria.data.questionarioUsuarioList.find(
            (entrevistador) => entrevistador.idVoto === entrevistadorSelected.idVoto
          );

          setEntrevistasList(JSON.parse(JSON.stringify(entrevistaAtual.votoAuditoriaList)));
          setEntrevistasListWithoutEdit(JSON.parse(JSON.stringify(entrevistaAtual.votoAuditoriaList)));
        }
      })
      .catch((err) => {
        SwalMessage({
          title: 'Falha ao carregar página',
          text: 'Não foi possível carregar a página, tente novamente',
          err,
        });
      })
      .finally(() => {
        swal.close();
      });
  };

  /**
   * Inicializa a classe
   */
  useEffect(() => {
    loadingSwal({ title: 'Carregando' });

    getAuditorias(false);
  }, []);

  /**
   * Retorna label da tipo de questão na grid de auditoria
   */
  const convertTipoQuestao = (value) => {
    switch (value) {
      case 0:
        return 'Escolha simples';
      case 1:
        return 'Múltipla escolha';
      case 2:
        return 'Escala linear';
      case 3:
        return 'Aberta';
      default:
        return '';
    }
  };

  /**
   * Armazena o entrevistador selecionado e altera a TAB
   */
  const entrevistadorSelect = (select) => {
    setEntrevistadorSelected(select);
    setEntrevistadorSelectedWithoutEdit(JSON.parse(JSON.stringify(select)));
    setEntrevistasList(JSON.parse(JSON.stringify(select.votoAuditoriaList)));
    setEntrevistasListWithoutEdit(JSON.parse(JSON.stringify(select.votoAuditoriaList)));
    setTabSelected(2);
    setComparativoList(select.questaoAuditoriaList);
  };

  /**
   * Armazena a entrevista selecionada
   */
  const entrevistaSelecionada = (select) => {
    setEntrevistaSelected(select);
    setEntrevistaSelectedWithoutEdit(JSON.parse(JSON.stringify(select)));
  };

  /**
   * Armazena a auditoria selecionada
   */
  const auditoriaSelecionada = (select) => {
    setAuditoriaSelected(select);
    setAuditoriaSelectedWithoutEdit(JSON.parse(JSON.stringify(select)));
  };

  /*
   * Atualiza item editado
   */
  const clickSalvar = (select, valueSelect) => {
    let itemExists = [];
    entrevistaSelected.stRegistro = 1;
    entrevistaSelected.votoItemList.forEach((item) => {
      if (item.questao.idQuestao === select.idQuestao) {
        itemExists.push(item);
      }
    });

    if (select.tpQuestao === 3) {
      select.dsAlternativa = valueSelect;

      const newItem = {
        idVotoItem: select.votoItem.idVotoItem,
        questao: select.votoItem.questao,
        dsResposta: valueSelect,
        stRegistro: 1,
      };
      entrevistaSelected.votoItemList.push(newItem);
    } else if (select.tpQuestao === 2) {
      itemExists.forEach((exists) => {
        let alternativeItem = select.alternativaList.find(
          (linear) => exists.alternativa.idAlternativa === linear.idAlternativa
        );

        if (alternativeItem.dsAlternativa !== valueSelect && alternativeItem.usuarioAnswer) {
          exists.stRegistro = 2;

          let linearItem = select.alternativaList.find((linear) => valueSelect === linear.dsAlternativa);

          const newItem = {
            alternativa: {
              idAlternativa: linearItem.idAlternativa,
            },
            stRegistro: 0,
            questao: {
              idQuestao: linearItem.idQuestao,
            },
          };
          entrevistaSelected.votoItemList.push(newItem);
        }
      });
    } else {
      let itemChanged = [];
      select.alternativaList.forEach((item) => {
        if (item.usuarioAnswer) {
          itemChanged.push(item);
        }
      });

      let newItens = [];

      itemExists.forEach((item) => {
        let deletedItem = itemChanged.find(
          (changed) => item.alternativa.idAlternativa === changed.idAlternativa
        );

        if (deletedItem === undefined) {
          item.stRegistro = 2;
          newItens.push(item);
        } else {
          item.stRegistro = 1;
          newItens.push(item);
        }
      });

      itemChanged.forEach((changed) => {
        let novoItem = newItens.find(
          (newItem) => newItem.alternativa.idAlternativa === changed.idAlternativa
        );

        if (novoItem === undefined) {
          const newItem = {
            alternativa: {
              idAlternativa: changed.idAlternativa,
            },
            stRegistro: 0,
            questao: {
              idQuestao: changed.idQuestao,
            },
          };
          entrevistaSelected.votoItemList.push(newItem);
          newItens.push(newItem);
        }
      });
    }
  };

  return (
    <div style={{ width: '100%', marginBottom: 15 }}>
      <CombTab
        onChangeTab={(tab) => {
          if (tabSelected === 4) {
            SwalConfirmacao({ text: 'Ao voltar as alterações serão perdidas, deseja voltar?' }).then(
              (res) => {
                if (res) {
                  setEntrevistaSelected(JSON.parse(JSON.stringify(entrevistaSelectedWithoutEdit)));
                  setAuditoriaSelected(JSON.parse(JSON.stringify(auditoriaSelectedWithoutEdit)));

                  setQuantitativoList(JSON.parse(JSON.stringify(quantitativoListWithoutEdit)));
                  setTabSelected(tab);
                  setEnableAuditoria(false);
                }
              }
            );
          } else {
            setTabSelected(tab);
            setEnableAuditoria(false);
          }
        }}
        tabSelected={tabSelected}
        tab={[
          {
            title: 'Quantitativo',
            value: 0,
            content: <Quantitativo quantitativoList={quantitativoList} />,
          },
          {
            title: 'Entrevistadores',
            value: 1,
            withoutUnmount: true,
            content: (
              <Entrevistadores
                entrevistadorSelected={entrevistadorSelected}
                entrevistadoresList={entrevistadoresList}
                entrevistadorSelect={(select) => {
                  entrevistadorSelect(select);
                }}
              />
            ),
          },
          {
            title: 'Comparativo',
            value: 2,
            content: <Comparativo comparativoList={comparativoList} />,
            disabledItem: !entrevistadorSelected,
          },
          {
            title: 'Entrevistas',
            value: 3,
            content: (
              <Entrevistas
                quantitativoList={quantitativoList}
                entrevistaSelected={entrevistaSelected}
                entrevistasList={entrevistasList}
                entrevistaSelecionada={(select) => entrevistaSelecionada(select)}
                editing={(editing) => setShowFAB(editing)}
                clickEditarAuditoria={(values) => {
                  values.forEach((item) => {
                    item.dsTpQuestao = convertTipoQuestao(item.tpQuestao);
                  });
                  setAuditoriaList(values);
                  setTabSelected(4);
                  setEnableAuditoria(true);
                  setShowFAB(true);
                }}
                descartarEntrevista={() => {
                  entrevistaSelected.stVoto = 2;
                  updateAuditoria(entrevistaSelected)
                    .then(() => {
                      getAuditorias(true);
                      SwalMessage({
                        text: 'Auditoria salva',
                        icon: 'success',
                        callback: () => {
                          setTabSelected(3);
                          setEnableAuditoria(false);
                        },
                      });
                    })
                    .catch(() => {
                      SwalMessage({ title: 'Erro ao salvar auditoria' });
                    });
                }}
                clickVoltar={() => {
                  setEntrevistasList(JSON.parse(JSON.stringify(entrevistasListWithoutEdit)));
                  setEntrevistaSelected(null);
                  setAuditoriaSelected(JSON.parse(JSON.stringify(auditoriaSelectedWithoutEdit)));

                  setQuantitativoList(JSON.parse(JSON.stringify(quantitativoListWithoutEdit)));
                  setEntrevistadorSelected(JSON.parse(JSON.stringify(entrevistadorSelectedWithoutEdit)));
                }}
                clickConfirmar={() => {
                  let listAll = { ...entrevistasList.find((item) => item.stRegistro !== undefined) };
                  listAll.stVoto = 1;

                  let listFinal = [];
                  listFinal = listAll.votoItemList.filter((item) => item.stRegistro !== undefined);

                  listAll.votoItemList = listFinal;

                  updateAuditoria(listAll)
                    .then(() => {
                      setEntrevistaSelectedWithoutEdit(JSON.parse(JSON.stringify(entrevistaSelected)));
                      setAuditoriaSelectedWithoutEdit(JSON.parse(JSON.stringify(auditoriaSelected)));
                      setQuantitativoListWithoutEdit(JSON.parse(JSON.stringify(quantitativoList)));
                      setEntrevistasListWithoutEdit(JSON.parse(JSON.stringify(entrevistasList)));
                      setEntrevistadorSelectedWithoutEdit(
                        JSON.parse(JSON.stringify(entrevistadorSelected))
                      );

                      getAuditorias(true);

                      SwalMessage({
                        text: 'Auditoria salva',
                        icon: 'success',
                        callback: () => {
                          setTabSelected(3);
                          setEnableAuditoria(false);
                        },
                      });
                    })
                    .catch(() => {
                      SwalMessage({ title: 'Erro ao salvar auditoria' });
                    });
                }}
              />
            ),
            disabledItem: !entrevistadorSelected,
          },
          {
            title: 'Auditoria',
            value: 4,
            content: (
              <AuditoriaDetalhamento
                auditoriaSelecionada={(select) => auditoriaSelecionada(select)}
                auditoriaSelected={auditoriaSelected}
                quantitativoList={quantitativoList}
                auditoriaList={auditoriaList}
                entrevistasList={entrevistasList}
                editing={(editing) => setShowFAB(editing)}
                clickSalvar={clickSalvar}
              />
            ),
            disabledItem: !entrevistadorSelected || !enableAuditoria,
          },
        ]}
      />

      {tabSelected === 4 && showFAB && (
        <ButtonFAB
          iconJSX={<Check style={{ color: '#FFFFFF' }} />}
          positionDefault={true}
          onClick={() => {
            setTabSelected(3);
            setEnableAuditoria(false);
          }}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    itensState: state.toolbarReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ updateToolbar, enableLoading }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(style)(EditarAuditoria)));
