import axios from 'axios';
import { URL_API } from '../common';

const URL_API_CLIENTE = `${URL_API}/cliente`;

const getAllClientes = async () => {
  return await axios.get(URL_API_CLIENTE);
};

const getClienteById = async id => {
  return await axios.get(URL_API_CLIENTE + '/' + id);
};

const insertCliente = async data => {
  return await axios.post(URL_API_CLIENTE, data);
};

const updateCliente = async data => {
  return await axios.put(URL_API_CLIENTE, data);
};

const deleteCliente = async id => {
  return await axios.delete(URL_API_CLIENTE + '/' + id);
};

export { getAllClientes, getClienteById, insertCliente, updateCliente, deleteCliente };
