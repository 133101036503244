import axios from 'axios';
import { URL_API } from '../common';

let dadosUsuario = null;

function findPerfilHandler() {
  const auth = JSON.parse(localStorage.getItem('token'));

  if (dadosUsuario !== null) {
    return Promise.resolve(dadosUsuario);
  } else if (auth) {
    return axios
      .get(URL_API + '/perfil', {
        headers: { Authorization: 'Bearer ' + auth.access_token }
      })
      .then(response => {
        dadosUsuario = response.data;
        return response.data;
      });
  } else {
    return Promise().reject();
  }
}

function validarCadastroHandler(data) {
  return axios
    .post(URL_API + '/cliente/ativar', {
      nmSchema: data
    })
    .then(response => {
      dadosUsuario = response.data;
      return response.data;
    });
}

function resetPerfilHandler() {
  dadosUsuario = null;
}

function editUsuariosHandler(values) {
  return axios.put(URL_API + '/perfil', values);
}

export default {
  findPerfil: findPerfilHandler,
  resetPerfil: resetPerfilHandler,
  validarCadastro: validarCadastroHandler,
  editUsuarios: editUsuariosHandler
};
