import axios from 'axios';
import { URL_API } from '../common';

const URL_API_MUNICIPIO = `${URL_API}/municipio`;

const getAllMunicipios = async () => {
  return await axios.get(URL_API_MUNICIPIO);
};

const getMunicipioById = async id => {
  return await axios.get(URL_API_MUNICIPIO + '/' + id);
};

const getMunicipiosByUf = async id => {
  return await axios.get(URL_API_MUNICIPIO + '/uf/' + id);
};

export { getAllMunicipios, getMunicipioById, getMunicipiosByUf };
