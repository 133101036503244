/*
    Autor: Bruno Eduardo
*/

import React, { Component } from 'react';
import GroupButtonGrid from '../../../components/GroupButtonGrid/GroupButtonGrid';
import DataTable from '../../../components/DataTable/DataTable';
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import SwalConfirmacao from '../../../utils/functions/SwalConfirmacao/SwalConfirmacao';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EditarContato from './EditarContato';
import clone from '../../../utils/functions/Clone/Clone';
import {
  goAddContato,
  changeListaContatos,
  showButtonFAB,
  exitEditContato,
  goEditContato
} from '../../../states/actions/ClienteActions';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2BA69F'
    }
  },
  typography: {
    useNextVariants: true
  }
});

const styles = theme => ({
  containerTable: {
    display: 'flex',
    height: 'calc(100vh - 186px)'
  }
});

class HistoricoContatos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      head: [
        { field: 'dtContatoUI', headerName: 'Data', col: 2 },
        { field: 'hrContato', headerName: 'Hora', col: 1 },
        { field: 'nmUsuario', headerName: 'Usuário', col: 3 },
        { field: 'dsTitulo', headerName: 'Titulo', col: 4 }
      ],
      contatoSelected: '',
      disabledEdit: true,
      disabledDelete: true
    };

    this.selectChange = this.selectChange.bind(this);
    this.removeContato = this.removeContato.bind(this);
    this.exitEdit = this.exitEdit.bind(this);
    this.goAdd = this.goAdd.bind(this);
    this.goEdit = this.goEdit.bind(this);
  }

  removeContato() {
    if (this.state.contatoSelected) {
      SwalConfirmacao({ text: 'Tem certeza que deseja remover este registro de contato?' }).then(res => {
        if (res) {
          const auxLista = clone(this.props.contatosData);
          auxLista.splice(this.state.contatoSelected.index, 1);
          auxLista.forEach((item, i) => (item.index = i));
          this.props.changeListaContatos(auxLista);
          this.exitEdit();
        }
      });
    }
  }

  selectChange(select) {
    if (select) {
      this.setState({ contatoSelected: select, disabledEdit: false, disabledDelete: false });
    } else {
      this.setState({ contatoSelected: '', disabledEdit: true, disabledDelete: true });
    }
  }

  goAdd() {
    this.selectChange('');
    this.props.goAddContato();
  }

  goEdit() {
    if (this.state.contatoSelected) {
      this.props.goEditContato(this.state.contatoSelected);
      this.selectChange('');
    }
  }

  exitEdit() {
    this.props.exitEditContato();
    this.props.showButtonFAB(true);
  }

  render() {
    const { classes } = this.props;

    const pagination = { length: this.props.contatosData.length, page: 0 };

    return (
      <MuiThemeProvider theme={theme}>
        {!this.props.editandoContato && (
          <>
            <GroupButtonGrid
              showAdd={true}
              showEdit={true}
              showDelete={true}
              disableEdit={this.state.disabledEdit}
              disableDelete={this.state.disabledDelete}
              onClickEdit={this.goEdit}
              onClickAdd={this.goAdd}
              onClickDelete={this.removeContato}
              disableMarginTop
            />

            <div className={classes.containerTable}>
              <DataTable
                data={this.props.contatosData}
                columns={this.state.head}
                selectChange={this.selectChange}
                showPagination={true}
                pagination={pagination}
                handleChangePage={() => {}}
                handleChangeRowsPerPage={() => {}}
              />
            </div>
          </>
        )}

        {this.props.editandoContato && true && <EditarContato />}
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  contatosData: state.clienteReducer.contatosData,
  editandoContato: state.clienteReducer.editandoContato
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { goAddContato, changeListaContatos, showButtonFAB, exitEditContato, goEditContato },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(HistoricoContatos));
