/*
    Autor: Bruno Eduardo
*/

import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import ArrowDown from '@material-ui/icons/ArrowDownwardRounded';
import classNames from 'classnames';
import CardFile from './CardFile';
import { Formik, FastField } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import swal from '@sweetalert/with-react';
import * as moment from 'moment';
import DateTimePicker from '../../../components/DateTimePicker/DateTimePicker';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CardIcon from '../../../components/CardIcon/CardIcon';
import Grid from '@material-ui/core/Grid';
import { bindActionCreators } from 'redux';
import SwalMessage from '../../../utils/functions/SwalMessage/SwalMessage';
import SwalConfirmacao from '../../../utils/functions/SwalConfirmacao/SwalConfirmacao';
import TextField from '../../../components/TextFieldOwn/TextFieldOwn';
import clone from '../../../utils/functions/Clone/Clone';
import loadingSwal from '../../../utils/functions/LoadingSwal/LoadingSwal';
import {
  showButtonFAB,
  changeContatoSelected,
  exitEditContato,
  changeListaContatos
} from '../../../states/actions/ClienteActions';

const styles = theme => ({
  containerCard: {
    height: 'calc(100vh - 280px)',
    display: 'flex',
    overflow: 'hidden',
    marginBottom: 55,
    paddingRight: 30,
    flexDirection: 'column'
  },
  containerButtons: {
    position: 'absolute',
    bottom: 15,
    left: 0,
    paddingRight: 30,
    paddingTop: 15
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  buttonActions: {
    boxShadow: '0px 3px 6px 0px rgba(0,0,0,0.24)'
  },
  cardDragDrop: {
    width: 200,
    height: 150,
    marginRight: 15,
    marginBottom: 15,
    border: '1px dashed #707070',
    borderRadius: 10,
    color: '#707070',
    fontFamily: 'Roboto'
  },
  cardFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 200,
    height: 150,
    marginTop: -47
  },
  textUploadFile: {
    textAlign: 'center',
    opacity: 0.87,
    color: '#707070',
    fontFamily: 'Roboto',
    fontSize: '14px'
  },
  containerCardFlex: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxHeight: 'calc(100vh - 510px)',
    overflowY: 'auto',
    paddingTop: 15
  },
  inputFile: {
    display: 'none',
    width: 0,
    height: 0
  }
});

function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    if (file.size <= 20000000) {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(file);
    } else {
      reject();
    }
  });
}

class EditarContato extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.props.contatoSelected,
      key: ''
    };

    this.onAddFiles = this.onAddFiles.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.submit = this.submit.bind(this);
    this.exitEdit = this.exitEdit.bind(this);
  }

  componentDidMount() {
    if (this.props.editandoContato) {
      this.props.showButtonFAB(false);
    }
  }

  componentWillUnmount() {
    this.props.changeContatoSelected({
      dtContato: this.state.dtContato,
      dsTitulo: this.state.dsTitulo,
      dsObservacao: this.state.dsObservacao,
      clienteAnexoList: this.state.clienteAnexoList
    });
  }

  submit() {
    const auxLista = clone(this.props.contatosData);
    const contato = {
      idClienteContato: this.state.idClienteContato || null,
      dsTitulo: this.state.dsTitulo,
      dsObservacao: this.state.dsObservacao,
      clienteAnexoList: this.state.clienteAnexoList,
      index: this.state.index,
      usuarioDTO: this.props.usuarioLogado,
      nmUsuario: this.props.usuarioLogado.nmUsuario,
      dtContato: moment(this.state.dtContato).format('YYYY-MM-DDTHH:mm:ssZZ'),
      dtContatoUI: moment(this.state.dtContato).format('DD/MM/YYYY'),
      hrContato: moment(this.state.dtContato).format('HH:mm')
    };

    if (this.props.atualizandoContato) {
      auxLista[contato.index] = contato;
    } else {
      auxLista.push(contato);
    }

    auxLista.forEach((item, i) => (item.index = i));

    this.props.changeListaContatos(auxLista);

    this.exitEdit();
  }

  async onAddFiles(e, files = []) {
    if (files.length === 0) return;
    e.preventDefault();

    await new Promise(resolve => loadingSwal({ title: 'Fazendo upload', onMounted: resolve }));

    const auxFiles = this.state.clienteAnexoList;
    let allValid = true;

    const promises = Array.from(files).map(async file => {
      return await fileToBase64(file)
        .then(dsBase64 =>
          auxFiles.push({
            id: null,
            nmAnexo: file.name,
            dsBase64: dsBase64.split(';base64,')[1],
            dsLinkS3: null
          })
        )
        .catch(() => (allValid = false));
    });

    await Promise.all(promises);

    if (allValid) {
      swal.close();
    } else {
      SwalMessage({
        title: 'Atenção',
        text: 'Um ou mais arquivos não foram enviados pois são maiores que 20MB',
        icon: 'warning'
      });
    }

    this.setState({ clienteAnexoList: auxFiles });
  }

  removeFile(i) {
    SwalConfirmacao({ text: 'Tem certeza que deseja remover este arquivo?' }).then(res => {
      if (res) {
        const auxFiles = this.state.clienteAnexoList;
        auxFiles.splice(i, 1);
        this.setState({ clienteAnexoList: auxFiles });
      }
    });
  }

  exitEdit() {
    this.props.exitEditContato();
    this.props.showButtonFAB(true);
  }

  render() {
    const { classes } = this.props;

    return (
      <Formik
        key={this.state.key}
        initialValues={{
          dtContato: this.state.dtContato,
          dsTitulo: this.state.dsTitulo,
          dsObservacao: this.state.dsObservacao
        }}
        onSubmit={this.submit}
        validateOnBlur
        enableReinitialize={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          dtContato: Yup.date()
            .required('Digite uma data e hora válida')
            .typeError('Digite uma data e hora válida')
            .nullable(),
          dsTitulo: Yup.string()
            .required('Campo obrigatório')
            .max(200, 'Deve ter no máximo 200 caracteres'),
          dsObservacao: Yup.string()
        })}
        render={({ handleSubmit, values, errors, touched, handleBlur, setFieldValue }) => (
          <CardIcon titulo='Registrar contato' styleCollapse={{ paddingRight: 0 }}>
            <form autoComplete='off' className={classes.containerCard}>
              <Grid container spacing={0} style={{ marginTop: 25, marginBottom: 15 }}>
                <Grid item xs={3} style={{ height: 70, paddingRight: 15 }}>
                  <DateTimePicker
                    id='dtContato'
                    name='dtContato'
                    label='Data'
                    value={values.dtContato}
                    onChange={date =>
                      this.setState({ dtContato: date }, () => setFieldValue('dtContato', date))
                    }
                    onBlur={handleBlur}
                    error={errors.dtContato && touched.dtContato && true}
                    helperText={errors.dtContato && touched.dtContato ? errors.dtContato : null}
                  />
                </Grid>

                <Grid item xs={9} style={{ height: 70 }}>
                  <FastField name='dsTitulo'>
                    {({ form, field }) => (
                      <TextField
                        {...field}
                        id='dsTitulo'
                        label='Título'
                        onBlur={e => {
                          this.setState({ dsTitulo: e.target.value });
                          form.handleBlur(e);
                        }}
                        error={form.errors.dsTitulo && form.touched.dsTitulo}
                        helperText={
                          form.errors.dsTitulo && form.touched.dsTitulo ? form.errors.dsTitulo : null
                        }
                        inputProps={{ maxLength: 200 }}
                      />
                    )}
                  </FastField>
                </Grid>
              </Grid>

              <Grid container spacing={0}>
                <Grid item xs style={{ height: 90 }}>
                  <FastField name='dsObservacao'>
                    {({ form, field }) => (
                      <TextField
                        {...field}
                        id='dsObservacao'
                        label='Descrição'
                        onBlur={e => {
                          this.setState({ dsObservacao: e.target.value });
                          form.handleBlur(e);
                        }}
                        multiline={true}
                        rowsMax={2}
                        error={form.errors.dsObservacao && form.touched.dsObservacao}
                        helperText={
                          form.errors.dsObservacao && form.touched.dsObservacao
                            ? form.errors.dsObservacao
                            : null
                        }
                      />
                    )}
                  </FastField>
                </Grid>
              </Grid>

              <Grid container spacing={0}>
                <Grid
                  item
                  xs
                  className={classes.containerCardFlex}
                  onDrop={e => this.onAddFiles(e, e.dataTransfer.files)}
                  onDragOver={e => e.preventDefault()}
                >
                  <div
                    className={classNames(classes.cardDragDrop, classes.cardFlex)}
                    style={{ marginTop: 0, cursor: 'pointer' }}
                    onClick={() => this.inputFilesRef.click()}
                  >
                    <input
                      accept='*'
                      className={classes.inputFile}
                      multiple
                      type='file'
                      ref={ref => (this.inputFilesRef = ref)}
                      onChange={e => this.onAddFiles(e, e.target.files)}
                    />
                    <div style={{ fontSize: 65, height: 65, fontWeight: 'bold' }}>
                      <ArrowDown color='inherit' fontSize='inherit' fontWeight='bold' />
                    </div>
                    <Typography variant='caption' className={classes.textUploadFile}>
                      Solte os arquivos ou clique aqui para anexá-los
                      <br />
                      (arquivos de até 20MB)
                    </Typography>
                  </div>

                  {this.state.clienteAnexoList.map((file, i) => (
                    <CardFile
                      classes={classes}
                      file={file}
                      key={i}
                      onDeleteFile={() => this.removeFile(i)}
                    />
                  ))}
                </Grid>
              </Grid>

              <Grid container spacing={0} className={classes.containerButtons}>
                <Grid item xs>
                  <div className={classes.buttons}>
                    <Button
                      variant='contained'
                      color='secondary'
                      style={{ marginRight: 15, backgroundColor: '#124268' }}
                      onClick={this.exitEdit}
                      className={classes.buttonActions}
                    >
                      CANCELAR
                    </Button>

                    <Button
                      variant='contained'
                      color='secondary'
                      style={{ backgroundColor: '#2BA69F' }}
                      onClick={handleSubmit}
                      className={classes.buttonActions}
                    >
                      CONFIRMAR
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </CardIcon>
        )}
      />
    );
  }
}

const mapStateToProps = state => ({
  contatosData: state.clienteReducer.contatosData,
  editandoContato: state.clienteReducer.editandoContato,
  atualizandoContato: state.clienteReducer.atualizandoContato,
  contatoSelected: state.clienteReducer.contatoSelected,
  usuarioLogado: state.loginReducer.usuario
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { showButtonFAB, exitEditContato, changeContatoSelected, changeListaContatos },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EditarContato));
