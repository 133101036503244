import { combineReducers } from 'redux';
import questionarioReducer from './reducers/QuestionarioReducer';
import clienteReducer from './reducers/ClienteReducer';
import loginReducer from './reducers/LoginReducer';
import menuLateralReducer from './reducers/MenuLateralReducer';
import toolbarReducer from './reducers/ToolbarReducer';
import cardFiltersReducer from './reducers/CardFiltersReducer';

const Reducers = combineReducers({
  loginReducer,
  menuLateralReducer,
  clienteReducer,
  questionarioReducer,
  toolbarReducer,
  cardFiltersReducer
});

export default Reducers;
