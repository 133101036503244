import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
  root: {
    position: 'fixed',
    top: '0px',
    zIndex: '1',
    height: '3.5px',
    width: '100%'
  },
  linearColorPrimary: {
    backgroundColor: '#124268'
  },
  linearBarColorPrimary: {
    backgroundColor: '#42ADE8'
  }
});

function CustomizedProgress(props) {
  const { classes } = props;

  return (
    <LinearProgress
      classes={{
        colorPrimary: classes.linearColorPrimary,
        barColorPrimary: classes.linearBarColorPrimary,
        root: classes.root
      }}
    />
  );
}

CustomizedProgress.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CustomizedProgress);
