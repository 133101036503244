/*
    Autor: Bruno Eduardo
*/

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import SwalConfirmacao from '../../../utils/functions/SwalConfirmacao/SwalConfirmacao';
import { bindActionCreators } from 'redux';
import DataTable from '../../../components/DataTable/DataTable';
import GroupButtonGrid from '../../../components/GroupButtonGrid/GroupButtonGrid';
import ButtonGrid from '../../../components/ButtonGrid/ButtonGrid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import EditarPergunta from './EditarPergunta';
import ImportarPerguntas from './ImportarPerguntas';
import SwalMessage from '../../../utils/functions/SwalMessage/SwalMessage';
import clone from '../../../utils/functions/Clone/Clone';
import {
  hiddenButtonFAB,
  showButtonFAB,
  goEditPergunta,
  goAddPergunta,
  exitEditPergunta,
  changeListaPerguntas,
  goImportarPerguntas
} from '../../../states/actions/QuestionarioActions';

const styles = theme => ({
  containerTable: {
    display: 'flex',
    height: 'calc(100vh - 186px)'
  }
});

class Perguntas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      head: [
        {
          valueGetter: args => `${args.node.rowIndex + 1} - ${args.node.data.dsTitulo}`,
          headerName: '# - Título',
          col: 9,
          rowDrag: true
        },
        { field: 'tipoRespostaUI', headerName: 'Tipo de resposta', col: 3 }
      ],
      selected: '',
      disabledEdit: true,
      disabledDelete: true
    };

    this.refreshTable = null;

    this.goEditPergunta = this.goEditPergunta.bind(this);
    this.goAddPergunta = this.goAddPergunta.bind(this);
    this.goImportarPerguntas = this.goImportarPerguntas.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onRowDragEnd = this.onRowDragEnd.bind(this);
    this.validaRemove = this.validaRemove.bind(this);
  }

  componentDidMount() {
    if (!this.props.editandoPergunta && !this.props.importandoPerguntas) {
      this.props.showButtonFAB();
    }
  }

  onRemove() {
    if (this.state.selected && this.props.statusQuestionario === 0) {
      if (this.validaRemove()) {
        SwalMessage({
          text:
            'Não é possivel remover esta pergunta porque ela esta sendo usada em uma regra de análise ou fluxo de exceção'
        });
        return;
      }

      SwalConfirmacao({ text: 'Deseja mesmo remover esta pergunta da lista?' }).then(res => {
        if (res) {
          const auxLista = clone(this.props.perguntasDataAll);
          if (this.state.selected.stRegistro === 1) {
            auxLista[this.state.selected.nrOrdem].stRegistro = 2;
          } else {
            auxLista.splice(this.state.selected.nrOrdem, 1);
          }
          this.props.changeListaPerguntas(auxLista);
          this.props.exitEditPergunta();
          this.props.showButtonFAB();
        }
      });
    }
  }

  validaRemove() {
    const { selected } = this.state;

    const validacoesAnalises = regra => {
      return (
        regra.questaoAnaliseList.find(item => item.questao.idQuestao === selected.idQuestao) ||
        (regra.questaoSintetizadora && regra.questaoSintetizadora.idQuestao === selected.idQuestao)
      );
    };

    const validacoesQuestoes = quest => {
      return quest.questaoAvanco && quest.questaoAvanco.idQuestao === selected.idQuestao;
    };

    return (
      selected.stFluxoExcecao === 1 ||
      this.props.regrasData.find(validacoesAnalises) ||
      this.props.perguntasData.find(validacoesQuestoes)
    );
  }

  goEditPergunta() {
    if (this.state.selected && this.props.statusQuestionario === 0) {
      this.props.goEditPergunta(this.state.selected);
      this.selectChange('');
    }
  }

  goAddPergunta() {
    if (this.props.statusQuestionario === 0) {
      this.selectChange('');
      this.props.goAddPergunta();
      this.props.hiddenButtonFAB();
    }
  }

  goImportarPerguntas() {
    this.selectChange('');
    this.props.goImportarPerguntas();
    this.props.hiddenButtonFAB();
  }

  selectChange(select) {
    if (select) {
      this.setState({ selected: select, disabledEdit: false, disabledDelete: false });
    } else {
      this.setState({ selected: '', disabledEdit: true, disabledDelete: true });
    }
  }

  onRowDragEnd(newOrder, e, overIndex, fromIndex) {
    if (!newOrder || JSON.stringify(newOrder) === JSON.stringify(this.props.perguntasData)) return;

    const changeLista = () => {
      this.props.changeListaPerguntas(newOrder);
      e.api.ensureIndexVisible(overIndex, 'middle');
    };

    let found = false;
    for (const [indexQuestao, item] of newOrder.entries()) {
      if (item.stRegistro !== 2 && item.questaoAvanco) {
        const indexAvanco = newOrder.findIndex(
          subItem => subItem.idQuestao === item.questaoAvanco.idQuestao
        );

        if (indexAvanco <= indexQuestao) {
          found = true;

          SwalConfirmacao({
            text: 'Esta questão tem um fluxo de exceção que será removido, deseja continuar mesmo assim?'
          }).then(res => {
            if (res) {
              changeLista();
            } else {
              this.refreshTable();
            }
          });

          break;
        }
      }
    }
    if (found) return;

    const movingItem = this.props.perguntasData[fromIndex];

    const isQuestaoAvanco = this.props.perguntasData.find(
      item => item.questaoAvanco && item.questaoAvanco.idQuestao === movingItem.idQuestao
    );

    if (isQuestaoAvanco) {
      SwalConfirmacao({
        text: `Alterar a posição desta questão irá impactar nas questões obrigatórias do fluxo de exceção #${isQuestaoAvanco.nrOrdem +
          1}, deseja continuar?`,
        textConfirm: 'Sim',
        textCancel: 'Não'
      }).then(res => {
        if (res) {
          changeLista();
        } else {
          this.refreshTable();
        }
      });
      return;
    }

    if (movingItem.stFluxoExcecao === 1 && movingItem.questaoAvanco) {
      SwalConfirmacao({
        text: `Alterar a posição desta questão irá impactar nas questões obrigatórias do fluxo de exceção #${movingItem.nrOrdem +
          1}, deseja continuar?`,
        textConfirm: 'Sim',
        textCancel: 'Não'
      }).then(res => {
        if (res) {
          changeLista();
        } else {
          this.refreshTable();
        }
      });
      return;
    }

    function isBetweenFluxoExcecao(item, i, array) {
      if (item.stRegistro !== 2 && item.stFluxoExcecao === 1 && item.questaoAvanco) {
        const indexExcecao = item.nrOrdem;
        const indexAvanco = item.questaoAvanco.nrOrdem;

        const findIndex = subItem => subItem.idQuestao === movingItem.idQuestao;
        const indexMovingItem = array.find(findIndex).nrOrdem;

        return indexMovingItem > indexExcecao && indexMovingItem < indexAvanco;
      }
      return false;
    }

    newOrder.forEach((item, i) => (item.nrOrdem = i));
    newOrder.forEach((item, i, array) => {
      if (item.stRegistro !== 2 && item.questaoAvanco) {
        item.questaoAvanco = array.find(subItem => subItem.idQuestao === item.questaoAvanco.idQuestao);
      }
    });

    const estavaEntreFluxoExcecao = this.props.perguntasData.find(isBetweenFluxoExcecao);
    const estaraEntreFluxoExcecao = newOrder.find(isBetweenFluxoExcecao);

    if (estavaEntreFluxoExcecao && !estaraEntreFluxoExcecao) {
      SwalConfirmacao({
        text: `Esta questão deixará de ser obrigatória no fluxo de exceção #${estavaEntreFluxoExcecao.nrOrdem +
          1}, deseja continuar?`,
        textConfirm: 'Sim',
        textCancel: 'Não'
      }).then(res => {
        if (res) {
          changeLista();
        } else {
          this.refreshTable();
        }
      });
      return;
    }

    if (estaraEntreFluxoExcecao && !estavaEntreFluxoExcecao) {
      SwalConfirmacao({
        text: `Esta questão passará a ser obrigatória no fluxo de exceção #${estaraEntreFluxoExcecao.nrOrdem +
          1}, deseja continuar?`,
        textConfirm: 'Sim',
        textCancel: 'Não'
      }).then(res => {
        if (res) {
          changeLista();
        } else {
          this.refreshTable();
        }
      });
      return;
    }

    changeLista();
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        {!this.props.editandoPergunta && !this.props.importandoPerguntas && (
          <>
            <GroupButtonGrid
              showAdd={true}
              showEdit={true}
              showDelete={true}
              disableEdit={this.state.disabledEdit || this.props.statusQuestionario !== 0}
              disableDelete={this.state.disabledDelete || this.props.statusQuestionario !== 0}
              disableAdd={this.props.statusQuestionario !== 0}
              onClickEdit={this.goEditPergunta}
              onClickAdd={this.goAddPergunta}
              onClickDelete={this.onRemove}
              customButtons={[
                <ButtonGrid
                  show
                  disabled={this.props.statusQuestionario !== 0 || this.props.loading}
                  iconJSX={<FontAwesomeIcon icon={faFileImport} color='white' />}
                  color='#463766'
                  onClick={this.goImportarPerguntas}
                />
              ]}
              disableMarginTop
            />

            <div className={classes.containerTable}>
              <DataTable
                data={this.props.perguntasData}
                columns={this.state.head}
                selectChange={this.selectChange}
                onRowDragEnd={this.onRowDragEnd}
                getRefreshTable={refreshTable => (this.refreshTable = refreshTable)}
                enableDrag
              />
            </div>
          </>
        )}

        {this.props.editandoPergunta && !this.props.importandoPerguntas && <EditarPergunta />}

        {this.props.importandoPerguntas && !this.props.editandoPergunta && <ImportarPerguntas />}
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    tabPerguntasQuestionarioReducer,
    tabRegrasQuestionarioReducer,
    mainQuestionarioReducer
  } = state.questionarioReducer;

  return {
    editandoPergunta: tabPerguntasQuestionarioReducer.editandoPergunta,
    importandoPerguntas: tabPerguntasQuestionarioReducer.importandoPerguntas,
    perguntasData: tabPerguntasQuestionarioReducer.perguntasData.filter(item => item.stRegistro !== 2),
    perguntasDataAll: tabPerguntasQuestionarioReducer.perguntasData,
    regrasData: tabRegrasQuestionarioReducer.regrasData,
    statusQuestionario: mainQuestionarioReducer.statusQuestionario,
    loading: state.toolbarReducer.loading
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      hiddenButtonFAB,
      showButtonFAB,
      goEditPergunta,
      goAddPergunta,
      goImportarPerguntas,
      exitEditPergunta,
      changeListaPerguntas
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Perguntas));
