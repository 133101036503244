//@author Gabriela Farias
import React, { Component } from 'react';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import blue from '@material-ui/core/colors/lightBlue';

const styles = theme => ({
  labelEntrar: {
    color: 'white',
    fontWeight: 'lighter',
    fontSize: 14,
    cursor: 'pointer'
  },
  marginBotao: {
    background: '#124268'
  },
  botaoProgresso: {
    color: blue[500],
    position: 'absolute',
    marginTop: 6,
    marginLeft: 50
  },
  divBotao: {
    justifyContent: 'flexEnd',
    display: 'flex'
  }
});

const theme = createMuiTheme({
  palette: {
    primary: blue
  },
  typography: {
    useNextVariants: true
  }
});

class Botao extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <div className={classes.divBotao} ref={this.props.buttonRef}>
            <Button
              variant='contained'
              color='primary'
              className={classes.marginBotao}
              style={{
                width: this.props.statusConfirmarCadatro ? null : 126
              }}
              onClick={this.props.onClickButton}
              disabled={this.props.loadingButton}
              buttonRef={this.props.buttonRef}
            >
              <label className={classes.labelEntrar}>{this.props.text}</label>
            </Button>

            {this.props.loadingButton && (
              <CircularProgress size={24} className={classes.botaoProgresso} />
            )}
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default withStyles(styles)(Botao);
