/*
    Autor: Bruno Eduardo
*/

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { updateToolbar, enableLoading } from '../../states/actions/ToolbarActions';
import { connect } from 'react-redux';
import { withRouter, Prompt } from 'react-router-dom';
import CardIcon from '../../components/CardIcon/CardIcon';
import Grid from '@material-ui/core/Grid';
import SwipeableViews from 'react-swipeable-views';
import IconSetaDireita from '@material-ui/icons/ArrowForwardIos';
import ButtonFABMenu from './../../components/ButtonFABMenu/ButtonFABMenu';
import IconSetaEsquerda from '@material-ui/icons/ArrowBackIos';
import Button from '@material-ui/core/Button';
import SwalMessage from '../../utils/functions/SwalMessage/SwalMessage';
import { getRespostasByQuestionario, insertRespostas } from '../../resources/api/resposta';
import RespostaAlternativas from './RespostaAlternativas';
import SwalConfirmacao from '../../utils/functions/SwalConfirmacao/SwalConfirmacao';
import moveItem from '../../utils/functions/MoveItem/MoveItem';
import clone from '../../utils/functions/Clone/Clone';
import { withStyles } from '@material-ui/core/styles';
import loadingSwal from '../../utils/functions/LoadingSwal/LoadingSwal';

const style = theme => ({
  root: {
    height: 'calc(100vh - 100px)',
    width: '100%',
    color: 'gray',
    fontFamily: 'Roboto'
  },
  questoes: {
    height: 'calc(100vh - 245px)'
  },
  botoes: {
    width: '100%',
    height: 50,
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid #cccccc',
    paddingTop: 10,
    paddingBottom: 10
  },
  header: {
    fontSize: 20,
    fontWeight: 500,
    borderBottom: '1px solid #cccccc',
    height: 70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& :first-child': {
      width: 'calc(100vw - 500px)',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    }
  },
  detalhesQuestao: {
    marginTop: 15,
    fontSize: 18,
    '& span': {
      display: 'block'
    },
    '& span:nth-child(2)': {
      marginTop: 10,
      fontSize: 14
    }
  }
});

class LancarRespostas extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;

    this.questaoRespostaList = [];
    this.questionario = { idQuestionario: +this.props.match.params.id };

    this.initialQuestaoRespostaList = [];
    this.initialQuestionario = { idQuestionario: +this.props.match.params.id };

    this.state = { canLeave: false, pages: 0, pageSelected: 0 };

    const title = 'Lançar Respostas';
    if (this.props.itensState.name !== title) {
      this.props.updateToolbar({ toolbar: title, name: title });
    }
    this.props.enableLoading(true);

    this.isDirty = function() {
      return (
        JSON.stringify(this.initialQuestionario) !== JSON.stringify(this.questionario) ||
        JSON.stringify(this.initialQuestaoRespostaList) !== JSON.stringify(this.questaoRespostaList)
      );
    }.bind(this);

    window.onbeforeunload = function() {
      if (this.isDirty()) {
        return true;
      }
    }.bind(this);

    this.loadQuestionario = this.loadQuestionario.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.onChangeAlternativa = this.onChangeAlternativa.bind(this);
    this.submit = this.submit.bind(this);
    this.onTryExit = this.onTryExit.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadQuestionario();
  }

  componentWillUnmount() {
    window.onbeforeunload = undefined;
    this._isMounted = false;
  }

  submit() {
    this.props.enableLoading(true);
    loadingSwal();

    const questionarioResposta = {
      questionario: this.questionario,
      questaoRespostaList: this.questaoRespostaList.filter(
        questaoResposta =>
          !!questaoResposta.sinteseList.filter(
            sintese => sintese.nrTotalRespostas !== null && sintese.nrTotalRespostas !== undefined
          ).length
      )
    };

    this._isMounted &&
      insertRespostas(questionarioResposta)
        .then(res => {
          SwalMessage({
            title: 'Sucesso',
            text: 'Respostas cadastradas com sucesso!',
            icon: 'success',
            callback: () => {
              this.props.enableLoading(false);
              this.setState(
                { canLeave: true },
                () => this.state.canLeave && this.props.history.push('/app/respostas')
              );
            }
          });
        })
        .catch(err => {
          SwalMessage({
            title: 'Falha ao validar cadastro',
            text: 'Ocorreu um erro ao tentar salvar respostas',
            err,
            callback: () => this.props.enableLoading(false)
          });
        });
  }

  loadQuestionario() {
    if (!this.questionario.idQuestionario) return;
    this.props.enableLoading(true);

    this._isMounted &&
      getRespostasByQuestionario(this.questionario.idQuestionario)
        .then(res => {
          let key = 0;

          res.data.questaoRespostaList.forEach(item => {
            item.sinteseList.forEach(subItem => {
              if (subItem.idSintese > key) key = subItem.idSintese;
              key++;
              subItem.key = key;
            });

            const indexOutros = item.sinteseList.findIndex(
              subItem => subItem.alternativa && subItem.alternativa.tpAlternativa === 1
            );
            if (indexOutros !== -1 && indexOutros !== item.sinteseList.length - 1) {
              moveItem(item.sinteseList, indexOutros, item.sinteseList.length - 1);
            }
          });

          this._isMounted &&
            this.setState({
              pages: res.data.questaoRespostaList.length - 1,
              pageSelected: 0
            });

          this.questaoRespostaList = res.data.questaoRespostaList;
          this.questionario = {
            idQuestionario: res.data.questionario.idQuestionario,
            dsTitulo: res.data.questionario.dsTitulo
          };

          this.initialQuestaoRespostaList = clone(res.data.questaoRespostaList);
          this.initialQuestionario = {
            idQuestionario: res.data.questionario.idQuestionario,
            dsTitulo: res.data.questionario.dsTitulo
          };

          this.props.enableLoading(false);
        })
        .catch(err => {
          SwalMessage({
            title: 'Falha ao carregar questionário',
            text: 'Não foi possível carregar o questionário, tente novamente',
            err,
            callback: () => this.props.enableLoading(false)
          });
        });
  }

  nextPage() {
    if (this.state.pageSelected + 1 <= this.state.pages) {
      this.setState(prevState => ({ pageSelected: prevState.pageSelected + 1 }));
    }
  }

  previousPage() {
    if (this.state.pageSelected - 1 >= 0) {
      this.setState(prevState => ({ pageSelected: prevState.pageSelected - 1 }));
    }
  }

  onChangeAlternativa(e, indexQuestao, indexSintese) {
    const { sinteseList } = this.questaoRespostaList[indexQuestao];
    sinteseList[indexSintese].nrTotalRespostas = +e.target.value;
  }

  onTryExit(location) {
    if (this.isDirty()) {
      SwalConfirmacao({
        text: 'Existem alterações não salvas, deseja sair e descarta-las?',
        textConfirm: 'Sair',
        textCancel: 'Voltar',
        config: { closeOnClickOutside: false }
      }).then(res => {
        if (res) {
          this.setState(
            { canLeave: true },
            () => this.state.canLeave && this.props.history.push(location.pathname)
          );
        }
      });
      return false;
    }

    this.setState(
      { canLeave: true },
      () => this.state.canLeave && this.props.history.push(location.pathname)
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Prompt when={!this.state.canLeave} message={this.onTryExit} />

        <div style={{ width: '100%' }}>
          <CardIcon disableHeader>
            <div className={classes.root}>
              <div className={classes.header}>
                <div>{this.questionario.dsTitulo}</div>
                <div>{`Questão ${this.state.pageSelected + 1} de ${this.state.pages + 1}`}</div>
              </div>

              <div className={classes.questoes}>
                <SwipeableViews
                  index={this.state.pageSelected}
                  ignoreNativeScroll={false}
                  slideStyle={{ overflow: 'hidden', paddingBottom: 2 }}
                >
                  {this.questaoRespostaList.map((item, indexQuestao) => (
                    <div
                      key={item.questao.idQuestao}
                      style={{ height: 'calc(100vh - 245px)', overflowY: 'auto' }}
                    >
                      {indexQuestao === this.state.pageSelected && (
                        <>
                          <Grid container>
                            <Grid item xs className={classes.detalhesQuestao}>
                              <span>{`${item.questao.nrOrdem + 1}) ${item.questao.dsTitulo}`}</span>
                              <span style={{ fontStyle: 'italic' }}>{item.questao.dsApoio}</span>
                            </Grid>
                          </Grid>

                          <Grid container>
                            <Grid item xs>
                              <RespostaAlternativas
                                questaoResposta={item}
                                indexQuestao={indexQuestao}
                                onChange={this.onChangeAlternativa}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </div>
                  ))}
                </SwipeableViews>
              </div>

              <div className={classes.botoes}>
                <div>
                  <Button
                    color='primary'
                    onClick={this.previousPage}
                    disabled={this.state.pageSelected - 1 < 0}
                  >
                    <IconSetaEsquerda
                      style={{ color: this.state.pageSelected - 1 < 0 ? '#95989a' : '#2BA69F' }}
                    />
                    Anterior
                  </Button>

                  <Button
                    color='primary'
                    onClick={this.nextPage}
                    disabled={this.state.pageSelected + 1 > this.state.pages}
                  >
                    Próximo
                    <IconSetaDireita
                      style={{
                        color: this.state.pageSelected + 1 > this.state.pages ? '#95989a' : '#2BA69F'
                      }}
                    />
                  </Button>
                </div>

                <ButtonFABMenu
                  actionSalvar={{ onClick: this.submit }}
                  actionCancelar={{ onClick: () => this.props.history.push('/app/respostas') }}
                />
              </div>
            </div>
          </CardIcon>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbarReducer
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ updateToolbar, enableLoading }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(style)(LancarRespostas)));
