//@author Gabriela Farias

import React from "react";
import { InlineDatePicker } from "material-ui-pickers/DatePicker";
import MuiPickersUtilsProvider from "material-ui-pickers/MuiPickersUtilsProvider";
import DateFnsUtils from "@date-io/moment";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import * as moment from "moment";
import "moment/locale/pt-br";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2BA69F"
    }
  },
  overrides: {
    MuiInput: {
      formControl: {
        marginTop: "16px !important"
      },
      underline: {
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottomColor: "#2BA69F"
        }
      }
    },
    MuiFormLabel: {
      root: {
        fontFamily: "Roboto",
        fontSize: "14px !important",
        top: "5px !important"
      }
    },
    MuiInputBase: {
      input: {
        height: "20px !important"
      }
    }
  },
  typography: {
    useNextVariants: true
  }
});

const DatePicker = props => {
  const onChangeDatePicker = value => {
    if (!value) return;
    if (
      typeof value === "string" &&
      value.split("_").join("").length === 10 &&
      moment(value, "DD/MM/YYYY").isValid()
    ) {
      value = moment(value, "DD/MM/YYYY");
      props.onChange(value);
    } else if (typeof value === "object") {
      moment(value).isValid() && props.onChange(value);
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <div onBlur={props.onBlur}>
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
          locale={props.locale || "pt-BR"}
        >
          <InlineDatePicker
            disabled={props.disabled}
            style={{ width: "100%", whiteSpace: "nowrap", fontSize: 14 }}
            keyboard
            label={props.label}
            name={props.name}
            id={props.id}
            value={props.value}
            minDate={props.minDate}
            maxDate={props.maxDate}
            error={props.error && true}
            helperText={props.error ? props.errorText : props.helperText}
            onChange={onChangeDatePicker}
            onInputChange={e => onChangeDatePicker(e.target.value)}
            onBlur={props.onBlur}
            format={props.format || "DD/MM/YYYY"}
            mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
            autoComplete="off"
            {...props.datePickerProps}
          />
        </MuiPickersUtilsProvider>
      </div>
    </MuiThemeProvider>
  );
};

export default DatePicker;
