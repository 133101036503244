import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const TextFieldOwn = props => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#2BA69F'
      }
    },
    typography: {
      useNextVariants: true
    },
    overrides: {
      MuiInputLabel: {
        root: {
          fontSize: '14px !important',
          fontFamily: 'Roboto'
        }
      },
      MuiInput: {
        underline: {
          '&:hover:not($disabled):not($focused):not($error):before': { borderBottomColor: '#2BA69F' }
        },
        formControl: {
          marginTop: '16px !important'
        }
      },
      MuiInputBase: {
        input: {
          height: props.multiline ? '' : '20px !important'
        }
      }
    }
  });

  return (
    <MuiThemeProvider theme={theme}>
      <TextField
        {...props}
        style={{ width: '100%', ...props.style }}
        inputProps={{ autoComplete: 'off', ...props.inputProps }}
        autoComplete='off'
        type={props.type === 'number' || !props.type ? 'text' : props.type}
        onChange={e => {
          if (props.type === 'number' && !isNaN(+e.target.value)) {
            e.target.value = +e.target.value
              .split('e')
              .join('')
              .split('E')
              .join('');
            props.onChange(e);
          } else if (props.type !== 'number') {
            props.onChange(e);
          }
        }}
      />
    </MuiThemeProvider>
  );
};

export default TextFieldOwn;
