/*
    Autor: Gabriela Farias
*/
import React, { Component } from 'react';
import DataTable from './../../components/DataTable/DataTable';
import CardFilters from './../../components/CardFilters/CardFilters';
import GroupButtonGrid from './../../components/GroupButtonGrid/GroupButtonGrid';
import ButtonFAB from './../../components/ButtonFab/ButtonFab';
import iconAdd from './../../assets/images/icon_add.png';
import ImpressoraIcon from '@material-ui/icons/Print';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ButtonGrid from '../../components/ButtonGrid/ButtonGrid';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { updateToolbar, enableLoading } from '../../states/actions/ToolbarActions';
import * as moment from 'moment';
import SwalConfirmacao from '../../utils/functions/SwalConfirmacao/SwalConfirmacao';
import SwalMessage from '../../utils/functions/SwalMessage/SwalMessage';
import FilterUtil from '../filterUtil';
import {
  getAllQuestionarios,
  deleteQuestionario,
  exportQuestionario
} from '../../resources/api/questionario';

const style = theme => ({
  containerTable: {
    display: 'flex',
    height: '100%'
  },
  btnConfirmExport: {
    backgroundColor: '#2BA69F !important',
    '&:hover': {
      backgroundColor: '#2BA69F !important'
    }
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2BA69F'
    }
  },
  typography: {
    useNextVariants: true
  }
});

class Questionarios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataInTable: [],
      dataAppSync: [],
      itemSelect: '',
      disableButtons: true,
      head: [
        { field: 'cliente.nmFantasia', headerName: 'Cliente', col: 3 },
        { field: 'dsTitulo', headerName: 'Título', col: 3 },
        { field: 'inicio', headerName: 'Data inicial', sort: 'desc', col: 2 },
        { field: 'fim', headerName: 'Data final', col: 2 },
        { field: 'situacao', headerName: 'Situação', col: 2 }
      ],
      operationValues: [
        {
          label: 'Cliente',
          field: 'nmCliente',
          type: 'string'
        },
        {
          label: 'Título',
          field: 'dsTitulo',
          type: 'string'
        },
        {
          label: 'Data inicial',
          field: 'inicio',
          type: 'date'
        },
        {
          label: 'Data final',
          field: 'fim',
          type: 'date'
        },
        {
          label: 'Situação',
          field: 'stQuestionario',
          type: 'enum',
          options: [
            { label: 'Aberto', field: 'Aberto', value: '0' },
            { label: 'Em andamento', field: 'Em andamento', value: '1' },
            { label: 'Fechado', field: 'Fechado', value: '2' }
          ]
        }
      ]
    };

    const title = 'Questionários';
    if (this.props.itensState.name !== title) {
      this.props.updateToolbar({ toolbar: title, name: title });
    }
    this.props.enableLoading(true);

    this._isMounted = false;

    this.selectChange = this.selectChange.bind(this);
    this.goAdd = this.goAdd.bind(this);
    this.goEdit = this.goEdit.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.exportQuestionario = this.exportQuestionario.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.onFilter();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onDelete() {
    if (this.state.itemSelect) {
      if (this.state.itemSelect.stQuestionario === 1) {
        SwalMessage({
          title: 'Falha ao excluir questionário',
          text: 'Não é possível excluir questionários em andamento'
        });
        return;
      }

      SwalConfirmacao({ text: 'Deseja mesmo excluir este questionário?' }).then(res => {
        if (res) {
          this.props.enableLoading(true);
          this._isMounted &&
            deleteQuestionario(this.state.itemSelect.idQuestionario)
              .then(() => {
                SwalMessage({ text: 'Questionário excluído', icon: 'success', callback: this.onFilter });
              })
              .catch(err => {
                if (err.response && err.response.data.codigo === 'QUESTIONARIO_COM_RESPOSTAS') {
                  SwalMessage({
                    title: 'Falha ao excluir questionário',
                    text: 'Não é possível excluir este questionário pois ele possui respostas',
                    err
                  });
                } else if (err.response && err.response.data.codigo === 'QUESTAO_RELACIONADA_MARCOS') {
                  SwalMessage({
                    title: 'Falha ao excluir questionário',
                    text:
                      'Não é possível excluir este questionário pois ele está sendo usado como uma variação em outro questionário',
                    err
                  });
                } else {
                  SwalMessage({
                    title: 'Falha ao excluir questionário',
                    text: 'Não foi possível excluir o questionário, tente novamente',
                    err
                  });
                }

                this.props.enableLoading(false);
              });
        }
      });
    }
  }

  selectChange(select) {
    if (select) {
      this.setState({ itemSelect: select, disableButtons: false });
    } else {
      this.setState({ itemSelect: '', disableButtons: true });
    }
  }

  goAdd() {
    this.props.history.push('/app/questionarios/novo');
  }

  goEdit() {
    if (this.state.itemSelect) {
      this.props.history.push('/app/questionarios/editar/' + this.state.itemSelect.idQuestionario);
    }
  }

  exportQuestionario() {
    if (this.state.itemSelect) {
      SwalConfirmacao({
        text: 'Deseja exportar este questionário para PDF?',
        classNameBtnConfirm: this.props.classes.btnConfirmExport
      }).then(res => {
        if (res) {
          this.props.enableLoading(true);
          this._isMounted &&
            exportQuestionario(this.state.itemSelect.idQuestionario)
              .then(res => {
                window.open(res.data, '_parent');
                this.props.enableLoading(false);
              })
              .catch(err => {
                SwalMessage({
                  title: 'Falha ao exportar questionário',
                  text: 'Não foi possível exportar o questionário para PDF, tente novamente',
                  err,
                  callback: () => this.props.enableLoading(false)
                });
              });
        }
      });
    }
  }

  onFilterChanged(dataChips) {
    this.setState({
      dataInTable: FilterUtil.applyFilters(this.state.dataAppSync, dataChips)
    });
  }

  onFilter(dataChips) {
    dataChips = dataChips || [];

    this.props.enableLoading(true);
    this._isMounted &&
      getAllQuestionarios()
        .then(res => {
          const data = res.data.map(item => ({
            ...item,
            situacao: ['Aberto', 'Em andamento', 'Fechado'][item.stQuestionario],
            inicio: moment(item.dtInicio).format('DD/MM/YYYY'),
            fim: moment(item.dtTermino).format('DD/MM/YYYY'),
            nmCliente: item.cliente.nmFantasia
          }));

          this._isMounted &&
            this.setState(
              {
                dataInTable: FilterUtil.applyFilters(data, dataChips),
                dataAppSync: data
              },
              () => this.props.enableLoading(false)
            );
        })
        .catch(err => {
          SwalMessage({
            title: 'Falha ao carregar página',
            text: 'Não foi possível carregar a página, tente novamente',
            err,
            callback: () => {
              this._isMounted && this.setState({ dataAppSync: [] });
              this.props.enableLoading(false);
            }
          });
        });
  }

  render() {
    const { classes } = this.props;
    const pagination = { length: this.state.dataInTable.length, page: 0 };

    return (
      <MuiThemeProvider theme={theme}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <CardFilters
            filtros={this.state.operationValues}
            onFilter={this.onFilter}
            onFilterChanged={this.onFilterChanged}
            usePaddings={false}
            viewKey='questionarios'
          />

          <GroupButtonGrid
            showAdd={false}
            showEdit={true}
            showDelete={true}
            disableEdit={this.state.disableButtons}
            disableDelete={this.state.disableButtons}
            onClickEdit={this.goEdit}
            onClickDelete={this.onDelete}
            customButtons={[
              <ButtonGrid
                show
                disabled={this.state.disableButtons || this.props.loading}
                iconJSX={
                  <div style={{ color: 'white' }}>
                    <ImpressoraIcon color='inherit' />
                  </div>
                }
                color='#463766'
                onClick={this.exportQuestionario}
              />
            ]}
          />

          <div className={classes.containerTable}>
            <DataTable
              data={this.state.dataInTable}
              columns={this.state.head}
              selectChange={this.selectChange}
              showPagination={true}
              pagination={pagination}
              handleChangePage={() => {}}
              handleChangeRowsPerPage={() => {}}
            />
          </div>

          <ButtonFAB icon={iconAdd} positionDefault={true} onClick={this.goAdd} />
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  itensState: state.toolbarReducer,
  loading: state.toolbarReducer.loading,
  chipData: state.cardFiltersReducer.chipData.filter(date => {
    return date.id === 'questionarios';
  })[0].content
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ updateToolbar, enableLoading }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(style)(Questionarios)));
