import iconeUsuario from '../../assets/images/icon_usuarios.png';
import iconeClientes from '../../assets/images/icon_clientes.png';
import iconQuestionarios from '../../assets/images/icon_questionarios.png';
import iconDemostrativo from '../../assets/images/icon_demostrativos.png';
import iconRespostas from '../../assets/images/icon_respostas.png';
import iconAuditoria from '../../assets/images/icon_auditoria.png';

const itensMenu = [
  {
    name: 'Questionários',
    icon: iconQuestionarios,
    selecionado: false,
    route: '/questionarios',
  },
  {
    name: 'Respostas',
    icon: iconRespostas,
    selecionado: false,
    route: '/respostas',
  },
  {
    name: 'Demonstrativos',
    icon: iconDemostrativo,
    selecionado: false,
    route: '/demonstrativos',
  },
  {
    name: 'Auditoria',
    icon: iconAuditoria,
    selecionado: false,
    route: '/auditoria',
  },
  {
    name: 'Clientes',
    icon: iconeClientes,
    selecionado: false,
    route: '/cliente',
  },
  {
    name: 'Usuarios',
    icon: iconeUsuario,
    selecionado: false,
    route: '/usuario',
  },
];

export default itensMenu;
