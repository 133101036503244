import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Login from "../views/Login/Login";
import React from "react";
import AppMainLayout from "../views/AppMainLayout/AppMainLayout";
import RecuperarSenha from "./../views/RecuperarSenha/RecuperarSenha";
import ConfirmarCadastro from "./../views/ConfirmarCadastro/ConfirmarCadastro";

const createRoutes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/app" />} />
      <Route path="/login" component={Login} />
      <Route path="/resetar-senha" component={RecuperarSenha} />
      <Route path="/confirmar-cadastro" component={ConfirmarCadastro} />
      <Route path="/app" component={AppMainLayout} />
    </Switch>
  </BrowserRouter>
);

export default createRoutes;
