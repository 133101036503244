import ActionTypes from '../actionTypes/ClienteActionTypes';

const initialState = {
  buttonFabEnabled: true,
  contatosData: [],
  editandoContato: false,
  atualizandoContato: false,
  contatoSelected: ''
};

const clienteReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_CONTATO_SELECTED_CLIENTE:
      return { ...state, contatoSelected: action.payload };

    case ActionTypes.EXIT_EDIT_CONTATO_CLIENTE:
      return { ...state, editandoContato: false, atualizandoContato: false, contatoSelected: '' };

    case ActionTypes.GO_EDIT_CONTATO_CLIENTE:
      return {
        ...state,
        editandoContato: true,
        atualizandoContato: true,
        contatoSelected: action.payload
      };

    case ActionTypes.GO_ADD_CONTATO_CLIENTE:
      return {
        ...state,
        editandoContato: true,
        atualizandoContato: false,
        contatoSelected: {
          dtContato: null,
          dsTitulo: '',
          dsObservacao: '',
          clienteAnexoList: []
        }
      };

    case ActionTypes.CHANGE_LISTA_CONTATOS_CLIENTE:
      return { ...state, contatosData: action.payload };

    case ActionTypes.SHOW_BUTTON_FAB:
      return { ...state, buttonFabEnabled: action.payload };

    case ActionTypes.DEFAULT_CLIENTE:
      return {
        buttonFabEnabled: true,
        contatosData: [],
        editandoContato: false,
        atualizandoContato: false,
        contatoSelected: ''
      };

    default:
      return state;
  }
};

export default clienteReducer;
