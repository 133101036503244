//@author Gabriela Farias
import classNames from 'classnames';
import React from 'react';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import iconeFABMenuClose from '../../assets/images/icon_fab_menu.png';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import '../../assets/styles/ButtonFABMenu.css';
import iconeFABMenuOpen from '../../assets/images/icon_add.png';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2BA69F',
    },
    secondary: {
      main: '#95989a',
    },
  },
  typography: {
    useNextVariants: true,
  },
});

const styles = (theme) => ({
  controls: {
    margin: theme.spacing.unit * 3,
  },
  exampleWrapper: {
    position: 'relative',
    height: 0,
  },
  radioGroup: {
    margin: `${theme.spacing.unit}px 0`,
  },
  buttonDisabled: {
    backgroundColor: '#95989A',
    cursor: 'default',
  },
  fab: {
    position: 'fixed',
    bottom: 220,
    right: 30,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    zIndex: 1,
    marginBottom: 0,
  },
  buttonsFAB: {
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
});

class ButtonFABMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      hidden: false,
    };

    this.handleClickMenu = this.handleClickMenu.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleClickMenu() {
    this.setState((state) => ({ open: !state.open }));
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleOpen() {
    this.setState({ open: true });
  }

  render() {
    const { classes, disabled = this.props.loading } = this.props;
    const { hidden, open } = this.state;
    const direction = this.props.direction;
    const speedDialClassName = classNames(classes.speedDial);

    /*{ Objeto de configuração
      className,
      onClick,
      label,
      icon,
      iconStyle,
      disabled
    }*/
    let actions = [];
    if (this.props.retireActions) {
      actions = this.props.additionalActions || [];
    } else {
      actions = [
        {
          icon: <CheckIcon style={{ color: '#2BA69F', ...this.props.actionSalvar.iconStyle }} />,
          label: 'Salvar',
          disabled: false,
          ...this.props.actionSalvar,
        },
        {
          icon: <CloseIcon style={{ color: '#2BA69F', ...this.props.actionCancelar.iconStyle }} />,
          label: 'Cancelar',
          disabled: false,
          ...this.props.actionCancelar,
        },
      ].concat(this.props.additionalActions || []);
    }

    return (
      <div className={classNames(classes.fab, this.props.rootClassName)} style={this.props.rootStyle}>
        <div className={classes.exampleWrapper}>
          <MuiThemeProvider theme={theme}>
            <SpeedDial
              ariaLabel='SpeedDial example'
              className={speedDialClassName}
              hidden={hidden}
              icon={
                <SpeedDialIcon
                  icon={<img src={iconeFABMenuClose} alt='' style={{ marginTop: '5px' }} />}
                  openIcon={<img src={iconeFABMenuOpen} alt='' id='openIcone' />}
                />
              }
              onBlur={this.handleClose}
              onClick={this.handleClickMenu}
              onClose={this.handleClose}
              onFocus={this.handleOpen}
              onMouseEnter={this.handleOpen}
              onMouseLeave={this.handleClose}
              open={open && !disabled}
              direction={direction}
              style={{ width: 56 }}
              ButtonProps={{ color: !disabled ? 'primary' : 'secondary' }}
            >
              {actions.map((action, i) => (
                <SpeedDialAction
                  ButtonProps={{ disableRipple: action.disabled, disableFocusRipple: action.disabled }}
                  key={i}
                  icon={action.icon}
                  classes={{
                    button: action.disabled
                      ? classes.buttonDisabled
                      : classNames(classes.buttonsFAB, action.className),
                    buttonClosed: action.disabled
                      ? classes.buttonDisabled
                      : classNames(classes.buttonsFAB, action.className),
                  }}
                  tooltipTitle={action.disabled ? '' : action.label}
                  onClick={(e) => {
                    if (!action.disabled) {
                      this.handleClickMenu();
                      action.onClick(e);
                    }
                  }}
                />
              ))}
            </SpeedDial>
          </MuiThemeProvider>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.toolbarReducer.loading,
});

export default connect(mapStateToProps)(withStyles(styles)(ButtonFABMenu));
