import React from 'react';
import swal from '@sweetalert/with-react';
import './SwalConfirmacao.css';
import classNames from 'classnames';

export default ({
  text,
  titulo = 'Atenção',
  textConfirm = 'CONFIRMAR',
  textCancel = 'CANCELAR',
  classNameBtnConfirm,
  classNameBtnCancel,
  containerMsgStyle,
  config
}) => {
  const swalBody = (
    <div
      style={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        fontFamily: 'Roboto',
        textAlign: 'left',
        fontWeight: 'bold',
        ...containerMsgStyle
      }}
    >
      <span style={{ fontSize: 24, color: 'rgba(0,0,0,0.6)', marginBottom: 20 }}>{titulo}</span>
      <div style={{ width: '100%', fontSize: 20, color: 'rgba(0,0,0,0.7)' }}>{text}</div>
    </div>
  );

  return swal({
    content: swalBody,
    className: 'swal-stretched',
    dangerMode: true,
    buttons: {
      cancel: {
        text: textCancel,
        value: false,
        closeModal: true,
        visible: true,
        className: classNames('swal-botao-cancel', classNameBtnCancel)
      },
      confirm: {
        text: textConfirm,
        value: true,
        className: classNames('swal-botao-confirm', classNameBtnConfirm)
      }
    },
    ...config
  });
};
