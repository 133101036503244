import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Header from './Header';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { withRouter } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import { getDemonstrativoById } from '../../resources/api/demonstrativo';
import SwalMessage from '../../utils/functions/SwalMessage/SwalMessage';
import { getQuestionarioById } from '../../resources/api/questionario';
import { Map, GoogleApiWrapper, HeatMap } from 'google-maps-react';
import * as moment from 'moment';
import Resultado from './charts/Resultado';
import Periodo from './charts/Periodo';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const style = theme => ({
  card: {
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: '#FFFFFF',
    paddingTop: 30,
    paddingBottom: 30,
    paddingRight: 40,
    paddingLeft: 40,
    flexDirection: 'column'
  },
  cardText: {
    display: 'flex',
    height: 50,
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: 30
  },
  title: {
    fontSize: 18,
    color: '#666666',
    fontWeight: 400,
    display: 'flex',
    justifyContent: 'space-between'
  },
  description: {
    fontSize: 16,
    color: '#666666',
    display: 'flex',
    justifyContent: 'space-between'
  },
  iconClose: {
    height: 24,
    width: 24,
    cursor: 'pointer'
  },
  cardGraficos: {
    padding: 1,
    borderRadius: 5,
    height: 'calc(50% - 15px)',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  cardHalfGraph: {
    padding: 1,
    border: '0.5px solid #666666',
    borderRadius: 5,
    height: '100%',
    width: 'calc(50% - 15px)'
  },
  cardPositions: {
    height: 'calc(100% - 140px)',
    position: 'absolute',
    width: 'calc(100% - 80px)',
    marginTop: 80,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  icon: {
    height: 24,
    width: 24,
    color: '#707070',
    cursor: 'pointer'
  },
  divButtonLeft: {
    cursor: 'pointer',
    height: '100%',
    width: 40,
    marginLeft: -40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    '&:hover': {
      backgroundColor: 'rgb(245, 245, 245, 0.5)'
    }
  },
  divButtonRight: {
    cursor: 'pointer',
    height: '100%',
    width: 40,
    right: -42,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    '&:hover': {
      backgroundColor: 'rgb(245, 245, 245, 0.5)'
    }
  },
  preferenciaRegional: {
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 18,
    color: '#666666',
    fontWeight: 400
  },
  preferencia: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 18,
    color: '#666666',
    fontWeight: 400,
    paddingRight: 12,
    paddingLeft: 12,
    paddingTop: 12
  },
  buttonPeriodo: {
    width: 55,
    height: 24,
    borderRadius: 5,
    backgroundColor: '#CCCCCC',
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonsVariacoes: {
    display: 'flex'
  },
  cardCarregando: {
    height: '80%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: '#757575',
    fontSize: 20
  },
  image: {
    width: 300,
    marginBottom: 30
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2BA69F'
    }
  },
  typography: {
    useNextVariants: true
  }
});

class Analise extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listAnalises: [],
      loading: true,
      indexVariacao: 0,
      bounds: {},
      variacaoX: [],
      variacaoY: [],
      listGeolocationList: [],
      periodoList: [],
      diarioList: [],
      idAtual: 0,
      idsAnalises: [],
      title: '',
      description: '',
      pageInitial: 0,
      pageFinal: 0,
      dataAppSync: [],
      graphAtual: {
        analiseItem: {
          dsTitulo: ''
        },
        geolocationList: []
      }
    };

    this.onClickClose = this.onClickClose.bind(this);
    this.onClickLeft = this.onClickLeft.bind(this);
    this.onClickRight = this.onClickRight.bind(this);
    this.getItem = this.getItem.bind(this);
    this.clickItemGraph = this.clickItemGraph.bind(this);
    this.changeLeft = this.changeLeft.bind(this);
    this.changeRight = this.changeRight.bind(this);
    this.backPeriodo = this.backPeriodo.bind(this);
    this.clickItemPeriodo = this.clickItemPeriodo.bind(this);
  }

  componentDidMount() {
    getQuestionarioById(this.props.match.params.id).then(res => {
      var listAnalises = [];
      const idsAnalises = res.data.analiseItemList.map(doc => {
        listAnalises.push(doc);
        return doc.idAnaliseItem;
      });

      this.setState({
        listAnalises: listAnalises,
        idsAnalises: idsAnalises,
        idAtual: idsAnalises[0],
        pageFinal: idsAnalises.length,
        pageInitial: idsAnalises.length > 0 ? 1 : 0
      });

      if (idsAnalises.length > 0) {
        this.getItem(idsAnalises[0]);
      }
    });
  }

  onClickRight() {
    if (this.state.pageInitial !== this.state.pageFinal) {
      this.setState(
        {
          listGeolocationList: []
        },
        () => this.changeRight()
      );
    }
  }

  changeRight() {
    const position = this.state.idsAnalises.indexOf(this.state.idAtual);

    this.setState({
      idAtual: this.state.idsAnalises[position + 1],
      pageInitial: this.state.pageInitial + 1
    });

    const validation = this.state.dataAppSync.find(doc => {
      return doc.analiseItem.idAnaliseItem === this.state.idsAnalises[position + 1];
    });

    if (validation === undefined) {
      this.getItem(this.state.idsAnalises[position + 1]);
    } else {
      this.setState({
        indexVariacao: 0,
        periodoList: this.state.dataAppSync[position + 1].periodoListFormat,
        graphAtual: this.state.dataAppSync[position + 1],
        listGeolocationList: this.state.dataAppSync[position + 1].geolocationList
      });
    }
  }

  changeLeft() {
    const position = this.state.idsAnalises.indexOf(this.state.idAtual);

    this.setState({
      indexVariacao: 0,
      periodoList: this.state.dataAppSync[position - 1].periodoListFormat,
      graphAtual: this.state.dataAppSync[position - 1],
      idAtual: this.state.idsAnalises[position - 1],
      pageInitial: this.state.pageInitial - 1,
      listGeolocationList: this.state.dataAppSync[position - 1].geolocationList
    });
  }

  onClickLeft() {
    if (this.state.pageInitial !== 1) {
      this.setState(
        {
          listGeolocationList: []
        },
        () => this.changeLeft()
      );
    }
  }

  onClickClose() {
    this.props.history.push('/app/demonstrativos');
  }

  getItem(idAnalise) {
    const data = this.state.listAnalises.find(doc => {
      return doc.idAnaliseItem === idAnalise;
    });

    this.setState({
      listGeolocationList: [],
      loading: true,
      graphAtual: {
        analiseItem: data
      }
    });

    getDemonstrativoById(idAnalise)
      .then(res => {
        var item = res.data;
        var categoria = {};
        var totalVotos = {};
        var codigo = [];
        let percents = [];
        var head = [];
        var headData = [];
        var groupCategoria = [];
        var groupTitles = [];

        if (item.analiseItem.tpItem === 1 || item.analiseItem.tpItem === 2) {
          if (item.analiseItem.tpVisualizacao === 1) {
            item.resultadoList.forEach(doc => {
              const allCategorias = groupCategoria.find(id => {
                return doc.dsGrupo === id;
              });

              if (allCategorias === undefined) {
                groupCategoria.push(doc.dsGrupo);
              }
            });

            var dataItens = [];
            var titles = [];
            groupCategoria.forEach(group => {
              var itens = { categoria: group };
              titles = [];
              item.resultadoList.forEach(doc => {
                if (doc.dsGrupo === group) {
                  itens[doc.dsCategoria] = {};
                  titles.push({
                    categoria: doc.dsCategoria,
                    titulo: doc.dsTitulo
                  });
                  itens.nrCodigo = doc.nrCodigo;

                  let valueSplitted =
                    (doc.vlPorcentagem + '').split('.').length > 1
                      ? (doc.vlPorcentagem + '').split('.')
                      : [`${doc.vlPorcentagem}`, '0'];

                  let values = `${doc.nrTotalVotos} -
                   ${valueSplitted[0]},${valueSplitted[1].substr(0, 1)}%`;

                  itens[doc.dsCategoria][doc.dsTitulo] = values;

                  let pcTotal =
                    (doc.pcVotosAlternativa + '').split('.').length > 1
                      ? (doc.pcVotosAlternativa + '').split('.')
                      : [`${doc.pcVotosAlternativa}`, '0'];

                  itens.total = pcTotal + '%';
                }
              });
              dataItens.push(itens);
            });

            categoria = dataItens;
            totalVotos = titles;

            head.push({
              field: 'categoria',
              headerName: 'Alternativa',
              suppressSizeToFit: true
            });
            head.push({
              field: 'total',
              headerName: 'Total',
              suppressSizeToFit: true
            });

            if (item.analiseItem.tpItem === 1) {
              item.resultadoList.forEach(doc => {
                const allCategorias = groupTitles.find(id => {
                  return doc.dsTitulo === id;
                });

                if (allCategorias === undefined) {
                  groupTitles.push(doc.dsTitulo);
                }
              });

              groupTitles.forEach(title => {
                var children = [];
                totalVotos.forEach(doc => {
                  var validation = totalVotos.indexOf(doc) + 1 === totalVotos.length;

                  if (title === doc.titulo) {
                    children.push({
                      minWidth: 150,
                      suppressSizeToFit: validation ? false : true,
                      field: doc.categoria + '.' + doc.titulo,
                      headerName: doc.categoria
                    });
                  }
                });

                head.push({
                  headerName: title,
                  children: children
                });
              });
            } else {
              totalVotos.forEach(doc => {
                head.push({
                  field: doc.categoria + '.' + doc.titulo,
                  headerName: doc.categoria
                });
              });
            }
          } else {
            item.resultadoList.forEach(doc => {
              const allCategorias = groupCategoria.find(id => {
                return doc.dsCategoria === id;
              });

              if (allCategorias === undefined) {
                groupCategoria.push(doc.dsCategoria);
              }
            });

            var itens = [];
            groupCategoria.forEach(group => {
              var categoria = [];
              var total = [];
              let percents = [];
              item.resultadoList.forEach(doc => {
                if (group === doc.dsCategoria) {
                  categoria.push(doc.dsGrupo);
                  total.push(item.analiseItem.tpGrafico === 2 ? doc.nrTotalVotos : doc.vlPorcentagem);
                  codigo.push(doc.nrCodigo);

                  let valueSplitted =
                    (doc.vlPorcentagem + '').split('.').length > 1
                      ? (doc.vlPorcentagem + '').split('.')
                      : [`${doc.vlPorcentagem}`, '0'];
                  let percent = valueSplitted[0] + ',' + valueSplitted[1].substr(0, 1) + '%';

                  percents.push(`${percent} - ${doc.nrTotalVotos}`);
                }
              });
              itens.push({
                categoria: categoria,
                totalVotos: total,
                percents
              });
            });
            categoria = groupCategoria;
            totalVotos = itens;
          }
        } else {
          headData = [
            {
              field: 'dsCategoria',
              headerName: 'Alternativa',
              col: 6
            },
            { field: 'nrTotalVotos', headerName: 'Total', col: 3 },
            {
              valueGetter: args => {
                let valueSplitted =
                  (args.node.data.vlPorcentagem + '').split('.').length > 1
                    ? (args.node.data.vlPorcentagem + '').split('.')
                    : [`${args.node.data.vlPorcentagem}`, '0'];
                return valueSplitted[0] + ',' + valueSplitted[1].substr(0, 1) + '%';
              },
              headerName: 'Porcentagem',
              col: 3
            }
          ];

          categoria = item.resultadoList.map(cat => {
            return cat.dsCategoria;
          });
          totalVotos = item.resultadoList.map(cat => {
            return item.analiseItem.tpGrafico === 2 ? cat.nrTotalVotos : cat.vlPorcentagem;
          });
          codigo = item.resultadoList.map(cat => {
            return cat.nrCodigo;
          });
          percents = item.resultadoList.map(cat => {
            let valueSplitted =
              (cat.vlPorcentagem + '').split('.').length > 1
                ? (cat.vlPorcentagem + '').split('.')
                : [`${cat.vlPorcentagem}`, '0'];

            let percent = valueSplitted[0] + ',' + valueSplitted[1].substr(0, 1) + '%';

            return `${percent} - ${cat.nrTotalVotos}`;
          });
        }

        var data = {
          ...item,
          headComposto: head,
          headData: headData,
          item: item.analiseItem.tpItem,
          orientacao: item.analiseItem.tpItem === 2 ? 'v' : ['h', 'v'][item.analiseItem.tpOrientacao],
          type: ['linha', 'bar', 'pie'][item.analiseItem.tpGrafico],
          categoria: categoria,
          totalVotos: totalVotos,
          codigo: codigo,
          percents
        };

        var diarioList = [];
        var nrTotalVotos = [];
        var dhRespostas = [];

        if (item.periodoList !== undefined) {
          item.periodoList.forEach(periodo => {
            diarioList.push(periodo.diarioList);
            nrTotalVotos.push(periodo.nrTotalVotos);
            dhRespostas.push(moment(periodo.dhResposta).format('DD/MM'));
          });
        }

        var periodoList = {
          diarioList: diarioList,
          nrTotalVotos: nrTotalVotos,
          dhRespostas: dhRespostas
        };

        data.periodoListFormat = periodoList;
        var dataAppSync = this.state.dataAppSync;
        dataAppSync.push(data);

        this.setState(
          {
            indexVariacao: 0,
            periodoList: periodoList,
            graphAtual: data,
            dataAppSync: dataAppSync,
            listGeolocationList: data.geolocationList || [],
            loading: false
          },
          () => {
            if (data.geolocationList !== undefined) {
              var bounds = new this.props.google.maps.LatLngBounds();
              for (var i = 0; i < data.geolocationList.length; i++) {
                var item = {
                  lat: parseFloat(data.geolocationList[i].dsLatitude),
                  lng: parseFloat(data.geolocationList[i].dsLongitude)
                };
                bounds.extend(item);
              }

              this.setState({
                bounds: bounds
              });
            }
          }
        );
      })
      .catch(err => {
        SwalMessage({
          title: 'Falha ao carregar página',
          text: 'Não foi possível carregar a página, tente novamente',
          err,
          callback: () => {
            this.setState({ dataAppSync: [], pageInitial: 0, pageFinal: 0, loading: false });
          }
        });
      });
  }

  clickItemGraph(event) {
    this.setState({
      listGeolocationList: []
    });

    var metadados = [];

    if (this.state.graphAtual.analiseItem.tpVisualizacao === 1) {
      if (event !== undefined) {
        metadados = this.state.graphAtual.metadadosList.find(doc => {
          return doc.nrCodigo === event.nrCodigo;
        });
        if (metadados === undefined) {
          metadados = [];
        }
      } else {
        metadados = this.state.graphAtual;
      }
    } else if (this.state.graphAtual.type === 'pie') {
      const item = this.state.graphAtual.resultadoList.find(doc => {
        return event.points[0].label === doc.dsCategoria;
      });

      metadados = this.state.graphAtual.metadadosList.find(doc => {
        return doc.nrCodigo === item.nrCodigo;
      });
    } else {
      metadados = this.state.graphAtual.metadadosList.find(doc => {
        return doc.nrCodigo === this.state.graphAtual.codigo[event.points[0].pointIndex];
      });
    }

    var diarioList = [];
    var nrTotalVotos = [];
    var dhRespostas = [];

    if (metadados !== undefined) {
      if (metadados.periodoList !== undefined) {
        metadados.periodoList.forEach(periodo => {
          diarioList.push(periodo.diarioList);
          nrTotalVotos.push(periodo.nrTotalVotos);
          dhRespostas.push(moment(periodo.dhResposta).format('DD/MM'));
        });
      }

      var periodoList = {
        diarioList: diarioList,
        nrTotalVotos: nrTotalVotos,
        dhRespostas: dhRespostas
      };

      this.setState({
        indexVariacao: 0,
        periodoList: periodoList,
        listGeolocationList: metadados.geolocationList !== undefined ? metadados.geolocationList : []
      });
    }
  }

  backPeriodo() {
    this.setState({
      indexVariacao: 0
    });
  }

  clickItemPeriodo(event) {
    if (this.state.indexVariacao === 0) {
      var metadados = this.state.periodoList.diarioList[event.points[0].pointIndex];

      var nrTotalVotos = [];
      var dhRespostas = [];
      metadados.forEach(doc => {
        dhRespostas.push(moment(doc.dhResposta).utc().format('HH:mm'));
        nrTotalVotos.push(doc.nrTotalVotos);
      });

      var horaList = {
        nrTotalVotos: nrTotalVotos,
        dhRespostas: dhRespostas
      };

      this.setState({
        horaList: horaList,
        indexVariacao: 1
      });
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Modal style={{ width: '100%', height: '100%' }} open={true}>
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            backgroundColor: '#FFFFFF'
          }}
        >
          <div style={{ width: '100%', height: '100%', position: 'absolute' }}>
            <div className={classes.card}>
              <Header
                pageInitial={this.state.pageInitial}
                pageFinal={this.state.pageFinal}
                description={this.state.graphAtual.analiseItem.dsApoio}
                title={this.state.title + this.state.graphAtual.analiseItem.dsTitulo}
                onClickClose={!this.state.loading ? this.onClickClose : () => {}}
              />

              {this.state.loading && (
                <MuiThemeProvider theme={theme}>
                  <div className={classes.cardCarregando}>
                    <CircularProgress />
                    <div style={{ marginTop: 20 }}>Carregando...</div>
                  </div>
                </MuiThemeProvider>
              )}
              <div className={classes.cardPositions}>
                <div
                  className={classes.divButtonLeft}
                  onClick={!this.state.loading ? this.onClickLeft : () => {}}
                >
                  <ChevronLeft className={classes.icon} />
                </div>
                {!this.state.loading && (
                  <div className={classes.cardGraficos} style={{ border: '0.5px solid #666666' }}>
                    <Resultado clickItemGraph={this.clickItemGraph} graphAtual={this.state.graphAtual} />
                  </div>
                )}
                {!this.state.loading && (
                  <div className={classes.cardGraficos}>
                    <div className={classes.cardHalfGraph}>
                      <div className={classes.preferencia}>
                        <div>Variação diária</div>
                        <div className={classes.buttonsVariacoes}>
                          <div
                            className={classes.buttonPeriodo}
                            style={{
                              backgroundColor: this.state.indexVariacao === 0 ? '#124268' : '#CCCCCC',
                              color: this.state.indexVariacao === 0 ? '#FFFFFF' : '#000000',
                              marginRight: 15,
                              cursor: 'pointer'
                            }}
                            onClick={this.backPeriodo}
                          >
                            Período
                          </div>
                          <div
                            style={{
                              backgroundColor: this.state.indexVariacao === 1 ? '#124268' : '#CCCCCC',
                              color: this.state.indexVariacao === 1 ? '#FFFFFF' : '#000000'
                            }}
                            className={classes.buttonPeriodo}
                          >
                            Hora
                          </div>
                        </div>
                      </div>
                      <Periodo
                        clickItemPeriodo={this.clickItemPeriodo}
                        indexVariacao={this.state.indexVariacao}
                        horaList={this.state.horaList}
                        periodoList={this.state.periodoList}
                      />
                    </div>
                    <div className={classes.cardHalfGraph}>
                      <div className={classes.preferenciaRegional}>Preferência regional</div>
                      <Map
                        mapTypeId='satellite'
                        streetViewControl={false}
                        google={this.props.google}
                        zoom={14}
                        style={{
                          width: 'calc(50% - 15px)',
                          height: 'calc(50% - 55px)',
                          position: 'absolute'
                        }}
                        bounds={this.state.bounds}
                      >
                        {this.state.listGeolocationList !== undefined &&
                          this.state.listGeolocationList.length > 0 && (
                            <HeatMap
                              positions={this.state.listGeolocationList.map(doc => {
                                return {
                                  lat: parseFloat(doc.dsLatitude),
                                  lng: parseFloat(doc.dsLongitude)
                                };
                              })}
                              opacity={1}
                              radius={20}
                            />
                          )}
                      </Map>
                    </div>
                  </div>
                )}
                <div
                  className={classes.divButtonRight}
                  onClick={!this.state.loading ? this.onClickRight : () => {}}
                >
                  <ChevronRight className={classes.icon} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDl3OdaYZy-cmOkYlsYEjXgoDhZvwKsycc',
  libraries: ['visualization']
})(withRouter(withStyles(style)(Analise)));
