const CadastroQuestionarioActionTypes = {
  CHANGE_REGRA_SELECTED: 'CHANGE_REGRA_SELECTED',
  EXIT_EDIT_REGRA: 'EXIT_EDIT_REGRA',
  GO_ADD_REGRA: 'GO_ADD_REGRA',
  GO_EDIT_REGRA: 'GO_EDIT_REGRA',
  CHANGE_LISTA_REGRAS: 'CHANGE_LISTA_REGRAS',
  CHANGE_AGRUPAR_RESPOSTAS_SELECTEDS: 'CHANGE_AGRUPAR_RESPOSTAS_SELECTEDS',
  CHANGE_AGRUPAR_RESPOSTAS_DATA: 'CHANGE_AGRUPAR_RESPOSTAS_DATA',
  CHANGE_MARCOS_REGRAS: 'CHANGE_MARCOS_REGRAS',
  DEFAULT_REGRAS: 'DEFAULT_REGRAS',

  CHANGE_ENTREVISTADOR_SELECTED: 'CHANGE_ENTREVISTADOR_SELECTED',
  EXIT_EDIT_ENTREVISTADOR: 'EXIT_EDIT_ENTREVISTADOR',
  GO_ADD_ENTREVISTADOR: 'GO_ADD_ENTREVISTADOR',
  GO_EDIT_ENTREVISTADOR: 'GO_EDIT_ENTREVISTADOR',
  CHANGE_LISTA_ENTREVISTADORES: 'CHANGE_LISTA_ENTREVISTADORES',
  DEFAULT_ENTREVISTADORES: 'DEFAULT_ENTREVISTADORES',

  CHANGE_PERGUNTA_SELECTED: 'CHANGE_PERGUNTA_SELECTED',
  CHANGE_IMPORTAR_PERGUNTA: 'CHANGE_IMPORTAR_PERGUNTA',
  EXIT_EDIT_PERGUNTA: 'EXIT_EDIT_PERGUNTA',
  GO_ADD_PERGUNTA: 'GO_ADD_PERGUNTA',
  GO_EDIT_PERGUNTA: 'GO_EDIT_PERGUNTA',
  GO_IMPORTAR_PERGUNTAS: 'GO_IMPORTAR_PERGUNTAS',
  CHANGE_LISTA_PERGUNTAS: 'CHANGE_LISTA_PERGUNTAS',
  CHANGE_CONFIG_FECHADA: 'CHANGE_CONFIG_FECHADA',
  CHANGE_CONFIG_MULTIPLA_ESCOLHA: 'CHANGE_CONFIG_MULTIPLA_ESCOLHA',
  CHANGE_CONFIG_ESCALA_LINEAR: 'CHANGE_CONFIG_ESCALA_LINEAR',
  CHANGE_OPCOES_PERGUNTAS: 'CHANGE_OPCOES_PERGUNTAS',
  SYNC_ST_PERGUNTA: 'SYNC_ST_PERGUNTA',
  SET_INITIAL_ID_ALTERNATIVA: 'SET_INITIAL_ID_ALTERNATIVA',
  NEW_ID_ALTERNATIVA: 'NEW_ID_ALTERNATIVA',
  DEFAULT_PERGUNTAS: 'DEFAULT_PERGUNTAS',

  CHANGE_EXCECAO_SELECTED: 'CHANGE_EXCECAO_SELECTED',
  GO_EDIT_EXCECAO: 'GO_EDIT_EXCECAO',
  EXIT_EDIT_EXCECAO: 'EXIT_EDIT_EXCECAO',
  DEFAULT_EXCECOES: 'DEFAULT_EXCECOES',

  SHOW_BUTTON_FAB: 'SHOW_BUTTON_FAB',
  HIDDEN_BUTTON_FAB: 'HIDDEN_BUTTON_FAB',
  CHANGE_STATUS_QUESTIONARIO: 'CHANGE_STATUS_QUESTIONARIO',
  CHANGE_CLIENTE_QUESTIONARIO: 'CHANGE_CLIENTE_QUESTIONARIO',
  CHANGE_ATUALIZANDO_QUESTIONARIO: 'CHANGE_ATUALIZANDO_QUESTIONARIO',
  SET_ID_QUESTIONARIO: 'SET_ID_QUESTIONARIO',
  DEFAULT_QUESTIONARIO: 'DEFAULT_QUESTIONARIO'
};

export default CadastroQuestionarioActionTypes;
