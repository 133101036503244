/*
    Autor: Bruno Eduardo
*/

import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2BA69F'
    }
  },
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiInput: {
      underline: {
        '&:hover:not($disabled):not($focused):not($error):before': { borderBottomColor: '#2BA69F' }
      },
      formControl: {
        marginTop: '16px !important'
      }
    },
    MuiInputBase: {
      root: {
        fontSize: 14,
        fontFamily: 'Roboto'
      },
      input: {
        height: '20px !important'
      }
    }
  }
});

const FormSelectOwn = props => {
  const {
    error = false,
    name = 'select' + Math.random() * 1000000000,
    label = '',
    value,
    onChange,
    onBlur,
    options,
    optionLabel,
    optionValue,
    displayEmpty = true,
    helperText = '',
    errorText = '',
    disabled = false,
    selectProps,
    labelProps,
    formControlProps,
    helperTextProps,
    optionProps,
    disableOptionIf = () => false
  } = props;

  return (
    <MuiThemeProvider theme={theme}>
      <FormControl error={error && true} style={{ width: '100%' }} {...formControlProps}>
        <InputLabel htmlFor={name + '-input'} error={error && true} {...labelProps}>
          {label}
        </InputLabel>
        <Select
          id={name}
          name={name}
          SelectDisplayProps={{ style: { backgroundColor: 'transparent' } }}
          value={value + ''}
          displayEmpty={displayEmpty}
          onChange={onChange}
          error={error && true}
          inputProps={{ name, id: name + '-input' }}
          onBlur={onBlur}
          disabled={disabled && true}
          {...selectProps}
        >
          {options.map((option, i) => (
            <MenuItem
              value={optionValue(option, i) + ''}
              disabled={disableOptionIf(option, i)}
              key={i}
              {...optionProps}
            >
              {optionLabel(option, i) + ''}
            </MenuItem>
          ))}
        </Select>
        {!error && <FormHelperText {...helperTextProps}>{helperText}</FormHelperText>}
        {error && <FormHelperText {...helperTextProps}>{errorText}</FormHelperText>}
      </FormControl>
    </MuiThemeProvider>
  );
};

FormSelectOwn.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  optionLabel: PropTypes.func.isRequired,
  optionValue: PropTypes.func.isRequired
};

export default FormSelectOwn;
