import React, { Component } from 'react';
import Plot from 'react-plotly.js';
import DataTable from './../../../components/DataTable/DataTable';

class Resultado extends Component {
  constructor(props) {
    super(props);

    this.state = {
      head: [
        {
          field: 'dsCategoria',
          headerName: 'Alternativa',
          sort: 'asc',
          col: 6
        },
        { field: 'nrTotalVotos', headerName: 'Total', col: 6 }
      ]
    };
  }

  render() {
    const { graphAtual } = this.props;
    const orientation = graphAtual.orientacao === 'h';
    var data = [];

    if (graphAtual.item === 0 && graphAtual.analiseItem.tpVisualizacao === 0) {
      if (graphAtual.type === 'pie') {
        data = [
          {
            info: graphAtual,
            hoverlabel: { font: { color: '#FFF' } },
            values: graphAtual.totalVotos,
            labels: graphAtual.categoria,
            id: graphAtual.codigo,
            type: graphAtual.type,
            name: '',
            marker: {
              opacity: 0.8
            }
          }
        ];
      } else {
        data = [
          {
            id: graphAtual.codigo,
            hoverinfo: 'none',
            hovertemplate: orientation ? '%{customdata} : %{y}' : '%{customdata} : %{x}',
            hoverlabel: { font: { color: '#FFF' } },
            x: orientation ? graphAtual.totalVotos : graphAtual.categoria,
            y: orientation ? graphAtual.categoria : graphAtual.totalVotos,
            customdata: graphAtual.percents,
            type: graphAtual.type,
            marker: {
              color: '#0000FF',
              opacity: 0.8
            },
            orientation: graphAtual.orientacao,
            name: ''
          }
        ];
      }
    } else if (
      (graphAtual.item === 1 || graphAtual.item === 2) &&
      graphAtual.analiseItem.tpVisualizacao === 0
    ) {
      data = [];
      graphAtual.totalVotos.forEach(doc => {
        data.push({
          marker: {
            opacity: 0.8
          },
          id: doc.codigo,
          hoverinfo: 'x',
          hovertemplate: orientation ? '%{customdata} : %{y}' : '%{customdata} : %{x}',
          hoverlabel: { font: { color: '#FFF' } },
          x: orientation ? doc.totalVotos : doc.categoria,
          y: orientation ? doc.categoria : doc.totalVotos,
          customdata: doc.percents,
          name: graphAtual.categoria[graphAtual.totalVotos.indexOf(doc)],
          type: graphAtual.type,
          orientation: graphAtual.orientacao
        });
      });
    }

    return graphAtual.analiseItem.tpVisualizacao === 0 ? (
      <Plot
        hovermode={false}
        onClick={this.props.clickItemGraph}
        config={{ responsive: true, displayModeBar: false }}
        layout={{
          autosize: true,
          clickmode: 'event',
          hovermode: 'closest',
          margin: {
            l: 30,
            r: 20,
            b: 30,
            t: 15
          },
          barmode: 'group',
          xaxis: {
            automargin: true,
            fixedrange: true
          },
          yaxis: {
            automargin: true,
            fixedrange: true
          }
        }}
        data={data}
        style={{ width: '100%', height: '100%' }}
      />
    ) : (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <DataTable
          key={graphAtual.analiseItem.idAnaliseItem}
          noBox={true}
          data={graphAtual.item === 0 ? graphAtual.resultadoList : graphAtual.categoria}
          columns={(graphAtual.item === 0 ? graphAtual.headData : graphAtual.headComposto) || []}
          selectChange={this.props.clickItemGraph}
          showPagination={false}
          handleChangePage={() => {}}
          handleChangeRowsPerPage={() => {}}
        />
      </div>
    );
  }
}

export default Resultado;
