//@author Gabriela Farias
import React from 'react';
import CabecarioLogin from '../../components/CabecalhoLogin/CabecalhoLogin';
import ComponentesLogin from '../../components/TelaLogin/TelaLogin';

const imgFundo = require('../../assets/images/icon_fundo.png');

const styles = {
  divStyle: {
    display: 'relative',
    marginTop: '-200px'
  },
  cabStyle: {
    position: 'relative',
    left: 20,
    top: 110
  },
  backStyle: {
    height: '100vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${imgFundo})`,
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

const Login = props => {
  return (
    <div style={styles.backStyle}>
      <div style={styles.divStyle}>
        <div style={styles.cabStyle}>
          <CabecarioLogin />
        </div>
        <div>
          <ComponentesLogin />
        </div>
      </div>
    </div>
  );
};

export default Login;
